import tw from 'twin.macro';

export const CardWrapper = tw.div`
  w-full
  bg-white
  text-sm
  font-normal
  text-gray-800
`;

export const ButtonWrapper = tw.div`
  justify-between
  m-3
  flex-row
  lg:flex
`;

export const PrimaryButton = tw.button`
  border-purple-500
  bg-purple-500
  inline-block
  mt-3
  px-9
  py-1
  rounded
  text-sm
  text-white
  hover:bg-purple-700
  rtl:ml-4
`;

export const SecondaryButton = tw(PrimaryButton)`
  bg-white
  border-2
  border-gray-300
  text-gray-700
  hover:bg-gray-200
`;

export const CalendarButton = tw(PrimaryButton)`
  bg-white
  border-2
  border-gray-300
  flex
  items-center
  px-2
  space-x-2
  text-gray-700
  hover:bg-gray-200
`;
