import IconChart from './IconChart';
import IconHelp from './IconHelp';
import IconHouse from './IconHouse';
import IconSettings from './IconSettings';
import IconFire from './IconFIre';
import IconBadge from './IconBadge';
import IconFileUpload from './IconFileUpload';

import { TMenuIcon, TSvgComponentProps } from '../../types';

type TMenuIcons = Record<TMenuIcon, (props: TSvgComponentProps) => JSX.Element>;

/**
 * Menu Icons are exported as default export and consists of the icons
 * components which needs to be shown in the AppNavMenu feature.
 */
const menuIcons: TMenuIcons = {
  settings: IconSettings,
  chart: IconChart,
  help: IconHelp,
  house: IconHouse,
  fire: IconFire,
  badge: IconBadge,
  fileUpload: IconFileUpload,
};

/**
 * Export of User Info Icon which is used in the bottom of AppNavMenu.
 * @deprecated - To be replaced with another icon later.
 */
export { default as IconUserInfo } from './IconStoreSolid';

export default menuIcons;
