import {
  IAccountStatementUrlOptions,
  IExportAccountTransactionsUrlOptions,
} from '../types';

/**
 * `uploadBidBookUrl` is a function to generate endpoint for uploading bid book.
 */
export const paymentSummaryUrl = () =>
  '/v1/member-account-transactions/store-transactions/running-balance';

/**
 * `accountStatementUrl` generates endpoint for the displaying the bulk purchase,
 * transactions and payment details.
 */
export const accountStatementUrl = ({
  viewType,
  count,
  page,
  fromDate,
  toDate,
  search,
  filterQuery,
}: IAccountStatementUrlOptions) => {
  let url: string = `/v1/member-account-transactions/store-transactions?viewType=${viewType}&page=${page}&count=${count}`;

  if (fromDate && toDate) {
    url += `&fromDate=${fromDate}&toDate=${toDate}`;
  }

  if (search) {
    url += `&search=${search}`;
  }

  if (filterQuery) {
    // TODO: Do something with the filter query.
  }

  return url;
};

export const exportAccountTransactionsUrl = ({
  storeId,
  fromDate,
  toDate,
  isSummaryRequired,
  searchKey,
}: IExportAccountTransactionsUrlOptions) => {
  if (searchKey) {
    // TODO @Shawaf/@Jihin: Add the search key to the exports
  }

  return `v1/member-account-transactions/export/${storeId}?from_date=${fromDate}&to_date=${toDate}&isSummeryRequired=${isSummaryRequired}`;
};
