import { IStatsSectionTileItem } from '../StatsSectionTile';

/**
 * It will provide summary stastics label and key for mapping the data from api
 * response
 */
export const summaryList: IStatsSectionTileItem[] = [
  {
    title: 'Deals Won',
    value: 50,
    valueKey: 'dealsWon',
  },
  {
    title: 'Delivered to me',
    value: 30,
    valueKey: 'delivered',
  },
  {
    title: 'Payment due from me',
    value: 10,
    valueKey: 'paymentDue',
  },
  {
    title: 'Pending delivery',
    value: 10,
    valueKey: 'pendingDelivery',
  },
  {
    title: 'Paid by me',
    value: 30,
    valueKey: 'paid',
  },
];
