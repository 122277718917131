import { useQueryWithUserId } from '@northladder/services';

import { IApiError, IApiResponse } from '../../../../types';
import { ITopModelsPayload, ITopModelsResponse } from '../types';
import { CUSTOMER_SERVICE_API } from '../customerServiceClient';

/**
 * This is to track the models filter mutation keys in react query cache.
 */
export const FETCH_TOP_MODELS_QUERY = 'fetch-top-models-query-key';

function formatTopModelType(response: IApiResponse<ITopModelsResponse>) {
  const topModelItems = response?.data?.data.map((data) => ({
    title: data.groupName,
    desc: data.noOfDevices.toString().concat(' devices'),
  }));

  return topModelItems;
}

/**
 * This performs a post request and retrieves the top models.
 */
export const useTopModelsQuery = (payload: ITopModelsPayload) => {
  const { by, fromDate, toDate } = payload;
  const {
    data: topModels,
    isLoading,
    isError,
    error,
    refetch: callTopModels,
  } = useQueryWithUserId({
    queryKey: [FETCH_TOP_MODELS_QUERY, by, fromDate, toDate],
    networkMode: 'always',
    queryFn: () => CUSTOMER_SERVICE_API.fetchAllTopModels(payload),
    select: formatTopModelType,
    onSuccess: (response) => response,
    onError: (err: IApiError) => err,
  });

  return {
    topModels,
    error,
    isLoading,
    isError,
    callTopModels,
  };
};
