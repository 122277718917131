import { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';

import { IAssetRaw, IBidQueryDataPage } from '@northladder/apis';

import { SectionWrapper } from '../../../../styles';
import BidResultListItem from './BidResultListItem';

/**
 * BidFilterArrow component props
 */
interface IBidFilterArrowProps {
  isAscending: boolean;
}

/**
 * BidFilterArrow is a icon showing component for key sort it have `isAscending`
 * argument for checking the keys
 *
 * @param IBidFilterArrowProps
 *
 * @returns JSX.Element
 */
function BidFilterArrow({ isAscending }: IBidFilterArrowProps) {
  if (isAscending) {
    return <ArrowUpIcon className="mt-[0.2rem] h-3 w-5" />;
  }
  return <ArrowDownIcon className="mt-[0.2rem] h-3 w-5" />;
}

interface IBidsResultsListProps {
  /**
   * Asset list for mapping over the table from bidListPage.
   * this bidListPage is autogenerated from React query infinite
   */
  bidListPage?: IBidQueryDataPage[];
  onSortChange: (sort: string) => void;
}

type TKey = 'assetName' | 'myBid' | 'myQuantity' | 'gapToFirstRank';

interface keyFilterToggle {
  key?: TKey;
  isAscending: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * @param IBidsResultsListProps
 * @returns JSX.Element
 */
export function BidsResultsList({
  bidListPage,
  onSortChange,
}: IBidsResultsListProps) {
  const [keyFilter, setKeyFilter] = useState<keyFilterToggle>({
    isAscending: false,
  });

  const getIsAscending = (keyName: string): boolean =>
    !!(keyFilter.key === keyName && keyFilter.isAscending);

  const updateFilterKey = (key: TKey) => {
    onSortChange(key);
    setKeyFilter({
      key,
      isAscending: keyFilter.key === key ? !getIsAscending(key) : false,
    });
  };

  return (
    <SectionWrapper className="flex gap-2 p-4">
      <div className="w-full">
        <div
          className="
            -mx-4 overflow-hidden shadow-sm shadow-white/50 ring-1 ring-black
            ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg
          "
        >
          <table className="min-w-full divide-y divide-gray-100">
            <thead className="bg-purple-300">
              <tr>
                <th
                  className="flex flex-row py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-900 sm:pl-6"
                  onClick={() => {
                    updateFilterKey('assetName');
                  }}
                  scope="col"
                >
                  <span>Asset Model</span>
                  <BidFilterArrow isAscending={getIsAscending('assetName')} />
                </th>
                <th
                  className="text-center text-xs font-normal text-gray-900 lg:table-cell"
                  onClick={() => {
                    updateFilterKey('myBid');
                  }}
                  scope="col"
                >
                  <div className="mr-5 flex flex-row justify-end">
                    <span>My Bid</span>
                    <BidFilterArrow isAscending={getIsAscending('myBid')} />
                  </div>
                </th>

                <th
                  className="hidden px-3 py-3.5 text-left text-xs font-normal text-gray-900 sm:table-cell"
                  scope="col"
                >
                  Update My Bid
                </th>
                <th
                  className="flex flex-row px-3 py-3.5 text-left text-xs font-normal text-gray-900"
                  onClick={() => {
                    updateFilterKey('myQuantity');
                  }}
                  scope="col"
                >
                  <span>My Quantity</span>
                  <BidFilterArrow isAscending={getIsAscending('myQuantity')} />
                </th>
                <th
                  className="px-3 py-3.5 text-left text-xs font-normal text-gray-900"
                  scope="col"
                >
                  Top Bid
                </th>
                <th
                  className="flex flex-row px-3 py-3.5 text-left text-xs font-normal text-gray-900"
                  onClick={() => {
                    updateFilterKey('gapToFirstRank');
                  }}
                  scope="col"
                >
                  <span>Gap to 1st place</span>
                  <BidFilterArrow
                    isAscending={getIsAscending('gapToFirstRank')}
                  />
                </th>
                <th className="relative py-3.5 pl-3 pr-4 sm:pr-6" scope="col">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-50 bg-white">
              {bidListPage && bidListPage.length > 0 ? (
                bidListPage.map(
                  ({ assets }) =>
                    assets.map((assetItem) => {
                      const asset = assetItem as IAssetRaw;
                      return (
                        <BidResultListItem key={asset?._id} asset={asset} />
                      );
                    })
                  // eslint-disable-next-line function-paren-newline
                )
              ) : (
                <tr>
                  <td>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </SectionWrapper>
  );
}
