import { IApiError } from '../types';
import { checkIfTokenIsExpired } from '../utils';
import { sessionTimeoutError } from '../api-errors/handleErrorInAPICall';
import { globalFetchRefreshTokenAPICall } from '../dealer-api/clients/user-accounts/globalFetchRefreshTokenAPICall';

type ITokenValidity =
  | {
      hasToken: true;
      token: string;
      refreshToken: string;
    }
  | {
      hasToken: false;
      error: IApiError;
    };

/**
 * This helps to check if the token or refresh token have expired to generated
 * new ones or throw an error to remind the user to login.
 */
export const validateOrRenewAppSession = async (
  token: string,
  refreshToken: string
): Promise<ITokenValidity> => {
  /**
   * If the token is expired, try to check if the refresh token is renewable
   * otherwise prompt a login.
   */
  if (checkIfTokenIsExpired(token)) {
    /**
     * Throw a session timeout error to request the user to login again, if the
     * `refreshToken` also expired.
     */
    if (checkIfTokenIsExpired(refreshToken)) {
      return { hasToken: false, error: sessionTimeoutError };
    }

    /**
     * Fetch a new `token` if the `refreshToken` is not expired as yet.
     */
    const tokenResponse = await globalFetchRefreshTokenAPICall(refreshToken);

    if (tokenResponse.success) {
      return {
        hasToken: true,
        token: tokenResponse.token,
        // TODO: Why not updating the refresh token
        refreshToken,
      };
    }

    /**
     * Refresh API also failed. So returning the session timeout error.
     */
    return { hasToken: false, error: sessionTimeoutError };
  }

  /**
   * The token, is not expired, so return true with token details.
   */
  return { hasToken: true, token, refreshToken };
};
