import { TTextLocalized, Text } from '@northladder/i18n';

import { IApiError } from '@northladder/apis';
import { CircularLoader } from '@northladder/design-system';

function AppraisalListLoader() {
  return (
    <div className="flex h-[70vh] w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-8">
        <CircularLoader className="h-6 w-6" />
        <p className="text-xl text-purple-600">Please wait...</p>
      </div>
    </div>
  );
}

interface IAppraisalListErrorProps {
  errorMessage: TTextLocalized;
  onRefreshList: () => void;
}

function AppraisalListError({
  errorMessage = 'Something went wrong!',
  onRefreshList,
}: IAppraisalListErrorProps) {
  return (
    <>
      <Text className="text-red-800" text={errorMessage} />
      <button
        className="ml-1 mt-2 inline-flex justify-center rounded-md border
         border-transparent bg-purple-500 px-2 py-1 text-sm font-medium
          text-white hover:bg-purple-600"
        onClick={onRefreshList}
        type="button"
      >
        Retry
      </button>
    </>
  );
}

function AppraisalListEmpty() {
  return (
    <div className="flex h-[70vh] w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-8">
        <p className="text-xl text-purple-600">No data available</p>
      </div>
    </div>
  );
}

interface IAppraisalListFallbackProps {
  isLoading: boolean;
  error: IApiError | null;
  onRefreshList: () => void;
}

export function AppraisalListFallback({
  isLoading,
  error,
  onRefreshList,
}: IAppraisalListFallbackProps) {
  if (isLoading) return <AppraisalListLoader />;
  if (error) {
    return (
      <AppraisalListError
        errorMessage={error?.message}
        onRefreshList={onRefreshList}
      />
    );
  }
  return <AppraisalListEmpty />;
}
