import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * This styles the main div that surrounds the whole form.
 */
export const FormContainer = styled.div`
  ${tw`
    w-full
    rounded-lg
    bg-white
    transition-all
    ease-in-out
    delay-100
    duration-500
    relative

    sm:max-w-md
    md:mt-0
    xl:p-0

    // dark:(
    //   border
    //   border-gray-700
    //   bg-gray-800
    // )
  `};

  .form-responsive-wrapper {
    ${tw`
      space-y-4
      p-6
      sm:p-8
      md:space-y-6
   `};
  }

  .form-header {
    ${tw`
      py-2
      text-xl
      font-semibold
      leading-tight
      tracking-tight
      text-gray-800
      transition-all
      duration-500

      md:text-2xl
      // dark:text-white
  `};
  }
`;

/**
 * -----------------------------------------------------------------------------
 * Injects additional tailwind styles to the form, with twin.macro
 */
export const StyledForm = styled.form`
  ${tw`
    space-y-4
    transition-all
    ease-in-out
    delay-100
    duration-500

    md:space-y-6
 `};

  .form-field-row {
    ${tw`
      flex
      flex-col
      items-center
      justify-between
      gap-4

      sm:flex-row
    `};
  }
`;

/**
 * Additional styles to display a floating whatsapp icon.
 */
export const WhatsappIconWrapper = styled.div`
  ${tw`flex justify-end pt-2`};

  .floating-icon {
    ${tw`fixed bottom-2 right-4 z-[100] sm:right-10 sm:bottom-10`}
  }
`;
