import { HotDealsBadge } from './HotDealsBadge';

interface IColumnDeviceDetailsProps {
  /**
   * For showing the title of `ColumnDeviceDetails` component
   */
  title: string;
  /**
   * For showing the description of `ColumnDeviceDetails` component
   */
  description: string;
  /**
   * For showing and hiding `HotDealsBadge` component
   */
  isHotDeal: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This component is used to render device details like device name, model etc.
 */
export function ColumnDeviceDetails({
  title,
  description,
  isHotDeal = false,
}: IColumnDeviceDetailsProps) {
  return (
    <div className="ml-3 flex w-fit flex-col">
      <div className="flex flex-row">
        <p className="text-sm font-normal text-black">{title}</p>
        {isHotDeal ? <HotDealsBadge /> : null}
      </div>
      <p className="text-xs font-normal text-gray-600">{description}</p>
    </div>
  );
}
