import tw, { styled } from 'twin.macro';

/**
 * The AppSearchWrapper adds styling to the main input wrapper.
 */
export const AppSearchWrapper = styled.div`
  ${tw`
    w-full
    max-w-full
    transition-all
    delay-100
    duration-500
    ease-in-out
    sm:max-w-xs
    lg:max-w-lg
  `};

  .search-input-with-icon-container {
    ${tw`
      relative
      mb-3
      sm:mb-0
      transition-all
      delay-100
      duration-500
      ease-in-out
    `};
  }

  input {
    ${tw`
      py-2.5
      pl-10
      pr-4
      rtl:(pr-10 pl-4)
    `};
  }

  .search-icon-container {
    ${tw`
      absolute
      flex
      shrink-0
      items-center
      pointer-events-none
      inset-y-0
      left-0
      px-3
      z-0
      rtl:right-0
    `};
  }
`;
