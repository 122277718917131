import { IBidsRankData } from '../types';

export function formatBidRankResponse(
  bidRankData: IBidsRankData
): IBidsRankData {
  const {
    bidNotWinning,
    hotDeal,
    noBids,
    outOfQuantity,
    rankOne,
    totalAssetCount,
    totalBids,
    winningRange,
  } = bidRankData || {};

  return {
    bidNotWinning,
    hotDeal,
    myBids: totalBids,
    noBids,
    outOfQuantity,
    rankOne,
    totalAssetCount,
    totalBids,
    winningRange,
  };
}
