import { useCallback, useEffect } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

import { Text } from '@northladder/i18n';
import { IApiError, useSearchBidsQuery } from '@northladder/apis';
import {
  SimpleDropdown,
  PopoverMultiSelect,
  TMenuItem,
  IPopoverItem,
  CircularProgressLoader,
} from '@northladder/design-system';

import { SectionWrapper, DashboardMainWrapper } from '../../../../styles';
import { TDaysUpdateValue } from '../../../../components';

import { BidBookActionBarButtonsGroup } from '../../components';
import { useBidsFilter } from '../../hooks';

import { BidRankFilter } from '../BidsBadgesFiltersList';

import { BidsResultsList } from './BidsResultsList';

/**
 * List for render the `BidFilterList` Component.
 */
interface IBidsDropdownFiltersSectionProps {
  brandOptions: TMenuItem[];
  categoryOptions: TMenuItem[];
  modelsOptions: IPopoverItem[];
  onCategoryOptionSelect: (item: TMenuItem) => void;
  onBrandOptionSelect: (item: TMenuItem) => void;
  onPopoverSelectCB: (popoverItems: IPopoverItem[]) => void;
  onRefreshList: () => void;
  loadingState: boolean;
  isModelsLoading: boolean;
  categoryOptionsError: IApiError | null;
  modelOptionsError: IApiError | null;
}
/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * @returns JSX.Element
 */
function BidsDropdownFiltersSection({
  brandOptions,
  categoryOptions,
  modelsOptions,
  onCategoryOptionSelect,
  onBrandOptionSelect,
  onPopoverSelectCB,
  onRefreshList: refreshList,
  loadingState,
  isModelsLoading,
  categoryOptionsError,
  modelOptionsError,
}: IBidsDropdownFiltersSectionProps) {
  return (
    <SectionWrapper className="flex h-max flex-wrap items-center gap-2 p-4">
      <SimpleDropdown
        className="z-10"
        defaultTitle="Select category"
        error={categoryOptionsError}
        items={categoryOptions}
        loadingState={loadingState}
        menuItemsClassName="menu-item-list"
        onDropDownItemSelect={onCategoryOptionSelect}
      />
      <SimpleDropdown
        className="z-10"
        defaultTitle="Select brand"
        error={categoryOptionsError}
        items={brandOptions}
        loadingState={loadingState}
        menuItemsClassName="menu-item-list"
        onDropDownItemSelect={onBrandOptionSelect}
      />
      <div className="order-last w-[36rem] grow lg:order-none">
        <PopoverMultiSelect
          className="popover-layout"
          error={modelOptionsError}
          isModelsLoading={isModelsLoading}
          items={modelsOptions}
          onPopoverItemSelect={onPopoverSelectCB}
          title="Select Model"
        />
      </div>
      <div className="flex grow justify-end">
        <button className="ml-1 p-1" onClick={refreshList} type="button">
          <ArrowPathIcon
            aria-hidden="true"
            className="h-5 w-5 flex-1"
            title="Reset Search Filters"
          />
        </button>
      </div>
    </SectionWrapper>
  );
}

/**
 * `IBidsActionBarSectionProps` defines the props for BidsActionBarSection.
 */
interface IBidsActionBarSectionProps {
  categoryId?: string;
  brandId?: string;
  groupIds?: string[];
  onSelectDays: (selectedDays: TDaysUpdateValue) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * @returns JSX.Element
 */
function BidsActionBarSection({
  categoryId,
  brandId,
  groupIds,
  onSelectDays,
}: IBidsActionBarSectionProps) {
  return (
    <SectionWrapper
      className="
        mb-2 flex justify-between border-y border-gray-50 bg-gray-50/50
        py-2
        "
    >
      <div className="page-action-bar-bids-badges flex gap-2 px-4 py-0">
        <BidRankFilter
          brandId={brandId === 'all' ? '' : brandId}
          categoryId={categoryId}
          groupIds={groupIds}
        />
      </div>

      <span className="isolate inline-flex rounded-md px-2">
        <BidBookActionBarButtonsGroup
          brandId={brandId || ''}
          categoryId={categoryId || ''}
          onSelectDays={onSelectDays}
        />
      </span>
    </SectionWrapper>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This component houses all the main content of the bids screen, such as the
 * filters, list view, pagination, selected bid, etc.
 *
 * TODO: Update this with the other components being developed in isolation
 * TODO: Remove the grid layout and instead use flex.
 *
 * @returns JSX.Element
 */
export function BidsMainContent() {
  const {
    nextPageObserverRef,
    selectedCategory,
    selectedBrand,
    selectedGroupIds,
    modelsOptions,
    categoryOptions,
    brandOptions,
    isLoadingCategoryData,
    categoryError,
    isFetchingModels,
    modelError,
    sortBy,
    orderBy,
    searchKey,
    fromDay,
    today,
    bidRankFilterKey,
    onDaySelectionChange,
    onSortChange,
    onCategoryOptionSelect,
    onBrandOptionSelect,
    onPopoverSelectCB,
  } = useBidsFilter();

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
  } = useSearchBidsQuery({
    payload: {
      page: 1, // initial page count
      count: 10,
      search: searchKey,
      categoryId: selectedCategory?.id.toString() || '',
      brandId: selectedBrand?.id.toString() || '',
      groupIds: selectedGroupIds,
      fromDate: fromDay,
      toDate: fromDay ? today : '',
      filterBy: bidRankFilterKey as string | null,
      sortBy,
      orderBy,
    },
  });

  const handleObserver = useCallback(
    (entries: any[]) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const element = nextPageObserverRef.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [fetchNextPage, hasNextPage, handleObserver, nextPageObserverRef]);

  const handleOnRefreshList = () => {
    refetch();
  };

  return (
    <DashboardMainWrapper
      className="
        flex-col items-stretch !justify-start rounded-xl bg-white
      "
    >
      <BidsDropdownFiltersSection
        brandOptions={brandOptions}
        categoryOptions={categoryOptions}
        categoryOptionsError={categoryError}
        isModelsLoading={isFetchingModels}
        loadingState={isLoadingCategoryData}
        modelOptionsError={modelError}
        modelsOptions={modelsOptions}
        onBrandOptionSelect={onBrandOptionSelect}
        onCategoryOptionSelect={onCategoryOptionSelect}
        onPopoverSelectCB={onPopoverSelectCB}
        onRefreshList={handleOnRefreshList}
      />

      <BidsActionBarSection
        brandId={selectedBrand?.id.toString()}
        categoryId={selectedCategory?.id.toString()}
        groupIds={selectedGroupIds}
        onSelectDays={onDaySelectionChange}
      />

      <SectionWrapper className="min-h-full grow">
        {error ? (
          <>
            <Text className="text-red-800" text={error.message} />
            <button
              className="ml-1 mt-2 inline-flex justify-center rounded-md border
               border-transparent bg-purple-500 px-2 py-1 text-sm font-medium
                text-white hover:bg-purple-600"
              onClick={() => refetch()}
              type="button"
            >
              Retry
            </button>
          </>
        ) : null}

        <BidsResultsList
          bidListPage={data?.pages}
          onSortChange={onSortChange}
        />
      </SectionWrapper>
      <div ref={nextPageObserverRef} className="flex flex-row justify-center">
        {isFetchingNextPage && hasNextPage ? (
          <CircularProgressLoader className="h-5 w-5" strokecolor="#000000" />
        ) : (
          'No search left'
        )}
      </div>
      <SectionWrapper className="placeholder">
        <p className="placeholder-text">4</p>
      </SectionWrapper>
    </DashboardMainWrapper>
  );
}
