// TODO: Make this hook common
import { useState } from 'react';
import {
  getFormattedDate,
  getSubDate,
} from '@northladder/utilities/src/common/dateFunctions';

export type TTopModelsFilter = 'me' | 'nl';

interface IUseTopStatsProps {
  initialSubDayCount: number;
}

/**
 * This hook used for change  `modelsFilterState` data and change from date
 * to date data
 *
 * @returns modelsFilterState, handleBrandFilter, today, subtractedDate, setSubDate
 */
export const useTopStats = ({ initialSubDayCount }: IUseTopStatsProps) => {
  const [modelsFilterState, setModelsFilterState] =
    useState<TTopModelsFilter>('me');
  const now = new Date();
  const today = getFormattedDate(now);
  const [fromDay, setFromDay] = useState(getSubDate(initialSubDayCount));

  const handleFilterChange = (handleChange: TTopModelsFilter) => {
    setModelsFilterState(handleChange);
  };

  const setSubDate = (date: number) => {
    setFromDay(getSubDate(date));
  };

  return {
    modelsFilterState,
    handleFilterChange,
    today,
    fromDay,
    setSubDate,
  };
};
