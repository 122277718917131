import Link from 'next/link';
import tw, { styled } from 'twin.macro';

export const StyledTabbedNavLink = styled.a`
  ${tw`
    whitespace-nowrap
    border-b-2
    py-4
    px-1
    text-sm
    font-medium
    border-transparent
    text-gray-500
    hover:(border-gray-300 text-gray-700)
  `};

  &.tab-active {
    ${tw`
      border-purple-500
      text-purple-900
    `};
  }
`;

export const DisabledLink = styled.span`
  ${tw`
    flex
    font-normal
    items-center
    p-2
    ml-4
    rounded-md
    text-sm
    text-gray-300
    cursor-no-drop

    rtl:ml-0
  `};

  .nav-icon {
    ${tw`
      mr-4
      h-6
      w-6
      text-gray-300

      rtl:ml-4
  `};
  }
`;

export const StyledNavLink = styled(Link)`
  ${tw`
    flex
    font-normal
    items-center
    p-2
    ml-4
    rounded-md
    text-sm

    rtl:ml-0

    hover:(text-purple-600 bg-purple-50 text-purple-900)
  `};

  .nav-icon {
    ${tw`
      mr-4
      h-6
      w-6
      text-gray-400

      rtl:ml-4
  `};
  }

  &.is-current {
    ${tw`
      bg-purple-50
      text-purple-900
    `};

    .nav-icon {
      ${tw`
        text-purple-900
    `};
    }
  }
`;
