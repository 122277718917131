import { TTextLocalized } from '@northladder/services';

/**
 * `IFetchAllTopBrands` is type which defines toBrand result of topBrand API.
 */
export interface IFetchAllTopBrands {
  status: boolean;
  data: TopBrand[];
  message: string;
}
/**
 * `TopBrand` is type which defines toBrand item object of topBrand API.
 */
export interface TopBrand {
  brandName?: string;
  categoryName?: string;
  noOfDevices?: number;
  purchasePrice?: number;
  percentage?: number;
  name?: string;
}

/**
 * `ITopModelsPayload` is type which defines the TopModels payload.
 *  fromDate and toDate indicate the dates range to fetch top models from.
 */
export interface ITopModelsPayload {
  fromDate: string;
  toDate: string;
  /**
   * `by` takes 'me'- to fetch my top models and
   * 'nl'- to fetch NorthLadders top models.
   */
  by: 'me' | 'nl';
}

/**
 * `ITopModelsData` is the type for the data received from the Top Models response.
 */
export interface ITopModelsResponseData {
  groupName: string;
  brandName: string;
  categoryName: string;
  noOfDevices: number;
  purchasePrice: number;
  percentage: number;
}

/**
 * `ITopModelsResponse` is response type for TopModels.
 */
export interface ITopModelsResponse {
  status: boolean;
  message: string;
  data: ITopModelsResponseData[];
}

/**
 * `IFetchAllTopBrands` is type which defines summary result of
 * inventory summary API.
 */
export interface IFetchSummary {
  status: boolean;
  data: TSummary;
  message: string;
}

/**
 * `ISummary` is type which defines Summary data object of
 * inventory summary API.
 */
export type TSummary = {
  dealsWon: number;
  pendingDelivery: number;
  delivered: number;
  paid: number;
  paymentDue: number;
};

export enum ICourierStatus {
  IN_TRANSIT = 'In Transit',
  DELIVERED = 'Delivered',
  RETURNED = 'Returned',
  DELIVERY_REFUSED = 'Delivery Refused',
  CANCELLED = 'Cancelled',
  GRADE_REVIEW = 'Grade Review',
}

/**
 * Defines the response type for the appraisal list within `IDealsListResponseData`.
 */
export interface IDealListAppraisalRaw {
  approvedDeal: {
    approvedAmount: number;
  };
  appraisalCode: string;
  assetInfo: {
    label: string;
    label_ar: string;
  };
  courierStatus: ICourierStatus;
  displayStatus: string[];
  dealTS: number;
  deliveryDate: number;
  isDelivered: boolean;
  isSellAsset: boolean;
  sellAmount: {
    sellAmount: number;
  };
}

/**
 * Defines the interface for formatted response for the appraisal list.
 */
export interface IDealListAppraisal {
  appraisalCode: string;
  assetName: TTextLocalized;
  courierStatus: ICourierStatus;
  dealDate: number;
  deliveryDate: number;
  displayStatus: string[];
  isDelivered: boolean;
  purchasePrice: number;
}

/**
 * Defines the interface for raw filter options on `DealsListScreen`
 */
interface IDealsFilterOptionRaw {
  label: string;
  label_ar: string;
  value: string;
}

/**
 * Defines the interface for raw filter options on `DealsListScreen`
 */
export interface IDealsScreenFilterOption {
  label: TTextLocalized;
  value: string;
}

/**
 * `IDealsListResponseRawData` defines the raw response of the Deals api data type.
 */
export interface IDealsListResponseRawData {
  appraisals: IDealListAppraisalRaw[];
  count: number;
  filterOptions: IDealsFilterOptionRaw[];
}

/**
 * `IDealsListResponseData` defines the formatted response of the Deals api data.
 */
export interface IDealsListResponseData {
  appraisals: IDealListAppraisal[];
  count: number;
  filterOptions: IDealsScreenFilterOption[];
}

/**
 * `IDealsListResponse` defines response type of the deals list api call.
 */
export interface IDealsListResponse {
  data: IDealsListResponseRawData;
  message: string;
  status: boolean;
}

/**
 * Interface for appraisal data within the deal details API response.
 */
export interface IDealDetailAppraisalData {
  appraisalCode: string;
  approvedDeal: {
    assetPrice: string;
    assetCondition: {
      memberCondition: {
        condition: string;
        percentValue: string;
        dealerPercentValue: string;
      };
    };
  };
  awbNumber: string;
  awbTrackingURL: string;
  courierStatus: ICourierStatus;
  dealTS: number;
  deliveryDate: number;
  displayStatus: string[];
  invoices: [
    {
      invoiceType: string;
      invoiceUrl: string;
      appraisalCode: string;
    }
  ];
  isDelivered: boolean;
  isReturned: boolean;
  paymentType: string;
  serialNumber: string;
  totalAmount: number;
}

/**
 * Interface for deal details API response.
 */
export interface IDealDetailsResponseDataRaw {
  appraisalData: IDealDetailAppraisalData;
  assetInfo: {
    name: string;
    name_ar: string;
    attributes: [
      {
        key: string;
        value: string;
        key_ar: string;
        value_ar: string;
      }
    ];
    label: string;
    label_ar: string;
  };
  brandName: string;
  categoryName: string;
  gradeReviewRequestData: IGradeReviewDetailsResponseRawData[];
  outRightSaleInfo: {
    outRightsale: [
      {
        label: string;
        label_ar: string;
        value: string;
      }
    ];
  };
}

export interface IPurchasePrices {
  label: TTextLocalized;
  value: string;
}

/**
 * Formatted response of the deal details API response.
 */
export interface IDealDetailsResponseData {
  appraisalCode: string;
  assetName: TTextLocalized;
  attributes: [
    {
      key: string;
      value: string;
      key_ar: string;
      value_ar: string;
    }
  ];
  brandName: string;
  courierStatus: ICourierStatus;
  // TODO: Completely remove this later when state.
  // dealerGrade: string; // Removed this to instead use `itemDetailGrade`.
  dealDate: number;
  deliveryDate: number;
  gradeReviewRequestData: IGradeReviewDetailsResponseData[];
  imeiNumber: string;
  invoices: [
    {
      invoiceType: string;
      invoiceUrl: string;
      appraisalCode: string;
    }
  ];
  isDelivered: boolean;
  isReturned: boolean;
  itemDetailGrade: string;
  mobileGrade: string;
  myBidOnDealDate: string;
  paymentType: string;
  purchasePrices: IPurchasePrices[];
  shipmentNumber: string;
  shipmentTrackingUrl: string;
  statusLabels: string[];
  totalAmount: number;
}

export interface IDealDetailsResponse {
  data: IDealDetailsResponseDataRaw;
  message: string;
  status: boolean;
}

// -----------------------------------------------------------------------------
// ---------------------Dealer sign up types------------------------------------
// -----------------------------------------------------------------------------

/**
 * Base Payload for uploading License, VAT certificate in the registration screen.
 */
export type TFileUploadPayloadBase = {
  file: FormData | FormDataEntryValue | null;
  envType: 'WEB' | 'MOBILE';
};

/**
 * Specific type for mobile file upload payload.
 */
export type TFileUploadPayloadMobile = TFileUploadPayloadBase & {
  envType: 'MOBILE';
};

/**
 * Specific type for web file upload payload.
 */
export type TFileUploadPayloadWeb = TFileUploadPayloadBase & {
  path: string;
  type: string;
  envType: 'WEB';
};

export type IFileUploadPayload =
  | TFileUploadPayloadMobile
  | TFileUploadPayloadWeb;

/**
 * Response of the file upload api call in the registration screen.
 */
export interface IFileUploadData {
  baseUrl: string;
  filePath: string;
}

/**
 * Fetch appraisals api response type
 */
export interface IFetchAppraisalsResponse {
  data: IFetchAppraisalsData;
  message: string;
  status: boolean;
}

/**
 * `IFetchAppraisalsQueryData` is the response type from react infinite query.
 */
export interface IFetchAppraisalsQueryData {
  pageParams: unknown[];
  pages: IFetchAppraisalsData[];
}

/**
 * Fetch appraisals list response type
 */
export interface IFetchAppraisalsData {
  appraisals: IAppraisalData[];
  count: number;
}

/**
 * Appraisal data type
 *
 * TODO: Why not transform and flatten this if being consumed by components.
 */
export interface IAppraisalData {
  _id: string;
  appraisalCode: string;
  approvedDeal: {
    itemCondition: string;
  };
  assetInfo: {
    label: string;
  };
  assetInventory: {
    sourceStoreName: string;
  };
  dealTS: number;
  sellAmount: {
    dealPrice: number;
    underwriterSellTaxValue: number;
  };
  serialNumber: string;
}

export interface IFetchAppraisalDetailsResponse {
  data: any;
  message: string;
  status: boolean;
}

export interface IGetSingedUrlResponse {
  url: string;
}

/**
 * Fetch bid execution time api response type
 */
export interface IBidExecutionTimeResponse {
  data: IBidExecutionData;
  message: string;
  status: boolean;
}

export type TBidExecTime = {
  days: number[];
  time: number;
};

/**
 * IBidExecutionData interface.
 */
export interface IBidExecutionData {
  bidExecTime: TBidExecTime;
  nextExecutionDay: number;
  nextExecutionDayWithTime: number;
  validFrom: number;
  validFromDay: number;
}

/**
 * Credit limit api raw response data
 */
export interface ICreditLimitData {
  availableCredit: number;
  availableCreditPercentage: number;
  blockedCredit: number;
  blockedCreditPercentage: number;
  blockedDeals: any[];
  creditLimit: number;
  effectiveBalance: number;
  isExceeded: boolean;
  isExceededDate: number;
  runningBalance: number;
  runningBalancePercentage: number;
}

/**
 * Credit limit api raw response
 */
export interface ICreditLimitResponse {
  data: ICreditLimitData;
  message: string;
  status: boolean;
}

/**
 * Credit limit api response data convert interface for UI
 */
export interface ICreditLimit {
  availableCredit: number;
  availableCreditPercentage: number;
  blockedCredit: number;
  blockedCreditPercentage: number;
  blockedDealsCount: number;
  creditLimit: number;
  creditLimitExceededDate: number;
  isCreditLimitExceeded: boolean;
  runningBalance: number;
  runningBalancePercentage: number;
}

export interface DeviceComponentCondition {
  condition: string;
  itemCondition: string;
  itemConditionId: string;
}

export interface IDeviceGradeConditionData {
  _id: string;
  name: string;
  conditions: DeviceComponentCondition[];
}

export interface IDeviceGradeConditionRawResponse {
  status: boolean;
  message: string;
  data: IDeviceGradeConditionData[];
}

export interface IDeviceItemCondition {
  _id: string;
  condition: string;
  condition_ar?: string;
  freeze: boolean;
}

export interface IDeviceItemConditionData {
  _id: string;
  conditions: IDeviceItemCondition[];
}

export interface IDeviceItemConditionRawResponse {
  status: boolean;
  message: string;
  data: IDeviceItemConditionData;
}

/**
 * `IGradePopoverItem` defines the formatted response of the Device Item condition api data.
 */
export interface IGradePopoverItem {
  id: string;
  title: string;
  titleAr?: string;
  checked: boolean;
  freeze: boolean;
}

/**
 * `IDeviceGradeCondition` defines the formatted response of the Device Item condition api data.
 */
export interface IDeviceItemConditions {
  id: string;
  conditions: IGradePopoverItem[];
}

export enum IGradeStatus {
  UNDER_PROCESS = 'UNDER_PROCESS',
  GRADE_REVISED = 'GRADE_REVISED',
  GRADE_UNCHANGED = 'GRADE_UNCHANGED',
  ADDITIONAL_INFO_REQUIRED = 'ADDITIONAL_INFO_REQUIRED',
  REQUESTED_TO_RETURN = 'REQUESTED_TO_RETURN',
  RETURN_IN_PROGRESS = 'RETURN_IN_PROGRESS',
  DEVICE_RETURNED = 'DEVICE_RETURNED',
  // TODO: Currently it will not be used, inside this enum will change after sometime.
  // TODO: May be we need to remove this entire enum to change it something reusable
  // TODO:  otherwise the Grade card will not be reusable. `cc: @Sowed, @Yaten`
  DEVICE_DELIVERED = 'DELIVERED',
  NONE = 'NONE',
}

export enum GradeReviewStatus {
  UNDER_PROCESS = 'UNDER_PROCESS',
  GRADE_REVISED = 'GRADE_REVISED',
  GRADE_UNCHANGED = 'GRADE_UNCHANGED',
  ADDITIONAL_INFO_REQUIRED = 'ADDITIONAL_INFO_REQUIRED',
  REQUESTED_TO_RETURN = 'REQUESTED_TO_RETURN',
  RETURN_IN_PROGRESS = 'RETURN_IN_PROGRESS',
  DEVICE_RETURNED = 'DEVICE_RETURNED',
  NONE = 'NONE',
}

/**
 * Grade Review Request raw response data
 */
export interface IGradeReviewRequestDataRaw {
  _id: string;
  appraisalCode: string;
  assetName: string;
  createdAt: number;
  dealPrice: number;
  dealPaymentType: 'COD' | 'CC';
  financeCheck: boolean;
  isCreatedByNL: boolean;
  status: IGradeStatus;
  ticketNumber: string;
}

/**
 * Interface for single data item in grade review request list for formatted response.
 */
export interface IGradeReviewList {
  appraisalCode: string;
  assetName: string;
  brandName?: string;
  /**
   * We are using generic name as `date` since we are using this type at other
   * places as well. Date can be changed according to deal status.
   */
  date: number;
  displayStatus: string[];
  gradeCondition?: string;
  gradeReviewRequestId: string;
  gradeStatus: IGradeStatus;
  isCreatedByNL: boolean;
  purchasePrice: number;
  gradeReviewTicketNumber: string;
  serialNumber?: string;
  validateErrorMessages?: string[];
}

/**
 * Interface for raw filter options for grade review request list.
 */
export interface IGradeFilterOptionsRaw {
  count: number;
  key: keyof typeof IGradeStatus;
  label: {
    critical: boolean;
    value: {
      en: string;
      ar: string;
    };
  };
  value: {
    en: string;
    ar: string;
  };
}

/**
 * interface for raw response we get from the API for filters of grade review
 * request list.
 */
export interface IGradeReviewRequestListResponseRawData {
  gradeReviewRequestList: IGradeReviewRequestDataRaw[];
  totalCount: number;
  status: IGradeFilterOptionsRaw[];
}

/**
 * `IDealsListResponse` defines response type of the deals list api call.
 */
export interface IGradeReviewRequestListResponse {
  data: IGradeReviewRequestListResponseRawData;
  message: string;
  status: boolean;
}

/**
 * Interface for filter options for subtitle of grade review request list.
 */
export interface IGradeFilterOptionsWithSubtitle {
  isCritical: boolean;
  value: TTextLocalized;
}

/**
 * Interface to format the grade review request list response.
 */
export interface IGradeReviewScreenFilterOptions {
  count: number;
  label: TTextLocalized;
  subtitle: IGradeFilterOptionsWithSubtitle;
  value: keyof typeof IGradeStatus;
}

/**
 * Interface to format the grade review request list response.
 */
export interface IGradeReviewRequestListResponseData {
  gradeList: IGradeReviewList[];
  count: number;
  gradeFilterOptions: IGradeReviewScreenFilterOptions[];
}

/**
 * Interface of raw data for filter options for grade review request list.
 */
export interface IGradeReviewFilterOptionsRawData {
  key: keyof typeof IGradeStatus;
  value: {
    en: string;
  };
}

/**
 * Interface of raw response we get from the API for filters of grade review request list.
 */
export interface IGradeReviewFilterOptionsRawResponse {
  data: IGradeReviewFilterOptionsRawData[];
  message: string;
  status: boolean;
}

/**
 * Interface for Search Grade Deal list response
 */
export interface IGradeDealsSearchDealRawItem {
  _id: string;
  appraisalCode: string;
  assetId: string;
  brandName: string;
  appraisalStatus: string;
  serialNumber: string;
  saleGrade: string;
  label: string;
  label_ar: string;
  dealPrice: number;
  paymentType: string;
  courierStatus: string;
  deliveredOn: number;
  validationErrors: string[];
}

/**
 * Interface for Search Grade Deal list response data
 */
export interface IGradeDealsSearchRawData {
  appraisals: IGradeDealsSearchDealRawItem[];
  totalCount: number;
}

/**
 * Interface for Search Grade Deal list raw response
 */
export interface IGradeDealsSearchRawResponse {
  status: boolean;
  message: string;
  data: IGradeDealsSearchRawData;
}

/**
 * Interface for grade review details dealer evaluation
 */
export interface IGradeReviewDealerEvaluation {
  name: string;
  selectedCondition: string;
}

export interface IValidationMessage {
  critical: boolean;
  value: TTextLocalized;
}

/**
 * Interface for raw data of the grade review request details.
 * TODO: Tell BE to replace the _id to something meaningful.
 */
export interface IGradeReviewDetailsResponseRawData {
  _id: string;
  appraisalCode: string;
  AWBNumber: string;
  awbTrackingURL?: string;
  assetName: string;
  bidAmount: number;
  brandName: string;
  courierBooked: boolean;
  courierStatus: string;
  courierStatusLocale: TTextLocalized;
  createdAt: number;
  dealerActionBefore?: number;
  createdBy: string;
  creditAmount?: number;
  dealPaymentType: string;
  dealerBranchName: string;
  dealerEvaluation: IGradeReviewDealerEvaluation[];
  financeClosingDate?: number;
  deliveredDate?: number;
  revisedGrade?: string;
  revisedGradePercentage?: number;
  dealPrice: number;
  financeCheck: boolean;
  financeStatus: string;
  imageUrls: string[];
  imeiNumber: string;
  initialSaleGrade: string;
  initialSaleGradePercentage: number;
  isCreatedByNL: boolean;
  latestRemark: string;
  comment: string;
  requestedSaleGrade: string;
  requestedSaleGradeId: string;
  requestedSaleGradePercentage: number;
  status: keyof typeof IGradeStatus;
  ticketNumber: string;
  updatedAt?: number;
  validationMessages: IValidationMessage[];
  videoUrls: string[];
  canCancelRequest: boolean;
}

/**
 * Interface for response of the grade review request details.
 */
export interface IGradeReviewDetailsResponse {
  data: IGradeReviewDetailsResponseRawData;
  message: string;
  status: boolean;
}

/**
 * Interface for the formatted response of the grade review request details.
 */
export interface IGradeReviewDetailsResponseData {
  _id: string;
  appraisalCode: string;
  AWBNumber: string;
  awbTrackingURL?: string;
  assetName: string;
  bidPrice: number;
  branchName: string;
  brandName: string;
  isCourierBooked: boolean;
  courierStatus: TTextLocalized;
  createdAt: number;
  dealerActionBefore?: number;
  createdBy: string;
  creditAmount?: number;
  deliveredDate?: number;
  displayStatus: string[];
  dealPaymentType: string;
  financeClosingDate?: number;
  financeStatus: string;
  gradeReviewRequestId: string;
  gradeStatus: string;
  gradeStatusEnum: GradeReviewStatus;
  imageList: string[];
  imeiNumber: string;
  initialSaleGrade: string;
  initialSaleGradePercentage: number;
  isCreatedByNL: boolean;
  latestRemark: string;
  comment: string;
  proposedGradeReasonList: IGradeReviewDealerEvaluation[];
  purchasePrice: number;
  requestedSaleGrade: string;
  requestedSaleGradeId: string;
  requestedSaleGradePercentage: number;
  revisedGrade?: string;
  revisedGradePercentage?: number;
  ticketId: string;
  updatedAt?: number;
  validationMessages: IValidationMessage[];
  videoList: string[];
  canCancelRequest: boolean;
}

/**
 * Interface for the raw data of the grade review request history.
 */
export interface IGradeReviewHistoryResponseRawData
  extends IGradeReviewDetailsResponseRawData {
  gradeReviewRequestId: string;
}

export type TGradeReviewHistoryResponseRawData =
  IGradeReviewHistoryResponseRawData[];

/**
 * Interface for the raw response of the grade review request history.
 */
export interface IGradeReviewHistoryResponse {
  data: IGradeReviewHistoryResponseRawData[];
  message: string;
  status: boolean;
}

export interface IGradeReviewHistoryResponseData
  extends Omit<
    IGradeReviewDetailsResponseData,
    'canCancelRequest' | 'gradeStatusEnum' | 'validationMessages'
  > {
  gradeReviewRequestId: string;
}

/**
 * Interface for the formatted response of the grade review request history.
 */
export type TGradeReviewHistoryResponseData = {
  gradeReviewHistoryList: IGradeReviewHistoryResponseData[];
};

/**
 * Interface for the create grade change request response data.
 */
export interface ICreateGradeChangeRequestData {
  gradeReviewRequestId: string;
}

/**
 * Interface for the create grade change request response.
 */
export interface ICreateGradeChangeRequestResponse {
  status: boolean;
  message: string;
  data: ICreateGradeChangeRequestData;
}

/**
 * Interface for the update grade change request response data.
 */
export interface IUpdateGradeChangeRequestData {
  ticketNumber: string;
}
/**
 * Interface for the update grade change request response.
 */
export interface IUpdateGradeChangeRequestResponse {
  status: boolean;
  message: string;
  data: IUpdateGradeChangeRequestData;
}

/**
 * Interface for the video upload payload.
 */
export interface IVideoUploadPayload {
  file: FormData | FormDataEntryValue | null;
}

/**
 * Interface for the response of the video upload api call.
 */
export type TVideoUploadData = IFileUploadData;
