import { IBrand, IBrandRaw } from '@northladder/services';

import { IBidCategoryFiltersDataRaw, IBidCategoryFiltersData } from '../types';

/**
 * this transformer function transforms the raw brands data of category to
 * extract fields that is being consumed by the UI.
 */
function formatBrands(brands: IBrandRaw[]): IBrand[] {
  const formattedBrandList = brands.map((brandItem) => {
    const { name, name_ar: nameAr, _id } = brandItem;
    return {
      name: {
        ar: nameAr ?? name,
        en: name,
      },
      id: _id,
    };
  });

  return formattedBrandList;
}

/**
 * this transformer function transforms the raw category data coming from api
 * according to what ui needs.
 */
export function formatBidCategory(
  response: IBidCategoryFiltersDataRaw
): IBidCategoryFiltersData {
  const { categoryList, rankReport } = response;

  const formattedCategoryList = categoryList.map((category) => {
    const {
      _id,
      bidCount,
      brands,
      categoryName,
      categoryName_ar: categoryNameAr,
      imageUrl,
      priority,
    } = category;

    return {
      id: _id,
      bidCount,
      brands: formatBrands(brands),
      categoryName: {
        ar: categoryNameAr || categoryName,
        en: categoryName,
      },
      imageUrl,
      priority,
      value: categoryName,
    };
  });
  return {
    categoryList: formattedCategoryList,
    rankReport,
  };
}
