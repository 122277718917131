import {
  IGradeComponentReasonModel,
  IGradeReasons,
} from '@northladder/services';

import { IDeviceGradeConditionRawResponse } from '../../types';

/**
 * Type intermediate function for converting IDeviceGradeConditionRawResponse to
 *  IGradeReasons data for Device Grade api response
 */
export function formatDeviceGradeChangeResponse(
  response: IDeviceGradeConditionRawResponse
): IGradeReasons | undefined {
  const { data } = response || {};

  const deviceComponentIssues: IGradeComponentReasonModel[] = data?.map(
    (grade) => ({
      deviceComponent: grade.name,
      reasons: grade.conditions.map((reason) => {
        const { condition, itemCondition, itemConditionId } = reason;
        return {
          id: itemConditionId,
          reason: condition,
          itemCondition,
          checked: false,
        };
      }),
    })
  );

  return { deviceComponentIssues };
}
