import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconBadge component is svg for help icon which is customizable with some
 * default values configured. It is mainly used in Menu Items for navigation
 * but also can be used elsewhere.
 *
 * @param TSvgComponentProps Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
function IconBadge(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2497_41552)">
        <path
          d="M16.24 5.28308C18.58 7.62308 18.58 11.4231 16.24 13.7731C13.9 16.1131 10.1 16.1131 7.74999 13.7731C5.41 11.4331 5.41 7.63308 7.74999 5.28308C10.09 2.94308 13.89 2.94308 16.24 5.28308Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M8 21.5231L12 19.5231L16 21.5231L15.01 14.7031"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M8.99 14.7031L8 21.5231"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M10.9 8.41322L12 7.30322V11.7232"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M10.9 11.7332H13.1"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2497_41552">
          <rect
            fill="white"
            height="24"
            transform="translate(0 0.523193)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconBadge;
