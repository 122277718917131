import tw, { styled } from 'twin.macro';

export const DropdownButtonWrapper = styled.div`
  ${tw`inline-block`}

  .dropdown-button {
    ${tw`inline-flex items-center rounded-lg  border border-gray-200 px-4 py-2.5
    `}
  }

  .dropdown-button-bidscreen {
    ${tw`
    inline-flex items-center rounded-r-md  border border-gray-200 bg-white
    px-4 py-2.5 text-xs font-normal text-gray-600 hover:bg-purple-50
    hover:text-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-300
    rtl:rounded-l-md
  `};
  }
`;

export const DropdownLabelWrapper = styled.span`
  ${tw`
    block
    truncate
    text-xs
    font-normal

    ui-selected:text-purple-500
  `}
`;
