import Image from 'next/image';

interface IProfilePopoverTriggerBtnProps {
  /**
   * The first name of the user.
   */
  firstName: string;
  /**
   * The last name of the user.
   */
  lastName: string;
  /**
   * The image src of to render as the profile picture.
   * A fallback user icon is shown if the image is not present.
   */
  imageUrl?: string;
}

/**
 * -----------------------------------------------------------------------------
 * The button rendered as a small profile picture that triggers this pop-over
 * menu when clicked. It can be for example shown in the top right app header.
 */
export function ProfilePopoverTriggerBtn({
  firstName,
  lastName,
  imageUrl,
}: IProfilePopoverTriggerBtnProps) {
  return (
    <>
      <span className="sr-only">Open profile menu</span>

      {imageUrl ? (
        <Image
          alt={`Profile picture for logged in user - ${firstName} ${lastName}`}
          className="
            inline-block h-10 w-10 rounded-full border-2 border-purple-200/20
          "
          height={40}
          // placeholder="blur"
          src={imageUrl}
          width={40}
        />
      ) : (
        <div
          className="
            mx-auto flex h-10 w-10 shrink-0 items-center justify-center
            rounded-full border-4 border-purple-200/20 bg-purple-50/50
          "
        >
          <span
            aria-hidden="true"
            className="flex h-6 w-6 items-center justify-center text-purple-600"
          >
            {`${firstName[0].toUpperCase()}${(
              lastName[0] || ' '
            ).toUpperCase()}`}
          </span>
          {/* <UserIcon
            className="flex h-6 w-6 text-purple-600"
            aria-hidden="true"
          /> */}
        </div>
      )}
    </>
  );
}
