import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';

import {
  useBidsCategoryQuery,
  useBidsModelsQuery,
  IModelData,
  IBidModelsFiltersData,
} from '@northladder/apis';
import { ICategoryItem, IBrand, useSearchStore } from '@northladder/services';
import { TMenuItem, IPopoverItem } from '@northladder/design-system';

import { useTopStats, TDaysUpdateValue } from '../../../components';

const ASCENDING = 'asc';
const DESCENDING = 'desc';

type TOrderBy = 'asc' | 'desc';

/**
 * This custom hook provides reusable logic for the Bids Main Content.
 */
export const useBidsFilter = () => {
  const router = useRouter();
  const nextPageObserverRef = useRef(null);
  const { searchKey } = useSearchStore();
  const { today, fromDay, setSubDate } = useTopStats({ initialSubDayCount: 0 });
  const bidRankFilterKey = router.query?.bidFilter || '';

  const [modelsOptions, setModelsOptions] = useState<IPopoverItem[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<TMenuItem[]>([]);
  const [brandOptions, setBrandOptions] = useState<TMenuItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<TMenuItem | null>(
    null
  );
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState<TOrderBy | ''>('');
  const [selectedBrand, setSelectedBrand] = useState<TMenuItem | null>(null);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>();

  const {
    data: bidFilterCategoryData,
    isLoading: isLoadingCategoryData,
    error: categoryError,
  } = useBidsCategoryQuery();

  const addModelOptionsOnSuccess = (bidModelsData: IBidModelsFiltersData) => {
    if (bidModelsData?.groups) {
      const modelItems = bidModelsData.groups.map((model: IModelData) => ({
        id: model._id,
        title: model.name,
        checked: false,
      }));
      setModelsOptions(modelItems);
    }
  };

  const {
    refetch: callModelFiltersQuery,
    isFetching: isFetchingModels,
    error: modelError,
  } = useBidsModelsQuery({
    categoryId: selectedCategory?.id.toString() || '',
    brandId: selectedBrand?.id.toString() || '',
    onSuccessCallback: addModelOptionsOnSuccess,
  });

  const invertOrderBy = () => {
    if (orderBy === DESCENDING) setOrderBy(ASCENDING);
    else setOrderBy(DESCENDING);
  };

  const handleOnSortChange = (value: string) => {
    if (value === sortBy) invertOrderBy();
    else setOrderBy(DESCENDING);

    setSortBy(value);
  };

  const addBrandOptions = (brands: IBrand[]) => {
    const brandItems = brands?.map((brand: IBrand) => ({
      id: brand.id,
      title: brand.name,
    }));
    brandItems.unshift({ id: 'all', title: 'All Brands' });
    setBrandOptions(brandItems);
    setSelectedBrand(brandItems[0]);
  };

  const onCategoryOptionSelect = (item: TMenuItem) => {
    setSelectedCategory(item);
    const filteredCategory = bidFilterCategoryData?.categoryList.find(
      (category: ICategoryItem) => category.id === item.id
    );
    addBrandOptions(filteredCategory?.brands || []);
  };

  const onBrandOptionSelect = (item: TMenuItem) => {
    setSelectedBrand(item);
  };

  useEffect(() => {
    if (selectedCategory) {
      if (selectedBrand?.id === 'all') {
        setModelsOptions([]);
        setSelectedGroupIds([]);
      } else {
        callModelFiltersQuery();
      }
    }
  }, [callModelFiltersQuery, selectedBrand, selectedCategory]);

  useEffect(() => {
    if (bidFilterCategoryData?.categoryList) {
      const categoryItems = bidFilterCategoryData.categoryList.map(
        (category: ICategoryItem) => ({
          id: category.id,
          title: category.categoryName,
        })
      );

      setCategoryOptions(categoryItems);
      setSelectedCategory(categoryItems[0]);
      addBrandOptions(bidFilterCategoryData.categoryList[0]?.brands);
    }
  }, [bidFilterCategoryData]);

  const handleOnPopoverSelectCB = (popoverItems: IPopoverItem[]) => {
    setModelsOptions(popoverItems);

    const groupIds = popoverItems
      .filter((popoverItem) => popoverItem.checked)
      .map((popoverItem) => popoverItem.id);
    setSelectedGroupIds([...groupIds]);
  };

  const onDaySelectionChange = (value: TDaysUpdateValue) => {
    setSubDate(value.value);
  };

  return {
    nextPageObserverRef,
    selectedCategory,
    selectedBrand,
    selectedGroupIds,
    modelsOptions,
    categoryOptions,
    brandOptions,
    isLoadingCategoryData,
    categoryError,
    isFetchingModels,
    modelError,
    sortBy,
    orderBy,
    searchKey,
    fromDay,
    today,
    bidRankFilterKey,
    onSortChange: handleOnSortChange,
    onCategoryOptionSelect,
    onBrandOptionSelect,
    onPopoverSelectCB: handleOnPopoverSelectCB,
    onDaySelectionChange,
  };
};
