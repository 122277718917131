import { format } from '@northladder/utilities';
import { useBidExecutionTimeQuery } from '@northladder/apis';

import { DownloadBidsTemplate } from './DownloadBidsTemplate';

import { SectionWrapper } from '../../../../styles';
import { UploadBidsTemplate } from './UploadBidsTemplate';

/**
 * -----------------------------------------------------------------------------
 * This contains the action bar upload bids page.
 *
 * @param IUploadBidsActionBarProps
 * @returns JSX.Element
 */
export function UploadBidsActionBar() {
  const { data: bidExecutionData } = useBidExecutionTimeQuery();
  const { nextExecutionDayWithTime = null, validFrom = null } =
    bidExecutionData || {};

  return (
    <SectionWrapper className="grid grid-flow-col grid-rows-2 justify-items-start p-4 sm:flex sm:gap-2">
      <DownloadBidsTemplate />
      <UploadBidsTemplate />

      <div
        className="
          m-2 flex flex-col items-end justify-start sm:m-0
          sm:w-[30%] sm:pt-0
        "
      >
        <div>Next Price Upload Deadline (UAE time)</div>
        <span className="text-sm text-purple-500">
          {nextExecutionDayWithTime
            ? format(nextExecutionDayWithTime, 'do LLLL Y p')
            : '---'}
        </span>
      </div>

      <div
        className="
          m-2 flex flex-col items-end justify-start sm:m-0 sm:w-[30%] sm:pt-0
        "
      >
        <div>Newly Uploaded Prices will be</div>
        <span className="flex flex-col items-end">
          <span className="text-sm text-purple-500">
            Valid From:
            <span className="pl-2">
              {validFrom ? format(validFrom, 'do LLLL Y p') : '---'}
            </span>
          </span>
          <span className="text-sm text-purple-500">
            Valid Till:
            <span className="pl-2">
              {nextExecutionDayWithTime
                ? format(nextExecutionDayWithTime, 'do LLLL Y p')
                : '---'}
            </span>
          </span>
        </span>
      </div>
    </SectionWrapper>
  );
}
