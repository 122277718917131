import Link from 'next/link';
import tw, { styled } from 'twin.macro';

export const MyTotalBidsWrapper = styled(Link)`
  ${tw`
    flex
    items-center
    justify-start
    rounded-xl
    border
    py-2
    bg-white
    border-purple-100/50
    shadow-purple-100/50
    shadow-2xl
    relative
    col-span-1
    row-span-1
    transition-all
    ease-in-out
    duration-500
    bg-purple-400/50

    hover:(bg-purple-200/20 shadow-purple-100 border-purple-100/80)

    md:(bg-transparent  col-span-3 row-span-1 py-4 rounded-2xl)
    lg:(col-span-6 row-span-3)
  `};

  .icon-container {
    ${tw`
      flex
      shrink-0
      items-center
      justify-center
      h-12
      w-12
      mx-2
      bg-gray-400
      rounded-full
      stroke-gray-400
      transition-all
      ease-in-out
      duration-500

      md:(h-20 w-20 mx-6)
      lg:(mx-10)
    `};

    svg {
      ${tw`
        h-8
        w-8
        transition-all
        duration-500
        ease-in-out
      `};
    }

    path {
      ${tw`
        fill-white
        transition-all
        ease-in-out
        delay-100
        duration-500
      `};
    }
  }

  .link-arrow {
    ${tw`
      absolute
      top-2
      right-4
      h-6
      w-6
      opacity-0
      pointer-events-none
      transition-all
      ease-in-out
      delay-200
      duration-500
      text-purple-300
      rtl:(rotate-180 left-2 right-auto)
    `};
  }

  .my-bid-label {
    ${tw`
      mt-0
      text-xs
      text-gray-700
      transition-all
      duration-500
      ease-in-out

      md:(mt-2)
    `};
  }

  .my-bid-value {
    ${tw`
      mt-0
      text-2xl
      font-normal
      text-gray-700
      transition-all
      duration-500
      ease-in-out

      md:(mt-2 text-4xl)
  `};
  }
`;

/**
 * -----------------------------------------------------------------------------
 * BidFilterItemWrapper wraps around a single bid filter item to provide
 * underlying styles.
 */
export const BidFilterItemWrapper = styled(Link)`
  ${tw`
    flex
    items-start
    justify-start
    rounded-xl
    border
    p-2
    shadow-2xl
    max-h-14
    outline-1
    outline-gray-50
    transition-all
    ease-in-out
    duration-500

    focus:(border-white outline-none ring-2 ring-white)

    md:(items-center justify-center max-h-fit  rounded-2xl)
    lg:p-4
  `};

  .bid-icon-title-container {
    ${tw`
      flex
      flex-row
      items-center

      md:(flex-col gap-2)
      lg:(gap-8)
    `};
  }

  .dashboard-layout & {
    ${tw`
      relative
      col-span-1
      row-span-2

      sm:(col-span-1 row-span-1)
      lg:(bg-transparent col-span-2 row-span-3)
    `};
  }

  .link-arrow {
    ${tw`
      absolute
      top-1
      right-2
      h-6
      w-6
      opacity-0
      pointer-events-none
      transition-all
      ease-in-out
      delay-200
      duration-500
      text-white

      rtl:(rotate-180 left-2 right-auto)
    `};
  }

  .bid-badge-star {
    ${tw`
      flex
      h-10
      w-10
      transition-all
      ease-in-out
      duration-200

      md:(h-14 w-14 items-center justify-center)
    `};
  }

  .bid-badge-count {
    ${tw`
      absolute
      top-1/4
      left-0
      w-full
      text-center
      text-sm
      font-normal
      text-gray-100
      transition-all
      ease-in-out
      duration-500

      md:(text-lg items-center justify-center)
  `};
  }

  .icon-deal-fire {
    ${tw`
      absolute
      top-[20%]
      left-[20%]
      text-gray-100
      w-6
      h-6
      transition-all
      ease-in-out
      duration-500

      md:(top-1/4 w-8 h-8)
    `};
  }

  .bid-filter-title {
    ${tw`
      text-center
      text-xs
      font-normal
      text-gray-700
      mx-5
      transition-all
      ease-in-out
      delay-100
      duration-500

      md:(mx-0)
    `};
  }

  &.type-hot-deal {
    ${tw`
      bg-purple-50/50
      border-purple-100/50
      shadow-purple-100/80
      transition-all
      ease-in-out
      delay-100
      duration-500

      hover:(bg-purple-200/50 shadow-purple-200 border-purple-100/80)
    `};

    .bid-badge-star {
      path {
        ${tw`
          fill-[#35198a]
          transition-all
          ease-in-out
          delay-100
          duration-500

          hover:fill-orange-100
        `};
      }

      polygon {
        ${tw`
          fill-[#1C0B50]
          transition-all
          ease-in-out
          delay-100
          duration-500

          hover:fill-orange-500
        `};
      }
    }
  }

  &.OUT_OF_QUANTITY {
    ${tw`
      bg-red-50/50
      border-red-100/50
      shadow-red-100/80

      hover:(bg-red-200/50 shadow-red-200 border-red-100/80)
    `};

    .bid-badge-star {
      path {
        ${tw`
          fill-red-600
          hover:fill-red-900
        `};
      }

      polygon {
        ${tw`
          fill-red-700
          hover:fill-red-800
        `};
      }
    }
  }

  &.BID_NOT_WINNING {
    ${tw`
      bg-orange-50/50
      border-orange-100/50
      shadow-orange-100/80

      hover:(bg-orange-200/50 shadow-orange-200 border-orange-100/80)
    `};

    .bid-badge-star {
      path {
        ${tw`
          fill-[#fa9e82]

          hover:fill-orange-400
        `};
      }

      polygon {
        ${tw`
          fill-[#F68563]

          hover:fill-orange-600
        `};
      }
    }
  }

  &.RANK_ONE {
    ${tw`
      bg-green-50/50
      border-green-100/50
      shadow-green-100/80

      hover:(bg-green-200/50 shadow-green-200 border-green-100/80)
    `};

    .bid-badge-star {
      path {
        ${tw`
          fill-green-600

          hover:fill-green-900
        `};
      }

      polygon {
        ${tw`
          fill-green-700

          hover:fill-green-800
        `};
      }
    }
  }

  &.WINING_RANGE {
    ${tw`
    bg-sky-50/50
    border-sky-100/50
    shadow-sky-100/80
    hover:(bg-sky-200/50 shadow-sky-200 border-sky-100/80)
  `};

    .bid-badge-star {
      path {
        ${tw`
        fill-sky-300

        hover:fill-sky-500
      `};
      }

      polygon {
        ${tw`
        fill-sky-500

        hover:fill-sky-300
      `};
      }
    }
  }

  .page-action-bar-bids-badges & {
    ${tw`
      px-2
      py-0
      border-0
      bg-none
      rounded-lg
      min-w-[20%]
      items-center
      justify-start
    `};

    .bid-icon-title-container {
      ${tw`
        gap-2

        md:(flex-row)
      `};
    }

    .bid-badge-star {
      ${tw`
        flex
        rounded-full
        h-8
        w-8

        md:(h-8 w-8)
      `};
    }

    .bid-badge-count {
      ${tw`
      top-[20%]
      text-sm
     `};
    }

    .icon-deal-fire {
      ${tw`
        top-[15%]
        w-5
        h-5
      `};
    }

    .bid-filter-title {
      font-size: 10px;

      ${tw`md:(text-xs)`};
    }

    &.type-hot-deal {
      ${tw`
        -ml-2
      `};
    }
  }
`;

export const UploadBidActionWrapper = styled.main`
  ${tw`
    flex
    grow
    px-2
    justify-center
    mx-auto
    mb-5
    w-full
    h-full
    max-w-[80rem]
    transition-all
    ease-in-out
    duration-200

    sm:px-4
    sm:py-0
    md:px-8
    xl:p-0
  `};
`;
