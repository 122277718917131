import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconFire component is svg for fire icon which is customizable with some
 * default values configured. It is mainly used in Menu Items for navigation
 * but also can be used elsewhere.
 *
 * @param TSvgComponentProps Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
function IconFire(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 16 19"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.00583 6.29212L6.72658 6.77263L7.09901 5.99054L9.55009 0.843272C10.716 2.01213 11.9916 3.38353 12.9808 4.95872C14.171 6.85421 14.7277 8.67211 14.7277 10.5343C14.7277 12.3216 14.0177 14.0356 12.754 15.2994C11.4902 16.5632 9.77612 17.2732 7.98887 17.2732C6.20161 17.2732 4.48755 16.5632 3.22377 15.2994C1.95999 14.0356 1.25 12.3216 1.25 10.5343C1.25 8.88122 1.95387 6.88962 3.46115 4.59564L6.00583 6.29212ZM9.61032 0.716799C9.61027 0.716889 9.61023 0.71698 9.61019 0.71707L9.61027 0.716902L9.61032 0.716799ZM3.37855 4.54058L3.37872 4.54069C3.37865 4.54064 3.37859 4.5406 3.37852 4.54056L3.37855 4.54058Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default IconFire;
