// TODO: Remove this line once the API is ready.
/* eslint-disable max-len */

import { ApiClient } from '../api-client';
import { ICosmeticsAssessmentOptions } from '../types';
import {
  deviceImageUploadsListUrl,
  deviceImageUploadUrl,
  doneUploadingImageUrl,
} from '../urls';

import {
  IDeviceImageListResponseDataRaw,
  IDeviceImageListGradeProResponseDataRaw,
  IDeviceImageUploadOptions,
  IDeviceImageUploadPayload,
  IDeviceImageUploadResponseData,
  IDoneUploadingDeviceImagesPayload,
  IDoneUploadingDeviceImagesResponseData,
  IImageListItem,
  TImageUploadStatus,
} from './types';

/**
 * Transforms the images list to the format expected by the UI.
 */
export function formatDeviceImageUploadsListApple(
  responseData: IDeviceImageListResponseDataRaw['data']
) {
  const { tokens: imagesList, nlAssetName: productName } = responseData || {};

  const updatedImagesList: IImageListItem[] = imagesList.map((image) => {
    const status = image?.status?.toLowerCase() as TImageUploadStatus;

    return {
      ...image,
      status,
    };
  });

  return { images: updatedImagesList, productName };
}

/**
 * Transforms the images list to the format expected by the UI.
 */
export function formatDeviceImageUploadsListGradePro(
  responseData: IDeviceImageListGradeProResponseDataRaw['data']
) {
  // TODO: Pass down the product name to the screens later.
  const {
    images: imagesList,
    productName: _,
    nlAssetName: productName,
  } = responseData || {};

  const updatedImagesList: IImageListItem[] = imagesList.map((image) => {
    const {
      imageId: id,
      fileUploadedPath: url = '',
      uploadedOn,
      position,
    } = image;
    const status = image?.status?.toLowerCase() as TImageUploadStatus;

    return {
      id,
      status,
      url,
      uploadedOn,
      metadata: {
        color: 'ANY',
        position,
        size: 'Cropped',
      },
    };
  });

  return { images: updatedImagesList, productName };
}

/**
 * This is provides the API client methods for all user authentication,
 * authorization and identity services like login, forgot password etc.
 *
 * NOTE: The `refreshToken` service has been moved out as a global to avoid
 * cyclic dependencies since its to be consumed within the base `ApiClient`.
 *
 */
export class CosmeticEvaluationClient extends ApiClient {
  private static classInstance?: CosmeticEvaluationClient;

  baseURL = process.env.NEXT_PUBLIC_DIAGNOSTICS_BASE_URL;

  private constructor() {
    super({
      baseURL: process.env.NEXT_PUBLIC_DIAGNOSTICS_BASE_URL,
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new CosmeticEvaluationClient();
    }

    return this.classInstance;
  };

  /**
   * This fetches the list of required images to be uploaded later to the apple
   * diagnostic servers. With a key, token, url for each image.
   */
  public fetchDeviceImageUploadsList = async ({
    sessionId,
    assessmentType,
  }: ICosmeticsAssessmentOptions) => {
    const response = await this.get<
      IDeviceImageListResponseDataRaw | IDeviceImageListGradeProResponseDataRaw
    >(deviceImageUploadsListUrl({ sessionId, assessmentType }), {
      baseURL: this.baseURL,
      requiresAuth: false,
    });

    if (!response.success) throw response;

    if (assessmentType === 'APPLE') {
      // TODO: Tell @Jihin to add common keys to both responses.
      // @ts-ignore
      return formatDeviceImageUploadsListApple(response.data?.data);
    }

    // TODO: Tell @Jihin to add common keys to both responses.
    // @ts-ignore
    return formatDeviceImageUploadsListGradePro(response.data?.data);
  };

  /**
   * This uploads the captured device image to the servers.
   * Mainly expects the image file the image color id as `imageId` and the
   * sessionid as `id`.
   */
  public uploadDeviceImage = async ({
    id,
    imageId,
    file,
    assessmentType,
  }: IDeviceImageUploadOptions) => {
    const response = await this.post<
      IDeviceImageUploadResponseData,
      IDeviceImageUploadPayload
    >(
      deviceImageUploadUrl(),
      { assessmentType, id, imageId, file },
      {
        baseURL: this.baseURL,
        requiresAuth: false,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 1 * 60 * 1000, // Force a 1 minute wait on uploads.
      }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * This API verifies with the backend that all the images have been completed
   * for server to kill this session and have the user proceed to the Retail App.
   */
  public doneUploadingImage = async (sessionId: string) => {
    const response = await this.post<
      IDoneUploadingDeviceImagesResponseData,
      IDoneUploadingDeviceImagesPayload
    >(
      doneUploadingImageUrl(),
      { id: sessionId, status: 'COMPLETED' },
      {
        baseURL: this.baseURL,
        requiresAuth: false,
      }
    );

    if (!response.success) throw response;

    return response;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for cosmetic evaluations related requests.
 */
export const COSMETIC_EVALUATION_API =
  CosmeticEvaluationClient.getClientInstance();
