import { IFetchBidItemConditionRawResponse, IBidItemCondition } from '../types';

export function formatBidItemConditionResponse(
  rawItemCondition: IFetchBidItemConditionRawResponse
): IBidItemCondition[] {
  if (rawItemCondition?.data?.conditions) {
    const cleanItemCondition: IBidItemCondition[] =
      rawItemCondition.data.conditions?.map((rawConditionData) => ({
        conditionName: rawConditionData?.condition,
        conditionPercentageValue: rawConditionData?.dealerPercentValue
          ? rawConditionData?.dealerPercentValue
          : rawConditionData?.percentValue,
      }));

    return cleanItemCondition;
  }

  return [];
}
