import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';

import { DropdownButtonWrapper, DropdownLabelWrapper } from './styles';

/**
 * The expected fields on each days object
 */
export type TDays = {
  id: number;
  /**
   * The label of the `DateSelectionPopup` item
   */
  label: string;
  value: number;
};

/**
 * A list of sort days to show on the drop down select menu.
 */
export const defaultDays: TDays[] = [
  { id: 1, label: 'Last 30 days', value: 30 },
  { id: 2, label: 'Last 15 days', value: 15 },
  { id: 3, label: 'Last 7 days', value: 7 },
  { id: 4, label: 'Yesterday', value: 1 },
];

/**
 * Updates a react piece of state with the `TDays` value.
 */
export type TDaysUpdateValue = TDays;

export interface IDateSelectionPopupProps {
  /**
   * Callback supplied to the parent component to provide the selected days.
   */
  onSelectDays: (selectedDays: TDaysUpdateValue) => void;
  /**
   * Takes the dropdown options.
   */
  days?: TDays[];
  /**
   * For styling the dropdown.
   */
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Dropdown provides UX for choosing sort days from a list of days, rendering
 * the days we need to sort.
 *
 * TODO: Move this to the design system.
 */
export function DateSelectionPopup({
  onSelectDays,
  days = defaultDays,
  className,
}: IDateSelectionPopupProps) {
  const [itemSelected, setItemSelected] = useState(days[0]);

  const handleSelectionChange = (value: TDays) => {
    onSelectDays(value);
    setItemSelected(value);
  };

  return (
    <DropdownButtonWrapper>
      <Listbox onChange={handleSelectionChange} value={itemSelected}>
        <div className="relative">
          <Listbox.Button className={className}>
            <CalendarDaysIcon
              className="mr-2 flex items-center fill-purple-500 rtl:ml-2"
              height="18px"
              width="18px"
            />
            <span className="block truncate">{itemSelected.label}</span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="
                absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white
                py-1 text-xs font-normal shadow-lg ring-1 ring-black ring-opacity-5
                focus:outline-none sm:text-sm
              "
            >
              {days?.map((day) => (
                <Listbox.Option
                  key={day.id}
                  className="
                    cursor-pointer select-none py-1 pl-3 pr-1
                    hover:bg-purple-50 hover:font-medium hover:text-purple-500
                  "
                  value={day}
                >
                  <DropdownLabelWrapper>{day.label}</DropdownLabelWrapper>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </DropdownButtonWrapper>
  );
}
