import { useState, ChangeEvent, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import {
  ActionBarButton,
  AppModal,
  SimpleDropdown,
  TMenuItem,
} from '@northladder/design-system';
import { IApiError, useUploadBidFileMutation } from '@northladder/apis';
import { Text } from '@northladder/i18n';
import {
  ModalChildComponentWrapper,
  ModalFooterComponentWrapper,
} from '../../../../styles';
import { useUploadBidsContentData } from './useUploadBidsContentData';
import { TemplateBidsFallback } from './TemplateBidsFallback';

interface IUploadModalChildComponentProps {
  categoryOptions: TMenuItem[];
  onCategoryOptionSelect: (item: TMenuItem) => void;
  isLoadingCategoryData: boolean;
  categoryError: IApiError | null;
  selectedCategory: TMenuItem | null;
  handleUploadBidFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedUploadBidFile: File | null;
  resetUploadBidFile: () => void;
  uploadModalError: string;
  onBidFileUpload: () => void;
  isLoading: boolean;
  error: IApiError | null;
  isSuccess: boolean;
  successMessage: string;
}

function UploadModalChildComponent({
  categoryOptions,
  onCategoryOptionSelect,
  isLoadingCategoryData,
  categoryError,
  selectedCategory,
  handleUploadBidFileChange,
  selectedUploadBidFile,
  resetUploadBidFile,
  uploadModalError,
  isLoading,
  error,
  isSuccess,
  successMessage,
  onBidFileUpload,
}: IUploadModalChildComponentProps) {
  if (isLoading || error || isSuccess) {
    return (
      <TemplateBidsFallback
        error={error}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onDownloadRetry={onBidFileUpload}
        successMessage={successMessage}
      />
    );
  }

  return (
    <ModalChildComponentWrapper className="upload-modal-child-wrapper">
      <div className="mt-3.5 flex flex-row gap-2">
        <SimpleDropdown
          className="pl-2"
          defaultTitle="Select category"
          error={categoryError}
          items={categoryOptions}
          loadingState={isLoadingCategoryData}
          menuItemsClassName="menu-item-list z-20"
          onDropDownItemSelect={onCategoryOptionSelect}
        />
        <div className="relative">
          <label className="browse-label" htmlFor="upload-bid-file">
            Browse
          </label>
          <input
            className="browse-btn w-1/4"
            id="upload-bid-file"
            onChange={handleUploadBidFileChange}
            type="file"
          />
        </div>
      </div>
      <div className="text-sm text-red-400">{uploadModalError}</div>

      {selectedUploadBidFile ? (
        <div className="flex flex-row justify-between p-3 text-sm">
          <Text text={selectedCategory?.title || ''} />
          <div className="flex flex-row items-center justify-center">
            {selectedUploadBidFile?.name}
            <XMarkIcon
              className="h-6 w-6 cursor-pointer text-purple-500"
              onClick={resetUploadBidFile}
            />
          </div>
        </div>
      ) : null}
    </ModalChildComponentWrapper>
  );
}

interface IModalFooterComponentProps {
  onCancel: () => void;
  handleBidFileUpload: () => void;
}

function ModalFooterComponent({
  onCancel,
  handleBidFileUpload,
}: IModalFooterComponentProps) {
  return (
    <ModalFooterComponentWrapper>
      <button className="cancel-btn w-2/4" onClick={onCancel} type="button">
        Cancel
      </button>
      <button
        className="upload-btn w-2/4"
        onClick={handleBidFileUpload}
        type="button"
      >
        Upload
      </button>
    </ModalFooterComponentWrapper>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This component manages the state and component for download bids template.
 *
 * @returns JSX.Element
 */
export function UploadBidsTemplate() {
  const {
    categoryOptions,
    isLoadingCategoryData,
    categoryError,
    callUploadBidFilesQuery,
  } = useUploadBidsContentData();
  const {
    data,
    mutate: callUploadBidFile,
    isLoading,
    error,
    reset,
    isSuccess,
  } = useUploadBidFileMutation();

  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [selectedUploadBidFile, setSelectedUploadBidFile] =
    useState<File | null>(null);
  const [selectedUploadBidFilesCategory, setSelectedUploadBidFilesCategory] =
    useState<TMenuItem | null>(null);
  const [uploadModalError, setUploadModalError] = useState<string>('');

  const onUploadBidFilesCategoryOptionSelect = (item: TMenuItem) => {
    setSelectedUploadBidFilesCategory(item);
  };

  const resetUploadBidFile = () => {
    setUploadModalError('');
    setSelectedUploadBidFile(null);
  };

  const toggleUploadModal = () => {
    setOpenUploadModal((state) => {
      if (state) {
        reset();
        callUploadBidFilesQuery();
      }
      return !state;
    });
    resetUploadBidFile();
  };

  const onUploadBidFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setSelectedUploadBidFile(null);
      return;
    }
    setUploadModalError('');
    setSelectedUploadBidFile(event?.target?.files[0]);
  };

  const onBidFileUpload = () => {
    const formData = new FormData();
    if (!selectedUploadBidFile) {
      // throw error that file is required
      setUploadModalError('Please upload the file.');
      return;
    }

    if (!selectedUploadBidFilesCategory || !selectedUploadBidFilesCategory.id) {
      setUploadModalError('Category is required!');
      // throw error if category is not selected.
      return;
    }

    formData.append('file', selectedUploadBidFile);

    callUploadBidFile({
      queryParams: {
        categoryId: selectedUploadBidFilesCategory.id.toString(),
      },
      file: formData,
    });
  };

  useEffect(() => {
    if (categoryOptions && categoryOptions.length > 0) {
      setSelectedUploadBidFilesCategory(categoryOptions[0]);
    }
  }, [categoryOptions]);

  return (
    <>
      <div
        className="
          m-2 flex flex-col items-start justify-start sm:m-0 sm:w-[20%]
        "
      >
        <div>Upload</div>
        <ActionBarButton
          className="no-border-button"
          icon="iconArrowDown"
          iconClassName="h-4 w-4 rotate-180"
          label="Customer price"
          onClick={toggleUploadModal}
        />
      </div>

      <AppModal
        footerComponent={
          isSuccess ? null : (
            <ModalFooterComponent
              handleBidFileUpload={onBidFileUpload}
              onCancel={toggleUploadModal}
            />
          )
        }
        isOpen={openUploadModal}
        modalChildComponent={
          <UploadModalChildComponent
            categoryError={categoryError}
            categoryOptions={categoryOptions}
            error={error}
            handleUploadBidFileChange={onUploadBidFileChange}
            isLoading={isLoading}
            isLoadingCategoryData={isLoadingCategoryData}
            isSuccess={isSuccess}
            onBidFileUpload={onBidFileUpload}
            onCategoryOptionSelect={onUploadBidFilesCategoryOptionSelect}
            resetUploadBidFile={resetUploadBidFile}
            selectedCategory={selectedUploadBidFilesCategory}
            selectedUploadBidFile={selectedUploadBidFile}
            successMessage={
              data?.data.message || 'Bids template uploaded successfully!'
            }
            uploadModalError={uploadModalError}
          />
        }
        setIsOpen={toggleUploadModal}
        title="Upload customer price for each category"
      />
    </>
  );
}
