import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconChart component is svg for chart icon which is customizable with some
 * default values configured. It is mainly used in Menu Items for navigation
 * but also can be used elsewhere.
 *
 * @param TSvgComponentProps Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
export default function IconChart(props: TSvgComponentProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M6.9 18.3c-.4 0-.8-.3-.8-.8V10c0-.4.3-.8.8-.8s.8.4.8.8v7.5c0 .5-.4.8-.8.8zM12 18.3c-.4 0-.8-.3-.8-.8V6.4c.1-.4.4-.7.8-.7s.8.3.8.8v11.2c0 .3-.3.6-.8.6zM17.1 18.3c-.4 0-.8-.3-.8-.8V14c0-.4.3-.8.8-.8s.8.3.8.8v3.6c-.1.4-.4.7-.8.7z" />
      <path d="M17.2 23.8H6.8C2.9 23.8.2 21 .2 16.9V7.1C.2 3 2.9.2 6.8.2h10.3c3.9 0 6.6 2.8 6.6 6.9v9.7c.1 4.2-2.6 7-6.5 7zM6.8 1.7c-3.1 0-5.1 2.2-5.1 5.5v9.7c0 3.3 2 5.4 5.1 5.4h10.3c3.1 0 5.1-2.1 5.1-5.4V7.1c0-3.3-2-5.4-5.1-5.4H6.8z" />
    </svg>
  );
}
