import { useRouter } from 'next/router';

import { IAppraisalData } from '@northladder/apis';
import { format } from '@northladder/utilities';
import { useAuthStore } from '@northladder/services';

/**
 * Upload bids map over uploaded bid files data
 * on to be displayed in the components below.
 */
export interface IAppraisalListItem {
  appraisalData: IAppraisalData;
}

/**
 * -----------------------------------------------------------------------------
 * This renders an individual appraisal list item.
 */
export default function AppraisalListItem({
  appraisalData,
}: IAppraisalListItem) {
  const { push } = useRouter();
  const { activeSession } = useAuthStore();
  const accountId = activeSession?.index || 0;

  if (!appraisalData) return null;

  const {
    dealTS,
    appraisalCode,
    assetInfo,
    serialNumber,
    approvedDeal,
    sellAmount,
    assetInventory,
  } = appraisalData;

  const handleNavigation = () => {
    push(`/${accountId}/my-deals/${appraisalCode}`);
  };

  return (
    <tr
      className="group cursor-pointer transition-all delay-100 duration-200 ease-in-out
        even:bg-purple-50/40 hover:bg-purple-100/50"
      onClick={handleNavigation}
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        {format(dealTS, 'dd MMM yyyy')}
      </td>
      <td className="py-4 text-sm text-gray-500 lg:table-cell">
        {appraisalCode}
      </td>
      <td className="flex flex-row items-center px-3 py-4 text-sm">
        {assetInfo?.label}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {serialNumber}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {approvedDeal?.itemCondition}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {sellAmount.dealPrice + sellAmount.underwriterSellTaxValue}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {assetInventory?.sourceStoreName}
      </td>
    </tr>
  );
}
