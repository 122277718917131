import { ICreditLimit, ICreditLimitData } from '../../types';

/**
 * Type intermediate function for converting ICreditLimitData to ICreditLimit
 * data for CreditLimit api response
 */
export function formatCreditLimitResponse(
  response: ICreditLimitData
): ICreditLimit {
  const {
    availableCredit,
    availableCreditPercentage,
    blockedCredit,
    blockedCreditPercentage,
    runningBalance,
    runningBalancePercentage,
    creditLimit,
    blockedDeals,
    isExceeded,
    isExceededDate,
  } = response || {};

  return {
    availableCredit,
    availableCreditPercentage,
    blockedCredit,
    blockedCreditPercentage,
    runningBalance,
    runningBalancePercentage,
    creditLimit,
    blockedDealsCount: blockedDeals.length,
    isCreditLimitExceeded: isExceeded,
    creditLimitExceededDate: isExceededDate,
  };
}
