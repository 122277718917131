/**
 * This renders reusable translations common to the Profile sections.
 * TODO: Move this to a Strapi API endpoint
 */
export const translations = {
  yes: {
    en: 'Yes',
    ar: 'نعم',
  },
  no: {
    en: 'No',
    ar: 'لا',
  },
  cancel: {
    en: 'Cancel',
    ar: 'يلغي',
  },
  edit: {
    en: 'Edit',
    ar: 'تعديل',
  },
  profileInfo: {
    en: 'Profile Info',
    ar: 'معلومات الشخصي',
  },
  storeInfo: {
    en: 'Store Info',
    ar: 'معلومات المتجر',
  },
  teamMembers: {
    en: 'Team Members',
    ar: 'أعضاء الفريق',
  },
  phoneNumber: {
    en: 'Phone Number',
    ar: 'رقم التليفون',
  },
  accountType: {
    en: 'Account Type',
    ar: 'نوع الحساب',
  },
  emailAddress: {
    en: 'Email Address',
    ar: 'عنوان البريد الالكترونى',
  },
  region: {
    en: 'Region',
    ar: 'منطقة',
  },
  role: {
    en: 'Role',
    ar: 'دور',
  },
  joined: {
    en: 'Joined',
    ar: 'انضم',
  },
  branchName: {
    en: 'Branch Name',
    ar: 'اسم الفرع',
  },
  storeType: {
    en: 'Store Type',
    ar: 'نوع المتجر',
  },
  isBranchActive: {
    en: 'Is Active',
    ar: 'نشط',
  },
  currency: {
    en: 'Currency',
    ar: 'عملة',
  },
  account: {
    en: 'Account',
    ar: 'حساب',
  },
  team: {
    en: 'Team',
    ar: 'فريق',
  },
  settings: {
    en: 'Settings',
    ar: 'إعدادات',
  },
  change: {
    en: 'Change',
    ar: 'يتغير',
  },
};
