import {
  IGradeDealsSearchRawData,
  IGradeReviewList,
  IGradeReviewRequestListResponseData,
  IGradeStatus,
} from '../../types';

/**
 * Formatter to transform the `raw grade deal search request list API response`
 * to IGradeReviewRequestListResponseData
 */
export function formatRequestGradeDealsSearchResponse(
  response: IGradeDealsSearchRawData
): IGradeReviewRequestListResponseData {
  const { appraisals, totalCount } = response || {};

  const gradeList: IGradeReviewList[] = appraisals.map((deliveredDevices) => {
    const {
      appraisalCode,
      appraisalStatus,
      dealPrice,
      deliveredOn,
      label,
      paymentType,
      validationErrors,
      brandName,
      saleGrade,
      serialNumber,
    } = deliveredDevices || {};

    let displayStatus = [appraisalStatus];

    if (paymentType) {
      displayStatus = [paymentType, ...displayStatus];
    }

    return {
      appraisalCode,
      assetName: label,
      serialNumber,
      gradeCondition: saleGrade,
      brandName,
      gradeReviewRequestId: '',
      date: deliveredOn,
      gradeReviewTicketNumber: '',
      displayStatus,
      gradeStatus: deliveredOn
        ? IGradeStatus.DEVICE_DELIVERED
        : IGradeStatus.NONE,
      purchasePrice: dealPrice,
      validateErrorMessages: validationErrors,
      isCreatedByNL: false,
    };
  });

  return {
    gradeList,
    count: totalCount,
    gradeFilterOptions: [],
  };
}
