import { isMobile } from '@northladder/utilities';

import {
  TBidModelsFiltersUrlOptions,
  TFetchAllBidsUrlOptions,
  TPaginationOptions,
  IDownloadBidBookUrlOptions,
  TFetchBidHistoryParams,
  TFetchUploadBidFilesPayload,
  TDownloadUploadBidFilePayload,
  IFetchBidDetailsParam,
} from '../types';

/**
 * `bidCategoryFiltersUrl` is a function to generate endpoint for category, brands
 * and rank report filter dropdowns and component.
 * @returns string
 */
export const bidCategoryFiltersUrl = (): string =>
  '/category/active-list-with-brand';

/**
 * `bidModelsFiltersUrl` is a function to generate endpoint for model filter
 * dropdown and data for rank report component.
 * @param TBidModelsFiltersUrl
 * @returns string
 */
export const bidModelsFiltersUrl = ({
  categoryId,
  brandId,
}: TBidModelsFiltersUrlOptions) => `/assets/groups/${categoryId}/${brandId}`;

/**
 * `downloadBidBookUrl` is a function to generate endpoint for downloading bid book.
 *
 * @param IDownloadBidBook
 * @returns string
 */
export const downloadBidBookUrl = ({
  categoryId,
  brandId,
}: IDownloadBidBookUrlOptions) =>
  `/assets/export/active-asset-bids/${categoryId}/${brandId}`;

export const bidStatusUpdateUrl = (assetId: string) =>
  `/bids/dealer/${assetId}`;

/**
 * Update bid mutation url function
 */
export const updateBidUrl = () => '/bids';

export const fetchAllBidsUrl = ({
  categoryId,
  brandId,
  page,
  count,
}: TFetchAllBidsUrlOptions) => {
  if (brandId === 'all') {
    return `/assets/all-assets-with-bid/${categoryId}?page=${page}&count=${count};`;
  }
  return `/assets/all-assets-with-bid/${categoryId}/${brandId}?page=${page}&count=${count};`;
};

export const fetchAllBidsByModelsUrl = ({ page, count }: TPaginationOptions) =>
  `/assets/with-bid-details-by-groupIds?page=${page}&count=${count};`;

export const fetchBidsQuantityUrl = (assetId: string) =>
  `/bids/bid-options/${assetId}`;

/**
 * `uploadBidBookUrl` is a function to generate endpoint for uploading bid book.
 */
export const uploadBidBookUrl = () => '/assets/import/active-asset-bids';

/**
 * function to generate endpoint for bid list.
 */
export const bidSearchUrl = (isHotDealsSearch?: boolean) => {
  if (isMobile) {
    if (isHotDealsSearch) return '/assets/top-trending/list';

    return '/assets/bid-list?version=v2';
  }

  return '/assets/biding';
};

/**
 * function to generate endpoint for bid list.
 */
export const bidSearchPaasUrl = () => '/paas/biding';

export const fetchBidHistoryUrl = ({ assetId }: TFetchBidHistoryParams) =>
  `/bids/${assetId}/history`;

export const rankReportUrl = () => '/bids/summary';

export const fetchStepperValueUrl = (assetId: string) =>
  `/bids/stepper-value/${assetId}`;

/**
 * function to generate endpoint for bid item condition.
 */
export const fetchBidItemConditionUrl = (assetId: string) =>
  `/v3/configuration/item-condition/info/${assetId}`;

/**
 * function to generate endpoint for bid item condition.
 */
export const bidRankUrl = () => '/bids/ranks/';

/**
 * function to generate endpoint for bid details.
 */
export const fetchBidDetailsUrl = ({ assetId }: IFetchBidDetailsParam) =>
  `/assets/asset-details-with-bid-details/${assetId}`;

// -----------------------------------------------------------------------------
// ------------------------ 📝 DEALER SIGN UP ENDPOINTS ------------------------
// -----------------------------------------------------------------------------

/**
 * This endpoint that sends the OTP required for user registration.
 */
export const sendUserRegistrationOTPUrl = () => '/dealer-signup/send-otp';

/**
 * This endpoint that validate/verifies the OTP required for user registration.
 */
export const validateUserRegistrationOTPUrl = () => '/dealer-signup/verify-otp';

/**
 * This endpoint updates the current registration details of the user based on
 */
export const updateUserRegistrationDetailsUrl = () => '/dealer-signup/update';

/**
 * This endpoint retrieves the current registration details of the user based on
 * the provided token.
 */
export const fetchUserRegistrationDetailsUrl = () => '/dealer-signup/details';

/**
 * This endpoint retrieves the category of devices users can trade in.
 */
export const fetchTradeInDeviceCategoriesUrl = () => '/category/electronics';

/**
 * Function to generate endpoint for uploaded bid file list.
 */
export const fetchUploadBidFilesUrl = ({
  categoryId,
  count,
  page,
}: TFetchUploadBidFilesPayload) =>
  `/paas/dealer/bid-upload-files/${categoryId}?count=${count}&page=${page}`;

/**
 * Function to generate endpoint for upload bid file template.
 */
export const downloadUploadBidFileTemplateUrl = ({
  categoryId,
}: TDownloadUploadBidFilePayload) =>
  `/assets/${categoryId}/export/asset-bid-upload-template`;

/**
 * Function to generate endpoint for bid file upload by category.
 */
export const uploadBidFileUrl = ({
  categoryId,
}: TDownloadUploadBidFilePayload) => `/paas/dealer/bid-upload/${categoryId}`;
