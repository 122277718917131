import { IFetchAppraisalsData } from '@northladder/apis';

import AppraisalListItem from './AppraisalListItem';

interface IAppraisalListItemsProps {
  items: IFetchAppraisalsData[];
}

/**
 * -----------------------------------------------------------------------------
 * This renders a list of appraisal flattened appraisals.
 *
 */
function AppraisalListItems({ items }: IAppraisalListItemsProps) {
  return (
    <>
      {items.map(
        ({ appraisals }) =>
          appraisals.map((appraisalData) => (
            <AppraisalListItem
              key={appraisalData?._id}
              appraisalData={appraisalData}
            />
          ))
        // eslint-disable-next-line function-paren-newline
      )}
    </>
  );
}

interface IAppraisalListTableProps {
  items: IFetchAppraisalsData[];
}

export function AppraisalListTable({ items }: IAppraisalListTableProps) {
  return (
    <table className="min-w-full divide-y divide-gray-100">
      <thead className="bg-purple-300">
        <tr>
          <th
            className="text-xs font-normal text-gray-900 lg:table-cell"
            scope="col"
          >
            <div className="flex flex-row justify-start px-6 font-medium">
              <span>Deal Date</span>
            </div>
          </th>
          <th
            className="text-start text-xs font-medium text-gray-900 lg:table-cell"
            scope="col"
          >
            <div className="mr-5 flex flex-row justify-start">
              <span>Deal ID</span>
            </div>
          </th>

          <th
            className="
          w-56 px-3 py-3.5 text-left text-xs font-medium
          text-gray-900 sm:table-cell
        "
            scope="col"
          >
            Device Name
          </th>
          <th
            className="
          flex flex-row px-3 py-3.5 text-left text-xs font-medium
          text-gray-900
        "
            scope="col"
          >
            <span>IMEI</span>
          </th>
          <th
            className="px-3 py-3.5 text-left text-xs font-medium text-gray-900"
            scope="col"
          >
            Grade
          </th>
          <th
            className="px-3 py-3.5 text-left text-xs font-medium text-gray-900"
            scope="col"
          >
            Customer Price AED
          </th>
          <th
            className="px-3 py-3.5 text-left text-xs font-medium text-gray-900"
            scope="col"
          >
            Store Name
          </th>
        </tr>
      </thead>

      <tbody className="divide-y divide-gray-50 bg-white">
        <AppraisalListItems items={items} />
      </tbody>
    </table>
  );
}
