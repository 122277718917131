import { useMutation } from '@northladder/services';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@northladder/utilities';

import { IApiError } from '../../../../types';

import { ISendRegistrationOPTData } from '../types';
import { USER_REGISTRATION_API } from '../userRegistrationAPIClient';

/**
 * This is to track the sent reg. otp mutation keys in react query cache.
 */
const MUT_SEND_USER_REG_OTP = 'send-user-reg-otp';

interface ISendUserRegistrationOTPOptions {
  onSuccessCallback: (otpData: ISendRegistrationOPTData) => void;
  onErrorCallback: (err: IApiError) => void;
}

/**
 * This performs an otp generation request on the server that is sent to the
 * user's mobile to get the OTP used during registration.
 */
export const useSendUserRegistrationOTPMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: ISendUserRegistrationOTPOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_SEND_USER_REG_OTP],
    networkMode: 'always',
    retry: false,
    mutationFn: USER_REGISTRATION_API.sendUserRegistrationOTP,
    onSuccess: (response) => {
      onSuccessCallback(response);
    },
    onError: (err: IApiError) => {
      onErrorCallback(err);
      errorLogToRemoteUtil({
        error,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Sending Registration OTP Attempt',
        message: err.message,
      });

      return err;
    },
  });

  return {
    error,
    isLoading,
    sendRegistrationOTP: mutate,
  };
};
