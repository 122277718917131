import dynamic from 'next/dynamic';

/**
 * This dynamically imports `RegistrationFormStep2` content at the lazily when required.
 * This helps prevent nextjs hydration errors.
 */
const DynamicRegistrationFormStep3 = dynamic(
  () => import('./RegistrationStep3Form'),
  {
    ssr: false,
  }
);

export default DynamicRegistrationFormStep3;
