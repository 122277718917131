import { XMarkIcon } from '@heroicons/react/20/solid';

import { useBidHistoryQuery, IBidHistoryItemData } from '@northladder/apis';
import { getFormattedDate } from '@northladder/utilities';
import { TCountryCurrency, useAuthStore } from '@northladder/services';
import { Text } from '@northladder/i18n';
import { CircularProgressLoader } from '@northladder/design-system';

interface ISingleHistoryValueItemProps {
  /**
   * Title or value of the `SingleHistoryValueItem` item
   */
  title: string;
}

/**
 * -----------------------------------------------------------------------------
 * SingleHistoryValueItem it will return a text value with basic text style
 *
 * @returns JSX.Element
 */
function SingleHistoryValueItem({ title }: ISingleHistoryValueItemProps) {
  return <span className="flex-1 text-base font-medium">{title}</span>;
}

interface IBidHistoryListItemProps extends IBidHistoryItemData {
  currencyType: TCountryCurrency;
}

/**
 * -----------------------------------------------------------------------------
 * It is a single bid history item to show bid history data. it will accept
 * rankDescription, bidAmount, updatedOn
 */
export function BidHistoryListItem({
  rankDescription,
  bidAmount,
  updatedOn,
  currencyType,
}: IBidHistoryListItemProps) {
  const date = new Date(updatedOn);
  const formattedDate = getFormattedDate(date, 'dd/MM/yyyy');

  return (
    <div className="mt-2 flex flex-row justify-between">
      <SingleHistoryValueItem title={formattedDate} />
      <SingleHistoryValueItem title={rankDescription} />
      <SingleHistoryValueItem title={`${bidAmount} ${currencyType}`} />
    </div>
  );
}

interface IBidHistoryListProps {
  /**
   * bid id for api calling
   */
  assetId: string;
  /**
   * asset name for title of popup
   */
  assetName: string;
  /**
   * Provide call back to the parent for close the popup
   */
  onCloseModel: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * It will render a popup body with bid history list. `BidHistoryList` will call
 * `useBidHistoryQuery` hook for api. and it will handle the loading state and
 * error state of api
 */
export function BidHistoryList({
  assetId,
  assetName,
  onCloseModel,
}: IBidHistoryListProps) {
  const { isLoading, isError, error, bidsHistoryData, refetch } =
    useBidHistoryQuery({ assetId });

  const { data: bidHistoryList } = bidsHistoryData?.data || {};

  // const { activeSession } = useSessionInstance();
  const { activeSession } = useAuthStore();
  const { currency } = activeSession?.user || {};

  const handleOnClickRetry = () => {
    refetch();
  };

  if (isError && error) {
    return (
      <>
        <Text
          className="text-red-800"
          text={error?.message || 'Something went wrong!'}
        />
        <br />
        <span className="text-red-800">
          Unable fetch new data please try again
        </span>
        <button
          className="
            mt-4 inline-flex justify-center rounded-md border border-transparent
            bg-purple-500 px-24 py-2 text-sm font-medium text-white
            hover:bg-purple-600
          "
          onClick={handleOnClickRetry}
          type="button"
        >
          Retry
        </button>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-row justify-between">
        <span className="mb-3 inline-block text-lg font-bold">{assetName}</span>
        <XMarkIcon
          className="h-6 w-6"
          onClick={onCloseModel}
          type="presentation"
        />
      </div>
      <div className="flex flex-row justify-between">
        <span className="flex-1 text-xs">Date</span>
        <span className="inline-block flex-1  text-xs">Rank</span>
        <span className="inline-block flex-1  text-xs">Value</span>
      </div>

      {isLoading ? (
        <div className="m-6 flex flex-row justify-center">
          <CircularProgressLoader className="h-5 w-5" strokecolor="#000000" />
        </div>
      ) : (
        bidHistoryList?.map((history) => (
          <BidHistoryListItem
            key={history.rank}
            bidAmount={history.bidAmount || 0}
            currencyType={currency || 'AED'}
            rank={history.rank}
            rankDescription={history.rankDescription}
            updatedOn={history.updatedOn}
          />
        ))
      )}
      <div className="flex flex-row justify-center">
        <button
          className="
            mt-4 inline-flex justify-center rounded-md border border-transparent
            bg-purple-500 px-24 py-2 text-sm font-medium text-white
            hover:bg-purple-600
          "
          onClick={onCloseModel}
          type="button"
        >
          OK
        </button>
      </div>
    </>
  );
}
