import React from 'react';

import {
  SimpleDropdown,
  CircularProgressLoader,
} from '@northladder/design-system';
import { Text } from '@northladder/i18n';

import { UploadBidActionWrapper } from '../../styles';

import { SectionWrapper, DashboardMainWrapper } from '../../../../styles';

import { UploadBidsFilesList } from './UploadBidsFilesList';
import { useUploadBidsContentData } from './useUploadBidsContentData';
import { UploadBidsActionBar } from './UploadBidsActionBar';

/**
 * -----------------------------------------------------------------------------
 * This component houses all the main content of the bids screen, such as the
 * filters, list view, pagination, selected bid, etc.
 *
 * @returns JSX.Element
 */
export function UploadBidsContent() {
  const {
    nextPageObserverRef,
    categoryOptions,
    isLoadingCategoryData,
    categoryError,
    bidSearchResultData,
    isFetchingNextPage,
    bidSearchResultHasNextPage,
    bidSearchResultError,
    callUploadBidFilesQuery,
    onCategoryOptionSelect,
    getSignedUrl,
  } = useUploadBidsContentData();

  return (
    <>
      <UploadBidActionWrapper className="flex-col items-stretch !justify-start rounded-xl bg-white">
        <UploadBidsActionBar />
      </UploadBidActionWrapper>

      <DashboardMainWrapper className="flex-col items-stretch !justify-start rounded-xl bg-white">
        <SectionWrapper className="flex w-full flex-row items-center p-4 sm:w-[32%]">
          <p className="mr-2 text-base font-semibold">Filter By</p>
          <SimpleDropdown
            className="!w-32 pl-2"
            defaultTitle="Select category"
            error={categoryError}
            items={categoryOptions}
            loadingState={isLoadingCategoryData}
            menuItemsClassName="menu-item-list z-20"
            onDropDownItemSelect={onCategoryOptionSelect}
          />
        </SectionWrapper>

        <SectionWrapper className="min-h-full grow">
          {bidSearchResultError ? (
            <>
              <Text
                className="text-red-800"
                text={bidSearchResultError?.message}
              />
              <button
                className="ml-1 mt-2 inline-flex justify-center rounded-md border
               border-transparent bg-purple-500 px-2 py-1 text-sm font-medium
                text-white hover:bg-purple-600"
                onClick={() => callUploadBidFilesQuery()}
                type="button"
              >
                Retry
              </button>
            </>
          ) : null}
          <UploadBidsFilesList
            onFileDownload={getSignedUrl}
            uploadBidFilesListPage={bidSearchResultData?.pages}
          />
        </SectionWrapper>
        <div ref={nextPageObserverRef} className="flex flex-row justify-center">
          {isFetchingNextPage && bidSearchResultHasNextPage ? (
            <CircularProgressLoader className="h-5 w-5" strokecolor="#000000" />
          ) : (
            'No search left'
          )}
        </div>
      </DashboardMainWrapper>
    </>
  );
}
