import { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';

import { KebabMenu } from '@northladder/design-system';

import { BidHistoryList } from './BidHistoryList';

interface IBidItemOptionsProps {
  assetId: string;
  assetName: string;
}
/**
 * -----------------------------------------------------------------------------
 * It will give bid list item options and it will render bid list history
 * popup using headless ui
 *
 * @returns JSX.Element
 */
export function BidItemOptions({ assetId, assetName }: IBidItemOptionsProps) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const viewBidHistory = () => {
    openModal();
  };

  const bidRowKebabMenuOptions = [
    {
      title: 'View Bid History',
      onClick: viewBidHistory,
    },
  ];

  return (
    <>
      <KebabMenu menuOptions={bidRowKebabMenuOptions} />

      <Transition appear as={Fragment} show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 blur-xl" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
                  <BidHistoryList
                    assetId={assetId}
                    assetName={assetName}
                    onCloseModel={() => closeModal()}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
