import { useMutation } from '@northladder/services';

import { IApiError } from '../../../../types';
import { CUSTOMER_SERVICE_API } from '../customerServiceClient';
import { IFileUploadData } from '../types';

/**
 * This is to track the file upload mutation keys in react query cache.
 */
const MUT_FILE_UPLOAD = 'file-upload-mutation-key';

interface IFileUploadMutationProps {
  /**
   * Callback to be called when the file is uploaded successfully
   */
  onSuccessCallback: (fileUploadData: IFileUploadData) => void;
  onErrorCallback: (err: IApiError) => void;
}
/**
 * This performs a post request for uploading a file.
 */
export const useFileUploadMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IFileUploadMutationProps) => {
  const { isLoading, isError, error, mutate } = useMutation({
    mutationKey: [MUT_FILE_UPLOAD],
    networkMode: 'always', // Even make calls when offline
    mutationFn: CUSTOMER_SERVICE_API.userRegistrationFileUpload,
    onSuccess: (response) => {
      onSuccessCallback(response);
    },
    onError: (err: IApiError) => {
      // TODO: Log this error in remote utilities.
      onErrorCallback(err);
    },
  });

  return {
    error,
    isLoading,
    isError,
    mutate,
  };
};
