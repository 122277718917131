import { useRouter } from 'next/router';

import { THeroIcon } from '@northladder/design-system';
import { Text, TTextLocalized } from '@northladder/i18n';

import { DisabledLink, StyledNavLink } from './styles';

interface ISubNavItemProps {
  disabled?: boolean;
  href: string;
  Icon: THeroIcon;
  isCurrent: boolean;
  text: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the sub navigation bar items for the sidebar.
 */
export function SubNavItem({
  disabled,
  href,
  Icon,
  isCurrent,
  text,
}: ISubNavItemProps) {
  const { asPath } = useRouter();
  const path = `${asPath}${href}`;

  return disabled ? (
    <DisabledLink>
      <Icon
        aria-hidden="true"
        className="nav-icon group-hover:text-purple-500"
      />
      <Text text={text} />
    </DisabledLink>
  ) : (
    <StyledNavLink
      aria-current={isCurrent ? 'page' : undefined}
      className={`group ${isCurrent ? 'is-current' : ''}`}
      href={path}
    >
      <Icon
        aria-hidden="true"
        className="nav-icon group-hover:text-purple-500"
      />
      <Text text={text} />
    </StyledNavLink>
  );
}
