import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconHouse component is svg for house icon which is customizable with some
 * default values configured. It is mainly used in Menu Items for navigation
 * but also can be used elsewhere.
 *
 * @param TSvgComponentProps Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
function IconHouse(props: TSvgComponentProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M2.8 23.7c-1.4 0-2.5-1.1-2.5-2.5V9.5c0-.8.4-1.5 1-2L10.5.8c.9-.6 2.1-.6 3 0l9.2 6.7c.6.5 1 1.2 1 2v11.7c0 1.4-1.1 2.5-2.5 2.5H2.8zm8.7-21.6c.3-.2.7-.2 1 0l9.2 6.7c.2.2.3.5.3.7v11.7c0 .5-.4.8-.8.8h-5v-5.8c0-2.3-1.9-4.2-4.2-4.2s-4.2 1.9-4.2 4.2V22h-5c-.4 0-.8-.4-.8-.8V9.5c0-.2.1-.5.3-.7l9.2-6.7zm-2 14.1c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5V22h-5v-5.8z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
      />
    </svg>
  );
}

export default IconHouse;
