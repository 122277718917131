import { ISidebarOptions } from './types';
import { SubNavItem } from './SubNavItem';

interface ISidebarDesktopProps extends ISidebarOptions {
  // Add more SidebarDesktop prop types here.
}

/**
 * -----------------------------------------------------------------------------
 * This renders the side bar on larger screens. See also `SidebarMobile`.
 */
export function SidebarDesktop({
  navigationItems,
  secondaryNavigationItems,
}: ISidebarDesktopProps) {
  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex w-64 flex-col">
        {/* Sidebar component, content */}
        <div className="flex h-full min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-100">
          <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
            <div className="flex flex-shrink-0 items-center px-4" />
            <nav aria-label="Sidebar" className="mt-5 flex-1">
              <div className="space-y-1 px-2">
                {navigationItems.map(
                  ({ href, icon, current, name, disabled }) => (
                    <SubNavItem
                      key={href}
                      disabled={disabled}
                      href={href}
                      Icon={icon}
                      isCurrent={current}
                      text={name}
                    />
                  )
                )}
              </div>
              <hr
                aria-hidden="true"
                className="my-5 border-t border-gray-200"
              />
              <div className="flex-1 space-y-1 px-2">
                {secondaryNavigationItems.map(
                  ({ href, icon, current, name, disabled }) => (
                    <SubNavItem
                      key={href}
                      disabled={disabled}
                      href={href}
                      Icon={icon}
                      isCurrent={current}
                      text={name}
                    />
                  )
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
