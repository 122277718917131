import { getRegionApiBaseURL } from './getRegionApiBaseURL';

/**
 * It is a imageURL generate function it wants endpoint for generate url
 *
 * @param `endpoint`
 */
export function generateImageURL(endpoint: string): string {
  if (!endpoint) return '';

  const CDN_BASE_URL = getRegionApiBaseURL({
    apiBaseURL: 'CDN_S3_BUCKET_URL',
    requiresAuth: true,
  });

  return `${CDN_BASE_URL}${endpoint}`;
}

/**
 * It is User Profile imageURL generate function it wants endpoint
 * for generate url
 *
 * @param `endpoint`
 */
export function generateProfileImageURL(endpoint: string): string {
  // if (!endpoint) return '/images/samples/user-northladder.jpeg';

  return generateImageURL(endpoint);
}
