import {
  IGradeReviewRequestListResponseData,
  IGradeReviewRequestListResponseRawData,
} from '../../types';

/**
 * Formatter to transform the `raw grade review request list API response` to the format that
 */
export function formatGradeReviewRequestListResponse(
  response: IGradeReviewRequestListResponseRawData
): IGradeReviewRequestListResponseData {
  const {
    gradeReviewRequestList,
    totalCount: count,
    status: rawStatusFilterOptions,
  } = response;
  const gradeList = gradeReviewRequestList.map((gradeReviewRequest) => {
    const {
      _id: gradeReviewRequestId,
      appraisalCode,
      assetName,
      createdAt,
      dealPrice: purchasePrice,
      dealPaymentType,
      isCreatedByNL,
      status: gradeStatus,
      ticketNumber: gradeReviewTicketNumber,
    } = gradeReviewRequest;

    const displayStatus = [dealPaymentType];

    return {
      appraisalCode,
      assetName,
      displayStatus,
      gradeReviewRequestId,
      date: createdAt,
      gradeReviewTicketNumber,
      gradeStatus,
      isCreatedByNL,
      purchasePrice,
    };
  });

  const gradeFilterOptions = rawStatusFilterOptions.map((filter) => {
    const { count: filterCount, key, label, value } = filter;

    return {
      label: {
        en: value.en,
        ar: value.ar || value.en,
      },
      value: key,
      count: filterCount,
      subtitle: {
        isCritical: label.critical,
        value: {
          en: label.value.en,
          ar: label.value.ar || label.value.en,
        },
      },
    };
  });

  return {
    gradeList,
    count,
    gradeFilterOptions,
  };
}
