import axios from 'axios';

import { IApiError, IApiResponse, IApiTokenResponseData } from '../../../types';
import { refreshTokenUrl } from '../../../urls';
import { getRegionApiBaseURL } from '../../../utils';

interface ITokenSuccessData {
  success: true;
  token: string;
  refreshToken: string;
}

/**
 * This fetches a new token and refresh token from the server.
 *
 * - Note: To avoid cyclic dependencies with the `ApiClient` class that is
 * consuming a the `validateOrRenewAppSession` function which uses this function,
 * this API was extracted out of the `UserAccountsAPIClient` to be an independent.
 *
 * @param refreshToken string - The old API token to use in freshing the token.
 * @returns Promise<string | IApiError> - The token or an error for failed renewal.
 */
export const globalFetchRefreshTokenAPICall = async (
  refreshToken: string,
  identifier?: string
): Promise<ITokenSuccessData | IApiError> => {
  try {
    const baseURL = getRegionApiBaseURL({
      apiBaseURL: 'IDENTITY_SERVICE_BASE_URL',
      requiresAuth: true,
    });

    const response = await axios.post<
      IApiTokenResponseData,
      IApiResponse<IApiTokenResponseData>
    >(refreshTokenUrl(), { refreshToken, identifier }, { baseURL });

    return {
      success: true,
      token: response.data?.data?.token,
      refreshToken: response.data?.data?.refreshToken,
    };
  } catch (error) {
    return error as IApiError;
  }
};
