import { ChangeEvent, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { useDebounce } from '@northladder/utilities';

import { useSearchStore } from '@northladder/services';
import { AppSearchWrapper } from './styles';

/**
 * `IAppSearchProps` is interface for props of AppSearch component.
 */
interface IAppSearchProps {
  /**
   * Custom validation regex.
   */
  validationRegex?: RegExp;
  /**
   * Placeholder for the search input.
   */
  placeholder?: string;
  /**
   * Label for the search input.
   */
  label?: string;
}
/**
 * -----------------------------------------------------------------------------
 * The `AppSearch` component is responsible for rendering the search input
 * with debounce. It can handle a callback for API or function to be called
 * after a delay.
 *
 * @returns JSX.Element
 */
export default function AppSearch({
  validationRegex = /[^0-9a-zA-Z\s\u0600-\u06FF]/g,
  placeholder = 'Search',
  label = 'Search',
}: IAppSearchProps): JSX.Element {
  /**
   * The cached searchText before API calls. Keep it in the search context.
   */
  const [searchText, setSearchText] = useState('');

  const { updateSearchKey } = useSearchStore();

  /**
   * This is called to make the final API call for the search.
   * Get this from the SearchContext.
   */
  const handleSubmitSearch = (newSearchValue: string) => {
    setSearchText(newSearchValue);
    updateSearchKey(newSearchValue);
  };

  const {
    debouncedValue: inputValue,
    setDebouncedValue: setInputValue,
    onChangeDebounced,
  } = useDebounce({
    initialValue: searchText,
    delay: 1000,
    onChangeCb: handleSubmitSearch,
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    /**
     * TODO: Use validation functions from form-utils.
     */
    const inputText = e.target.value.replace(validationRegex, '');
    setInputValue(inputText);
    onChangeDebounced(inputText);
  };

  return (
    <AppSearchWrapper>
      <label className="sr-only" htmlFor="app-search">
        {label}
      </label>
      <div className="search-input-with-icon-container">
        <input
          id="app-search"
          onChange={handleSearchInputChange}
          placeholder={placeholder}
          type="text"
          value={inputValue}
        />
        <div className="search-icon-container">
          <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5" />
        </div>
      </div>
    </AppSearchWrapper>
  );
}
