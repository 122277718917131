/* eslint-disable class-methods-use-this */
import {
  updateAuthStoreTokenInfo,
  useAuthStore,
  useRegionStore,
} from '@northladder/services';

import { TBaseURLS } from '@northladder/utilities';
import { IApiConfiguration, IApiError } from '../types';

import { getRegionApiBaseURL } from '../utils/getRegionApiBaseURL';
import {
  EXPIRED_SESSION_REFRESH_TOKEN,
  sessionTimeoutError,
} from '../api-errors/handleErrorInAPICall';

import { validateOrRenewAppSession } from './validateOrRenewAppSession';

interface IInterceptAxiosRequestConfigPassed {
  type: 'PASSED';
  config: IApiConfiguration;
}

interface IInterceptAxiosRequestConfigFailed {
  type: 'FAILED';
  error: IApiError;
}

type TInterceptAxiosRequestConfigCallbackReturn =
  | IInterceptAxiosRequestConfigPassed
  | IInterceptAxiosRequestConfigFailed;

export const interceptAxiosRequestConfigCallback = async (
  config: IApiConfiguration,
  clientApiBaseURL: '' | TBaseURLS // this.apiBaseURL
): Promise<TInterceptAxiosRequestConfigCallbackReturn> => {
  const { baseURL, requiresAuth = true, regionId, accessToken } = config;

  let authHeader = {};
  let regionBaseURL = '';
  let acceptLanguage = {};

  const { activeSession } = useAuthStore.getState();
  const { appLanguage } = useRegionStore.getState();

  if (baseURL) {
    regionBaseURL = baseURL;
  } else if (clientApiBaseURL) {
    regionBaseURL = getRegionApiBaseURL({
      apiBaseURL: clientApiBaseURL,
      requiresAuth,
      regionId,
    });
  }

  /**
   * If we have a given accessToken give it high priority over the users
   * active session token.
   */
  if (accessToken) {
    authHeader = {
      Authorization: `Bearer ${accessToken}`,
    };
  } else if (requiresAuth) {
    if (activeSession?.isExpired) {
      const errorObj = {
        ...sessionTimeoutError,
        name: EXPIRED_SESSION_REFRESH_TOKEN,
        config: {
          baseURL: regionBaseURL,
          url: config.url,
        },
      };

      return {
        type: 'FAILED',
        error: errorObj,
      };
    }

    /**
     * If this endpoints needs a Bearer token from the identity service, check
     * for existing tokens validity, refresh them if possible otherwise throw
     * session timeout error on request.
     */
    const tokenValidity = await validateOrRenewAppSession(
      activeSession?.token || '',
      activeSession?.refreshToken || ''
    );

    if (!tokenValidity.hasToken) {
      const { error } = tokenValidity;
      const errorObject = {
        ...error,
        name: EXPIRED_SESSION_REFRESH_TOKEN,
        config: {
          baseURL: regionBaseURL,
          url: config.url,
        },
      };

      updateAuthStoreTokenInfo({
        token: '',
        refreshToken: '',
        userId: activeSession?.userId || '',
        isExpired: true,
      });

      // throw errorObject;

      return {
        type: 'FAILED',
        error: errorObject,
      };
    }

    const { token, refreshToken } = tokenValidity;

    updateAuthStoreTokenInfo({
      token,
      refreshToken,
      userId: activeSession?.userId || '',
      isExpired: false,
    });

    authHeader = {
      Authorization: `Bearer ${token}`,
    };
  }

  acceptLanguage = {
    'Accept-Language': appLanguage || 'en',
  };

  const requestConfig = {
    ...config,
    baseURL: regionBaseURL,
    headers: {
      ...acceptLanguage,
      ...authHeader,
      ...config.headers,
    },
  };

  return {
    type: 'PASSED',
    config: requestConfig,
  };
};
