/**
 * This manages reusable translations common to the Registration Forms sections.
 * TODO: Move this to a Strapi API endpoint
 */
export const translations = {
  registrationStep1Title: {
    en: 'Your Contact Info',
    ar: 'معلومات الاتصال الخاصة بك',
  },
  registrationsStep2Title: {
    en: 'Store / Business Details',
    ar: 'تفاصيل المتجر / العمل',
  },
  registrationStep3Title: {
    en: 'Trading Information',
    ar: 'معلومات التداول',
  },
  individualTrader: {
    en: 'Individual Trader',
    ar: 'تاجر فردي',
  },
  storeType: {
    en: 'Store Type',
    ar: 'نوع المتجر',
  },
  saveAndContinue: {
    en: 'Save and Continue',
    ar: 'احفظ واستمر',
  },
  saveAndSubmit: {
    en: 'Save and Submit',
    ar: 'حفظ وإرسال',
  },
  pleaseWait: {
    en: 'Saving, Please wait',
    ar: 'حفظ، رجاء انتظر',
  },
  verifyCode: {
    en: 'Verify Code',
    ar: 'التحقق من كود',
  },
  restart: {
    restartTitle: {
      en: 'Restart Registration',
      ar: 'إعادة التسجيل',
    },
    restartMessage: {
      en: 'Do you wish to restart registration?',
      ar: 'هل ترغب في إعادة التسجيل',
    },
    restartButton: {
      en: 'Restart',
      ar: 'إعادة تشغيل',
    },
  },
  steps: {
    one: {
      en: '1',
      ar: '١',
    },
    two: {
      en: '2',
      ar: '٢',
    },
    three: {
      en: '3',
      ar: '٣',
    },
    step1: {
      en: 'Step 1: Contact Information',
      ar: 'الخطوة ١: معلومات الاتصال',
    },
    step2: {
      en: 'Step 2: Trading Information',
      ar: 'الخطوة ٢: معلومات التداول',
    },
    step3: {
      en: 'Step 3: Store or Business Details',
      ar: 'الخطوة ٣: تفاصيل المتجر أو العمل',
    },
  },
  storeOptions: {
    storeOrCompany: {
      en: 'Legally registered Store/Company',
      ar: 'متجر / شركة مسجلة قانوناً',
    },
    // requiresLegalDocs: {
    //   en: 'Requires Legal documents like Licenses.',
    //   ar: 'يتطلب مستندات قانونية مثل التراخيص',
    // },
    individual: {
      en: 'I am an Individual trader',
      ar: 'أنا تاجر فردي',
    },
    noRegisteredStore: {
      en: 'Do not have a registered store.',
      ar: 'ليس لديك متجر مسجل.',
    },
  },
  formSubmission: {
    savingDetails: {
      en: 'Saving your details, ',
      ar: 'حفظ التفاصيل الخاصة بك ،',
    },
    pleaseWait: {
      en: 'Please Wait',
      ar: 'ارجوك انتظر',
    },
  },
  formMessages: {
    step1: {
      description: {
        en: 'Enter Info for primary account holder',
        ar: 'أدخل معلومات لصاحب الحساب الأساسي',
      },
      errorRegionMSg: {
        en: 'Please select the region to proceed',
        ar: 'الرجاء تحديد المنطقة للمتابعة',
      },
      errorRegionDesc: {
        en: 'This is required to customize your experience.',
        ar: 'هذا مطلوب لتخصيص تجربتك.',
      },
    },
    step2: {
      message: {
        en: '',
        ar: '',
      },
      descriptionIndividual: {
        en: 'Please fill in all your details below',
        ar: 'يرجى ملء جميع التفاصيل الخاصة بك أدناه',
      },
      descriptionStore: {
        en: 'Please fill in all the store details below',
        ar: 'يرجى ملء جميع تفاصيل المتجر أدناه',
      },
    },
    step3: {
      // message: {
      //   en: 'Once submitted our customer service team will call you to guide
      //        you through account activation process',
      //   ar: '',
      // },
      description: {
        en: 'Tell us about the devices you regularly trade in',
        ar: 'أخبرنا عن الأجهزة التي تستبدلها بانتظام',
      },
    },
  },
  formFields: {
    /**
     * -------------------------------------------------------------------------
     * NAME TRANSLATIONS
     */
    fullName: {
      en: 'Name',
      ar: 'اسم',
    },
    theFullName: {
      en: 'The full name',
      ar: 'الاسم الكامل',
    },
    fullNamePlaceholder: {
      en: 'Enter your full name',
      ar: 'أدخل اسمك الكامل',
    },
    /**
     * -------------------------------------------------------------------------
     * STORE NAME TRANSLATIONS
     */
    storeName: {
      en: 'Store Name',
      ar: 'اسم المتجر',
    },
    storeNamePlaceholder: {
      en: 'Enter the store name',
      ar: 'أدخل اسم المتجر',
    },
    storeLocation: {
      en: 'Store Location',
      ar: 'موقع المتجر',
    },
    storeLocationPlaceholder: {
      en: 'Please select a store location (GPS)',
      ar: 'الرجاء تحديد موقع المتجر (GPS)',
    },
    /**
     * -------------------------------------------------------------------------
     * PHONE NUMBER TRANSLATIONS
     */
    phoneNumber: {
      en: 'Phone Number',
      ar: 'رقم التليفون',
    },
    thePhoneNumber: {
      en: 'The phone number',
      ar: 'رقم الهاتف',
    },
    phoneNumberPlaceholder: {
      en: 'Enter your mobile number',
      ar: 'أدخل رقم هاتفك المحمول',
    },
    /**
     * -------------------------------------------------------------------------
     * EMAIL ADDRESS TRANSLATIONS
     */
    email: {
      en: 'Email',
      ar: 'بريد إلكتروني',
    },
    theEmailAddress: {
      en: 'The email address',
      ar: 'عنوان البريد الإلكتروني',
    },
    emailAddressPlaceholder: {
      en: 'Email Address',
      ar: 'عنوان البريد الالكترونى',
    },
    /**
     * -------------------------------------------------------------------------
     * BUILDING NAME (Individual & Store ) TRANSLATIONS
     */
    buildingName: {
      en: 'Building Name',
      ar: 'اسم المبنى',
    },
    theBuildingName: {
      en: 'The building name',
      ar: 'اسم المبنى',
    },
    buildingNamePlaceholder: {
      en: 'Building Name',
      ar: 'اسم المبنى',
    },
    /**
     * -------------------------------------------------------------------------
     * STREET & AREA TRANSLATIONS
     */
    streetArea: {
      en: 'Street/ Area',
      ar: 'الشارع / المنطقة',
    },
    streetAreaPlaceholder: {
      en: 'Street/ area',
      ar: 'الشارع / المنطقة',
    },
    theStreetArea: {
      en: 'The street/ area',
      ar: 'الشارع / المنطقة',
    },
    /**
     * -------------------------------------------------------------------------
     * CITY TRANSLATIONS
     */
    city: {
      en: 'City',
      ar: 'مدينة',
    },
    cityPlaceholder: {
      en: 'City',
      ar: 'مدينة',
    },
    theCity: {
      en: 'The city',
      ar: 'المدينة',
    },
    /**
     * -------------------------------------------------------------------------
     * WORKING HOURS TRANSLATIONS
     */
    workingHours: {
      en: 'Working Hours',
      ar: 'ساعات العمل',
    },
    workingHoursPlaceholder: {
      en: 'Working hours',
      ar: 'ساعات العمل',
    },
    theWorkingHours: {
      en: 'The working hours',
      ar: 'ساعات العمل',
    },
    /**
     * -------------------------------------------------------------------------
     * NATIONAL ID NUMBER
     */
    nationalId: {
      en: 'National ID',
      ar: 'الهوية الوطنية',
    },
    theNationalId: {
      en: 'The National ID',
      ar: 'الهوية الوطنية',
    },
    nationalIdPlaceholder: {
      en: 'Your National ID Number',
      ar: 'رقم الهوية الوطنية الخاصة بك',
    },
    /**
     * -------------------------------------------------------------------------
     * DEVICE QUANTITY TRANSLATIONS
     */
    deviceQuantityLabel: {
      en: 'Device Quantity',
      ar: 'كمية الجهاز',
    },
    deviceQuantitySubLabel: {
      en: 'What is the average quantity of devices you purchase?',
      ar: 'ما هو متوسط ​​كمية الأجهزة التي تشتريها؟',
    },
    deviceQuantityPlaceholder: {
      en: 'Mobile Phones: ______ Qty\nTablet: _____________ Qty\nSmart watch: ________ Qty\nLaptop: _____________ Qty',
      ar: 'هواتف نقالة: ______ الكمية\nلوح: _____________ الكمية\nساعة ذكية: ________ الكمية\nحاسوب محمول: _____________ الكمية',
    },
    theDeviceQuantity: {
      en: 'The device quantity',
      ar: 'كمية الجهاز',
    },
  },
  /**
   * -------------------------------------------------------------------------
   * DLOCATION PICKER
   */
  pdfLocation: {
    en: 'gpsField',
    ar: 'كمية الجهاز',
  },
  deviceQuantitySubLabel: {
    en: 'What is the average quantity of devices you purchase in a month?',
    ar: 'ما هو متوسط ​​كمية الأجهزة التي تشتريها في الشهر؟',
  },
  /**
   * -------------------------------------------------------------------------
   * TRADE-IN DEVICES TRANSLATIONS
   */
  tradeInDevice: {
    tradeInOptionsLabel: {
      en: 'Device Categories',
      ar: 'فئات الأجهزة',
    },
    selectDeviceCategory: {
      en: 'You need to select categories above to add this section.',
      ar: 'تحتاج إلى تحديد الفئات أعلاه لإضافة هذا القسم.',
    },
    placeholder: {
      en: 'What do you trade in? (eg: Mobile phones, laptops)',
      ar: 'ما الذي تتاجر به؟ (على سبيل المثال: الهواتف المحمولة وأجهزة الكمبيوتر المحمولة)',
    },
    theTradeInOptions: {
      en: 'The trade-in devices',
      ar: 'أجهزة المقايضة',
    },
    frequencyLabel: {
      perMonth: {
        en: 'per month',
        ar: 'في الشهر',
      },
      perDay: {
        en: 'per day',
        ar: 'في اليوم',
      },
      perWeek: {
        en: 'per week',
        ar: 'في الأسبوع',
      },
      perYear: {
        en: 'per year',
        ar: 'في السنة',
      },
    },
  },
  /**
   * -------------------------------------------------------------------------
   * PRODUCT SOURCES TRANSLATIONS
   */
  productSource: {
    productSourcesLabel: {
      en: 'Product Sources',
      ar: 'مصادر المنتج',
    },
    productSourcesDescription: {
      en: 'What are the sources of acquiring your products ?',
      ar: 'ما هي مصادر الحصول على منتجاتك؟',
    },
    productSourceOptions: {
      distributors: {
        en: 'I buy from distributors',
        ar: 'أشتري من الموزعين',
      },
      individuals: {
        en: 'I buy from individuals',
        ar: 'أشتري من الأفراد',
      },
      retailers: {
        en: 'I buy from other retailers',
        ar: 'أشتري من تجار تجزئة آخرين',
      },
      imports: {
        en: 'I import the devices',
        ar: 'أنا أستورد الأجهزة',
      },
      others: {
        en: 'Others',
        ar: 'آحرون',
      },
    },
  },
  documentUpload: {
    uploadLicense: {
      en: 'Upload Company License',
      ar: 'تحميل رخصة الشركة',
    },
    uploadVATCert: {
      en: 'Upload VAT Certificate',
      ar: 'تحميل شهادة ضريبة القيمة المضافة',
    },
    uploadVATCertLabel: {
      en: 'Are you VAT Registered ?',
      ar: 'هل أنت مسجل في ضريبة القيمة المضافة ?',
    },
    uploadCTA: {
      en: 'Click to Upload',
      ar: 'تحميل رخصة الشركة',
    },
    addAnother: {
      en: 'Add another',
      ar: 'أضف آخر',
    },
    delete: {
      en: 'Delete',
      ar: 'يمسح',
    },
    done: {
      en: 'Done',
      ar: 'منتهي',
    },
  },
  locationError: {
    locationErrorMessage: {
      en: 'Please select the gps location',
      ar: 'الرجاء تحديد موقع GPS',
    },
    locationErrorDesc: {
      en: 'This is required to proceed.',
      ar: 'هذا مطلوب للمتابعة.',
    },
  },
  uploadCompanyLicenseError: {
    message: {
      en: 'Please upload your Company license',
      ar: 'الرجاء تحميل رخصة الشركة الخاصة بك',
    },
  },
  uploadVatCertificate: {
    message: {
      en: 'Please upload your VAT certificate',
      ar: 'يرجى تحميل شهادة ضريبة القيمة المضافة الخاصة بك',
    },
  },
  selectDeviceError: {
    deviceErrorMessage: {
      en: 'Please select a device.',
      ar: 'الرجاء تحديد جهاز للمتابعة',
    },
    deviceErrorDesc: {
      en: 'Select at least one option to proceed',
      ar: 'حدد خيارًا واحدًا على الأقل للمتابعة',
    },
  },
  selectProductSourceError: {
    productSourceErrorMessage: {
      en: 'Please select a product source',
      ar: 'الرجاء تحديد مصدر المنتج',
    },
    productSourceErrorDesc: {
      en: 'Select at least one source of acquiring your products',
      ar: 'حدد مصدرًا واحدًا على الأقل للحصول على منتجاتك',
    },
  },
  /**
   * ---------------------------------------------------------------------------
   * Registration Status Translations
   */
  registrationStatusDetails: {
    registrationApproved: {
      header: {
        en: 'Account Active',
        ar: 'الحساب نشط',
      },
      statusActive: {
        en: 'Active',
        ar: 'نشيط',
      },
      message: {
        en: 'Welcome to Dealer Network of NorthLadder',
        ar: 'مرحبًا بكم في شبكة وكلاء NorthLadder',
      },
      description: {
        en: 'Login details are sent to you by Email or Phone number.',
        ar: 'يتم إرسال تفاصيل تسجيل الدخول إليك عبر البريد الإلكتروني أو رقم الهاتف.',
      },
    },
    registrationRejected: {
      header: {
        en: 'Account Rejected',
        ar: 'الحساب مرفوض',
      },
      statusRejected: {
        en: 'Rejected',
        ar: 'مرفوض',
      },
      message: {
        en: 'Sorry, Your account could not be activated.',
        ar: 'عذرا ، لا يمكن تفعيل حسابك.',
      },
    },
    registrationInReview: {
      header: {
        en: 'Request Submitted',
        ar: 'طلب مقدم',
      },
      statusInReview: {
        en: 'In Review',
        ar: 'في مراجعة',
      },
      clickToEdit: {
        en: 'Click to Edit',
        ar: 'إضغط للتعديل',
      },
      contactInfo: {
        title: {
          en: 'Your Contact Information',
          ar: 'معلومات الاتصال الخاصة بك',
        },
        message: {
          en: 'Your contact details are complete.',
          ar: 'تفاصيل الاتصال الخاصة بك كاملة.',
        },
      },
      storeInfo: {
        title: {
          en: 'Store / Business Details',
          ar: 'تفاصيل المتجر / العمل',
        },
        message: {
          en: 'Your business details are up-to date.',
          ar: 'تفاصيل عملك محدثة.',
        },
        description: {
          en: 'One of our representative will be in touch with you soon to activate your account. After your account will be activated you will be able to add prices to devices.',
          ar: 'سيتواصل معك أحد ممثلينا قريبًا لتفعيل حسابك. بعد تنشيط حسابك ، ستتمكن من إضافة الأسعار إلى الأجهزة.',
        },
      },
    },
    footer: {
      message1: {
        en: 'You can call us at',
        ar: 'يمكنك الاتصال بنا على',
      },
      message2: {
        en: 'Email:',
        ar: 'بريد إلكتروني:',
      },
      message3: {
        en: 'or chat via WhatsApp',
        ar: 'أو الدردشة عبر WhatsApp',
      },
    },
    button: {
      login: {
        en: 'Click here to Login',
        ar: 'انقر هنا لتسجيل الدخول',
      },
      home: {
        en: 'Back to Home page',
        ar: 'العودة إلى الصفحة الرئيسية',
      },
    },
    status: {
      en: 'Status :',
      ar: 'حالة :',
    },
  },
  loadingState: {
    message: {
      en: 'Loading Status Details',
      ar: 'تفاصيل حالة التحميل',
    },
    pleaseWait: {
      en: 'Please wait',
      ar: 'انتظر من فضلك',
    },
  },
  errorSate: {
    message: {
      en: 'Failed to get your registration status',
      ar: 'فشل في الحصول على حالة التسجيل الخاصة بك',
    },
    description: {
      en: 'Tap to retry fetching.',
      ar: 'انقر لإعادة محاولة الجلب.',
    },
    retry: {
      en: 'Retry',
      ar: 'أعد المحاولة',
    },
    retryHelpText: {
      en: 'Please retry fetching your details',
      ar: 'يرجى إعادة محاولة جلب التفاصيل الخاصة بك',
    },
  },
  fileMissing: {
    en: 'Please select a file to upload',
    ar: 'الرجاء تحديد ملف للتحميل',
  },
};
