import tw, { styled } from 'twin.macro';

export const FormStatusHeader = styled.div`
  ${tw`
    flex
    flex-col
    items-center
  `};

  .active-icon-styles {
    ${tw`
      h-24
      w-24
      rounded-full
      p-6
      stroke-green-400
      bg-green-100
    `};
  }

  .review-icon-styles {
    ${tw`
      h-24
      w-24
      rounded-full
      p-6
      stroke-yellow-400
      bg-yellow-100
    `};
  }

  .rejected-icon-styles {
    ${tw`
      h-20
      w-20
      rounded-full
      p-4
      stroke-red-400
      bg-red-100
    `};
  }

  .status-button-styles {
    ${tw`
      flex
      rounded-full
      mt-3
      text-sm
      font-normal
      justify-between
    `};

    &.active-styles {
      ${tw`
        px-4
        py-2
        bg-green-400
        w-36
      `};
    }

    &.review-styles {
      ${tw`
        px-4
        py-2
        bg-yellow-400
        w-40
      `};
    }

    &.rejected-styles {
      ${tw`
        px-4
        py-2
        bg-red-400
        w-40
      `};
    }
  }
`;

export const StyledInput = styled.span`
  ${tw`
    relative
    mt-4
    p-2
    border
    rounded
    text-sm
    // text-gray-300
  `};
`;

export const DetailsWrapper = styled.div`
  ${tw`
    min-w-full
    mt-4
    flex
    items-start
    rounded
    border
  `};

  .details-header {
    ${tw`
      w-full
      mr-6
      flex
      flex-col
      font-normal
      justify-between
      p-2
    `};
  }
`;
