import { FlutterMobileHeader } from '../../../mobile-headers';

import { FormContainer } from '../styles';

import { RegistrationApproved } from './components/RegistrationStatusDetails/RegistrationApproved';

/**
 * -----------------------------------------------------------------------------
 * This renders the registration form that captures the user details and authenticates
 * them.
 */
export function RegistrationStatusActive() {
  return (
    <FormContainer className="!shadow-gray-100">
      <div className="form-responsive-wrapper">
        <FlutterMobileHeader />
        <div className="col-1 items-center">
          <RegistrationApproved />
        </div>
      </div>
    </FormContainer>
  );
}
