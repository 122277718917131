import { ActionBarButton, Tooltip } from '@northladder/design-system';
import { useBidExecutionTimeQuery } from '@northladder/apis';
import { format } from '@northladder/utilities';

import { SectionWrapper } from '../../../../styles';

/**
 * `IBidsActionBarSectionProps` defines the props for BidsActionBarSection.
 */
interface IBidsActionBarSectionProps {
  onDownloadBidBookPaas: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * @returns JSX.Element
 */
function BidsActionBarSection({
  onDownloadBidBookPaas,
}: IBidsActionBarSectionProps) {
  return (
    <SectionWrapper className="flex justify-between py-2">
      <ActionBarButton
        className="no-border-button"
        icon="iconArrowDown"
        iconClassName="h-4 w-4"
        label="Bid Book"
        onClick={onDownloadBidBookPaas}
      />
    </SectionWrapper>
  );
}

/**
 * `IBidsPaasActionBarProps` defines the props for BidsPaasActionBar.
 */
interface IBidsPaasActionBarProps {
  onDownloadBidBookPaas: () => void;
  brandId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the action bar upload bids page.
 *
 * @param IUploadBidsActionBarProps
 * @returns JSX.Element
 */
export function BidsPaasActionBar({
  onDownloadBidBookPaas,
  brandId,
}: IBidsPaasActionBarProps) {
  const { data: bidExecutionData } = useBidExecutionTimeQuery();
  const { nextExecutionDayWithTime = null, validFrom = null } =
    bidExecutionData || {};

  return (
    <SectionWrapper className="flex justify-between gap-2 p-4">
      <Tooltip
        message={
          brandId === 'all' || brandId === ''
            ? 'Select a brand first'
            : 'Download'
        }
      >
        <div className="flex  flex-col items-start justify-center">
          <div>Download</div>
          <BidsActionBarSection onDownloadBidBookPaas={onDownloadBidBookPaas} />
        </div>
      </Tooltip>

      <div className="flex flex-col items-end justify-center">
        <div>Prices will be</div>
        <span className="flex flex-col items-end">
          <span className="text-sm text-purple-500">
            Valid From:
            <span className="pl-2">
              {validFrom ? format(validFrom, 'do LLLL Y p') : '---'}
            </span>
          </span>
          <span className="text-sm text-purple-500">
            Valid Till:
            <span className="pl-2">
              {nextExecutionDayWithTime
                ? format(nextExecutionDayWithTime, 'do LLLL Y p')
                : '---'}
            </span>
          </span>
        </span>
      </div>
    </SectionWrapper>
  );
}
