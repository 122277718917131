import {
  IApiError,
  IRankReports,
  IRankReportsRaw,
  useFetchRankReportQuery,
} from '@northladder/apis';
import { CircularProgressLoader } from '@northladder/design-system';
import { Text } from '@northladder/i18n';

import { useSessionInstance } from '../../../user-accounts';

import { bidRankIds, bidRanksById } from '../../constants';
import { TBidRankType, TBidRankMobileType } from '../../types';

import { BidFilterItem } from './BidFilterItem';

export interface IBidFilterItem {
  id: string | number;
  title: string;
  type: string;
  count: string | number;
}

interface IDashboardBidFiltersProps {
  accountId: number;
  rankReport?: IRankReports | IRankReportsRaw;
  isLoading: boolean;
  isError: boolean;
  error: IApiError | null;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a list of bids badge filters.
 */
export function BidsBadgesFilters({
  accountId,
  rankReport,
  isLoading,
  isError,
  error,
}: IDashboardBidFiltersProps) {
  if (rankReport) {
    return (
      <>
        {isLoading ? <CircularProgressLoader strokecolor="#020203" /> : null}
        {isError && error ? (
          <Text className="text-red-800" text={error.message} />
        ) : null}

        {bidRankIds.map((id) => {
          const bidRankItem = bidRanksById[id];

          if (rankReport.type === 'web') {
            const rankCount = rankReport[bidRankItem.type as TBidRankType];
            return (
              <BidFilterItem
                key={id}
                count={rankCount || 0}
                item={bidRanksById[id]}
                path={`/${accountId}/bids/${id}`}
              />
            );
          }

          const rankCount = rankReport[bidRankItem.type as TBidRankMobileType];
          return (
            <BidFilterItem
              key={id}
              count={rankCount || 0}
              item={bidRanksById[id]}
              path={`/${accountId}/bids/${id}`}
            />
          );
        })}
      </>
    );
  }

  return <span>No data found</span>;
}

/**
 * `BidRankFilter` component props. all props are nullable.
 */
interface IBidRankFilterProps {
  categoryId?: string;
  brandId?: string;
  groupIds?: string[];
  onMyTotalBid?: (totalBid: number) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This is a api fetcher component and provide api data and react query state
 * data to `BidsBadgesFiltersList`
 *
 * TODO: Move this component to the design system.
 */
export function BidRankFilter({
  categoryId,
  brandId,
  groupIds,
  onMyTotalBid,
}: IBidRankFilterProps) {
  const { activeSession } = useSessionInstance();
  const accountId = activeSession?.index || 0;

  const {
    isLoading,
    isError,
    error,
    data: rankReports,
  } = useFetchRankReportQuery({
    categoryId,
    brandId,
    groupIds,
  });

  // if (rankReports) onMyTotalBid?.(rankReports.totalBids || 0);

  if (rankReports) {
    const totalBidsCount =
      rankReports.type === 'web'
        ? rankReports.totalBids
        : rankReports.totalBidsCount;

    onMyTotalBid?.(totalBidsCount || 0);
  }

  return (
    <BidsBadgesFilters
      accountId={accountId}
      error={error}
      isError={isError}
      isLoading={isLoading}
      rankReport={rankReports}
    />
  );
}
