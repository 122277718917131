import { IAsset } from '@northladder/services';

import { IFetchBidDetailsData } from '../types';

export function formatBidDetailsResult(assetRaw: IFetchBidDetailsData): IAsset {
  const { assetDetails } = assetRaw || {};

  const { myBid, hotDealFlag } = assetDetails || {};
  const {
    autoTopupEnabled: isAutoTopupEnabled,
    availableQuantity,
    _id: myBidId,
    isBidActive,
    isSpotSummit,
    maxQuantity,
    rank,
    spotPrice,
    updatedDate,
  } = myBid ?? {};

  const {
    _id,
    attributes,
    brandId,
    brandName,
    categoryName,
    imageUrl,
    name,
    stepperValue,
    topBid,
  } = assetDetails || {};

  let assetNameStr = `${brandName || ''} ${name || ''} `;

  assetNameStr += attributes.map((attr) => `${attr.value}`).join(', ');

  // TODO: Add arabic name later as iraq team needs english name for now.
  // let assetNameAr = `${brandName || ''} ${name || ''} `;
  // assetNameAr += attributes.map((attr) => `${attr.value_ar}`).join(', ');

  const cleanAsset: IAsset = {
    assetId: _id,
    assetImage: imageUrl,
    assetName: {
      en: assetNameStr,
      // ar: assetNameAr || assetNameStr,
      ar: assetNameStr,
    },
    brandId,
    brandName,
    categoryName,
    isHotDeal: hotDealFlag,
    myBid: {
      availableQuantity,
      bidId: myBidId,
      isAutoTopupEnabled,
      isBidActive,
      isSpotSummit,
      maxQuantity,
      rankNumber: rank,
      spotPrice,
      updatedDate,
    },
    stepperValue: stepperValue || 0,
    topBidAmount: topBid?.spotPrice || 0,
  };

  return cleanAsset;
}
