import { useQueryWithUserId } from '@northladder/services';

import { IApiError } from '../../../../types';

import { ACCOUNT_SERVICE_API } from '../accountServiceApiClient';

/**
 * This is to track the top brands react query cached data
 */
export const FETCH_PAYMENT_STATUS_KEY = 'FETCH_PAYMENT_STATUS';

/**
 * this hook will call payment status account service api and manage the cached
 * data. it return the loading, data, error etc.. states from reactQuery
 */
export const usePaymentStatsQuery = () => {
  const { data, isError, isFetching, error, isLoading, refetch } =
    useQueryWithUserId({
      queryKey: [FETCH_PAYMENT_STATUS_KEY],
      queryFn: () => ACCOUNT_SERVICE_API.fetchPaymentSummary(),
      onSuccess: (response) => response,
      onError: (err: IApiError) => err,
    });

  return {
    error,
    isError,
    isFetching,
    data,
    isLoading,
    refetch,
  };
};
