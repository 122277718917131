import Link from 'next/link';
import { UserPlusIcon } from '@heroicons/react/24/outline';

/**
 * -----------------------------------------------------------------------------
 * This renders an CTA that allows the user to log into another account for
 * multiple session navigation.
 */
export function AddAnotherLoginBtn() {
  return (
    <Link
      className="
        group inline-flex w-full items-center px-8 py-4 text-sm
        leading-5 text-gray-500 hover:bg-purple-50
        hover:text-purple-800 focus:outline-none focus:ring-2
        focus:ring-purple-100 focus:ring-offset-1
      "
      href="/login"
    >
      <UserPlusIcon
        aria-hidden="true"
        className="
          mr-4 h-5 w-5 text-gray-700 group-hover:text-purple-800
          rtl:ml-4 rtl:mr-0
        "
      />
      <span>Add another account</span>
    </Link>
  );
}
