import { useState } from 'react';

import { useProfileUpdateMutation, IApiError } from '@northladder/apis';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@northladder/utilities';
import {
  FirstNameInputField,
  FormFeedbackSection,
  LastNameInputField,
  useForm,
} from '@northladder/design-system';
import { useAuthStore } from '@northladder/services';

import { useSessionInstance } from '../../hooks';

export interface IEditProfileFormInputFields {
  firstName: string;
  lastName: string;
}

interface IProfileDetailsEditProps {
  onCancel: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This shows the editable fields on the profile screen. Currently the first
 * and last names.
 *
 * TODO: 0. Bind API calls to the update the profile.
 */
export function ProfileDetailsEdit({ onCancel }: IProfileDetailsEditProps) {
  // TODO: Get these from a config or CMS
  const FORM_MESSAGE = 'Note! You can only update your Names';
  const FORM_MESSAGE_DESC = 'Please contact the admin for further edits.';

  const { firstName: firstNameInit, lastName: lastNameInit } =
    useSessionInstance().activeSession?.user || {};

  const [formValues, setFormValues] = useState<IEditProfileFormInputFields>({
    firstName: firstNameInit || '',
    lastName: lastNameInit || '',
  });

  /**
   * The fields to be tracked within the form.
   */
  const profileUpdateInputFieldsValues: IEditProfileFormInputFields = {
    firstName: firstNameInit || '',
    lastName: lastNameInit || '',
  };

  const handleOnProfileUpdateError = (error: IApiError) => {
    errorLogToRemoteUtil({
      error,
      errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
      errorTitle: 'Error in Profile Info Update Attempt',
      message: error.message,
    });
  };

  const { updateProfileDetails } = useAuthStore();
  const { activeSession } = useSessionInstance();
  const { user } = activeSession || {};

  /**
   * Redirect the user to the dashboard or previous route attempt.
   * Persist the login Details to query cache.
   */
  const handleOnProfileUpdateSuccess = () => {
    if (user) {
      updateProfileDetails({
        ...user,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      });
    }
    onCancel();
  };

  const {
    error: formError,
    isLoading: isFormSubmitting,
    submitProfileDetails,
  } = useProfileUpdateMutation({
    onSuccessCallback: handleOnProfileUpdateSuccess,
    // TODO: Temp, remove it later.
    onErrorCallback: handleOnProfileUpdateError,
  });

  const handlePostDetails = (values: IEditProfileFormInputFields) => {
    submitProfileDetails({
      firstName: values.firstName,
      lastName: values.lastName,
    });
    setFormValues({
      firstName: values.firstName,
      lastName: values.lastName,
    });
  };

  const {
    values,
    errors,
    handleOnInputChange,
    handleOnBlur,
    handleOnError,
    handleOnSubmitForm,
  } = useForm({
    initialValues: profileUpdateInputFieldsValues,
    onSubmitCb: handlePostDetails,
  });

  return (
    <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
      {formError?.message ? (
        <FormFeedbackSection
          description={formError.description}
          isFormSubmitting={isFormSubmitting}
          message={formError.message}
          type="error"
        />
      ) : (
        <FormFeedbackSection
          description={FORM_MESSAGE_DESC}
          isFormSubmitting={isFormSubmitting}
          message={FORM_MESSAGE}
          type="info"
        />
      )}

      <form className="py-4" onSubmit={handleOnSubmitForm}>
        <FirstNameInputField
          className="flex w-full flex-col items-start gap-2 py-2 sm:w-3/4 sm:flex-row sm:items-center sm:bg-transparent"
          classNameInput="flex w-full"
          classNameLabel="w-full sm:w-1/2 flex"
          error={errors.firstName}
          label="First Name"
          name="firstName"
          onBlurCallback={handleOnBlur}
          onChangeCallback={handleOnInputChange}
          onError={handleOnError}
          value={values.firstName}
        />
        <LastNameInputField
          className="flex w-full flex-col items-start gap-2 py-2 sm:w-3/4 sm:flex-row sm:items-center sm:bg-transparent"
          classNameInput="flex w-full"
          classNameLabel="w-full sm:w-1/2 flex"
          error={errors.lastName}
          label="Last Name"
          name="lastName"
          onBlurCallback={handleOnBlur}
          onChangeCallback={handleOnInputChange}
          onError={handleOnError}
          value={values.lastName}
        />

        <button
          className="form-btn my-4 w-full sm:w-auto "
          disabled={isFormSubmitting}
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  );
}
