import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { IconBid } from '@northladder/design-system';

import { MyTotalBidsWrapper } from '../../styles';

interface IMyTotalBidsProps {
  path: string;
  value: string | number;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the total bids summary card at on the dashboard.
 * TODO: NOTE: This is to be merged @Renji's Summary card.
 */
export function MyTotalBids({ path, value }: IMyTotalBidsProps) {
  return (
    <MyTotalBidsWrapper className="group" href={path}>
      <ChevronRightIcon
        className="
          link-arrow group-hover:right-0 group-hover:opacity-100
          rtl:group-hover:left-0
        "
      />
      <div
        className="
          icon-container group-hover:-translate-y-0.5 group-hover:scale-105
          group-hover:bg-purple-100
        "
      >
        <IconBid className="group-hover:-translate-y-0.5 group-hover:scale-105" />
      </div>

      <div>
        <p className="my-bid-value group-hover:-translate-y-0.5 group-hover:scale-105">
          {value}
        </p>
        <h3 className="my-bid-label group-hover:translate-y-1 group-hover:scale-105 ">
          My Total Bids
        </h3>
      </div>
    </MyTotalBidsWrapper>
  );
}
