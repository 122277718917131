import { useRouter } from 'next/router';

import { CompanyLogo, KebabMenu } from '@northladder/design-system';
import { LocaleSwitcher, Text } from '@northladder/i18n';
import { usePAAS } from '@northladder/utilities';

import { AppSearch } from '../../../app-search';
import { ProfileFlyoutMenu } from '../../../user-accounts';

import { IAppNavBarOptions } from '../types';

import { AppNavActionsWrapper } from './styles';

export interface IAppNavBarProps extends IAppNavBarOptions {
  // Add more props here specific to AppNavBar
}

/**
 * -----------------------------------------------------------------------------
 * This renders top application navigation bar in the header. Showing the page
 * title, search bar and nav bar actions like settings, overflow menus, and
 * profile indicator.
 *
 * TODO: Make this further customizable from the parent component
 *
 * @param IAppNavBarProps
 *
 * @returns JSX.Element
 */
export function AppNavBar({
  pageTitle,
  showOverflowActions = true,
  showProfile = true,
  showSearch = true,
}: IAppNavBarProps) {
  const router = useRouter();
  const { isPaasEnabled } = usePAAS();

  const handleClearFilters = () => {
    // 'TODO:: About to Clear Filters here'
  };

  const handleNavigation = (route = '') => {
    router.push(`/${route}`);
  };

  const kebabMenuOptions = [
    {
      title: 'Clear All Filters',
      onClick: handleClearFilters,
    },
    {
      title: 'Settings',
      onClick: () => handleNavigation(),
    },
  ];

  return (
    <nav className="shrink-0 py-0 sm:py-4 md:py-6">
      <div className="mx-auto max-w-[80rem] px-2 sm:px-4 md:px-8 xl:px-0">
        <div
          className="
            relative flex flex-col items-stretch justify-between
            sm:flex-row sm:items-center sm:justify-end
          "
        >
          {isPaasEnabled ? (
            <div
              className="
              relative top-5 mx-14 flex-1 truncate text-lg font-light
              transition-all duration-200 ease-in-out sm:top-auto sm:text-sm lg:text-2xl xl:mx-0
            "
            >
              <CompanyLogo className="block h-12" />
            </div>
          ) : (
            <div
              className="
              relative top-5 mx-14 flex-1 truncate text-lg font-light text-gray-800
              transition-all duration-200 ease-in-out
            "
            >
              <Text text={pageTitle} />
            </div>
          )}

          {showSearch ? (
            <div
              className="
                order-1 flex flex-1 justify-center
                px-1 sm:mt-0 sm:justify-end sm:px-2  lg:ml-6
              "
            >
              <AppSearch />
            </div>
          ) : null}

          {isPaasEnabled ? (
            <div className="absolute hidden w-full text-center text-3xl font-medium text-gray-700 md:block">
              <h1>Trade-in platform</h1>
            </div>
          ) : null}

          <AppNavActionsWrapper>
            {showOverflowActions && !isPaasEnabled ? (
              <KebabMenu menuOptions={kebabMenuOptions} />
            ) : null}

            {!isPaasEnabled ? (
              <LocaleSwitcher className="hidden sm:block" isInline />
            ) : null}

            {showProfile ? <ProfileFlyoutMenu /> : null}
          </AppNavActionsWrapper>
        </div>
      </div>
    </nav>
  );
}
