import { usePAAS } from '@northladder/utilities';
import dynamic from 'next/dynamic';

/**
 * This dynamically imports `LoginForm` content at the lazily when required.
 * This helps prevent nextjs hydration errors.
 */
const DynamicLoginForm = dynamic(
  () => {
    const { isPaasEnabled } = usePAAS();

    return isPaasEnabled ? import('./PaasLoginForm') : import('./LoginForm');
  },
  { ssr: false }
);

export default DynamicLoginForm;
