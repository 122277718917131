import { getTokenExpiryTime } from './getTokenExpiryTime';

const TODAY_TIME = new Date().getTime();

/**
 * This compares the retrieves the expiry time from the token payload and
 * compares it with the current date.
 */
export const checkIfTokenIsExpired = (token: string) => {
  const tokenExpiryTime = getTokenExpiryTime(token);

  return TODAY_TIME >= tokenExpiryTime;
};
