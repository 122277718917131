import { useCycle, Variants } from 'framer-motion';
import { useRef, RefObject } from 'react';

import {
  LogoNorthladderLight,
  LogoDealerNorthLadder,
  CompanyLogo,
} from '@northladder/design-system';
import { usePAAS } from '@northladder/utilities';

import useDimensions from './hooks/use-dimensions';

import { TAppNavMenuItem } from './types';

import { MenuToggle, Navigation } from './components';
import MenuWrapper, { MenuHeaderWrapper } from './styles';

/**
 * pointerEvents: variants to be used in the animation of pointer events on menu
 * wrapper.
 */
const pointerEvents: Variants = {
  open: {
    pointerEvents: 'all',
  },
  closed: {
    pointerEvents: 'none',
  },
};

/**
 * `IAppNavMenuProps` is the type definition for AppNavMenu
 */
interface IAppNavMenuProps {
  /**
   * Data about the menu list items to be rendered as nav link
   */
  items: TAppNavMenuItem[];
}

/**
 * -----------------------------------------------------------------------------
 *  AppNavMenu is the wrapper component which use's multiple components to
 *  achieve the navigation functionality with customized props.
 *
 * @param props: { items} items is any array of objects to render
 *  the list of links with icons.
 *
 * @returns JSX.Element
 */
export default function AppNavMenu({ items }: IAppNavMenuProps): JSX.Element {
  const { isPaasEnabled } = usePAAS();
  const [isMenuOpen, toggleMenu] = useCycle(false, true);
  const containerRef: RefObject<HTMLElement> = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <div className="pointer-events-none absolute left-0 right-0 top-0 z-50 flex h-screen">
      <div
        className="
         mx-auto flex w-full max-w-[80rem] shrink-0 px-2 sm:px-4 md:px-8 xl:px-0
        "
      >
        <MenuWrapper
          ref={containerRef}
          animate={isMenuOpen ? 'open' : 'closed'}
          className={`top-nav ${isMenuOpen ? 'open-menu' : ''}`}
          custom={height}
          initial={false}
          variants={pointerEvents}
        >
          <div className="background" />

          <MenuHeaderWrapper>
            <div className="relative">
              <MenuToggle isOpen={isMenuOpen} toggleMenu={toggleMenu} />

              {isMenuOpen ? (
                <div
                  className="
                  b2xl logo-container mx-16 h-full transition-all
                  ease-in-out sm:mx-2 md:mx-14 lg:mx-2
                "
                >
                  <div>
                    {isPaasEnabled ? (
                      <CompanyLogo className="paas-logo" />
                    ) : (
                      <>
                        <LogoDealerNorthLadder className="logo" />
                        <LogoNorthladderLight className="logo opacity-80" />
                      </>
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <Navigation isOpen={isMenuOpen} items={items} />
          </MenuHeaderWrapper>
        </MenuWrapper>
      </div>
    </div>
  );
}
