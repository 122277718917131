export interface IStatsSectionTileItem {
  /**
   * Label of `StatsSectionTile`.
   * For showing the title of summery count component
   * (eg:deal summery description)
   */
  title: string;
  /**
   * Value of `StatsSectionTile`.
   * For showing the count of the deal  (deal count)
   */
  value: number;

  valueKey:
    | 'dealsWon'
    | 'pendingDelivery'
    | 'delivered'
    | 'paid'
    | 'paymentDue';
}

/**
 * Props for `StatsSectionTile` separated from data interface
 * inherit from `IStatsSectionTileItem` - (data props interface)
 */
export interface IStatsSectionTileProps
  extends Omit<IStatsSectionTileItem, 'valueKey'> {
  // Add more StatsSectionTile props here
}

/**
 * -----------------------------------------------------------------------------
 * This component will accept `ISummeryCountItem` objet and render the summery
 * count item. This component will show the label and value of Bid summary
 *
 * @param ISummeryCountItem - Expects `title` and `Value`
 *
 * @return JSX.Element
 */
export function StatsSectionTile({ value, title }: IStatsSectionTileProps) {
  return (
    <div className="self-start pt-3">
      <p className="text-xl font-semibold text-gray-600">
        {value?.toLocaleString() || 0}
      </p>
      <p className="mt-1 text-base font-medium text-gray-600">{title}</p>
    </div>
  );
}
