import { Base64 } from '@northladder/utilities';

/**
 * This compares the saved token validity with the current time and checks for
 * it's expiry time.
 *
 * @param token string - The Raw encrypted token from login API.
 * @returns number - The Unix timestamp from the API response.
 */
export const getTokenExpiryTime = (token: string) => {
  let expiryTime = 0;

  if (!token) return expiryTime;

  const [, encryptedTokenPayload] = token.split('.');

  if (encryptedTokenPayload) {
    const payloadString = Base64.atob(encryptedTokenPayload);
    const { exp } = JSON.parse(payloadString);

    expiryTime = new Date(exp * 1000).getTime();
  }

  return expiryTime;
};
