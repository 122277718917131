import { IBidQuantityItem } from '../types';

/**
 * This variable for checking user selected asset quantity to no limit
 * TODO: Remove if not used
 */
export const INFINITY = 'INFINITY';

/**
 * Default bid Quantity for update bid. If the api giving error or empty data
 * We will pass this value as default
 * This quantity value will define how many assets needed for the bidding
 * user.
 *
 * TODO: Remove if not used
 */
export const DEFAULT_BID_QUANTITY = '5';

/**
 * This transformer function transforms the allowed quantity of the bid into a
 * format expected on the UI.
 */
export function formatBidDetailsQuantity(
  responseData: number[]
): IBidQuantityItem[] {
  return responseData.map((quantity) => ({
    id: quantity.toString(),
    value: quantity.toString(),
  }));
}
