import { Text } from '@northladder/i18n';
import { useRegionStore } from '@northladder/services';

import { translations } from '../../translations';

export function RegistrationStatusFooter() {
  const { region } = useRegionStore();
  const {
    contactNumber = '+971551804804',
    email = 'mangesh.mohanan@northladder.com',
  } = region || {};

  return (
    <div className="mt-2 text-center text-sm font-light text-gray-800">
      <p>
        <Text text={translations.registrationStatusDetails.footer.message1} />
        <span className="p-2 text-purple-500" dir="ltr">
          <a href={`tel:${contactNumber}`}>{contactNumber}</a>
        </span>
      </p>
      <p>
        <Text text={translations.registrationStatusDetails.footer.message2} />
        <span className="p-2 text-purple-500">
          <a href={`mailto:${email}`}>{email}</a>
        </span>
      </p>
      <p>
        <Text text={translations.registrationStatusDetails.footer.message3} />
      </p>
    </div>
  );
}
