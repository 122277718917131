import axios, { AxiosError } from 'axios';

import { updateAuthStoreTokenInfo, useAuthStore } from '@northladder/services';

import { IApiError, IError } from '../types';

import { translations } from './translations';

/**
 * The error code thrown when the app session expires and cannot be renewed by
 * the refresh token.
 */
export const EXPIRED_SESSION_REFRESH_TOKEN = 'EXPIRED_SESSION_REFRESH_TOKEN';

export const sessionTimeoutError: IApiError = {
  description: translations.sessionTimeoutError.description,
  error: Error('Session has Expired'),
  message: translations.sessionTimeoutError.message,
  success: false,
  status: false,
  statusCode: '419',
  type: 'SESSION_ERROR',
};

/**
 * This handles errors returned by network requests such as Axios bad requests,
 * failed server responses, and retrieving the thrown error and returning a
 * formatted error object for the UI Error sections & boundaries.
 *
 * - TODO: Use translated Error message from `CMS`.
 * - TODO: Log this error to a `remote service` / in-house .
 *
 * @link [Axios Docs : Handling Errors](https://axios-http.com/docs/handling_errors)
 */
export const handleErrorInAPICall = (error: AxiosError<IError>): IApiError => {
  /**
   * Setup the default error. Later override the keys to update them depending
   * on the filtered out error case.
   */
  const errorResponse: IApiError = {
    error,
    message: translations.errorResponse.message,
    description: translations.errorResponse.description,
    success: false,
    status: error?.response?.data?.status || false,
    type: 'JS_TYPE_ERROR',
  };

  const { activeSession } = useAuthStore.getState();

  /**
   * If the error is thrown by the API interceptor and it's a session timeout
   */
  if (error?.name === EXPIRED_SESSION_REFRESH_TOKEN) {
    updateAuthStoreTokenInfo({
      token: '',
      refreshToken: '',
      userId: activeSession?.userId || '',
      isExpired: true,
    });

    return sessionTimeoutError;
  }

  if (!axios.isAxiosError(error)) {
    return errorResponse;
  }

  /**
   * The request was made and the server responded with a status code that falls
   * out of the range of 2xx.
   */
  if (error.response) {
    /**
     * There was an error in the payload. Such an an invalid password in a login
     * request.Usually the server responds with a proper message, use that first
     * unless otherwise.
     */
    if (error.response.status === 400) {
      return {
        ...errorResponse,
        message:
          error.response.data?.message ||
          translations.errorResponse.status400.message,
        description: translations.errorResponse.status400.description,
        status: error.response.data?.status || false,
        type: 'SERVER_ERROR',
      };
    }

    /**
     * Server forbid this response. Axios possibly timed out when fetching.
     * TODO: Log this server to a teams channel and dev email thread.
     */
    // if (error.response.status === 401 || error.response.status === 403) {
    //   updateAuthStoreTokenInfo({
    //     token: '',
    //     refreshToken: '',
    //     userId: activeSession?.userId || '',
    //     isExpired: true,
    //   });

    //   return sessionTimeoutError;
    // }

    /**
     * Wrong endpoint called & server returned with `404`. Happens when API was
     * migrated or server is down.
     * TODO: Log this server to a teams channel and dev email thread.
     */
    if (error.response.status === 404) {
      return {
        ...errorResponse,
        message: translations.errorResponse.status404.message,
        description: translations.errorResponse.status404.description,
        status: error.response.data?.status || false,
        type: 'SERVER_ERROR',
      };
    }

    // TODO: Handle 500 Status Codes.......
  } else if (error.request) {
    /**
     * The request was made but no response was received. Note the `error.request`
     * is an instance of `XMLHttpRequest` in the browser and an instance of
     * `http.ClientRequest` in node.js.
     * This is usually caused by network issues, timeouts or hanged js threads.
     */

    /**
     * Error in network connection, thrown when `axios` failed to establish a
     * connection to the server.
     */
    if (error.code === 'ERR_NETWORK') {
      return {
        ...errorResponse,
        message: translations.errorResponse.errNetwork.message,
        description: translations.errorResponse.errNetwork.description,
        status: false,
        type: 'NETWORK_ERROR',
      };
    }

    /**
     * Request timed out based on what was set in the `config.timeout`,
     * thrown by `axios` when the server takes longer than expected to respond.
     */
    if (error.code === 'ECONNABORTED') {
      return {
        ...errorResponse,
        message: translations.errorResponse.econnAborted.message,
        description: translations.errorResponse.econnAborted.description,
        status: false,
        type: 'NETWORK_ERROR',
      };
    }

    /**
     * TODO: Handle this other case for successful request but failed response
     * besides network error.
     */
  } else {
    /**
     * Something happened in setting up the request that triggered an Error
     * Return the generic error object.
     */
    return errorResponse;
  }

  /**
   * Worst case scenario, that something happened in setting up the request that
   * triggered an Error.
   *
   * - Only God & the devil know what happened to this API call 🥹
   * Though might be caused by runtime error such as indexing something undefined.
   * - Lets make a silent prayer for our customers and hope for the best. 🤞
   */
  return errorResponse;
};
