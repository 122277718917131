import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { TTextLocalized, Text } from '@northladder/i18n';

import { IApiError } from '@northladder/apis';
import { CircularLoader } from '@northladder/design-system';

function TemplateBidsLoader() {
  return (
    <div className="flex h-52 w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-8">
        <CircularLoader className="h-6 w-6" />
        <p className="text-xl text-purple-600">In progress...</p>
      </div>
    </div>
  );
}

interface ITemplateBidsSuccessProps {
  successMessage: string;
}

function TemplateBidsSuccess({ successMessage }: ITemplateBidsSuccessProps) {
  return (
    <div className="flex h-52 w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-8">
        <CheckCircleIcon className="h-16 w-16 cursor-pointer text-green-500" />
        <p className="text-xl text-green-600">{successMessage}</p>
      </div>
    </div>
  );
}

interface ITemplateBidsErrorProps {
  errorMessage: TTextLocalized;
  onDownloadRetry: () => void;
}

function TemplateBidsError({
  errorMessage = 'Something went wrong!',
  onDownloadRetry,
}: ITemplateBidsErrorProps) {
  return (
    <>
      <Text className="text-red-800" text={errorMessage} />
      <button
        className="ml-1 mt-2 inline-flex justify-center rounded-md border
         border-transparent bg-purple-500 px-2 py-1 text-sm font-medium
          text-white hover:bg-purple-600"
        onClick={onDownloadRetry}
        type="button"
      >
        Retry
      </button>
    </>
  );
}

interface ITemplateBidsFallbackProps {
  isLoading: boolean;
  error: IApiError | null;
  isSuccess: boolean;
  onDownloadRetry: () => void;
  successMessage: string;
}

export function TemplateBidsFallback({
  isLoading,
  error,
  onDownloadRetry,
  isSuccess,
  successMessage,
}: ITemplateBidsFallbackProps) {
  if (isLoading) return <TemplateBidsLoader />;
  if (error) {
    return (
      <TemplateBidsError
        errorMessage={error?.message}
        onDownloadRetry={onDownloadRetry}
      />
    );
  }
  if (isSuccess) {
    return <TemplateBidsSuccess successMessage={successMessage} />;
  }
  return null;
}
