import { useEffect, useState } from 'react';
import Image from 'next/image';

import {
  IApiError,
  IApiResponse,
  useDownloadUploadBidTemplateQuery,
} from '@northladder/apis';
import { ICategoryItem, TLocale, useRegionStore } from '@northladder/services';
import { createDomElementWithFileInBody } from '@northladder/utilities';
import {
  ActionBarButton,
  AppModal,
  TMenuItem,
} from '@northladder/design-system';

import { ModalChildComponentWrapper } from '../../../../styles';

import { useUploadBidsContentData } from './useUploadBidsContentData';
import { TemplateBidsFallback } from './TemplateBidsFallback';

interface IDownloadCategoryTemplate {
  categoryData: ICategoryItem;
  onTemplateDownload: (category: TMenuItem) => void;
}

function DownloadCategoryTemplate({
  categoryData,
  onTemplateDownload,
}: IDownloadCategoryTemplate) {
  const handleTemplateDownload = () =>
    onTemplateDownload({
      id: categoryData.id,
      title: categoryData.categoryName,
    });
  return (
    <button
      className="category-image-btn"
      onClick={handleTemplateDownload}
      type="button"
    >
      <Image
        alt="Category Image"
        className="h-28 w-full object-cover object-center px-2"
        height={100}
        src={
          process.env.NEXT_PUBLIC_CDN_S3_BUCKET_URL_PAAS + categoryData.imageUrl
        }
        width={100}
      />
      <span className="text-sm">{categoryData.value}</span>
    </button>
  );
}

interface IDownloadModalChildComponentProps {
  onDownloadTemplate: (category: TMenuItem) => void;
  callDownloadUploadBidTemplate: () => void;
  isLoading: boolean;
  error: IApiError | null;
  isSuccess: boolean;
}

function DownloadModalChildComponent({
  onDownloadTemplate,
  callDownloadUploadBidTemplate,
  isLoading,
  error,
  isSuccess,
}: IDownloadModalChildComponentProps) {
  const { categoryListData } = useUploadBidsContentData();

  if (isLoading || error || isSuccess) {
    return (
      <TemplateBidsFallback
        error={error}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onDownloadRetry={callDownloadUploadBidTemplate}
        successMessage="Bids template downloaded successfully!"
      />
    );
  }

  return (
    <ModalChildComponentWrapper className="download-modal-child-wrapper">
      <div
        className="
          my-2 mt-3.5 grid grid-cols-2 place-items-center sm:flex sm:flex-row
          sm:gap-3
        "
      >
        {categoryListData &&
          categoryListData.map((categoryData) => (
            <DownloadCategoryTemplate
              key={categoryData.id}
              categoryData={categoryData}
              onTemplateDownload={onDownloadTemplate}
            />
          ))}
      </div>
    </ModalChildComponentWrapper>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This component manages the state and component for download bids template.
 *
 * @returns JSX.Element
 */
export function DownloadBidsTemplate() {
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<TMenuItem | null>(
    null
  );

  const handleDownloadTemplate = (category: TMenuItem) => {
    setSelectedCategory(category);
  };

  const { appLanguage } = useRegionStore();

  function downloadBidsTemplate(
    templateData: IApiResponse<BlobPart> | undefined
  ) {
    if (templateData && selectedCategory) {
      if (selectedCategory) {
        const { title } = selectedCategory;
        const { ar, en } = title as Record<TLocale, string>;

        createDomElementWithFileInBody({
          fileData: templateData.data,
          fileNamePrefix: `${appLanguage === 'en' ? en : ar}_Bids_`.replace(
            ' ',
            '_'
          ),
        });
      }
    }
  }

  const {
    refetch: callDownloadUploadBidTemplate,
    isLoading,
    error,
    isSuccess,
    remove,
  } = useDownloadUploadBidTemplateQuery({
    queryParams: {
      categoryId: selectedCategory?.id || '',
    },
    onSuccessCallBack: (data) => {
      downloadBidsTemplate(data);
    },
  });

  useEffect(() => {
    if (selectedCategory) {
      callDownloadUploadBidTemplate();
    }
  }, [callDownloadUploadBidTemplate, selectedCategory]);

  const toggleDownloadModal = () => {
    setOpenDownloadModal((state) => {
      if (state) {
        remove();
      }
      return !state;
    });
  };

  return (
    <>
      <div
        className="
          m-2 flex flex-col items-start justify-start sm:m-0 sm:w-[20%]
        "
      >
        <div>Download</div>
        <ActionBarButton
          className="no-border-button"
          icon="iconArrowDown"
          iconClassName="h-4 w-4"
          label="Excel template"
          onClick={toggleDownloadModal}
        />
      </div>

      <AppModal
        isOpen={openDownloadModal}
        modalChildComponent={
          <DownloadModalChildComponent
            callDownloadUploadBidTemplate={callDownloadUploadBidTemplate}
            error={error}
            isLoading={isLoading}
            isSuccess={isSuccess}
            onDownloadTemplate={handleDownloadTemplate}
          />
        }
        setIsOpen={toggleDownloadModal}
        title="Choose category for template"
      />
    </>
  );
}
