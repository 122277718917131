import { TDaysUpdateValue } from '../../../../components';

import { DownloadBidBook, IDownloadBidBookProps } from '../DownloadBidBook';
import { UploadBidBook } from '../UploadBidBook';
import { BidCalendarPopup } from '../BidCalendarPopup';

/**
 *
 */
interface BidBookActionBarButtonsGroupProps extends IDownloadBidBookProps {
  onSelectDays: (selectedDays: TDaysUpdateValue) => void;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the Action Bar Buttons.
 *
 * @returns JSX.Element
 */
export function BidBookActionBarButtonsGroup({
  categoryId,
  brandId,
  onSelectDays,
}: BidBookActionBarButtonsGroupProps) {
  const handleDaySelectionChange = (value: TDaysUpdateValue) => {
    onSelectDays(value);
  };

  return (
    <div className="flex flex-wrap">
      <DownloadBidBook brandId={brandId} categoryId={categoryId} />
      <UploadBidBook />
      <BidCalendarPopup onSelectDays={handleDaySelectionChange} />
    </div>
  );
}
