import { useMutation } from '@northladder/services';

import { IApiError } from '../../../../types';

import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the edit profile mutation keys in react query cache.
 */
const MUT_PROFILE_UPDATE = 'profile-update-mutation-key';

interface IUseProfileUpdateOptions {
  onSuccessCallback?: () => void;
  onErrorCallback: (error: IApiError) => void;
}

/**
 * This performs a profile update request.
 *
 * @param IUseProfileUpdateOptions
 */
export const useProfileUpdateMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseProfileUpdateOptions) => {
  const { isLoading, error, mutate } = useMutation({
    mutationKey: [MUT_PROFILE_UPDATE],
    networkMode: 'always',
    mutationFn: USER_ACCOUNTS_API.userProfileUpdate,
    onSuccess: onSuccessCallback,
    onError: (err: IApiError) => {
      onErrorCallback(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    submitProfileDetails: mutate,
  };
};
