import { ApiClient } from '../../../api-client';
import { ITermsAndConditionParams } from '../../../types';

import { termsAndConditionUrl } from '../../../urls';
import { ITermsAndConditionResponse } from './types';

/**
 * This is provides the API client methods for all external api calls usng CDN
 * base url. Eg: Terms and condition, download pdf, download files from cdn etc
 *
 */
export class ExternalAPIClient extends ApiClient {
  private static classInstance?: ExternalAPIClient;

  private constructor() {
    super({
      apiBaseURL: 'CDN_S3_BUCKET_URL',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new ExternalAPIClient();
    }

    return this.classInstance;
  };

  /**
   * Api call to fetch terms and condition
   *
   * @returns IFetchTermsAndConditionResponse
   */
  public fetchTermsAndConditions = async (
    queryParams: ITermsAndConditionParams
  ) => {
    const response = await this.get<ITermsAndConditionResponse>(
      termsAndConditionUrl(queryParams),
      {
        requiresAuth: false,
      }
    );

    if (!response.success) throw response;

    return response?.data;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for All External api requests.
 */
export const EXTERNAL_API = ExternalAPIClient.getClientInstance();
