import { useQueryWithUserId } from '@northladder/services';

import { IApiError } from '../../../../types';

import { USER_REGISTRATION_API } from '../userRegistrationAPIClient';
import { ICategoryData } from '../types';

/**
 * This is to track the device categories from the backend.
 */
const DEVICE_CATEGORIES_KEY = 'device-categories-key';

/**
 * This hook fetches a list of device category id and name.
 * These category ids and names are mapped in the step-3 `TradeInDeviceDropdown`
 * component.
 */
export const useDeviceCategoriesQuery = () => {
  const { data, isError, error, isLoading } = useQueryWithUserId({
    queryKey: [DEVICE_CATEGORIES_KEY],
    networkMode: 'always',
    queryFn: USER_REGISTRATION_API.fetchTradeInDeviceCategories,
    select: (response) => {
      const categoryItems = response?.map((category: ICategoryData) => ({
        id: category._id,
        title: {
          en: category.categoryName,
          ar: category.categoryName_ar,
        },
        checked: false,
      }));
      return categoryItems;
    },
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isError,
    data,
    isLoading,
  };
};
