import { ILoginUserData } from '../types';

/**
 * TODO:: Move this to utilities
 * @param string - The string to be capitalized
 * @returns The capitalized string
 */
function capitalizeFirstLetter(string: string) {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * It will format raw response data of login mutation.
 */
export function formatUserDetails(userDetails: ILoginUserData): ILoginUserData {
  const { user, ...rest } = userDetails || {};

  const {
    firstName: firstNameRaw,
    lastName: lastNameRaw,
    ...userRest
  } = user || {};

  const firstName = capitalizeFirstLetter(firstNameRaw);
  const lastName = capitalizeFirstLetter(lastNameRaw);

  const initials = `${firstName[0] || ''}${lastName[0] || ''}`;

  return {
    ...rest,
    user: {
      ...userRest,
      firstName,
      lastName,
      initials: initials.toUpperCase(),
      courierPaymentType: userDetails.storeOptions.courierPaymentType,
    },
  } as ILoginUserData;
}
