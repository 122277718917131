import { useAuthStore, useQueryWithUserId } from '@northladder/services';

import {
  IApiError,
  IApiResponse,
  TFetchAppraisalsPayload,
} from '../../../../types';

import { CUSTOMER_SERVICE_API } from '../customerServiceClient';

/**
 * This is to track the download appraisals query key in react query cache.
 */
const DOWNLOAD_APPRAISALS_QUERY = 'download-appraisals-query-key';

/**
 * `IUseAppraisalsQuery` is hook parameters for useDownloadAppraisalsQuery.
 */
interface IUseDownloadAppraisalsQuery {
  /**
   * Payload for filters and search key mapping
   */
  payload: TFetchAppraisalsPayload;
  /**
   * Success callback for download appraisal API.
   */
  onSuccessCallBack: (data: IApiResponse<BlobPart> | undefined) => void;
}

/**
 * `useDownloadAppraisalsQuery` handles the API call to download the appraisals.
 * @returns { error, isError, data, callDownloadAppraisalsAPI }
 */
export const useDownloadAppraisalsQuery = ({
  payload,
  onSuccessCallBack,
}: IUseDownloadAppraisalsQuery) => {
  let localPayload = { ...payload };

  if (payload.categoryId === 'all' || !payload.categoryId) {
    const { categoryId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.brandId === 'all' || !payload.brandId) {
    const { brandId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.fromDate) {
    const { fromDate, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.toDate) {
    const { toDate, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.brandId === 'all') {
    if (!payload.categoryId) {
      const { categoryId, ...rest } = localPayload;
      localPayload = rest;
    }

    if (!payload.brandId) {
      const { brandId, ...rest } = localPayload;
      localPayload = rest;
    }
  }

  const userId = useAuthStore.getState()?.activeSession?.userId || '';

  const {
    data: downloadAppraisalsData,
    error,
    isError,
    refetch: callDownloadAppraisalsAPI,
  } = useQueryWithUserId({
    queryKey: [DOWNLOAD_APPRAISALS_QUERY],
    enabled: false,
    queryFn: () =>
      CUSTOMER_SERVICE_API.downloadAppraisals({
        ...localPayload,
        userId,
      }),
    onError: (err: IApiError) => err,
    onSuccess: (data) => onSuccessCallBack(data),
  });

  return {
    error,
    isError,
    downloadAppraisalsData,
    callDownloadAppraisalsAPI,
  };
};
