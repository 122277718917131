import { useTopBrandsQuery } from '@northladder/apis';
import { Text } from '@northladder/i18n';
import { CircularProgressLoader } from '@northladder/design-system';

import { TDaysUpdateValue } from '../DateSelectionPopup';
import { BrandCard } from '../BrandCard';

import { TTopModelsFilter, useTopStats } from './useTopStats';

/**
 * For Showing top brand card this component is calling api for fetch data from
 * the server and store in react query client
 *
 * @returns JSX.Element
 */
export function TopBrandsCard() {
  const { modelsFilterState, handleFilterChange, today, fromDay, setSubDate } =
    useTopStats({ initialSubDayCount: 30 });

  const {
    isLoading,
    error,
    refetch,
    data: topBrandData,
  } = useTopBrandsQuery({
    fromDate: fromDay,
    toDate: today,
    by: modelsFilterState,
  });

  const handleMeNlFilter = (filter: TTopModelsFilter) => {
    handleFilterChange(filter);
    refetch();
  };

  const handleDaySelectionFilterChange = async (
    selectedDay: TDaysUpdateValue
  ) => {
    await setSubDate(selectedDay.value);

    refetch();
  };

  // TODO: Change Currency type using country selection
  const brandItems = topBrandData?.data.data.map(
    ({ brandName, categoryName, name, percentage, purchasePrice }) => {
      const price = purchasePrice ? `(AED ${purchasePrice})` : '';

      const brandTitle = brandName || name;
      const categoryTitle = categoryName ? ` | ${categoryName}` : '';
      const title = `${brandTitle}${categoryTitle}`;

      return {
        title,
        desc: `${percentage}% ${price}`,
      };
    }
  );

  return (
    <>
      {isLoading ? <CircularProgressLoader strokecolor="#020203" /> : null}

      {error ? <Text className="text-red-800" text={error.message} /> : null}

      {!isLoading && (!brandItems || brandItems?.length === 0) ? (
        <span className="text-red-800">No Data Found!</span>
      ) : null}

      <BrandCard
        brandItems={brandItems}
        onClickMe={() => {
          handleMeNlFilter('me');
        }}
        onClickNL={() => {
          handleMeNlFilter('nl');
        }}
        onSelectDays={handleDaySelectionFilterChange}
        title="Top Brands"
      />
    </>
  );
}
