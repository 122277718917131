import { isWeb } from '@northladder/utilities';

import { ApiClient } from '../../../api-client';
import {
  TFetchTopBrandsPayload,
  IFetchSummaryPayload,
  TFetchAppraisalsPayload,
  IFetchAppraisalDetailsPayload,
  IFetchCreditLimitUrlOptions,
  IGetSignedUrlPayload,
  IFetchDealsUrlOptions,
  IFetchDealDetailsUrlOptions,
  IFetchDeviceGradeConditionUrlOptions,
  IFetchGradeReviewUrlOptions,
  IFetchGradeDealsUrlOptions,
  IFetchGradeReviewDetailsUrlOptions,
  TFetchGradeReviewHistoryUrlOptions,
  ICreateGradeChangeRequestPayload,
  IUpdateGradeChangeRequestPayload,
} from '../../../types';
import {
  creditLimitUrl,
  topBrandsUrl,
  topModelsUrl,
  inventorySummaryUrl,
  fetchAppraisalsUrl,
  uploadFileUrl,
  downloadAppraisalsUrl,
  fetchAppraisalDetailsUrl,
  getSignedUrlEndpoint,
  getBidExecutionTimeUrl,
  dealsListUrl,
  dealDetailsUrl,
  fetchDealsForGradeRequestSearchUrl,
  fetchGradeReviewDetailsUrl,
  DeviceItemConditionUrl,
  DeviceGradeConditionUrl,
  fetchGradeReviewsListUrl,
  fetchGradeReviewHistoryUrl,
  createGradeChangeRequestUrl,
  updateGradeChangeRequestUrl,
  uploadVideoUrl,
} from '../../../urls';

import {
  formatCreditLimitResponse,
  formatDealDetailsResponse,
  formatDealsListResponse,
  formatDeviceGradeChangeResponse,
  formatDeviceItemConditionResponse,
  formatGradeDealSearchResponse,
  formatGradeReviewRequestDetailsResponse,
  formatGradeReviewRequestHistoryResponse,
  formatGradeReviewRequestListResponse,
} from './query-hooks/transformers';
import {
  ICreditLimitResponse,
  IFetchAllTopBrands,
  ITopModelsPayload,
  ITopModelsResponse,
  IFetchSummary,
  IFetchAppraisalsResponse,
  IFetchAppraisalDetailsResponse,
  IFileUploadPayload,
  IFileUploadData,
  IGetSingedUrlResponse,
  IBidExecutionTimeResponse,
  IDealsListResponse,
  IDealDetailsResponse,
  IDeviceGradeConditionRawResponse,
  IDeviceItemConditionRawResponse,
  IGradeReviewRequestListResponse,
  IGradeDealsSearchRawResponse,
  IGradeReviewDetailsResponse,
  IGradeReviewHistoryResponse,
  ICreateGradeChangeRequestResponse,
  IUpdateGradeChangeRequestResponse,
  IVideoUploadPayload,
  TVideoUploadData,
} from './types';

/**
 * This is provides the API client methods for customer service section,
 * it will provider top brands, top models, dashboard statistics apis etc...
 */
export class CustomerServiceApiClient extends ApiClient {
  private static classInstance?: CustomerServiceApiClient;

  private constructor() {
    super({
      apiBaseURL: 'CUSTOMER_SERVICE_BASE_URL',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new CustomerServiceApiClient();
    }

    return this.classInstance;
  };

  /**
   * Api call to fetch the top brands.
   *
   * @returns IFetchAllBidsResponse
   */
  public fetchTopBrands = async (payload: TFetchTopBrandsPayload) => {
    const response = await this.post<IFetchAllTopBrands>(
      topBrandsUrl(),
      payload
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Fetch all top models.
   */
  public fetchAllTopModels = async (payload: ITopModelsPayload) => {
    const response = await this.post<ITopModelsResponse>(
      topModelsUrl(),
      payload
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch the Inventory Summary.
   *
   * @returns IFetchSummary
   */
  public fetchInventorySummary = async (payload: IFetchSummaryPayload) => {
    const response = await this.post<IFetchSummary>(
      inventorySummaryUrl(),
      payload
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * API call to fetch all the deals list using the deal ID.
   */
  public fetchDealsList = async (queryParams: IFetchDealsUrlOptions) => {
    const response = await this.get<IDealsListResponse>(
      dealsListUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatDealsListResponse(response.data.data);
  };

  /**
   * API call to fetch the details of a deal using the deal ID.
   */
  public fetchDealDetails = async (
    queryParams: IFetchDealDetailsUrlOptions
  ) => {
    const response = await this.get<IDealDetailsResponse>(
      dealDetailsUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatDealDetailsResponse(response.data.data);
  };

  //----------------------------------------------------------------------------
  // ------------------Dealer Sign up upload file API call----------------------
  //----------------------------------------------------------------------------

  /**
   * API call to upload files such as license, VAT certificate in the
   * registration screen.
   */
  public userRegistrationFileUpload = async (payload: IFileUploadPayload) => {
    const response = await this.post<IFileUploadData>(
      uploadFileUrl(),
      isWeb ? payload : payload.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        requiresAuth: false,
        timeout: 1 * 60 * 1000, // Force a 1 minute wait on uploads.
      }
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api call to fetch the appraisals.
   *
   * @returns IFetchAppraisalsResponse
   */
  public fetchAppraisals = async (queryParams: TFetchAppraisalsPayload) => {
    const response = await this.get<IFetchAppraisalsResponse>(
      fetchAppraisalsUrl(queryParams)
    );

    if (!response.success) throw response;

    return response.data?.data;
  };

  /**
   * Api call to download appraisals excel.
   */
  public downloadAppraisals = async (queryParams: TFetchAppraisalsPayload) => {
    const response = await this.get<BlobPart>(
      downloadAppraisalsUrl(queryParams),
      {
        responseType: 'blob',
      }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch the appraisal details.
   *
   * @returns IFetchAppraisalsResponse
   */
  public fetchAppraisalDetails = async (
    queryParams: IFetchAppraisalDetailsPayload
  ) => {
    const response = await this.get<IFetchAppraisalDetailsResponse>(
      fetchAppraisalDetailsUrl(queryParams)
    );

    if (!response.success) throw response;

    return response.data?.data;
  };

  /**
   * Api call to get the signed url.
   *
   * @returns IGetSingedUrlResponse
   */
  public getSignedUrl = async (payload: IGetSignedUrlPayload) => {
    const response = await this.post<IGetSingedUrlResponse>(
      getSignedUrlEndpoint(),
      payload
    );
    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api call to download appraisals excel.
   */
  public getBidExecutionTime = async () => {
    const response = await this.get<IBidExecutionTimeResponse>(
      getBidExecutionTimeUrl()
    );

    if (!response.success) throw response;

    return response.data?.data;
  };

  /**
   * Api call to fetch the creditLimit.
   */
  public fetchCreditLimit = async (
    queryParams: IFetchCreditLimitUrlOptions
  ) => {
    const response = await this.get<ICreditLimitResponse>(
      creditLimitUrl(queryParams),
      {
        timeout: 3 * 60 * 1000, // Force timeout to 3 minutes.
      }
    );

    if (!response.success) throw response;

    return formatCreditLimitResponse(response.data?.data);
  };

  /**
   * API call to fetch the grade review lists.
   */
  public fetchGradeReviewList = async (
    queryParams: IFetchGradeReviewUrlOptions
  ) => {
    const response = await this.get<IGradeReviewRequestListResponse>(
      fetchGradeReviewsListUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatGradeReviewRequestListResponse(response.data.data);
  };

  /**
   * API call to fetch the grade review request details.
   */
  public fetchGradeReviewDetails = async (
    queryParams: IFetchGradeReviewDetailsUrlOptions
  ) => {
    const response = await this.get<IGradeReviewDetailsResponse>(
      fetchGradeReviewDetailsUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatGradeReviewRequestDetailsResponse(response.data.data);
  };

  /**
   * Api call to fetch the Device Grade Conditions.
   */
  public fetchDeviceGradeConditions = async (
    queryParams: IFetchDeviceGradeConditionUrlOptions
  ) => {
    const response = await this.get<IDeviceGradeConditionRawResponse>(
      DeviceGradeConditionUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatDeviceGradeChangeResponse(response.data);
  };

  /**
   * Api call to fetch the Device Item Conditions.
   * TODO: Change the param and response types.
   */
  public fetchDeviceItemConditions = async (
    queryParams: IFetchDeviceGradeConditionUrlOptions
  ) => {
    const response = await this.get<IDeviceItemConditionRawResponse>(
      DeviceItemConditionUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatDeviceItemConditionResponse(response.data);
  };

  /**
   * API call to fetch the grade review lists.
   */
  public fetchDealsForGradeRequestSearchList = async (
    queryParams: IFetchGradeDealsUrlOptions
  ) => {
    const response = await this.get<IGradeDealsSearchRawResponse>(
      fetchDealsForGradeRequestSearchUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatGradeDealSearchResponse(response.data.data);
  };

  /**
   * API call to fetch the grade review request history.
   */
  public fetchGradeReviewHistory = async (
    queryParams: TFetchGradeReviewHistoryUrlOptions
  ) => {
    const response = await this.get<IGradeReviewHistoryResponse>(
      fetchGradeReviewHistoryUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatGradeReviewRequestHistoryResponse(response.data.data);
  };

  /**
   * Api to create grade change request.
   */
  public createGradeChangeRequest = async (
    payload: ICreateGradeChangeRequestPayload
  ) => {
    const response = await this.post<ICreateGradeChangeRequestResponse>(
      createGradeChangeRequestUrl(),
      payload
    );
    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api to update grade change request.
   */
  public updateGradeChangeRequest = async (
    payload: IUpdateGradeChangeRequestPayload
  ) => {
    const response = await this.post<IUpdateGradeChangeRequestResponse>(
      updateGradeChangeRequestUrl(),
      payload
    );
    if (!response.success) throw response;

    return response.data;
  };

  /**
   * API call to upload videos for grade review request.
   */
  public videoUpload = async (payload: IVideoUploadPayload) => {
    const response = await this.post<TVideoUploadData>(
      uploadVideoUrl(),
      payload.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        requiresAuth: false,
        timeout: 1 * 60 * 1000, // Force a 1 minute wait on uploads.
      }
    );

    if (!response.success) throw response;

    return response.data;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for All User Customer Service related requests.
 */
export const CUSTOMER_SERVICE_API =
  CustomerServiceApiClient.getClientInstance();
