import tw, { styled } from 'twin.macro';

export const DashboardLayoutContainer = styled.div`
  ${tw`
    relative
    flex-col
    flex
    min-h-screen
  `};
`;

export const DashboardMainWrapper = styled.main`
  ${tw`
    flex
    grow
    px-2
    py-3
    mx-auto
    mb-2
    w-full
    h-full

    max-w-[80rem]
    min-h-[calc(100vh-200px)]

    transition-all
    ease-in-out
    duration-200
    sm:px-4
    sm:py-0
    md:px-8
    xl:p-0
  `};
`;

export const DashboardGridContainer = styled.div`
  ${tw`
    w-full
    flex
    flex-col
  `};

  .dashboard-bid-filters {
    ${tw`
      col-span-1
      row-span-2
      rounded-lg
      p-2

      sm:col-span-2
    `};
  }
`;

export const DashboardBidFiltersSection = styled.section`
  ${tw`
    grid
    grid-cols-1
    gap-1

    sm:(grid-cols-1 grid-rows-5)
    md:(grid-cols-4 grid-rows-3 gap-2)
    lg:(grid-cols-8 grid-rows-6 gap-4)
  `};
`;

export const SectionWrapper = styled.section`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .bg-sky-blue {
    background: #d8e7f7;
  }

  ${tw`
    font-normal
    text-sm
    text-gray-800
  `};

  &.placeholder {
    ${tw`
      inset-0
      border
      border-dashed
      border-gray-200
      bg-gray-50
      rounded-lg
      shadow-lg
      shadow-gray-50
    `};

    .placeholder-text {
      ${tw`
      text-4xl
      font-thin
      text-gray-300
      shadow-gray-50
    `};
    }
  }
`;

export const ModalFooterComponentWrapper = styled.div`
  ${tw`
    flex
    justify-between
    gap-2
    mt-7
  `}

  .cancel-btn,
  .upload-btn {
    ${tw`
      inline-flex
      justify-center
      rounded-md
      border
      border-gray-300
      bg-white
      px-4
      py-2
      text-sm
      font-medium
      text-gray-900

      hover:bg-gray-200
      focus:outline-none
      focus-visible:ring-2 focus-visible:ring-gray-500
      focus-visible:ring-offset-2
    `};
  }

  .upload-btn {
    ${tw`
      border-transparent
      bg-purple-100
      text-purple-900

      hover:bg-purple-200
      focus-visible:(ring-purple-500)
    `};
  }
`;

export const ModalChildComponentWrapper = styled.div`
  &.upload-modal-child-wrapper {
    ${tw`
      flex
      flex-col
      justify-start
      gap-2
      min-h-[10rem]
    `}
  }

  &.download-modal-child-wrapper {
    ${tw`
      flex
      flex-col
      justify-start
      gap-2
      min-h-[10rem]
    `}
  }

  .category-image-btn {
    ${tw`
      m-2
      w-32
      h-40
      flex
      flex-col
      justify-around
      items-center
      rounded-md
      border
      border-gray-300
      sm:m-0
    `}
  }

  .browse-label {
    ${tw`
      inline-flex
      justify-center
      rounded-md
      border
      border-purple-500
      bg-white
      px-4
      py-2.5
      text-sm
      font-medium
      text-purple-500

      hover:bg-gray-50
      focus:outline-none
      focus-visible:(
        ring-2
        ring-purple-500
        ring-offset-2
      )
    `};
  }

  .browse-btn {
    ${tw`
      absolute
      z-[-1]
      top-1.5
      left-1.5
      text-sm
    `}
  }
`;

export const PageTitleWrapper = styled.main`
  ${tw`
    flex
    grow
    px-2
    mx-auto
    my-4
    w-full
    h-full

    max-w-[80rem]

    transition-all
    ease-in-out
    duration-200

    sm:px-4
    sm:py-0
    md:px-8
    xl:p-0
  `};
`;
