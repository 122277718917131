import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { Popover, Transition } from '@headlessui/react';

import { IActiveSession } from '@northladder/services';
import { generateProfileImageURL } from '@northladder/apis';
import { usePAAS } from '@northladder/utilities';

import { useSessionInstance } from '../../hooks';

import {
  ActiveSessionPreview,
  AddAnotherLoginBtn,
  FooterSessionsListItems,
  SignOutBtn,
  SwitchAccountOptionItem,
  SessionsInfoContainer,
} from '../SessionManagement';

import { ProfilePopoverTriggerBtn } from './ProfilePopoverTriggerBtn';

interface IOtherSessionsListProps {
  closePopCallback: () => void;
  isPaasEnabled: boolean;
}

function OtherSessionsList({
  closePopCallback,
  isPaasEnabled,
}: IOtherSessionsListProps) {
  const router = useRouter();
  const { updateActiveSession, allSessionsByIndex, otherSessionsIndices } =
    useSessionInstance();

  if (!allSessionsByIndex) {
    return null;
  }

  const handleItemClick = (session: IActiveSession) => {
    updateActiveSession(session);
    if (isPaasEnabled) {
      router.replace(`/${session.index}/my-deals`);
    } else {
      router.replace(`/${session.index}/dashboard`);
    }
    closePopCallback();
  };

  return (
    <>
      {otherSessionsIndices.map((sessionIndex) => (
        <SwitchAccountOptionItem
          key={sessionIndex}
          onClickItem={handleItemClick}
          session={allSessionsByIndex[sessionIndex]}
          type="select"
        />
      ))}
    </>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders a dropdown that shows the profile options for the current account.
 * - It displays all the active session accounts and provides options for the user
 * to switch the active account.
 * - Provides CTA for the user to manage their accounts or add another account.
 * - Also adds helpful links to support or docs pages.
 *
 * TODO: Further reduce the font size for very small devices on this popup
 */
export function ProfileFlyoutMenu() {
  const { activeSession, sessionsCount } = useSessionInstance();
  const { isPaasEnabled } = usePAAS();

  if (!activeSession) {
    return null;
  }

  const { user } = activeSession;
  const { firstName, lastName, displayPicture } = user;

  if (!activeSession) {
    return null;
  }

  const handleNavigateToSupport = () => {
    /**
     * Note: Using windows since `router.push(path)` can't support target='_blank'
     */
    const newWindow = window?.open('/support', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleNavigateToHelp = () => {
    const newWindow = window?.open('/help', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const footerActions = [
    { label: 'Support', onClick: handleNavigateToSupport },
    { label: 'Help', onClick: handleNavigateToHelp },
  ];

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button
            className="
              flex rounded-full bg-purple-100 text-sm
              text-white shadow-lg shadow-purple-100 focus:outline-none
              focus:ring-2
              focus:ring-purple-200
            "
          >
            <ProfilePopoverTriggerBtn
              firstName={firstName}
              imageUrl={generateProfileImageURL(displayPicture || '')}
              lastName={lastName}
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className="
                absolute z-30 mt-3 w-screen max-w-xs transform px-2 ltr:right-0
                rtl:left-0 rtl:right-auto sm:px-0
              "
            >
              <SessionsInfoContainer>
                <div className="session-item-wrapper">
                  <ActiveSessionPreview activeSession={activeSession} />

                  <SignOutBtn label="Sign Out" targetSession={activeSession} />

                  <div className="accounts-list-wrapper">
                    <OtherSessionsList
                      closePopCallback={close}
                      isPaasEnabled={!!isPaasEnabled}
                    />
                    <AddAnotherLoginBtn />
                  </div>

                  {sessionsCount > 1 ? <SignOutBtn /> : null}

                  {!isPaasEnabled ? (
                    <FooterSessionsListItems actions={footerActions} />
                  ) : null}
                </div>
              </SessionsInfoContainer>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
