import { useInfiniteQuery, useAuthStore } from '@northladder/services';
import { usePAAS } from '@northladder/utilities';

import { IFetchAllBidsPayload, IApiError } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';
import { IFetchAllBidsData } from '../types';

/**
 * This is to track the bids listing key in react query cache.
 */
export const FETCH_ALL_BIDS_KEY = 'fetch-all-bids-key';

export interface IUseAllBidsSuccessCallbackProps {
  pages: IFetchAllBidsData[];
}

interface IUseAllBidsQueryOptions {
  /**
   * Payload for filters and search key mapping
   */
  payload: IFetchAllBidsPayload;
}

/**
 * Adds conditional filters to generate an optimally formatted payload for the
 * bids search results.
 */
export const formatSearchBidsQueryPayload = (payload: IFetchAllBidsPayload) => {
  let localPayload = { ...payload };

  if (!payload.categoryId) {
    const { categoryId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.brandId === 'all' || !payload.brandId) {
    const { brandId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.filterBy) {
    const { filterBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.sortBy) {
    const { sortBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.orderBy) {
    const { orderBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.groupIds?.length === 0) {
    const { groupIds, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.fromDate) {
    const { fromDate, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.toDate) {
    const { toDate, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.bidFilterKey) {
    const { bidFilterKey, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.brandList || payload.brandList.length === 0) {
    const { brandList, ...rest } = localPayload;
    localPayload = rest;
  }

  return localPayload;
};

/**
 * This performs a get request and retrieves the bids data by category and
 * brands.
 * Provides data fetching states to be used in the bids table.
 */
export const useSearchBidsQuery = ({ payload }: IUseAllBidsQueryOptions) => {
  const { isPaasEnabled } = usePAAS();

  const localPayload = formatSearchBidsQueryPayload(payload);

  const {
    categoryId,
    brandId,
    brandList,
    groupIds,
    count,
    search,
    toDate,
    fromDate,
    filterBy,
    sortBy,
    orderBy,
    isHotDealsTabActive,
    bidFilterKey,
  } = localPayload;

  // TODO: Create a custom useInfinite query and update the key into there
  const userId = useAuthStore.getState()?.activeSession?.userId || '';

  const {
    data,
    isLoading,
    isError,
    error,
    isPaused,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      FETCH_ALL_BIDS_KEY,
      isHotDealsTabActive,
      categoryId,
      brandId,
      brandList,
      bidFilterKey,
      groupIds,
      toDate,
      fromDate,
      search,
      filterBy,
      sortBy,
      orderBy,
      userId,
    ],
    networkMode: 'always', // Even make calls when offline
    queryFn: ({ pageParam = 1 }) =>
      BIDS_API.searchAllBids({ ...localPayload, page: pageParam }),
    select: (response) => response,
    enabled: !isPaasEnabled,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;

      const currentAssetCount = allPages.length * count;

      return currentAssetCount < lastPage.totalCount ? nextPage : undefined;
    },
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    isPaused,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  };
};
