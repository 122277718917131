import tw, { styled } from 'twin.macro';

/**
 * Wraps session list components such as the one displayed in the
 * `ProfileFlyoutMenu` and the `ActiveAccountsLoginForm` comonents.
 */
export const SessionsInfoContainer = styled.div`
  z-index: 1000;

  ${tw`
    overflow-hidden
    rounded-lg
    ring-1
    ring-black
    ring-opacity-5
    shadow-xl
    shadow-purple-400/10
  `};

  .session-item-wrapper {
    ${tw`
      relative
      grid
      gap-6
      bg-white
      py-4
      sm:gap-4
    `};

    .accounts-list-wrapper {
      ${tw`
        block
        shrink-0
      `};
    }
  }
`;

/**
 * Wrapper around each individual list item displaying a session profile.
 */
export const SwitchAccountOptionItemWrapper = styled.button`
  ${tw`
    flex
    w-full
    flex-1
    shrink-0
    flex-row
    px-6
    py-4
    transition-all
    ease-in-out
    delay-100
    duration-500
    outline-none

    hover:bg-purple-50
    focus:(bg-purple-50 ring-1 ring-purple-100 ring-offset-1)
  `};

  &.remove-operation {
    ${tw`
      hover:bg-red-50
      focus:(bg-red-50 ring-red-100)
    `};
  }

  .session-list-item-wrapper {
    ${tw`
      flex
      w-full
      items-center
    `};
  }

  .profile-image {
    ${tw`
      mx-auto
      h-9
      w-9
      shrink-0
      rounded-full
      border-2
      border-gray-50
    `};
  }

  .no-profile-image-icon {
    ${tw`
      mx-auto
      flex
      h-8
      w-8
      shrink-0
      items-center
      justify-center
      rounded-full
      bg-gray-100
    `};

    svg {
      ${tw`
        flex
        h-6
        w-6
        text-purple-600
      `};
    }
  }
`;
