import { useState, useEffect } from 'react';

import { useDownloadBidBookQuery } from '@northladder/apis';
import { Text } from '@northladder/i18n';
import { ActionBarButton, Tooltip } from '@northladder/design-system';
import { createDomElementWithFileInBody } from '@northladder/utilities';

/**
 * `IDownloadBidBookProps` include categoryId and brandId needed for
 *  downloading bid book.
 */
export interface IDownloadBidBookProps {
  categoryId: string;
  brandId: string;
}

/**
 * -----------------------------------------------------------------------------
 * This component calls the `ActionBarButton` to handle downloading bid book.
 * @returns JSX.Element
 */
export function DownloadBidBook({
  categoryId,
  brandId,
}: IDownloadBidBookProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const {
    data: downloadData,
    downloadBidbook,
    error,
    isError,
  } = useDownloadBidBookQuery({
    queryParams: {
      categoryId,
      brandId,
    },
    onSuccessCallBack: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (categoryId === 'all' || brandId === 'all') {
      setDisable(true);
    } else {
      setDisable(false);
    }

    const blobData = downloadData?.data;

    if (blobData) {
      createDomElementWithFileInBody({
        fileData: blobData as unknown as BlobPart,
        fileNamePrefix: 'Bids_',
      });
    }
  }, [brandId, categoryId, downloadData]);

  const handleDownloadBidBookAction = () => {
    setIsLoading(true);
    downloadBidbook();
  };

  return (
    <div className="flex">
      <Tooltip
        message={
          brandId === 'all' || brandId === ''
            ? 'Select a brand first'
            : 'Download'
        }
      >
        <ActionBarButton
          className={`download-button ${disable && 'style-disabled'}`}
          icon="download"
          isDisabled={disable}
          isLoading={isLoading}
          label="Download Bid Book"
          onClick={handleDownloadBidBookAction}
        />
      </Tooltip>
      {isError && error ? <Text text={error?.message} /> : null}
    </div>
  );
}
