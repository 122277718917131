import {
  IExportAccountTransactionsUrlOptions,
  IAccountStatementUrlOptions,
} from '../../../types';

import { ApiClient } from '../../../api-client';
import {
  accountStatementUrl,
  exportAccountTransactionsUrl,
  paymentSummaryUrl,
} from '../../../urls';

import {
  IAccountStatementResponse,
  IExportTransactionResponse,
  IPaymentSummaryResponse,
} from './types';
import {
  formatAccountStatementsData,
  formatPaymentData,
} from './query-hooks/transformers';

/**
 * This is provides the API client methods for account service,
 * account related apis like payment summary etc...
 */
export class AccountServiceApiClient extends ApiClient {
  private static classInstance?: AccountServiceApiClient;

  private constructor() {
    super({
      apiBaseURL: 'ACCOUNT_SERVICE_BASE_URL',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new AccountServiceApiClient();
    }

    return this.classInstance;
  };

  /**
   * Fetch payment summary data
   */
  public fetchPaymentSummary = async () => {
    const response = await this.get<IPaymentSummaryResponse>(
      paymentSummaryUrl(),
      {
        timeout: 3 * 60 * 1000, // Force timeout to 3 minutes.
      }
    );

    if (!response.success) throw response;

    return formatPaymentData(response.data.data);
  };

  /**
   * API call to fetch the account statements.
   */
  public fetchAccountStatement = async (
    queryParams: IAccountStatementUrlOptions
  ) => {
    const response = await this.get<IAccountStatementResponse>(
      accountStatementUrl(queryParams)
    );

    if (!response.success) throw response;

    return formatAccountStatementsData(response.data.data);
  };

  /**
   * API call to export account transactions.
   */
  public exportAccountTransactions = async (
    queryParams: IExportAccountTransactionsUrlOptions
  ) => {
    const response = await this.get<IExportTransactionResponse>(
      exportAccountTransactionsUrl(queryParams),
      {
        timeout: 1 * 60 * 1000, // Force a 1 minute wait on export.
      }
    );

    if (!response.success) throw response;

    return response.data;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for Account Service related requests.
 */
export const ACCOUNT_SERVICE_API = AccountServiceApiClient.getClientInstance();
