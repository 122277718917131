import { GradeReviewStatus } from '../../types';

/**
 * helper to format the grade status since we are currently getting as `UNDER_PROCESS`
 * TODO: Ask the backend to send the status in the correct format.
 */
export const formatStatus = (status: string) =>
  status
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

export function getGradeStatusFromString(
  statusString: string
): GradeReviewStatus {
  const statusEnumValue: GradeReviewStatus | undefined = (
    GradeReviewStatus as any
  )[statusString];
  return statusEnumValue || GradeReviewStatus.NONE;
}
