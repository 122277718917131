import Link from 'next/link';
import Image from 'next/image';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

import { generateProfileImageURL } from '@northladder/apis';
import { usePAAS } from '@northladder/utilities';

/**
 * `IProfileInfoProps` is type definition for ProfileInfo component with
 * extended optional support for direction props from `TIsRTL`.
 */
interface IProfileInfoProps {
  /**
   * The accountId from the path params
   */
  accountId: number;
  /**
   * Email to be shown in the card. This is the email of the user and it accepts
   * one more.
   */
  email: string;
  /**
   * First name of the user to be used in profile info card.
   */
  firstName: string;
  /**
   * Last name of the user to be used in profile info card.
   */
  lastName: string;
  /**
   * Icon of the profile info card. This is user profile image endpoint
   */
  imageUrl: string;
  /**
   * Function to perform the logout operation.
   */
  onLogOut: () => void;
}

/**
 * -----------------------------------------------------------------------------
 *  Profile Info component is used to render the user information.
 *
 * @param IProfileInfoProps
 *
 * @returns return a complete UI for showing the profile card.
 */
export function ProfileInfo({
  accountId,
  email,
  onLogOut,
  firstName,
  lastName,
  imageUrl,
}: IProfileInfoProps): JSX.Element {
  const { isPaasEnabled } = usePAAS();

  const navPathWithSession = isPaasEnabled ? '#' : `/${accountId}/profile`;
  const profileName = `${firstName} ${lastName}`;

  return (
    <div className="flex shrink-0 flex-col border-t border-t-gray-100 border-opacity-5 px-16  py-4 lg:px-0">
      <Link className="group block w-full shrink-0" href={navPathWithSession}>
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            {imageUrl ? (
              <Image
                alt={`Profile picture for ${profileName}`}
                className="inline-block h-9 w-9 shrink-0 rounded-full"
                height={32}
                // placeholder="blur"
                src={generateProfileImageURL(imageUrl || '')}
                width={32}
              />
            ) : (
              <div
                className="
            mx-auto flex h-10 w-10 shrink-0 items-center justify-center
            rounded-full border-4 border-purple-100 bg-white
          "
              >
                <span
                  aria-hidden="true"
                  className="flex h-6 w-6 items-center justify-center text-purple-600"
                >
                  {`${firstName[0].toUpperCase()}${(
                    lastName[0] || ' '
                  ).toUpperCase()}`}
                </span>
              </div>
            )}
          </div>
          <div className="mx-2">
            <p className="text-sm font-medium text-gray-100 group-hover:text-purple-200">
              {profileName}
            </p>
            <p className="text-xs font-medium text-gray-300 group-hover:text-purple-200">
              {email}
            </p>
          </div>
        </div>
      </Link>

      {!isPaasEnabled ? (
        <button
          className="
          form-link-action group my-4 inline-flex self-start px-5 font-normal text-white transition-all
            duration-500 ease-in-out
          "
          onClick={onLogOut}
          type="button"
        >
          <ArrowRightOnRectangleIcon
            aria-hidden="true"
            className="
              -ml-4 mr-4 h-5 w-5 text-gray-100
              group-hover:text-purple-800 rtl:-mr-4 rtl:ml-4
            "
          />
          <span>Logout</span>
        </button>
      ) : null}
    </div>
  );
}
