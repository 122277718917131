import { ICosmeticsAssessmentOptions } from '../types';

/**
 * Endpoint that is used to retrieve the devices list from the backend that was
 * received from apple.
 */
export const deviceImageUploadsListUrl = ({
  assessmentType,
  sessionId,
}: ICosmeticsAssessmentOptions) =>
  `b2b/asset-condition/auto-assessment/log/${sessionId}?assessmentType=${assessmentType}`;

/**
 * Endpoint used for capturing posting the image details which the backend will
 * then post to apple.
 */
export const deviceImageUploadUrl = () =>
  'b2b/asset-condition/auto-assessment/image/upload';

/**
 * Validates with the server that all the images have been uploaded successfully.
 * @deprecated
 */
export const doneUploadingImageUrl = () => 'as/auto-assessment/image/done';
