import Image from 'next/image';

import { IAssetPaas } from '@northladder/apis';
import { IMyBid } from '@northladder/services';
import { BidRankBadge, Tooltip, TRankIcon } from '@northladder/design-system';

import { convertToHumanReadableTimestamp } from '@northladder/utilities';

import { ColumnDeviceDetails } from '../../../../components';

type TDeviceBrands = {
  [brandId: string]: {
    id: string;
    iconId: string;
    imageUrl: string;
  };
};

const deviceBrands: TDeviceBrands = {
  '5bf02c2c0d6fb901c0c66153': {
    id: 'apple',
    iconId: 'APPLE',
    imageUrl: 'https://prod-cdn.ksa.northladder.com/brand/1613997248411.jpeg',
  },
  '5c09241db200ef01b7a3a4c2': {
    id: 'samsung',
    iconId: 'SAMSUNG',
    imageUrl: 'https://prod-cdn.ksa.northladder.com/brand/1613997284865.jpeg',
  },
};

/**
 * Asset maps over fields like device name, spotPrice, isBidActive and so
 * on to be displayed in the components below.
 */
export interface IBidResultListItemPaas {
  asset: IAssetPaas;
}

interface IMyBidColumnDataProps {
  myBid: IMyBid;
}

function MyBidColumnData({ myBid }: IMyBidColumnDataProps) {
  const { spotPrice, rank, isSpotSummit } = myBid || {};

  const calculateRank = (): TRankIcon | null => {
    let iconRank: TRankIcon | null = null;

    if (!rank) return null;

    if (isSpotSummit) {
      if (rank === 1) {
        iconRank = 'firstRank';
      } else if (rank < 1) {
        iconRank = 'winningRange';
      }
    }
    return iconRank;
  };

  return (
    <td
      className="
        table-cell w-auto max-w-none flex-row items-center justify-end py-8
        text-center text-sm font-medium text-gray-900
      "
    >
      {spotPrice === 0 ? (
        'N/A'
      ) : (
        <div className="flex items-center justify-center">
          <Tooltip message="First Rank bid">
            <BidRankBadge icon={calculateRank} />
          </Tooltip>
          {spotPrice}
        </div>
      )}
    </td>
  );
}

export default function BidResultListItemPaas({
  asset,
}: IBidResultListItemPaas) {
  const { myBids, itemCondition, assetName, brandId, latestBibUpdatedDate } =
    asset || {};

  const getSortedBids = () => {
    const sortedBids: IMyBid[] = [];
    if (myBids) {
      itemCondition.forEach((condition) => {
        const selectedBid = myBids.find(
          (bid) => bid.itemCondition === condition
        );
        if (selectedBid) {
          sortedBids.push(selectedBid);
        }
      });
    }
    return { sortedBids };
  };

  const { sortedBids } = getSortedBids();

  const bidTimeStamp = convertToHumanReadableTimestamp(
    Number(new Date(latestBibUpdatedDate))
  );
  const imageUrl = deviceBrands[asset?.brandId]?.imageUrl;

  return (
    <tr
      className="group transition-all delay-100
        duration-200 ease-in-out even:bg-purple-50/40 hover:bg-purple-100/50"
    >
      <td
        className="
          whitespace-normal py-4 pl-4 pr-3 text-sm sm:pl-6 lg:table-cell
        "
      >
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            {imageUrl ? (
              <Image
                alt="Device brand image"
                className="h-10 w-10 rounded-full"
                height={24}
                src={deviceBrands[brandId]?.imageUrl}
                width={24}
              />
            ) : null}
          </div>
          <ColumnDeviceDetails
            description={bidTimeStamp}
            isHotDeal={false}
            title={assetName}
          />
        </div>
      </td>
      {sortedBids &&
        sortedBids.length > 0 &&
        sortedBids.map((myBid) => (
          <MyBidColumnData
            key={`${myBid.bidId}${myBid.updatedDate}`}
            myBid={myBid}
          />
        ))}
    </tr>
  );
}
