import { DashboardMainWrapper } from '../../../../styles';

import { AppraisalDetailsView } from './AppraisalDetailsView';

/**
 * -----------------------------------------------------------------------------
 * This renders the main layout and content of the profile page.
 */
export function AppraisalDetailsContent() {
  return (
    <DashboardMainWrapper className="flex-col items-stretch !justify-start rounded-xl bg-white">
      <AppraisalDetailsView />
    </DashboardMainWrapper>
  );
}
