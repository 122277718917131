import Image from 'next/image';
import Link from 'next/link';
import { UserCircleIcon } from '@heroicons/react/24/outline';

import { FlagIcon } from '@northladder/i18n';
import { IActiveSession } from '@northladder/services';
import { generateProfileImageURL } from '@northladder/apis';
import { usePAAS } from '@northladder/utilities';

interface IActiveSessionPreviewProps {
  /**
   * The current active session got from the `authStore`.
   */
  activeSession: IActiveSession;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the main profile info in the flyover menu. Displaying the a large
 * profile picture, roles and account type and an option to navigate the user to
 * their full profile.
 *
 * It also renders a flag to indicate the actively logged in region.
 *
 * @returns JSX.Element
 */
export function ActiveSessionPreview({
  activeSession,
}: IActiveSessionPreviewProps) {
  const { isPaasEnabled } = usePAAS();

  if (!activeSession) {
    return null;
  }
  const { regionIconId, index } = activeSession;
  const { email, firstName, lastName, displayPicture } = activeSession.user;

  const fullName = `${firstName} ${lastName}`;

  const imageUrl = generateProfileImageURL(displayPicture || '');

  return (
    <div>
      <FlagIcon
        className="mx-6 shrink-0 rounded-md"
        id={regionIconId}
        size={20}
      />
      <div className="flex flex-1 flex-col px-8 py-2">
        {imageUrl ? (
          <Image
            alt={`${fullName} face`}
            className="
              mx-auto h-24 w-24 shrink-0 rounded-full border-4
              border-gray-50
            "
            height={200}
            // placeholder="blur"
            src={imageUrl}
            width={200}
          />
        ) : (
          <div
            className="
              mx-auto flex h-24 w-24 shrink-0 items-center
              justify-center rounded-full bg-purple-100
            "
          >
            <span
              aria-hidden="true"
              className="flex items-center justify-center text-4xl text-purple-600"
            >
              {`${firstName[0].toUpperCase()}${(
                lastName[0] || ' '
              ).toUpperCase()}`}
            </span>
            {/* <UserIcon
              className="flex  h-16 w-16 text-gray-200"
              aria-hidden="true"
            /> */}
          </div>
        )}

        <dl className="mt-1 flex grow flex-col items-center justify-center gap-1">
          <dt className="sr-only">Name</dt>
          <dd className="text-xl font-medium text-gray-900 sm:text-xl">
            {fullName}
          </dd>
          <dt className="sr-only">Email Address</dt>
          <dd className="text-sm text-gray-700">{email}</dd>

          {!isPaasEnabled ? (
            <>
              <dt className="sr-only">Title</dt>
              <dd className="text-sm text-gray-500">title</dd>
              <dt className="sr-only">Role</dt>
              <dd className="mt-3">
                <span
                  className="
                    rounded-full bg-purple-50 px-2 py-1 text-xs
                    font-medium text-purple-500
                  "
                >
                  role
                </span>
              </dd>
            </>
          ) : null}
        </dl>
      </div>

      {!isPaasEnabled ? (
        <div className="relative">
          <div
            aria-hidden="true"
            className="absolute inset-0 flex items-center"
          >
            <div className="w-full border-t border-gray-100" />
          </div>
          <div className="relative flex justify-center">
            <Link
              className="
                group inline-flex items-center rounded-full border
                border-gray-200 bg-white py-1.5 pl-6 pr-3 text-sm leading-5
                text-gray-500 shadow-sm hover:bg-purple-50
                hover:text-purple-800 focus:outline-none focus:ring-2
                focus:ring-purple-100 focus:ring-offset-1
                rtl:pl-3 rtl:pr-6
              "
              href={`/${index}/profile`}
            >
              <UserCircleIcon
                aria-hidden="true"
                className="
                  -ml-4 mr-2 h-5 w-5 text-gray-600
                  group-hover:text-purple-800 rtl:-mr-4 rtl:ml-2
                "
              />
              <span>Manage your Account</span>
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
}
