import { IApiError, usePaymentStatsQuery } from '@northladder/apis';
import { Text } from '@northladder/i18n';
import {
  CircularProgressLoader,
  SummaryIcon,
} from '@northladder/design-system';

interface IPaymentStatsItemProps {
  /**
   * label of `payment status item`
   */
  title: string;
  /**
   * value of `payment status item`
   */
  value?: number;
}

/**
 * -----------------------------------------------------------------------------
 * This component will render title and value of single item. for showing
 * payment status
 *
 * @param IPaymentStatsItemProps
 */
export function PaymentStatsItem({ title, value }: IPaymentStatsItemProps) {
  function handlePointValues(): string {
    if (!value) return '0';

    if (value % 1 === 0) {
      return value.toString();
    }

    return value.toFixed(2);
  }

  return (
    <div className="flex flex-col px-2 py-1">
      <span className="text-sm font-bold">{handlePointValues()}</span>
      <span className="text-xs">{title}</span>
    </div>
  );
}

interface IPaymentStatusComponentProps {
  /**
   * api loading variable for showing the loader
   */
  isLoading: boolean;
  /**
   * api error variable for showing error
   */
  error: IApiError | null;
  /**
   * running balance for mapping to the component
   */
  runningBalance?: number;
  /**
   * paymentDue for mapping to the component
   */
  paymentDue?: number;
  /**
   * InTransit for mapping to the component
   */
  inTransit?: number;
}

/**
 * -----------------------------------------------------------------------------
 * This component will render i have to pay card for showing payment status of
 * current user it will show runningBalance, paymentDue, InTransit amounts
 *
 * @param IPaymentStatusComponentProps
 */
export function PaymentStatusComponent({
  isLoading,
  error,
  runningBalance,
  paymentDue,
  inTransit,
}: IPaymentStatusComponentProps) {
  return (
    <div className="flex flex-col rounded-sm p-5 drop-shadow-sm">
      {isLoading ? <CircularProgressLoader strokecolor="#020203" /> : null}

      {error ? <Text className="text-red-800" text={error.message} /> : null}

      <div className="flex flex-row">
        <SummaryIcon className="mr-3 rtl:ml-3" icon="IconBank" />
        <span className="self-center text-lg font-bold text-black">
          I have to pay
        </span>
      </div>
      <hr className="mt-2 border-[0.5px] border-gray-200" />
      <div className="flex flex-row divide-x-[1px] divide-gray-200">
        <PaymentStatsItem title="Running balance" value={runningBalance} />
        <PaymentStatsItem title="Payment Due" value={paymentDue} />
        <PaymentStatsItem title="In Transit" value={inTransit} />
      </div>
    </div>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This is a component for api fetch and data mapping for `PaymentStatusComponent`
 * It will manage api states.
 */
export function PaymentStatusCard() {
  const { isLoading, error, data } = usePaymentStatsQuery();

  /**
   * TODO: Handle loading and errors states for this component.
   */
  if (!data) return null;

  const { inTransit, runningBalance, paymentDue } = data;

  return (
    <PaymentStatusComponent
      error={error}
      inTransit={inTransit}
      isLoading={isLoading}
      paymentDue={paymentDue}
      runningBalance={runningBalance}
    />
  );
}
