import { useMutation } from '@northladder/services';

import { IApiError } from '../../../../types';
import { CUSTOMER_SERVICE_API } from '../customerServiceClient';
import { IGetSingedUrlResponse } from '../types';

/**
 * This is to track the get signed url mutation keys in react query cache.
 */
const MUT_GET_SIGNED_URL = 'get-signed-url-mutation-key';

interface IUseGetSignedUrlMutationProps {
  /**
   * Callback to be called when the file is uploaded successfully
   */
  onSuccessCallback: (signedUrlResponse: IGetSingedUrlResponse) => void;
}

/**
 * This performs a post request for uploading a file.
 */
export const useGetSignedUrlMutation = ({
  onSuccessCallback,
}: IUseGetSignedUrlMutationProps) => {
  const { isLoading, isError, error, mutate } = useMutation({
    mutationKey: [MUT_GET_SIGNED_URL],
    networkMode: 'always', // Even make calls when offline
    mutationFn: CUSTOMER_SERVICE_API.getSignedUrl,
    onSuccess: (response) => {
      onSuccessCallback(response);
    },
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    mutate,
  };
};
