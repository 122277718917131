/* eslint-disable class-methods-use-this */
import axios, { AxiosInstance, AxiosResponse, AxiosHeaders } from 'axios';

import { useAuthStore } from '@northladder/services';
import {
  ErrorEventsEnum,
  errorLogToRemoteUtil,
  TBaseURLS,
} from '@northladder/utilities';

import { globalFetchRefreshTokenAPICall } from '../dealer-api/clients/user-accounts/globalFetchRefreshTokenAPICall';
import { handleErrorInAPICall } from '../api-errors/handleErrorInAPICall';
import {
  IApiClient,
  IApiConfiguration,
  IApiError,
  IApiResponse,
  TApiResponseError,
} from '../types';

import { interceptAxiosRequestConfigCallback } from './interceptAxiosRequestConfigCallback';

const JSON_HEADER = 'application/json';

/**
 * -----------------------------------------------------------------------------
 * This is the base class to be extended by all the other API clients.
 * It's the direct gateway to axios methods and creates type-safe wrappers
 * around the axios methods to be implemented by the instance clients
 * - such as the `UserAccountsAPIClient`.
 *
 * - It adds error handling logic throw interceptors and overrides successful
 * request to add additional success keys.
 * - Also injects tokens on sessions that require auth, and check for token
 * validity or refreshes it behind the scenes if valid. Will log suspend calls
 * if token is expired.
 *
 * @param IApiConfiguration config - axios Request Config.
 * @link [AxiosRequestConfig](https://github.com/axios/axios#request-config)
 */
export abstract class ApiClient implements IApiClient {
  private apiBaseURL: TBaseURLS | '';

  private isRefreshing: boolean = false;

  private failedQueue: object[] = [];

  protected readonly client: AxiosInstance;

  /**
   * Creates an instance of the `ApiClient`.
   * @param config IApiConfiguration
   */
  public constructor(config: IApiConfiguration) {
    this.apiBaseURL = config.apiBaseURL || '';
    this.client = ApiClient.createAxiosClient(config);
    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  protected static createAxiosClient(
    apiConfiguration: IApiConfiguration
  ): AxiosInstance {
    const { accessToken, responseType, timeout, contentType, acceptHeader } =
      apiConfiguration;

    return axios.create({
      responseType: responseType || 'json',
      timeout: timeout || 20 * 1000, // Fallback to 20 seconds
      headers: {
        'Content-Type': contentType || JSON_HEADER,
        Accept: acceptHeader || JSON_HEADER,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    });
  }

  private interceptAxiosRequestConfig = async (config: IApiConfiguration) => {
    const requestConfig = await interceptAxiosRequestConfigCallback(
      config,
      this.apiBaseURL
    );

    if (requestConfig.type === 'PASSED') {
      return requestConfig.config;
    }

    throw requestConfig.error;
  };

  private interceptAxiosRequestError = (error: Error) => Promise.reject(error);

  private initializeRequestInterceptor = () => {
    this.client.interceptors.request.use(
      this.interceptAxiosRequestConfig,
      this.interceptAxiosRequestError
    );
  };

  private interceptAxiosResponse = (response: AxiosResponse) => ({
    ...(response || {}),
    success: true,
  });

  protected processQueue = (token = '') => {
    this.failedQueue.forEach((prom: any) => {
      prom.resolve(token);
    });
    this.failedQueue = [];
  };

  protected interceptAxiosResponseError = async (error: TApiResponseError) => {
    const errorObject = handleErrorInAPICall(error);
    const { activeSession, updateTokenInfo } = useAuthStore.getState();
    const originalRequest: IApiConfiguration<{ headers: AxiosHeaders }> =
      error.config as IApiConfiguration<{ headers: AxiosHeaders }>;

    if (
      error.response?.status === 401 &&
      !originalRequest.retry &&
      activeSession?.refreshToken
    ) {
      if (this.isRefreshing) {
        const token = await new Promise((resolve, reject) => {
          this.failedQueue.push({ resolve, reject });
        });

        // @ts-ignore
        originalRequest.headers.Authorization = `Bearer ${token}`;

        return this.client(originalRequest);
      }

      originalRequest.retry = true;
      this.isRefreshing = true;

      const tokenResponse = await globalFetchRefreshTokenAPICall(
        activeSession?.refreshToken,
        activeSession?.identifier
      );

      if (tokenResponse.success) {
        const { token } = tokenResponse;

        updateTokenInfo({
          userId: activeSession.userId,
          token,
          refreshToken: activeSession.refreshToken,
          isExpired: false,
        });

        // @ts-ignore
        originalRequest.headers.Authorization = `Bearer ${token}`;

        this.isRefreshing = false;
        this.processQueue(token);

        return this.client(originalRequest);
      }

      errorLogToRemoteUtil({
        error,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: errorObject.type,
        message: errorObject.message,
      });

      updateTokenInfo({
        userId: activeSession.userId,
        token: '',
        refreshToken: '',
        isExpired: true,
      });

      throw errorObject;
    } else {
      errorLogToRemoteUtil({
        error,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: errorObject.type,
        message: errorObject.message,
      });

      // This code is not needed here
      // TODO:  Check this with @Sowed and remove this code
      // if (activeSession?.userId) {
      // updateTokenInfo({
      //   userId: activeSession.userId,
      //   token: '',
      //   refreshToken: '',
      //   isExpired: true,
      // });
      // }

      throw errorObject;
    }
  };

  private initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(
      this.interceptAxiosResponse,
      this.interceptAxiosResponseError
    );
  };

  /**
   * Wrapper around the axios post request to `POST` request calls to the axios
   * client.
   *
   * @param endpoint The URL of the request
   * @param payload
   * @param config
   * @returns
   */
  public post = async <TResponseData = any, TRequestPayload = any>(
    endpoint: string,
    payload: TRequestPayload,
    config?: IApiConfiguration<TRequestPayload>
  ): Promise<IApiResponse<TResponseData> | IApiError> => {
    try {
      const response = await this.client.post<
        TResponseData,
        IApiResponse<TResponseData>
      >(endpoint, payload, config);

      return response;
    } catch (error) {
      return error as IApiError;
    }
  };

  public patch = async <TResponseData = any, TRequestPayload = any>(
    endpoint: string,
    payload: TRequestPayload,
    config?: IApiConfiguration<TRequestPayload>
  ): Promise<IApiResponse<TResponseData> | IApiError> => {
    try {
      return this.client.patch<TResponseData, IApiResponse<TResponseData>>(
        endpoint,
        payload,
        config
      );
    } catch (error) {
      return error as IApiError;
    }
  };

  public put = async <TResponseData = any, TRequestPayload = any>(
    endpoint: string,
    payload: TRequestPayload,
    config?: IApiConfiguration<TRequestPayload>
  ): Promise<IApiResponse<TResponseData> | IApiError> => {
    try {
      return this.client.put<TResponseData, IApiResponse<TResponseData>>(
        endpoint,
        payload,
        config
      );
    } catch (error) {
      return error as IApiError;
    }
  };

  public get = async <TResponseData = any, TRequestConfig = any>(
    endpoint: string,
    config?: IApiConfiguration<TRequestConfig>
  ): Promise<IApiResponse<TResponseData> | IApiError> => {
    try {
      return this.client.get<TResponseData, IApiResponse<TResponseData>>(
        endpoint,
        config
      );
    } catch (error) {
      return error as IApiError;
    }
  };
}
