import dynamic from 'next/dynamic';

/**
 * This dynamically imports `RegistrationForm3Content`content at the lazily
 * when required. This helps prevent nextjs hydration errors.
 */
const DynamicRegistrationForm3Content = dynamic(
  () => import('./RegistrationForm3Content'),
  {
    ssr: false,
  }
);

export default DynamicRegistrationForm3Content;
