import { gradeChangeRequestContent } from './grade-change-request';

import { ITutorialSummary } from './types';

/**
 * Content for the summary of the tutorial page that is translations ready.
 */
export const tutorialPageIntro = {
  heading: {
    en: 'Tutorials',
    ar: 'دروس',
  },
  subHeading: {
    en: 'Some helpful articles to get you started with the NorthLadder Dealer application',
    ar: 'بعض المقالات المفيدة لتساعدك على البدء باستخدام تطبيق NorthLadder Dealer',
  },
};

/**
 * Append all the tutorial content here and this should be the summary for all
 * the respective tutorials from the other articles.
 * Currently only
 */
export const tutorialPosts: ITutorialSummary[] = [gradeChangeRequestContent];
