import Image from 'next/image';
import {
  ChevronRightIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { FlagIcon } from '@northladder/i18n';
import { IActiveSession } from '@northladder/services';

import { generateProfileImageURL } from '@northladder/apis';
import { SwitchAccountOptionItemWrapper } from '../styles';

interface IItemProfileImageProps {
  fullName: string;
  imageUrl?: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the profile picture on the session item in the list or a fallback
 * user icon if the image url is not present.
 */
function ItemProfileImage({ fullName, imageUrl }: IItemProfileImageProps) {
  return (
    <div className="shrink-0">
      {imageUrl ? (
        <Image
          alt=""
          className="profile-image"
          height={24}
          src={imageUrl}
          title={`${fullName}'s photo. The other account you are logged in too`}
          width={24}
        />
      ) : (
        <div className="no-profile-image-icon">
          <UserIcon aria-hidden="true" />
        </div>
      )}
    </div>
  );
}

interface IItemNameAndEmailProps {
  fullName: string;
  email: string;
}

/**
 * -----------------------------------------------------------------------------
 * Displays the full name and email address on the session item.
 */
function ItemNameAndEmail({ fullName, email }: IItemNameAndEmailProps) {
  return (
    <div className="center mx-3 grow text-start">
      <p className="text-sm font-medium capitalize text-gray-500 group-hover:text-gray-900">
        {fullName}
      </p>
      <p className="text-xs text-gray-400 group-hover:text-gray-700">{email}</p>
    </div>
  );
}

interface ISwitchAccountOptionItemProps {
  /**
   * The handler to be called on pressing the item. Such as close the popover later.
   */
  onClickItem: (session: IActiveSession) => void;
  /**
   * The profile information of the session instance.
   */
  session: IActiveSession;
  /**
   * Determines whether to show a delete option to remove the item or active it.
   */
  type: 'select' | 'remove';
}

/**
 * -----------------------------------------------------------------------------
 * This renders an individual link action from that shows a snippet of profile
 * information, and navigates the user to that account when clicked.
 *
 * @returns JSX.Element
 */
export function SwitchAccountOptionItem({
  session,
  onClickItem,
  type = 'remove',
}: ISwitchAccountOptionItemProps) {
  const { regionIconId } = session;
  const { email, firstName, lastName, displayPicture } = session.user;

  const fullName = `${firstName} ${lastName}`;

  const handleClick = () => {
    onClickItem(session);
  };

  return (
    <SwitchAccountOptionItemWrapper
      className={`group ${type}-operation`}
      onClick={handleClick}
      type="button"
    >
      <div className="session-list-item-wrapper">
        <ItemProfileImage
          fullName={fullName}
          imageUrl={generateProfileImageURL(displayPicture || '')}
        />
        <ItemNameAndEmail email={email} fullName={fullName} />

        <div className="shrink-0">
          <FlagIcon
            className="shrink-0 rounded-md"
            id={regionIconId}
            size={20}
          />
        </div>

        {type === 'select' ? (
          <div className="pointer-events-none ml-2 shrink-0 text-gray-300 group-hover:text-purple-600 rtl:ml-0 rtl:rotate-180">
            <ChevronRightIcon
              className="h-4 w-4 shrink-0 grow-0"
              height="16px"
              width="16px"
            />
          </div>
        ) : (
          <div className="pointer-events-none ml-2 shrink-0 text-gray-300 group-hover:text-red-600 rtl:ml-0 rtl:rotate-180">
            <XMarkIcon
              className="h-4 w-4 shrink-0 grow-0"
              height="16px"
              width="16px"
            />
          </div>
        )}
      </div>
    </SwitchAccountOptionItemWrapper>
  );
}

export default SwitchAccountOptionItem;
