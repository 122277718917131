import {
  IGradeReviewHistoryResponseData,
  TGradeReviewHistoryResponseRawData,
} from '../../types';

import { formatStatus } from './utils';

/**
 * Formats the raw grade review history data taking into account things require
 * to display in the UI.
 */
export function formatGradeReviewRequestHistoryResponse(
  response: TGradeReviewHistoryResponseRawData
): IGradeReviewHistoryResponseData[] {
  const gradeReviewHistoryList: IGradeReviewHistoryResponseData[] =
    response.map((item) => {
      const {
        _id,
        appraisalCode,
        assetName,
        AWBNumber,
        bidAmount: bidPrice,
        brandName,
        comment,
        courierBooked: isCourierBooked,
        courierStatus,
        creditAmount,
        dealPaymentType,
        deliveredDate,
        financeStatus,
        financeClosingDate,
        createdAt,
        createdBy,
        dealPrice: purchasePrice,
        dealerEvaluation: proposedGradeReasonList,
        dealerBranchName: branchName,
        financeCheck,
        gradeReviewRequestId,
        imageUrls: imageList,
        imeiNumber,
        initialSaleGrade,
        initialSaleGradePercentage,
        isCreatedByNL,
        latestRemark,
        revisedGrade,
        revisedGradePercentage,
        requestedSaleGrade,
        requestedSaleGradeId,
        requestedSaleGradePercentage,
        status: gradeStatus,
        ticketNumber: ticketId,
        updatedAt,
        videoUrls: videoList,
      } = item;

      const formattedGradeStatus = formatStatus(gradeStatus);
      const displayStatus = [financeCheck ? 'COD' : '', formattedGradeStatus];

      return {
        _id,
        appraisalCode,
        assetName,
        AWBNumber,
        courierStatus,
        creditAmount,
        bidPrice,
        brandName,
        branchName,
        comment,
        dealPaymentType,
        financeStatus,
        deliveredDate,
        financeClosingDate,
        createdAt,
        createdBy,
        displayStatus,
        gradeReviewRequestId,
        gradeStatus: formattedGradeStatus,
        imageList,
        imeiNumber,
        initialSaleGrade,
        initialSaleGradePercentage,
        isCourierBooked,
        isCreatedByNL,
        latestRemark,
        purchasePrice,
        proposedGradeReasonList,
        revisedGrade,
        revisedGradePercentage,
        requestedSaleGrade,
        requestedSaleGradeId,
        requestedSaleGradePercentage,
        ticketId,
        updatedAt,
        videoList,
      };
    });

  return gradeReviewHistoryList;
}
