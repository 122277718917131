import { useState } from 'react';

import { useManageBidMutation } from '@northladder/apis';
import { IMyBid } from '@northladder/services';
import { ToggleButton } from '@northladder/design-system';

interface IToggleBid {
  myBid?: IMyBid;
  assetId?: string;
  onChangeBidActive: (value: boolean) => void;
}

export function ToggleBidAction({
  myBid,
  assetId,
  onChangeBidActive,
}: IToggleBid) {
  const { bidId, isBidActive = false } = myBid || {};
  /**
   * For handling the state of the active bids toggle button.
   */
  const [toggleBid, setToggleBid] = useState(isBidActive);

  const { createOrUpdateBid } = useManageBidMutation();

  const handleToggle = (toggleValue: boolean) => {
    setToggleBid(toggleValue);
    onChangeBidActive(toggleValue);

    if (bidId) {
      createOrUpdateBid({
        assetId: assetId || '',
        bidId: bidId || '',
        isBidActive: toggleValue,
      });
    }
  };

  return <ToggleButton isActive={toggleBid} onHandleToggle={handleToggle} />;
}
