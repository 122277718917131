import { IRankReports, IRankReportsRaw } from '../types';

/**
 * This transforms the `raw bids reports API response` from  to the format that
 * the UI expects.
 *
 * Notes: It adds additional keys like the `transactionID` and `initials` fields
 * to the response.
 */
export function formatRankReportResponse(
  response: IRankReportsRaw
): IRankReports {
  const {
    totalBids: totalBidsCount,
    bidNotWinning: bidNotWinningCount,
    winningRange: winningRangeCount,
    hotDeal: hotDealCount,
    totalBids: bidsCountTotalCount,
    outOfQuantity: outOfQuantityCount,
    rankOne: rankOneCount,
  } = response || {};

  return {
    type: 'mobile',
    bidsCountTotalCount,
    totalBidsCount,
    outOfQuantityCount,
    bidNotWinningCount,
    rankOneCount,
    winningRangeCount,
    hotDealCount,
  };
}
