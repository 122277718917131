import { useState } from 'react';
import Image from 'next/image';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { Text } from '@northladder/i18n';
import { IUser } from '@northladder/services';
import { generateProfileImageURL } from '@northladder/apis';
import { getPlaceholderDataImageURL } from '@northladder/utilities';
import { CircularLoader, FieldsSection } from '@northladder/design-system';

import { useSessionInstance } from '../../hooks';

import { translations } from './translations';
import { IEditOptions } from './types';
import { StyledTabbedNavLink } from './styles';
import { ProfileDetailsEdit } from './ProfileDetailsEdit';

type TProfileHeaderProps = IEditOptions &
  Pick<IUser, 'displayPicture' | 'firstName' | 'lastName'>;

/**
 * -----------------------------------------------------------------------------
 * This renders the main profile page header, showing the profile picture, the
 * first name, last name and the Profile Edit CTA.
 */
function ProfileHeader({
  displayPicture,
  firstName,
  lastName,
  isEditing,
  onEdit,
  onCancel,
}: TProfileHeaderProps) {
  const editBtnText = isEditing ? translations.cancel : translations.edit;
  const EditBtnIcon = isEditing ? XMarkIcon : PencilSquareIcon;

  /**
   * TODO: 1. Update this property to update the profile picture.
   * Replacing this with the update mutation loader.
   */
  const [isUpdatingDisplayPic] = useState(false);

  const handleEditCTAClick = () => {
    if (isEditing) {
      onCancel();
    } else {
      onEdit();
    }
  };

  return (
    <div id="profile-info">
      <div className="bg-gradient-to-r from-pink-100 via-purple-200 to-purple-100">
        <div className="h-32 w-full object-cover lg:h-48" />
      </div>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <div className="relative overflow-hidden rounded-full  ring-4 ring-white">
              <Image
                alt={`Profile picture for ${firstName}`}
                blurDataURL={getPlaceholderDataImageURL()}
                className="h-28 w-28 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                height={112}
                placeholder="blur"
                src={generateProfileImageURL(displayPicture || '')}
                width={112}
              />

              {isUpdatingDisplayPic ? (
                <CircularLoader className="absolute left-0 top-0 z-50 h-full w-full " />
              ) : (
                <label
                  className="
                  absolute inset-0 flex h-full w-full items-center justify-center
                  bg-black bg-opacity-75 text-sm font-medium text-white
                  opacity-0 focus-within:opacity-100 hover:opacity-100
                "
                  htmlFor="desktop-user-photo"
                >
                  <span>
                    <Text text={translations.change} />
                  </span>
                  <span className="sr-only"> user photo</span>
                  <input
                    className="
                    absolute inset-0 h-full w-full cursor-pointer rounded-md
                    border-gray-300 opacity-0
                  "
                    id="desktop-user-photo"
                    name="user-photo"
                    type="file"
                  />
                </label>
              )}
            </div>
          </div>
          <div
            className="
              mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end
              sm:space-x-6 sm:pb-1
            "
          >
            <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
              <h1 className="flex flex-col flex-wrap text-2xl font-light text-gray-900 sm:flex-row">
                <span>{firstName}</span>
                <span className="font-medium sm:ml-2">{lastName}</span>
              </h1>
            </div>
            <div
              className="
                justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row
                sm:space-x-4 sm:space-y-0
              "
            >
              <button
                className="
                  inline-flex justify-center rounded-md border border-gray-300
                  bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm
                  hover:bg-gray-50 focus:outline-none focus:ring-2
                  focus:ring-purple-400 focus:ring-offset-2
                "
                onClick={handleEditCTAClick}
                type="button"
              >
                <EditBtnIcon
                  aria-hidden="true"
                  className="-ml-1 mr-2 h-5 w-5 text-gray-400 rtl:ml-2 rtl:mr-0"
                />
                <Text text={editBtnText} />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
          <h1 className="truncate text-2xl font-light text-gray-900">
            <span>{firstName}</span>
            <span className="ml-2 font-medium">{lastName}</span>
          </h1>
        </div>
      </div>
    </div>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders the tabbed navigation at the bottom of the profile header.
 */
function ProfileTabbedNavigation() {
  const tabs = [
    { name: translations.profileInfo, href: '#profile-info', current: true },
    { name: translations.storeInfo, href: '#store-info', current: false },
  ];

  return (
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200">
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <StyledTabbedNavLink
                key={tab.name.en}
                aria-current={tab.current ? 'page' : undefined}
                className={tab.current ? 'tab-active' : ''}
                href={tab.href}
              >
                <Text text={tab.name} />
              </StyledTabbedNavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

interface IProfileDetails extends IEditOptions {
  // Add more profile details specific props here.
}

/**
 * -----------------------------------------------------------------------------
 * This renders the profile details UI save for the sidebar navigation.
 */
export function ProfileDetails({
  isEditing,
  onEdit,
  onCancel,
}: IProfileDetails) {
  const { activeSession } = useSessionInstance();

  if (!activeSession) {
    return null;
  }

  const { user } = activeSession;
  const {
    createdOn,
    email,
    firstName,
    lastName,
    // roles = [],
    userInfo,
    // userType,
    currency,
    displayPicture,
  } = user || {};

  const { phoneNo, branchName, isBranchActive, storeType } = userInfo || {};

  const profileInfoFields = [
    {
      label: translations.phoneNumber,
      value: phoneNo,
    },
    // {
    //   label: translations.accountType,
    //   value: userType,
    // },
    {
      label: translations.emailAddress,
      value: email,
    },
    {
      label: translations.region,
      value: activeSession.regionIconId,
    },
    // {
    //   label: translations.role,
    //   value: roles[0],
    // },
    {
      label: translations.joined,
      value: createdOn,
    },
  ];

  const storeInfoFields = [
    {
      label: translations.branchName,
      value: branchName,
    },
    {
      label: translations.storeType,
      value: storeType,
    },
    {
      label: translations.isBranchActive,
      value: isBranchActive ? 'Yes' : 'No',
    },
    {
      label: translations.currency,
      value: currency,
    },
  ];

  return (
    <>
      {/* Profile header */}
      <ProfileHeader
        displayPicture={displayPicture}
        firstName={firstName}
        isEditing={isEditing}
        lastName={lastName}
        onCancel={onCancel}
        onEdit={onEdit}
      />

      {isEditing ? (
        <ProfileDetailsEdit onCancel={onCancel} />
      ) : (
        <>
          <ProfileTabbedNavigation />
          <FieldsSection items={profileInfoFields} />
          <FieldsSection
            items={storeInfoFields}
            title={translations.storeInfo}
          />
        </>
      )}
    </>
  );
}
