import { CheckIcon } from '@heroicons/react/24/outline';

import { THeroIcon } from '@northladder/design-system';
import { Text, TTextLocalized } from '@northladder/i18n';

import { FormStatusHeader } from '../../styles';

/**
 * `IRegistrationStatusHeaderProps` classes to style the icon and the button in
 * the header.
 */
export interface IRegistrationStatusHeaderProps {
  icon: THeroIcon;
  className: string;
  buttonClassName: string;
  title: TTextLocalized;
  status: TTextLocalized;
  constStatus: TTextLocalized;
}

/**
 * RegistrationStatus Header consists of the `CheckCircleIcon` and the status-
 * active or in review.
 */
export function RegistrationStatusHeader({
  icon,
  className,
  buttonClassName,
  title,
  status,
  constStatus,
}: IRegistrationStatusHeaderProps) {
  const Icon = icon || CheckIcon;
  return (
    <FormStatusHeader>
      <div className="form-header flex text-center">
        <Icon className={className} />
      </div>
      <h1 className="grow !font-normal">
        <Text text={title} />
      </h1>
      <button className={buttonClassName} type="button">
        <Text text={constStatus} />
        <Text text={status} />
      </button>
    </FormStatusHeader>
  );
}
