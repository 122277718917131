import tw, { styled } from 'twin.macro';

export const AppNavActionsWrapper = styled.div`
  ${tw`
    b2xl:mx-0
    relative
    -top-4
    flex
    items-center
    justify-end
    gap-2
    transition-all
    duration-300

    sm:(top-auto text-sm)
    lg:text-2xl
  `}
`;
