import { useMutation } from '@northladder/services';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@northladder/utilities';

import { IApiError } from '../../../../types';

import { IValidateRegistrationOPTData } from '../types';
import { USER_REGISTRATION_API } from '../userRegistrationAPIClient';

/**
 * This is to track the validate user reg. otp mutation keys in react query cache.
 */
const MUT_VALIDATE_USER_REG_OTP = 'validate-user-reg-otp';

interface IValidateUserRegistrationOTPOptions {
  onSuccessCallback: (otpData: IValidateRegistrationOPTData) => void;
  onErrorCallback: (err: IApiError) => void;
}

/**
 * This verifies the otp sent to the user's mobile during registration, and
 * return the user's data registration if the otp is valid.
 */
export const useValidateUserRegistrationOTPMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IValidateUserRegistrationOTPOptions) => {
  const { mutate, isLoading, error, data } = useMutation({
    mutationKey: [MUT_VALIDATE_USER_REG_OTP],
    networkMode: 'always',
    retry: false,
    mutationFn: USER_REGISTRATION_API.validateUserRegistrationOTP,
    onSuccess: (response) => {
      onSuccessCallback(response);
    },
    onError: (err: IApiError) => {
      onErrorCallback(err);
      errorLogToRemoteUtil({
        error,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Error in Validating OTP Attempt',
        message: err.message,
      });

      return err;
    },
  });

  return {
    error,
    otpData: data,
    isLoading,
    validateRegistrationOTP: mutate,
  };
};
