import { useInfiniteQuery, useAuthStore } from '@northladder/services';
import { usePAAS } from '@northladder/utilities';

import { IApiError, IFetchAllBidsPayload } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';
import { IFetchAllBidsPaasData } from '../types';

/**
 * This is to track the bids listing key for PAAS in react query cache.
 */
export const FETCH_ALL_BIDS_PAAS_KEY = 'fetch-all-bids-paas-key';

export interface IUseAllBidsSuccessCallbackProps {
  // TODO: Remove if it's not getting used.
  // pageParams: any[];
  pages: IFetchAllBidsPaasData[];
}

interface IUseAllBidsQueryOptions {
  /**
   * Payload for filters and search key mapping
   */
  payload: IFetchAllBidsPayload;
}

/**
 * This performs a get request and retrieves the bids data by category and
 * brands.
 * Provides data fetching states to be used in the bids table.
 */
export const usePaasSearchBidsQuery = ({
  payload,
}: IUseAllBidsQueryOptions) => {
  const { isPaasEnabled } = usePAAS();

  let localPayload = { ...payload };

  if (payload.brandId === 'all' || !payload.brandId) {
    const { brandId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.filterBy) {
    const { filterBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.sortBy) {
    const { sortBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.orderBy) {
    const { orderBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.groupIds?.length === 0) {
    const { groupIds, ...rest } = localPayload;
    localPayload = rest;
  }

  const {
    categoryId,
    brandId,
    groupIds,
    count,
    search,
    toDate,
    fromDate,
    filterBy,
    sortBy,
    orderBy,
  } = localPayload;
  // TODO: Create a custom useInfinite query and update the key into there
  const userId = useAuthStore.getState()?.activeSession?.userId || '';

  const {
    data,
    isLoading,
    isError,
    error,
    isPaused,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      FETCH_ALL_BIDS_PAAS_KEY,
      categoryId,
      brandId,
      groupIds,
      toDate,
      fromDate,
      search,
      filterBy,
      sortBy,
      orderBy,
      userId,
    ],
    networkMode: 'always', // Even make calls when offline
    queryFn: ({ pageParam = 1 }) =>
      BIDS_API.searchAllBidsPaas({ ...localPayload, page: pageParam }),
    select: (response) => response,
    enabled: Boolean(isPaasEnabled) && Boolean(localPayload.categoryId),
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;

      const currentAssetCount = allPages.length * count;

      return currentAssetCount < lastPage.totalCount ? nextPage : undefined;
    },
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    isPaused,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
