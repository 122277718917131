import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { ISidebarOptions } from './types';
import { SubNavItem } from './SubNavItem';

interface ISidebarMobileProps extends ISidebarOptions {
  isSidebarOpen: boolean;
  onCloseSidebar: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the side bar on larger screens. See also `SidebarDesktop`.
 */
export function SidebarMobile({
  isSidebarOpen,
  navigationItems,
  secondaryNavigationItems,
  onCloseSidebar,
}: ISidebarMobileProps) {
  return (
    <Transition.Root as={Fragment} show={isSidebarOpen}>
      <Dialog
        as="div"
        className="relative z-40 lg:hidden"
        onClose={onCloseSidebar}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel
              className="
                relative flex w-full max-w-xs flex-1 flex-col bg-white
                focus:outline-none
              "
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    className="
                      ml-1 flex h-10 w-10 items-center justify-center
                      rounded-full focus:outline-none focus:ring-2
                      focus:ring-inset focus:ring-white
                    "
                    onClick={onCloseSidebar}
                    type="button"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="h-0 flex-1 overflow-y-auto pb-4 pt-20">
                <nav aria-label="Sidebar" className="mt-5">
                  <div className="space-y-1 px-2">
                    {navigationItems.map(
                      ({ href, icon, current, name, disabled }) => (
                        <SubNavItem
                          key={href}
                          disabled={disabled}
                          href={href}
                          Icon={icon}
                          isCurrent={current}
                          text={name}
                        />
                      )
                    )}
                  </div>
                  <hr
                    aria-hidden="true"
                    className="my-5 border-t border-gray-200"
                  />
                  <div className="space-y-1 px-2">
                    {secondaryNavigationItems.map(
                      ({ href, icon, current, name, disabled }) => (
                        <SubNavItem
                          key={href}
                          disabled={disabled}
                          href={href}
                          Icon={icon}
                          isCurrent={current}
                          text={name}
                        />
                      )
                    )}
                  </div>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div aria-hidden="true" className="w-14 flex-shrink-0">
            {/* Forces the sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
