import { useMutation } from '@northladder/services';

import { IApiError } from '../../../../types';

import { USER_REGISTRATION_API } from '../userRegistrationAPIClient';
import { IRegistrationData } from '../types';

/**
 * This is to track the login mutation keys in react query cache.
 */
const MUT_REGISTER_USER = 'user-register-mutation-key';

interface IUseRegistrationOptions {
  onSuccessCallback: (registerUserData: IRegistrationData) => void;
  onErrorCallback: (err: IApiError) => void;
}

/**
 * This performs a registration request and retrieves the user info and token.
 * Provides data fetching states and a callback to be used in the redirection later.
 *
 * @param IUseRegistrationOptions
 */
export const useRegistrationMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseRegistrationOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_REGISTER_USER],
    networkMode: 'always',
    retry: false,
    mutationFn: USER_REGISTRATION_API.registerUser,
    onSuccess: (response) => {
      onSuccessCallback(response);
    },
    onError: (err: IApiError) => {
      onErrorCallback(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    submitRegistration: mutate,
  };
};
