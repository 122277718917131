import { useRouter } from 'next/router';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import { useFlutterMessageHandler } from '@northladder/utilities';
import { TLocale, useRegistrationStore } from '@northladder/services';
import { LogoNorthLadder } from '@northladder/design-system';

interface IFlutterMobileHeaderProps {
  /**
   * To allow the user to use the back arrow button.
   */
  canGoBack?: boolean;
  canResetSession?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a simple navigation header that shows a `Back` Arrow navigation
 * and the NorthLadder Logo. This is only shown if the app is running Flutter.
 */
export function FlutterMobileHeader({
  canGoBack = true,
  canResetSession = false,
}: IFlutterMobileHeaderProps) {
  const { asPath, push } = useRouter();
  const locale = useRouter().locale as TLocale;

  const { registrationSession, removeRegistrationSession } =
    useRegistrationStore();

  const { isFlutterApp, postMessageToFlutter } = useFlutterMessageHandler();

  if (!isFlutterApp) return null;

  const handleBackNavigation = () => {
    if (canResetSession) {
      postMessageToFlutter({ type: 'back', language: locale });

      if (registrationSession) {
        removeRegistrationSession();
      }
    } else if (asPath === '/registration/status') {
      push('/registration/step-3');
    } else if (asPath === '/registration/step-3') {
      push('/registration/step-2');
    } else if (asPath === '/registration/step-2') {
      push('/registration?mode=edit');
    } else if (asPath === '/registration?mode=edit') {
      postMessageToFlutter({ type: 'back', language: locale });

      if (registrationSession) {
        removeRegistrationSession();
      }
    } else if (asPath === '/registration') {
      postMessageToFlutter({ type: 'back', language: locale });
    } else if (asPath === '/registration/status-active') {
      if (registrationSession) {
        removeRegistrationSession();
      }
      push('/registration');
    }
  };

  return (
    <div className="group mb-4 flex w-full items-center justify-between text-violet-800">
      {canGoBack ? (
        <ArrowLeftIcon
          className="h-6 shrink-0 cursor-pointer hover:text-violet-500 rtl:rotate-180"
          onClick={handleBackNavigation}
        />
      ) : (
        <div className="h-5 w-5 shrink-0" />
      )}
      <LogoNorthLadder className="h-4" />
      <div className="h-5 w-5 shrink-0" />
    </div>
  );
}
