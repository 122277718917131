import { IApiError, useInventorySummaryQuery } from '@northladder/apis';

import { TDaysUpdateValue } from '../../DateSelectionPopup/DateSelectionPopup';

import { summaryList } from './constants';
import { useSummaryFilter } from './useSummaryFilter';

import { StatsSummaryList } from './StatsSectionSummaryList';

/**
 * -----------------------------------------------------------------------------
 * It is api fetch handler component it will call `useInventorySummaryQuery`
 * query and provide response to `StatsSummaryList` component
 *
 * @returns Jsx.Element
 */
export function StatsSectionSummary() {
  const { summaryType, setSubDate, today, fromDay, handleSummaryTypeChange } =
    useSummaryFilter({
      initialSubDayCount: 30,
    });

  const {
    data: summmaryVal,
    isLoading,
    isError,
    error,
  } = useInventorySummaryQuery({
    fromDate: fromDay,
    toDate: today,
    by: summaryType,
  });

  const handleDaySelectionChange = (selectedDate: TDaysUpdateValue) => {
    setSubDate(selectedDate.value);
  };

  return (
    <StatsSummaryList
      error={error as IApiError}
      isError={isError}
      isLoading={isLoading}
      items={summaryList}
      onSelectDays={handleDaySelectionChange}
      onSummaryTypeFilter={handleSummaryTypeChange}
      summaryValues={summmaryVal?.data?.data}
    />
  );
}
