import { useRouter } from 'next/router';
import { CheckIcon } from '@heroicons/react/24/outline';

import { Text } from '@northladder/i18n';
import { useFlutterMessageHandler } from '@northladder/utilities';
import {
  TLocale,
  useRegionStore,
  useRegistrationStore,
} from '@northladder/services';
import { Button } from '@northladder/design-system';

import { translations } from '../../translations';

import { RegistrationStatusHeader } from './RegistrationStatusHeader';
import { RegistrationStatusFooter } from './RegistrationStatusFooter';

/**
 * `RegistrationApproved` displays that the Registration is complete and the user
 * account is active.
 *
 * @param signUpDetails
 * @returns JSX.Element
 */
export function RegistrationApproved() {
  const { registrationSession, removeRegistrationSession } =
    useRegistrationStore();

  const { email, phoneNumber, businessInfo } = registrationSession?.user || {};
  const { region } = useRegionStore();
  const { replace } = useRouter();
  const locale = useRouter().locale as TLocale;

  const { isFlutterApp, postMessageToFlutter } = useFlutterMessageHandler();

  const handleContinueToLogin = () => {
    if (isFlutterApp && registrationSession) {
      postMessageToFlutter({
        countryCode: region?.countryCode || '+971',
        email: email || businessInfo?.email || '',
        language: locale,
        number: phoneNumber || '',
        type: 'login',
        status: 'NEW_MEMBER',
      });
    }
    replace('/login');

    if (registrationSession) {
      removeRegistrationSession();
    }
  };

  return (
    <div className="form-header">
      <RegistrationStatusHeader
        buttonClassName="status-button-styles active-styles"
        className="active-icon-styles"
        constStatus={translations.registrationStatusDetails.status}
        icon={CheckIcon}
        status={
          translations.registrationStatusDetails.registrationApproved
            .statusActive
        }
        title={
          translations.registrationStatusDetails.registrationApproved.header
        }
      />
      <div className="mt-8 text-center text-base font-normal">
        <div className="p-6">
          <p>
            <Text
              text={
                translations.registrationStatusDetails.registrationApproved
                  .message
              }
            />
          </p>
          <Text
            text={
              translations.registrationStatusDetails.registrationApproved
                .description
            }
          />
        </div>
        <Button
          className="mb-4 mt-16 w-48"
          isEnabled
          label={translations.registrationStatusDetails.button.login}
          loadingLabel={translations.loadingState.pleaseWait}
          onClick={handleContinueToLogin}
          type="button"
        />
      </div>
      <RegistrationStatusFooter />
    </div>
  );
}
