import { useMutation } from '@northladder/services';

import { IApiError } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';

/**
 * This is to track the upload bid file status mutation keys in react query cache.
 */
const MUT_UPLOAD_BID_FILE = 'upload-bid-file-mutation-key';

/**
 * This performs a post request for uploading the bid file by category.
 */
export const useUploadBidFileMutation = () => {
  const { data, isLoading, isError, error, mutate, reset, isSuccess } =
    useMutation({
      mutationKey: [MUT_UPLOAD_BID_FILE],
      networkMode: 'always', // Even make calls when offline
      mutationFn: BIDS_API.uploadBidFile,
      onError: (err: IApiError) => err,
    });

  return {
    isLoading,
    isError,
    error,
    mutate,
    reset,
    isSuccess,
    data,
  };
};
