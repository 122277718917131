import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconSettings component is svg for settings icon which is customizable with
 * some default values configured. It is mainly used in Menu Items for navigation
 * but also can be used elsewhere.
 *
 * @param TSvgComponentProps Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
export default function IconSettings(props: TSvgComponentProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M12.9 23.7h-1.5c-1.6 0-3-1.3-3-2.9 0-.2-.1-.5-.2-.7-.2-.3-.5-.6-.9-.7-.4-.1-.8 0-1.1.1-.6.5-1.5.6-2.2.4-.8-.2-1.4-.7-1.8-1.4l-.7-1.2c-.4-.8-.5-1.5-.3-2.3.2-.8.7-1.4 1.4-1.8.4-.2.7-.7.7-1.2s-.3-1-.7-1.3c-.7-.4-1.2-1-1.4-1.8-.2-.7-.1-1.5.3-2.2l.8-1.3c.8-1.4 2.6-1.9 4-1.1.2.2.5.3.7.3.8 0 1.4-.6 1.5-1.4 0-.8.3-1.5.9-2.1.6-.6 1.3-.9 2.1-.9H13c.8 0 1.6.3 2.1.9.6.6.9 1.3.8 2.1 0 .2.1.4.2.6.2.3.5.6.9.7.4.1.8 0 1.1-.2 1.4-.8 3.2-.3 4 1.1l.8 1.4c.7 1.4.2 3-1.2 3.8-.2.1-.4.3-.5.5-.2.5-.3.9-.2 1.2.1.4.3.7.7.9.7.4 1.2 1 1.4 1.8.2.8.1 1.6-.3 2.2l-.8 1.3c-.8 1.4-2.6 1.9-4 1.1-.2-.1-.5-.2-.7-.2-.4 0-.7.2-1 .4-.3.3-.4.6-.4 1-.1 1.6-1.4 2.9-3 2.9zM7 17.9c.3 0 .5 0 .8.1.8.2 1.4.7 1.8 1.4.3.4.4.9.4 1.4 0 .8.7 1.4 1.5 1.4H13c.8 0 1.5-.6 1.5-1.4 0-.8.3-1.5.9-2.1.6-.5 1.3-.9 2.1-.9.5 0 1 .1 1.4.4.7.4 1.6.2 2-.5l.8-1.3c.2-.3.2-.7.1-1.1-.1-.4-.3-.7-.7-.9-.7-.4-1.2-1-1.4-1.8-.2-.7-.1-1.6.3-2.2.3-.4.6-.8 1.1-1.1.7-.4.9-1.3.5-1.9l-.7-1.2c-.4-.7-1.3-.9-2-.5s-1.5.5-2.2.3c-.8-.2-1.4-.7-1.8-1.3-.3-.4-.4-.9-.4-1.4 0-.4-.1-.8-.4-1.1-.3-.3-.7-.4-1.1-.4h-1.5c-.4 0-.8.2-1 .4-.3.3-.4.6-.4 1 0 1.6-1.4 2.9-3 2.9-.5 0-1-.1-1.4-.4-.8-.4-1.7-.2-2.1.5l-.8 1.3c-.2.3-.3.7-.2 1.1.1.3.4.6.7.8.9.6 1.5 1.5 1.5 2.6s-.6 2-1.5 2.5c-.3.2-.6.5-.7.9-.1.4 0 .7.1 1.1l.7 1.2c.2.4.5.6.9.7.4.1.8.1 1.1-.1.6-.3 1.1-.4 1.6-.4z" />
      <path d="M12.2 15.3c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.6 3.4-3.4 3.4zm0-5.3c-1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9 1.9-.8 1.9-1.9-.9-1.9-1.9-1.9z" />
    </svg>
  );
}
