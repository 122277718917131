import { useInfiniteQuery, useAuthStore } from '@northladder/services';

import { IApiError, TFetchUploadBidFilesPayload } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';
import { IUploadedBidFilesPaginatedData } from '../types';

/**
 * This is to track the upload bid files key in react query cache.
 */
export const FETCH_UPLOAD_BID_FILES_KEY = 'fetch-upload-bid-files-key';

export interface IUseAllBidsSuccessCallbackProps {
  // TODO: Remove if it's not getting used.
  // pageParams: any[];
  pages: IUploadedBidFilesPaginatedData[];
}

interface IUseUploadBidFilesQuery {
  /**
   * Payload for filters and search key mapping
   */
  payload: TFetchUploadBidFilesPayload;
}

/**
 * This performs a get request and retrieves the upload bids files data by
 * category.
 * Provides data fetching states to be used in the upload bid files table.
 */
export const useUploadBidFilesQuery = ({
  payload,
}: IUseUploadBidFilesQuery) => {
  const userId = useAuthStore.getState()?.activeSession?.userId || '';

  const {
    data,
    isLoading,
    isError,
    error,
    isPaused,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [FETCH_UPLOAD_BID_FILES_KEY, userId, payload.categoryId],
    enabled: false, // It's a dependant query so don't run immediately.
    networkMode: 'always', // Even make calls when offline
    queryFn: ({ pageParam = 1 }) =>
      BIDS_API.fetchUploadBidFiles({ ...payload, page: pageParam }),
    select: (response) => response,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;

      const currentAssetCount = allPages.length * payload.count;

      return currentAssetCount < lastPage.totalCount ? nextPage : undefined;
    },
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    isPaused,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
