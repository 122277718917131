import { useQueryWithUserId } from '@northladder/services';

import { IApiError } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';

/**
 * This is to track the category filter query key in react query cache.
 */
export const CATEGORY_FILTER_KEY = 'category-filter-key';

interface IBidsCategoryQueryParam {
  isEnabled?: boolean;
}

/**
 * This performs a filter request and retrieves the category filter options,
 * brand filter options and rank report.
 * Provides data fetching states to be used in the dropdowns.
 */
export const useBidsCategoryQuery = (options?: IBidsCategoryQueryParam) => {
  /**
   * For now adding true if no option is passed
   *
   * @sowed can you please verify why options are not being passed to this
   * `useBidsCategoryQuery`.
   */
  const isEnabled = options?.isEnabled || true;

  const {
    isLoading,
    isError,
    error,
    isPaused,
    refetch,
    isFetching,
    data: categoryFilterAPIResponse,
  } = useQueryWithUserId({
    queryKey: [CATEGORY_FILTER_KEY],
    networkMode: 'always', // Even make calls when offline
    queryFn: BIDS_API.fetchAllBidCategoryFilters,
    // onSuccess: (response) => {},
    enabled: !!isEnabled,
    onError: (err: IApiError) => err,
    staleTime: 5 * 1000 * 60, // 5 seconds
  });
  return {
    error,
    isError,
    isFetching,
    isLoading,
    isPaused,
    refetch,
    data: categoryFilterAPIResponse,
  };
};
