import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import {
  useBidQuantityQuery,
  useManageBidMutation,
  IAssetRaw,
} from '@northladder/apis';
import { IAsset } from '@northladder/services';
import { TTextLocalized } from '@northladder/i18n';
import { CircularLoader, SimpleDropdown } from '@northladder/design-system';

import { DropdownWrapper } from '@northladder/design-system/src/components/dropdowns/styles';

/**
 * Quantity dropdown items type.
 */
export interface IBidQuantityData {
  id: string;
  title: TTextLocalized;
}

/**
 * QuantityDropDown takes a defaultTitle that displays the default quantity
 * and asset as props.
 */
interface IBidQuantityDropDownProps {
  defaultTitle: string;
  asset: IAsset | IAssetRaw;
}

/**
 * Reusing SimpleDropdown using headless UI to render the dynamic values and
 * additional styling attributes
 *
 * @param IBidQuantityDropDownProps - Refer the type for the props description.
 *
 * @returns JSX.Element
 */
export default function BidQuantityDropdown({
  defaultTitle,
  asset,
}: IBidQuantityDropDownProps): JSX.Element {
  const [loading, setLoading] = useState(false);

  const { myBid, assetId } = asset || {};
  const { isBidActive, bidId } = myBid || {};

  // Query hook to get data from the api.
  const {
    data: bidsQuantityData,
    refetch: callBidsQuantity,
    error,
  } = useBidQuantityQuery({
    assetId: assetId ?? '',
    defaultValue: defaultTitle,
    onSuccessCallback: () => {
      setLoading(false);
    },
  });

  // Mutation for submitting data through API.
  const { createOrUpdateBid } = useManageBidMutation();

  const handleOnQuantityOptionSelect = (item: IBidQuantityData) => {
    createOrUpdateBid({
      assetId: assetId || '',
      bidId: bidId || '',
      isBidActive: true,
      maxQuantity: Number(item.title),
    });
  };

  const handleDropDownClick = () => {
    setLoading(true);
    callBidsQuantity();
  };

  if (!bidsQuantityData) {
    return (
      <DropdownWrapper className="!w-20">
        <button
          className="dropdown-button"
          onClick={handleDropDownClick}
          type="button"
        >
          <span className="ltr:ml-1 rtl:mr-1">{defaultTitle}</span>
          {loading ? (
            <CircularLoader className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 text-gray-500" />
          )}
        </button>
      </DropdownWrapper>
    );
  }

  return (
    <SimpleDropdown
      className="quantity-dropdown-menu"
      defaultTitle={defaultTitle}
      error={error}
      isEnabled={isBidActive}
      items={bidsQuantityData || [{ id: 'all', title: defaultTitle }]}
      loadingState={loading}
      menuItemsClassName="quantity-menu-item-list"
      onDropDownItemSelect={handleOnQuantityOptionSelect}
    />
  );
}
