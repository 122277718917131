import { ChevronRightIcon, FireIcon } from '@heroicons/react/20/solid';

import { IconBidBookBadgeAlt } from '@northladder/design-system';

import { TBidRankValue } from '../../types';
import { BidFilterItemWrapper } from '../../styles';

export interface IBidFilterItem {
  id: string | number;
  title: string;
  type: string;
  count: string | number;
}

interface IBidFilterItemProps {
  path: string;
  item: TBidRankValue;
  count: number;
}
/**
 * -----------------------------------------------------------------------------
 * This a component renders a list of bids to be displayed on the dashboard.
 *
 * TODO: Extract out & merge the `BidFilterItem` with @Renji's more tested component.
 * TODO: Move this to the design-system.
 */
export function BidFilterItem({ path, item, count }: IBidFilterItemProps) {
  const { title, id } = item;

  return (
    <BidFilterItemWrapper className={`${id} group`} href={path}>
      <ChevronRightIcon
        className="
          link-arrow group-hover:right-0 group-hover:opacity-100
          rtl:group-hover:left-0
        "
      />
      <div className="bid-icon-title-container">
        <div className="relative group-hover:translate-y-1 group-hover:scale-105">
          <IconBidBookBadgeAlt
            className="
              bid-badge-star group-hover:-translate-y-1 group-hover:scale-110
            "
          />

          {id === 'type-hot-deal' ? (
            <FireIcon
              className="
                icon-deal-fire
                group-hover:-translate-y-1
                group-hover:scale-105
                group-hover:text-orange-300
              "
            />
          ) : (
            <span
              className="
                bid-badge-count
                group-hover:-translate-y-0.5
                group-hover:scale-105
              "
            >
              {count}
            </span>
          )}
        </div>
        <p className="bid-filter-title group-hover:translate-y-1">{title}</p>
      </div>
    </BidFilterItemWrapper>
  );
}
