import { motion, Variants } from 'framer-motion';

import { ProfileInfo, useSessionInstance } from '../../../../../user-accounts';

import { TMenuItem } from '../../types';

import { MenuItem } from '../MenuItem';

/**
 * variants - variants are from `framer-motion` lib to add the custom animation
 * on the ul element.
 */
const variants: Variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const logOut = () => {
  // eslint-disable-next-line no-console
  console.info('logout');
};

/**
 * `INavigationProps` represents the type definition for Navigation component
 *  which extents the optional props for the direction.
 */
interface INavigationProps {
  /**
   * Array of objects which contains the details to render the nav link.
   * Related to `IMenuItem`.
   */
  items: TMenuItem[];
  /**
   * Refers to state of the toggle button i.e opened or closed
   */
  isOpen: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * Navigation component accepts list of items to render in the form list with
 * navigation support and icons.
 *
 * @param INavigationProps - navigation item objects to render the links
 * with dashboard, isOpen is the state of the AppNavMenu feature to indicate
 * the menu is in opened state or closed state.
 *
 * @returns list of nav menu items.
 */
function Navigation({ items, isOpen }: INavigationProps): JSX.Element {
  const { activeSession } = useSessionInstance();
  const accountId = activeSession?.index || 0;

  const { displayPicture, email, firstName, lastName } =
    activeSession?.user || {};

  return (
    <div className="flex h-[calc(100vh-16rem)] flex-col justify-between">
      <motion.ul variants={variants}>
        {items.map((item) => (
          <MenuItem
            key={item.path}
            accountId={accountId}
            icon={item.icon}
            isExpandable={item.isExpandable}
            name={item.name}
            path={item.path}
            tabIndex={isOpen ? 0 : -1}
          />
        ))}
      </motion.ul>

      {isOpen ? (
        <ProfileInfo
          accountId={accountId}
          email={email || ''}
          firstName={firstName || ' '}
          imageUrl={displayPicture || ''}
          lastName={lastName || ' '}
          onLogOut={logOut}
        />
      ) : null}
    </div>
  );
}

export default Navigation;
