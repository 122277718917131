import { useQueryWithUserId } from '@northladder/services';

import { IApiError, IFetchAppraisalDetailsPayload } from '../../../../types';
import { CUSTOMER_SERVICE_API } from '../customerServiceClient';

/**
 * This is to track the appraisal info by code query key in react query cache.
 */
const GET_APPRAISAL_DETAILS_BY_ID = 'get-appraisal-details-by-id-key';

/**
 * `useFetchAppraisalDetailsQuery` handles the API call to to get the details
 * of the appraisal by appraisal id.
 *
 * @returns { data, mapItemDetails, error, isError, isLoading, refetch }
 */
export const useFetchAppraisalDetailsQuery = ({
  appraisalId,
}: IFetchAppraisalDetailsPayload) => {
  const { data, error, isError, isLoading, refetch } = useQueryWithUserId({
    queryKey: [GET_APPRAISAL_DETAILS_BY_ID],
    enabled: Boolean(appraisalId),
    queryFn: () => CUSTOMER_SERVICE_API.fetchAppraisalDetails({ appraisalId }),
    onError: (err: IApiError) => err,
  });

  const inspectionResults = data?.inspectionResults.map(
    (inspectionData: any) => ({
      label: inspectionData?.inspectionName,
      value: inspectionData?.condition,
    })
  );

  const appraisalDetails = {
    deviceDetails: [
      {
        label: 'Brand Name',
        value: data?.brandName,
      },
      {
        label: 'Name',
        value: data?.assetInfo?.name,
      },
      {
        label: 'Storage',
        value:
          data?.assetInfo?.attributes?.find(
            (attr: any) => attr.key === 'Storage'
          )?.value || '-',
      },
      {
        label: 'Grade',
        value:
          data?.appraisalData?.approvedDeal?.assetCondition?.memberCondition
            ?.condition || '-',
      },
      {
        label: 'Serial / IMEI Number',
        value: data?.appraisalData?.serialNumber || 'N/A',
      },
    ],
    transactionDetails: [
      {
        label: 'My Bid',
        value: data?.appraisalData?.approvedDeal?.assetPrice || '-',
      },
      {
        label: 'Grade',
        value:
          `${
            data?.appraisalData?.approvedDeal?.assetCondition?.memberCondition
              ?.condition
          } ${data?.appraisalData?.approvedDeal?.assetCondition?.memberCondition?.dealerPercentValue.toFixed(
            2
          )}` || '-',
      },
    ],
    customerInfo: [
      {
        label: 'Customer Name',
        value: data?.appraisalData?.customerName || '-',
      },
      {
        label: 'Phone Number',
        value: data?.appraisalData?.customerPhoneNo || '-',
      },
      {
        label: 'Email',
        value: data?.appraisalData?.customerEmail || '-',
      },
    ],
    evaluationResults: data?.inspectionResults ? [...inspectionResults] : [],
  };

  return {
    appraisalDetails,
    appraisalCreatedAt: data?.appraisalData?.dealTS,
    error,
    isError,
    isLoading,
    refetch,
  };
};
