import { useMutation } from '@northladder/services';

import { IApiError } from '../../../../types';
import { BIDS_API } from '../bidsAPIClient';

/**
 * This is to track the update bid status mutation keys in react query cache.
 */
const MUT_UPLOAD_BID_BOOK = 'upload-bid-book-mutation-key';

/**
 * This performs a post request for uploading the bid book.
 */
export const useUploadBidBookMutation = () => {
  const {
    isLoading,
    isError,
    error,
    mutate: callUploadBidBook,
  } = useMutation({
    mutationKey: [MUT_UPLOAD_BID_BOOK],
    networkMode: 'always', // Even make calls when offline
    mutationFn: BIDS_API.uploadBidBook,
    // onSuccess: (response) => {},
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    callUploadBidBook,
  };
};
