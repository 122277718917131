import Link from 'next/link';
import { motion } from 'framer-motion';
import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * ToggleButtonWrapper refers to the styles added for the toggle menu button
 * to handle the open and close of the hamburger menu.
 */
export const ToggleButtonWrapper = styled.button`
  ${tw`
    absolute
    flex
    items-center
    rounded-full
    cursor-pointer
    pointer-events-auto
    bg-gray-700
    border-gray-200
    h-10
    w-10
    right-auto
    p-1.5
    transition-all
    ease-in-out
    delay-100
    duration-500
    relative
    border-2
    shadow-[2px_2px_1rem_2px_rgba(0,0,0,0.25)]

    hover:(ring-purple-200 bg-gray-800)
    focus:(ring-purple-200 ring-2 outline-none)
    b2xl:-left-20
    rtl:(left-auto)
    rtl:b2xl:-right-20
  `};

  svg {
    stroke: white;

    ${tw`
      ml-1.5
      mt-1.5
    `};
  }

  &.active {
    ${tw`bg-white`};

    svg {
      stroke: black;
    }
  }
`;

/**
 * -----------------------------------------------------------------------------
 * MenuHeaderWrapper adds styling to header of the page with direction support.
 */
export const MenuHeaderWrapper = styled.div`
  ${tw`
    flex-col
    flex
    h-full
    justify-start
    relative
    py-3
    transition-all
    ease-in-out
    delay-100
    duration-500

    sm:py-4
    md:py-6
  `};

  .logo {
    ${tw`h-6`};

    path {
      ${tw`!fill-white`}

      &#A-Highlight {
        ${tw`!fill-purple-300`}
      }
    }
  }

  .paas-logo {
    ${tw`h-12`};

    path {
      ${tw`!fill-white`}

      &#A-Highlight {
        ${tw`!fill-purple-300`}
      }
    }
  }

  .logo-container {
    margin-top: -40px;
  }
`;

/**
 * -----------------------------------------------------------------------------
 * IconLinkWrapper adds styling to the links with icon.
 */
export const IconLinkWrapper = styled(Link)`
  ${tw`
    cursor-pointer
    flex
    justify-start
    items-start
    w-full
    my-6
    p-2
    -mx-1
    no-underline
    text-lg
    font-medium
    text-gray-50
    transition-all
    ease-in-out
    delay-100
    duration-500
    rounded-lg

    hover:(bg-purple-200/20 ring-purple-200)
    focus:(outline-none ring-1 bg-purple-200/20)
  `};

  span {
    ${tw`
      text-sm
      font-normal
      flex-1
      px-4
      font-secondary-en

      rtl:font-secondary-ar
      2xl:hover:font-medium
    `};
  }

  svg {
    ${tw`shrink-0`};
  }
`;

/**
 * -----------------------------------------------------------------------------
 * MenuWrapper adds styling to the nav of the list items.
 *
 * @returns JSX.Element
 */
const MenuWrapper = styled(motion.nav)`
  ${tw`w-full`};

  .background {
    clip-path: circle(0 at 6rem 2.4rem);

    ${tw`
      fixed
      border-none
      h-screen
      min-h-[740px]
      bottom-0
      left-0
      right-0
      -top-40
      shadow-2xl
      transition-all
      duration-200
    `};

    [dir='rtl'] & {
      clip-path: circle(0 at calc(100% - 5rem) 2.4rem);
    }
  }

  &.open-menu {
    position: fixed;
    .background {
      clip-path: circle(200rem at 150rem 12rem);
      background-color: rgba(0, 0, 0, 0.9);
      backdrop-filter: blur(5px);

      ${tw`
        transition-all
        duration-150
        ease-in-out
      `};
    }
  }

  ul {
    ${tw`
      mt-16
      flex-1
      px-16
      lg:px-0
      max-w-sm
    `};
  }
`;

export default MenuWrapper;
