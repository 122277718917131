import tw from 'twin.macro';

import { SummaryIcon, TSummaryIcon } from '@northladder/design-system';

/**
 * Tailwind Component For showing the padding and margin of child component
 */
const ContainerWrapper = tw.div`
  flex
  p-2
  // w-1/2;
  bg-white
  // ml-10
  // mb-10
  rounded-md
`;

export interface IStatsSummaryCardProps {
  /**
   * Name of the icon defined in the summaryCardIcons object.
   * Related to `summaryCardIcons`. it will only accept "bank" or "bidIcon"
   */
  icon: TSummaryIcon;
  /**
   * For showing title of StatusSummaryCard
   */
  title: string;
  /**
   * For showing value string in the bottom of title
   */
  value: string;
  /**
   * Optional prop for showing valuePrefix string (eg: currency name (AED))
   */
  valuePrefix?: string;
  /**
   * Optional prop for showing valuePostfix string (eg: date,time)
   */
  valuePostfix?: string;
  /**
   * Optional prop for change the color of value property or it will show
   * default color (red)
   */
  valueColor?: string;
}

/**
 * ----------------------------------------------------------------------------
 * This component display Statistics summary details eg: amount,
 * no of bids etc...
 *
 * @param IStatusSummary - Expects `icon`,`title` `value`, `valuePrefix`
 * `valuePostfix`, `valueColor`
 * @return JSX.Element
 */
export default function StatsSummaryCard({
  icon,
  title,
  value,
  valuePrefix,
  valuePostfix,
  valueColor,
}: IStatsSummaryCardProps) {
  return (
    <ContainerWrapper className="drop-shadow-sm rtl:mr-auto">
      <SummaryIcon className="mr-3 rtl:ml-3" icon={icon} />
      <div className="flex flex-col">
        <span className="text-lg font-bold text-gray-600">{title}</span>
        <div className="flex flex-row">
          <p
            className="text-3xl font-medium"
            // If `valueColor` is exist then will use `valueColor` or `defaultColor`
            style={{ color: valueColor || '#F68563' }}
          >
            {value}
          </p>
          {valuePrefix ? (
            <p className="mb-1 ml-1 mr-1 self-end text-sm font-bold">
              {valuePrefix}
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex grow" />
      {valuePostfix ? (
        <span className="mb-1 self-end text-xs font-medium">
          {valuePostfix}
        </span>
      ) : null}
    </ContainerWrapper>
  );
}
