import {
  IGradeReviewDetailsResponseData,
  IGradeReviewDetailsResponseRawData,
} from '../../types';

import { formatStatus, getGradeStatusFromString } from './utils';

/**
 * Formats the raw grade review details data taking into account things require
 * to display in the UI.
 */
export function formatGradeReviewRequestDetailsResponse(
  response: IGradeReviewDetailsResponseRawData
): IGradeReviewDetailsResponseData {
  const {
    _id,
    appraisalCode,
    AWBNumber,
    awbTrackingURL,
    assetName,
    bidAmount: bidPrice,
    brandName,
    courierBooked: isCourierBooked,
    courierStatusLocale: courierStatus,
    createdAt,
    createdBy,
    creditAmount,
    comment,
    dealerActionBefore,
    dealPrice: purchasePrice,
    dealerEvaluation: proposedGradeReasonList,
    deliveredDate,
    dealPaymentType,
    dealerBranchName: branchName,
    financeClosingDate,
    financeCheck,
    financeStatus,
    imageUrls: imageList,
    imeiNumber,
    initialSaleGrade,
    initialSaleGradePercentage,
    isCreatedByNL,
    latestRemark,
    revisedGradePercentage,
    requestedSaleGrade,
    requestedSaleGradeId,
    requestedSaleGradePercentage,
    revisedGrade,
    status: gradeStatus,
    ticketNumber: ticketId,
    updatedAt,
    validationMessages,
    videoUrls: videoList,
    canCancelRequest,
  } = response;

  const gradeReviewStatusEnum = getGradeStatusFromString(gradeStatus);
  const formattedGradeStatus = formatStatus(gradeStatus);
  const displayStatus = [];

  if (financeCheck) {
    displayStatus.push('COD');
  }

  return {
    _id,
    appraisalCode,
    AWBNumber,
    awbTrackingURL,
    assetName,
    bidPrice,
    brandName,
    branchName,
    comment,
    courierStatus,
    createdAt,
    createdBy,
    creditAmount,
    dealPaymentType,
    displayStatus,
    deliveredDate,
    dealerActionBefore,
    isCourierBooked,
    financeClosingDate,
    financeStatus,
    gradeStatus: formattedGradeStatus,
    gradeStatusEnum: gradeReviewStatusEnum,
    gradeReviewRequestId: _id,
    imageList,
    imeiNumber,
    initialSaleGrade,
    initialSaleGradePercentage,
    isCreatedByNL,
    latestRemark,
    purchasePrice,
    proposedGradeReasonList,
    revisedGradePercentage,
    requestedSaleGrade,
    requestedSaleGradeId,
    requestedSaleGradePercentage,
    revisedGrade,
    ticketId,
    validationMessages,
    videoList,
    updatedAt,
    canCancelRequest,
  };
}
