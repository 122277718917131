import { useQueryWithUserId } from '@northladder/services';

import { IApiError, TBidModelsFiltersUrlOptions } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';
import { IBidModelsFiltersData } from '../types';

/**
 * This is to track the models filter query key in react query cache.
 */
export const MODELS_FILTER_KEY = 'models-filter-key';

/**
 * This are available props for the the `useBidsModelsQuery` hook.
 */
interface IUseBidsModelsQuery extends TBidModelsFiltersUrlOptions {
  /**
   * Success function which is called on bid models query success.
   * @param modelsData
   * @returns
   */
  onSuccessCallback: (modelsData: IBidModelsFiltersData) => void;
}

/**
 * This performs a filter request and retrieves the models filter options and
 * rank report.
 * Provides data fetching states to be used in the models dropdowns and
 * rank report component.
 */
export const useBidsModelsQuery = ({
  categoryId,
  brandId,
  onSuccessCallback,
}: IUseBidsModelsQuery) => {
  const {
    isLoading,
    isError,
    error,
    isPaused,
    isFetching,
    data: modelsFilterAPIResponse,
    refetch,
  } = useQueryWithUserId({
    queryKey: [MODELS_FILTER_KEY],
    networkMode: 'always', // Even make calls when offline
    retry: true, // For failed Request, do retry three times.
    enabled: false, // It's a dependant query so don't run immediately.
    queryFn: () => BIDS_API.fetchAllBidModelsFilters({ categoryId, brandId }),
    onSuccess: (response) => onSuccessCallback(response.data.data),
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    isPaused,
    isFetching,
    data: modelsFilterAPIResponse?.data?.data,
    refetch,
  };
};
