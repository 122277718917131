import tw from 'twin.macro';

/**
 * Its a Normal button it will accept tailwind design classes and render button
 *  with normal button functionalities
 */
export const OptionButton = tw.button`
  rounded-lg py-2 px-6
  text-sm font-medium
`;
