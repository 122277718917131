import tw, { styled } from 'twin.macro';

export const FieldsSectionContainer = styled.div`
  ${tw`
    flex flex-col md:flex-row flex-auto flex-wrap items-start
  `};

  .field-wrapper {
    ${tw`
      flex flex-auto flex-wrap items-start w-full md:w-2/4
    `};
  }

  .field-section {
    ${tw`
      w-[95%] rounded-sm border border-gray-200 pb-8 shadow-sm
    `};
  }
`;

export const AppraisalDetailsHeaderWrapper = styled.div`
  ${tw`
    flex justify-between px-8 py-4 bg-purple-100/50 rounded-t-xl
  `};

  .detail-container {
    ${tw`
      flex items-center gap-2
    `};

    .detail-label {
      ${tw`
        text-base font-medium
      `};
    }

    .detail-value {
      ${tw`
        text-lg font-medium
      `};
    }
  }
`;
