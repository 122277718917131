import React, { useEffect } from 'react';

import {
  SimpleDropdown,
  ActionBarButton,
  DateRangePicker,
} from '@northladder/design-system';

import { useSearchStore } from '@northladder/services';
import {
  useAppraisalsQuery,
  useDownloadAppraisalsQuery,
  IApiResponse,
} from '@northladder/apis';
import { createDomElementWithFileInBody } from '@northladder/utilities';

import { SectionWrapper, DashboardMainWrapper } from '../../../../styles';

import { AppSearch } from '../../../app-search';

import { useAppraisalsFilters } from './useAppraisalsFilters';
import { AppraisalList } from './AppraisalList';

/**
 * -----------------------------------------------------------------------------
 * This component houses all the main content of the bids screen, such as the
 * filters, list view, pagination, selected bid, etc.
 *
 * @returns JSX.Element
 */
export function AppraisalMainContent() {
  const {
    brandOptions,
    categoryError,
    categoryOptions,
    isLoadingCategoryData,
    onBrandOptionSelect,
    onCategoryOptionSelect,
    onDatesChange,
    selectedEndDate,
    selectedCategory,
    selectedStartDate,
    selectedBrand,
  } = useAppraisalsFilters();

  const { searchKey } = useSearchStore();
  const {
    data,
    isLoading,
    isFetchingNextPage,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useAppraisalsQuery({
    payload: {
      page: 1, // initial page count
      count: 10,
      listType: 'MEM',
      dealProcessType: 'DIRECT',
      categoryId: selectedCategory?.id,
      brandId: selectedBrand?.id,
      fromDate: selectedStartDate
        ? Number(selectedStartDate.toDate().getTime())
        : 0,
      toDate: selectedEndDate ? Number(selectedEndDate.toDate().getTime()) : 0,
      search: searchKey,
    },
  });

  const handleAppraisalDownloadCallback = (
    appraisalData: IApiResponse<BlobPart> | undefined
  ) => {
    if (appraisalData?.data) {
      createDomElementWithFileInBody({
        fileData: appraisalData.data,
        fileNamePrefix: 'Deals_Won_',
      });
    }
  };

  const { callDownloadAppraisalsAPI } = useDownloadAppraisalsQuery({
    payload: {
      page: 1, // initial page count
      count: 10,
      listType: 'MEM',
      dealProcessType: 'DIRECT',
      categoryId: selectedCategory?.id,
      brandId: selectedBrand?.id,
      fromDate: selectedStartDate
        ? Number(selectedStartDate.toDate().getTime())
        : 0,
      toDate: selectedEndDate ? Number(selectedEndDate.toDate().getTime()) : 0,
      search: searchKey,
    },
    onSuccessCallBack: handleAppraisalDownloadCallback,
  });

  useEffect(() => {
    if (
      selectedCategory ||
      selectedBrand ||
      selectedStartDate ||
      selectedEndDate
    ) {
      refetch();
    }
  }, [
    refetch,
    selectedCategory,
    selectedBrand,
    searchKey,
    selectedStartDate,
    selectedEndDate,
  ]);

  const handleOnDownloadExcelClick = () => {
    callDownloadAppraisalsAPI();
  };

  return (
    <DashboardMainWrapper
      className="
        flex-col items-stretch !justify-start rounded-xl bg-white
        "
    >
      <SectionWrapper
        className="
          flex flex-row flex-wrap items-center justify-start gap-4 p-4
          "
      >
        <DateRangePicker
          onDatesChange={onDatesChange}
          selectedEndDate={selectedEndDate}
          selectedStartDate={selectedStartDate}
        />
        <div className="w-full sm:w-52">
          <SimpleDropdown
            className="sm:pl-2"
            defaultTitle="All categories"
            error={categoryError}
            items={categoryOptions}
            loadingState={isLoadingCategoryData}
            menuItemsClassName="menu-item-list"
            onDropDownItemSelect={onCategoryOptionSelect}
          />
        </div>
        <div className="w-full sm:w-52">
          <SimpleDropdown
            className="z-10"
            defaultTitle="All brands"
            error={categoryError}
            isEnabled={selectedCategory?.id !== 'all'}
            items={brandOptions}
            loadingState={isLoadingCategoryData}
            menuItemsClassName="menu-item-list"
            onDropDownItemSelect={onBrandOptionSelect}
          />
        </div>
        <div className="w-full sm:w-72">
          <AppSearch
            label="Search by deal"
            placeholder="Search by deal"
            validationRegex={/[^0-9-_a-zA-Z\s\u0600-\u06FF]/g}
          />
        </div>
        <ActionBarButton
          className="no-border-button"
          icon="iconArrowDown"
          iconClassName="h-4 w-4"
          label="Download Excel"
          onClick={handleOnDownloadExcelClick}
        />
      </SectionWrapper>

      <SectionWrapper className="min-h-full grow">
        <AppraisalList
          data={data}
          error={error}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          refetch={refetch}
        />
      </SectionWrapper>
    </DashboardMainWrapper>
  );
}
