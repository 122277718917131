import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

import { IActiveSession, useAuthStore } from '@northladder/services';
import { useLogoutMutation, ILogoutOptions } from '@northladder/apis';
import { Text } from '@northladder/i18n';
import { CircularProgressLoader } from '@northladder/design-system';

import { useRouter } from 'next/router';

interface ISignOutOfAllAccountsBtnProps {
  label?: string;
  targetSession?: IActiveSession | null;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a sign out button that will trigger a another modal requesting the
 * user of whether they really wish to sign out of all or just a single account.
 *
 * @returns JSX.Element
 */
export function SignOutBtn({
  label,
  targetSession,
}: ISignOutOfAllAccountsBtnProps) {
  const { replace } = useRouter();
  const {
    allSessionsIndices,
    allSessionsByIndex = {},
    removeSession,
    removeAllSessions,
  } = useAuthStore();

  const handleLogoutSuccess = () => {
    if (targetSession) {
      removeSession(targetSession);
    } else {
      removeAllSessions();
    }
    replace('/login');
  };

  /**
   * TODO: @divyansh, Please handle the cases for failed API logouts
   */
  const handleLogoutError = () => {
    // TODO: @divyansh, you forgot to add this case and it's breaking web builds
  };

  const { isLoading, error, callLogout } = useLogoutMutation({
    onSuccessCallback: handleLogoutSuccess,
    onErrorCallback: handleLogoutError,
  });

  const handleSignOutButtonClick = () => {
    if (targetSession) {
      const payloadOptions = {
        refreshToken: targetSession.refreshToken,
        token: targetSession.token,
        regionId: targetSession.regionId,
      };
      callLogout([payloadOptions]);
    } else {
      const logoutPayloads: ILogoutOptions[] = [];

      allSessionsIndices.forEach((sessionIndex) => {
        const session = allSessionsByIndex?.[sessionIndex];
        const payloadOptions = {
          refreshToken: session?.refreshToken || '',
          token: session?.token || '',
          regionId: session?.regionId || 'uae',
        };
        logoutPayloads.push(payloadOptions);
      });

      callLogout(logoutPayloads);
    }
  };

  return (
    <div className="relative">
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-100" />
      </div>
      <div className="relative flex justify-center">
        <button
          className="
            group inline-flex items-center rounded-full border
            border-gray-200 bg-white py-1.5 pl-6 pr-3 text-sm leading-5
            text-gray-500 shadow-sm hover:bg-purple-50
            hover:text-purple-800 focus:outline-none focus:ring-2
            focus:ring-purple-100 focus:ring-offset-1
            rtl:pl-3 rtl:pr-6
          "
          onClick={handleSignOutButtonClick}
          type="button"
        >
          {isLoading ? (
            <CircularProgressLoader strokecolor="#904bbe" />
          ) : (
            <ArrowRightOnRectangleIcon
              aria-hidden="true"
              className="
                -ml-4 mr-2 h-5 w-5 text-gray-600
                group-hover:text-purple-800 rtl:-mr-4 rtl:ml-2
              "
            />
          )}
          <span>{label || 'Sign out of all accounts'}</span>
        </button>
      </div>
      {error ? (
        <div className="text-center text-sm text-red-500">
          <Text text={error.message} />
        </div>
      ) : null}
    </div>
  );
}
