import { TBidRankById, TBidRankId } from '../types';

export const bidRanksById: TBidRankById = {
  'type-hot-deal': {
    id: 'type-hot-deal',
    title: 'Hot Deals to Bid',
    shortTitle: 'Hot Deals',
    type: 'hotDeal',
  },
  RANK_ONE: {
    id: 'RANK_ONE',
    title: '1st',
    shortTitle: '1st',
    type: 'rankOne',
  },
  OUT_OF_QUANTITY: {
    id: 'OUT_OF_QUANTITY',
    title: 'Out of Quantity',
    shortTitle: 'Out',
    type: 'outOfQuantity',
  },
  BID_NOT_WINNING: {
    id: 'BID_NOT_WINNING',
    title: 'Bid not winning',
    shortTitle: 'Not Winning',
    type: 'bidNotWinning',
  },
  WINING_RANGE: {
    id: 'WINING_RANGE',
    title: '2nd',
    shortTitle: '2nd',
    type: 'winningRange',
  },
};

export const bidRankIds = Object.keys(bidRanksById) as TBidRankId[];
