import { isMobile, isWeb } from '@northladder/utilities';
import { IAsset } from '@northladder/services';

import { ApiClient } from '../../../api-client';
import {
  TBidModelsFiltersUrlOptions,
  IDownloadBidBookUrlOptions,
  TDownloadUploadBidFilePayload,
  IFetchAllBidsPayload,
  TFetchBidHistoryParams,
  IFetchRankReportPayload,
  TFetchUploadBidFilesPayload,
  IFetchBidDetailsParam,
} from '../../../types';

import {
  bidCategoryFiltersUrl,
  bidModelsFiltersUrl,
  bidRankUrl,
  bidSearchUrl,
  bidSearchPaasUrl,
  bidStatusUpdateUrl,
  downloadBidBookUrl,
  downloadUploadBidFileTemplateUrl,
  fetchBidItemConditionUrl,
  fetchBidHistoryUrl,
  fetchBidsQuantityUrl,
  fetchStepperValueUrl,
  fetchUploadBidFilesUrl,
  rankReportUrl,
  updateBidUrl,
  uploadBidBookUrl,
  uploadBidFileUrl,
  fetchBidDetailsUrl,
} from '../../../urls';

import {
  TBidCategoryFiltersResponse,
  TBidModelsFiltersResponse,
  IBidHistoryResponse,
  IBidItemCondition,
  IBidStatusUpdateResponseData,
  ICreateOrUpdateBidPayload,
  IFetchAllBidsRawResponse,
  IFetchAllBidsRawData,
  IFetchAllBidsResponse,
  IFetchAllBidsPaasResponse,
  IFetchBidItemConditionRawResponse,
  IFetchBidsQuantityResponse,
  IFetchBidsRankResponse,
  IFetchBidStepperValResponse,
  IFetchUploadBidFilesResponse,
  IRankReportsRaw,
  IUploadBidBookPayload,
  IUploadFileResponse,
  IFetchBidDetailsResponse,
  IFetchBidDetailsData,
} from './types';

import {
  formatBidCategory,
  formatBidDetailsQuantity,
  formatBidSearchResults,
  formatBidItemConditionResponse,
  formatRankReportResponse,
  formatBidDetailsResult,
  formatBidRankResponse,
} from './transformers';

/**
 * This is provides the API client methods for all bids page,
 * filters dropdowns and components admin services like category, brands and
 * rank report etc.
 */
export class BidsAPIClient extends ApiClient {
  private static classInstance?: BidsAPIClient;

  private constructor() {
    super({
      apiBaseURL: 'ADMIN_SERVICE_BASE_URL',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new BidsAPIClient();
    }

    return this.classInstance;
  };

  /**
   * Api call to fetch the rank filters, category and brand filters options data.
   *
   * @returns TBidCategoryFiltersResponse
   */
  public fetchAllBidCategoryFilters = async () => {
    const response = await this.get<TBidCategoryFiltersResponse>(
      bidCategoryFiltersUrl()
    );

    if (!response.success) throw response;

    return formatBidCategory(response?.data?.data);
  };

  /**
   * Api call to fetch the rank filters, category and brand filters options data.
   *
   * @returns TBidModelsFiltersResponse
   */
  public fetchAllBidModelsFilters = async (
    queryParams: TBidModelsFiltersUrlOptions
  ) => {
    const response = await this.get<TBidModelsFiltersResponse>(
      bidModelsFiltersUrl(queryParams)
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch the bids by category and brands.
   *
   * @returns IFetchBidsByCategoryResponse
   */
  public searchAllBids = async ({
    isHotDealsTabActive,
    ...queryParams
  }: IFetchAllBidsPayload) => {
    const response = await this.post<
      IFetchAllBidsRawResponse | IFetchAllBidsResponse
    >(bidSearchUrl(isHotDealsTabActive), queryParams);

    if (!response.success) throw response;

    if (isMobile) {
      return formatBidSearchResults(
        (response?.data?.data as IFetchAllBidsRawData) || {},
        queryParams.page
      );
    }

    return response.data.data;
  };

  /**
   * Api call to fetch the bids by category and brands for PAAS.
   *
   * @returns IFetchBidsByCategoryResponse
   */
  public searchAllBidsPaas = async (queryParams: IFetchAllBidsPayload) => {
    const response = await this.post<IFetchAllBidsPaasResponse>(
      bidSearchPaasUrl(),
      queryParams
    );

    if (!response.success) throw response;

    return response?.data.data;
  };

  /**
   * Api call to download the paas bids by filters.
   *
   * @returns IFetchBidsByCategoryResponse
   */
  public downloadBidBookPaas = async (queryParams: IFetchAllBidsPayload) => {
    const response = await this.post<BlobPart>(
      bidSearchPaasUrl(),
      queryParams,
      {
        responseType: 'blob',
      }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch the quantity dropdown.
   */
  public fetchBidsQuantity = async (assetId: string) => {
    const response = await this.get<IFetchBidsQuantityResponse>(
      fetchBidsQuantityUrl(assetId)
    );

    if (!response.success) throw response;

    return formatBidDetailsQuantity(response.data.data || []);
  };

  /**
   * Api call to create bid for current user.
   */
  public createBidStatusRequest = async (
    payload: ICreateOrUpdateBidPayload
  ) => {
    const { assetId, ...restPayload } = payload;
    let localPayload = { ...restPayload };

    if (!payload.bidId) {
      const { bidId, ...rest } = localPayload;
      localPayload = rest;
    }

    if (!payload.spotPrice) {
      const { spotPrice, ...rest } = localPayload;
      localPayload = rest;
    }

    if (!payload.maxQuantity) {
      const { maxQuantity, ...rest } = localPayload;
      localPayload = rest;
    }

    if (!payload.autoTopupEnabled) {
      const { autoTopupEnabled, ...rest } = localPayload;
      localPayload = rest;
    }

    const response = await this.post<IBidStatusUpdateResponseData>(
      bidStatusUpdateUrl(assetId),
      localPayload
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to upload Bid book.
   */
  public uploadBidBook = async (payload: IUploadBidBookPayload) => {
    const response = await this.post<IUploadFileResponse>(
      uploadBidBookUrl(),
      payload.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 1 * 60 * 1000, // Force a 1 minute wait on uploads.
      }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * This provides download authentication services. Mainly expects a categoryId
   * and BrandId.
   */
  public downloadBidBookRequest = async (
    queryParams: IDownloadBidBookUrlOptions
  ) => {
    const response = await this.get<BlobPart>(downloadBidBookUrl(queryParams), {
      responseType: 'blob',
    });

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch the bid History.
   */
  public fetchBidHistory = async (queryParams: TFetchBidHistoryParams) => {
    const response = await this.get<IBidHistoryResponse>(
      fetchBidHistoryUrl(queryParams)
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch rank report
   */
  public fetchRankReports = async (payload?: IFetchRankReportPayload) => {
    const response = await this.post<IRankReportsRaw>(rankReportUrl(), payload);

    if (!response.success) throw response;

    if (isWeb) return response.data;

    return formatRankReportResponse(response.data);
  };

  /**
   * Api call to fetch stepper value for particular bid using bid id.
   */
  public fetchBidStepperValue = async (assetId: string) => {
    const response = await this.get<IFetchBidStepperValResponse>(
      fetchStepperValueUrl(assetId)
    );

    if (!response.success) throw response;

    return response?.data?.data?.minIncrementVal;
  };

  /**
   * Api call to create or update bid for current user.
   */
  public updateBidRequest = async (payload: ICreateOrUpdateBidPayload) => {
    let localPayload = { ...payload };

    if (!payload._id) {
      const { _id, ...rest } = localPayload;
      localPayload = rest;
    }

    if (!payload.maxQuantity) {
      const { maxQuantity, ...rest } = localPayload;
      localPayload = rest;
    }

    let response;

    if (payload._id) {
      response = await this.put<IBidStatusUpdateResponseData>(
        updateBidUrl(),
        localPayload
      );
    } else {
      response = await this.post<IBidStatusUpdateResponseData>(
        updateBidUrl(),
        localPayload
      );
    }

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to fetch Item condition for particular bid using bid id.
   * IFetchBidItemConditionRawResponse,
   * IBidItemCondition,
   */
  public fetchBidItemCondition = async (assetId: string) => {
    const response = await this.get<
      IFetchBidItemConditionRawResponse | IBidItemCondition[]
    >(fetchBidItemConditionUrl(assetId));

    if (!response.success) throw response;

    return formatBidItemConditionResponse(
      response.data as IFetchBidItemConditionRawResponse
    );
  };

  /**
   * Api call to fetch all the bids rank.
   */
  public fetchBidsRanks = async (payload?: IFetchRankReportPayload) => {
    const response = await this.post<IFetchBidsRankResponse>(
      bidRankUrl(),
      payload
    );

    if (!response.success) throw response;

    if (isWeb) return response.data;

    return formatBidRankResponse(response?.data?.data);
  };

  /**
   * Api call to fetch bid details
   */
  public fetchBidDetails = async (payload: IFetchBidDetailsParam) => {
    const response = await this.get<IFetchBidDetailsResponse | IAsset>(
      fetchBidDetailsUrl(payload)
    );

    if (!response.success) throw response;

    return formatBidDetailsResult(
      ((response.data as IFetchBidDetailsResponse)
        ?.data as IFetchBidDetailsData) || {}
    );
  };

  /**
   * Api call to fetch the upload bids files by category.
   *
   * @returns IFetchBidsByCategoryResponse
   */
  public fetchUploadBidFiles = async (
    queryParams: TFetchUploadBidFilesPayload
  ) => {
    const response = await this.get<IFetchUploadBidFilesResponse>(
      fetchUploadBidFilesUrl(queryParams)
    );

    if (!response.success) throw response;

    return response.data?.data;
  };

  /**
   * Api call to download upload bid file template.
   */
  public downloadUploadBidFileTemplate = async (
    queryParams: TDownloadUploadBidFilePayload
  ) => {
    const response = await this.get<BlobPart>(
      downloadUploadBidFileTemplateUrl(queryParams),
      {
        responseType: 'blob',
      }
    );

    if (!response.success) throw response;

    return response;
  };

  /**
   * Api call to upload bid file by category.
   */
  public uploadBidFile = async ({
    queryParams,
    file,
  }: {
    queryParams: TDownloadUploadBidFilePayload;
    file: FormData;
  }) => {
    const response = await this.post<IUploadFileResponse>(
      uploadBidFileUrl(queryParams),
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (!response.success) throw response;

    return response;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for All User Accounts related requests.
 */
export const BIDS_API = BidsAPIClient.getClientInstance();
