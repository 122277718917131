import { IPaymentDataRaw, IPaymentSummaryData } from '../../types';

/**
 * This transforms the raw payment data API response that contains running
 * balance, payment due and in transit values to the format that
 * the UI expects.
 */
export function formatPaymentData(
  response: IPaymentDataRaw
): IPaymentSummaryData {
  const {
    due,
    intransit,
    paymentDetails,
    runningBalance: runningBal,
  } = response || {};

  const runningBalance = runningBal;
  const inTransit = intransit;
  const paymentDue = due;

  let type: IPaymentSummaryData['type'] = 'BALANCED';

  if (due < 0) {
    type = 'PAY';
  } else if (due > 0) {
    type = 'RECEIVE';
  }

  return {
    paymentDetails,
    paymentDue,
    runningBalance,
    inTransit,
    type,
  };
}
