import { useMutation, useQueryClient } from '@northladder/services';

import { IApiError } from '../../../../types';
import { BIDS_API } from '../bidsAPIClient';
import { FETCH_ALL_BIDS_KEY } from './useSearchBidsQuery';

/**
 * This is to track the create or update bid status mutation keys in react query cache.
 */
const MUT_CREATE_BID_STATUS = 'create-bid-status-mutation-key';

/**
 * React Query mutation function for managing create or update bid api
 */
export const useManageBidMutation = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    error,
    data,
    mutate: createOrUpdateBid,
  } = useMutation({
    mutationKey: [MUT_CREATE_BID_STATUS],
    networkMode: 'always',
    mutationFn: BIDS_API.createBidStatusRequest,
    onSuccess: (_) => {
      queryClient.invalidateQueries({
        queryKey: [FETCH_ALL_BIDS_KEY],
      });
    },
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    createOrUpdateBid,
    data,
  };
};
