import { FireIcon } from '@heroicons/react/20/solid';

/**
 * -----------------------------------------------------------------------------
 * Component for showing a device is from hot deal or not so user can easily
 * understand bid is from hot deal or not.
 */
export function HotDealsBadge() {
  return (
    <div
      className="
      mx-1 flex flex-row self-end rounded border
     border-purple-900 py-px pl-[4px] pr-2
     "
    >
      <FireIcon
        className="self-center"
        color="purple"
        height="15px"
        width="15px"
      />
      <p className="text-xs font-medium text-purple-900">Hot Deals</p>
    </div>
  );
}
