import { Fragment, useState, useCallback } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';

import { ActionBarButton, Dropzone } from '@northladder/design-system';
import { useUploadBidBookMutation } from '@northladder/apis';

/**
 * Component for uploading the bid book.
 * @returns JSX.Element
 */
export function UploadBidBook() {
  /**
   * To open and close the modal.
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * To keep track of the file selected by the user.
   */
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  /**
   * To check whether the file was uploaded or not. If uploaded is set to true
   * then show the success message.
   */
  const [uploaded, setUploaded] = useState<boolean>(false);

  /**
   * Mutation for uploading the bid book.
   */
  const { isLoading, error, callUploadBidBook } = useUploadBidBookMutation();

  const closeModal = () => {
    setSelectedFile(null);
    setUploaded(false);
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      setSelectedFile(file);
    });
  }, []);

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      callUploadBidBook({ file: formData });
      setSelectedFile(null);
      setUploaded(true);
    }
  };

  return (
    <>
      <div className="flex">
        <ActionBarButton
          icon="upload"
          label="Upload Bid Book"
          onClick={openModal}
        />
      </div>

      <Transition appear as={Fragment} show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between text-lg font-medium leading-6 text-gray-900"
                  >
                    Upload Bid Book
                    <XMarkIcon className="h-6 w-6" onClick={closeModal} />
                  </Dialog.Title>
                  {uploaded ? (
                    <div>
                      <CheckCircleIcon className="my-4 h-10 w-10 fill-green-600" />
                      <p>Bid Book uploaded successfully</p>
                    </div>
                  ) : (
                    <Dropzone
                      error={error}
                      file={selectedFile}
                      isLoading={isLoading}
                      onDrop={onDrop}
                      onHandleUpload={handleUpload}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
