import { AppNavBar } from '../AppNavBar';
import { AppNavMenu, TAppNavMenuItem } from '../AppNavMenu';
import { IAppNavBarOptions } from '../types';

/**
 * Type definition for `PageHeader` props with optional props from `IDirection`.
 */
export interface IPageHeaderProps extends IAppNavBarOptions {
  /**
   * This is an array of objects with the key value pairs to render the nav menu
   * list items.
   */
  appNavMenuItems: TAppNavMenuItem[];
}

/**
 * -----------------------------------------------------------------------------
 * Page Header component is used to add the nav menu in the header of any page.
 *
 * @param IPageHeaderProps
 *
 * @returns JSX.Element.
 */
export function AppPageHeader({
  pageTitle,
  appNavMenuItems = [],
  showOverflowActions,
  showProfile,
  showSearch,
}: IPageHeaderProps): JSX.Element {
  return (
    <>
      <AppNavBar
        pageTitle={pageTitle}
        showOverflowActions={showOverflowActions}
        showProfile={showProfile}
        showSearch={showSearch}
      />
      <AppNavMenu items={appNavMenuItems} />
    </>
  );
}

export default AppPageHeader;
