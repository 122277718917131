export type TAppraisalDetailsTranslationValue = {
  en: string;
  ar: string;
};

/**
 * This renders reusable translations common to the Appraisal details sections.
 * TODO: Move this to a Strapi API endpoint
 */
export const translations: Record<string, TAppraisalDetailsTranslationValue> = {
  deviceDetails: {
    en: 'Device Details',
    ar: 'Device Details',
  },
  customerInfo: {
    en: 'Customer Info',
    ar: 'Customer Info',
  },
  evaluationResultsManual: {
    en: 'Evaluation Results: Manual',
    ar: 'Evaluation Results: Manual',
  },
  transactionDetails: {
    en: 'Transaction Details',
    ar: 'Transaction Details',
  },
  account: {
    en: 'Account',
    ar: 'حساب',
  },
};
