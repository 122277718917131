import { useQueryWithUserId } from '@northladder/services';

import { IApiError, IDownloadBidBookUrlOptions } from '../../../../types';
import { BIDS_API } from '../bidsAPIClient';

/**
 * This is to track the download bid book query key in react query cache.
 */
const DOWNLOAD_BID_BOOK_KEY = 'download-bid-book-key';

interface IDownloadBidBookProps {
  queryParams: IDownloadBidBookUrlOptions;
  onSuccessCallBack: () => void;
}

export const useDownloadBidBookQuery = ({
  queryParams,
  onSuccessCallBack,
}: IDownloadBidBookProps) => {
  const {
    data,
    error,
    isError,
    refetch: downloadBidbook,
  } = useQueryWithUserId({
    queryKey: [DOWNLOAD_BID_BOOK_KEY],
    enabled: false,
    queryFn: () => BIDS_API.downloadBidBookRequest(queryParams),
    onSuccess: () => onSuccessCallBack(),
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isError,
    data,
    downloadBidbook,
  };
};
