import { ApiClient } from '../../../api-client';
import {
  updateUserRegistrationDetailsUrl,
  sendUserRegistrationOTPUrl,
  validateUserRegistrationOTPUrl,
  fetchUserRegistrationDetailsUrl,
  fetchTradeInDeviceCategoriesUrl,
} from '../../../urls';

import {
  IAllRegistrationDetailsResponse,
  IRegistrationResponse,
  TRegistrationOptions,
  TRegistrationPayload,
  ISendRegistrationOPTResponse,
  ISendRegistrationOPTPayload,
  IValidateRegistrationOPTResponse,
  IValidateRegistrationOPTPayload,
  IDeviceCategoriesResponse,
} from './types';

/**
 * This is provides the API client methods for all user authentication,
 * authorization and identity services like login, forgot password etc.
 *
 * NOTE: The `refreshToken` service has been moved out as a global to avoid
 * cyclic dependencies since its to be consumed within the base `ApiClient`.
 *
 */
export class UserRegistrationAPIClient extends ApiClient {
  private static classInstance?: UserRegistrationAPIClient;

  private constructor() {
    super({
      apiBaseURL: 'ADMIN_SERVICE_BASE_URL',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new UserRegistrationAPIClient();
    }

    return this.classInstance;
  };

  /**
   * This sends the user's phone number to which the OTP will be sent to start
   * or resume the registration process.
   */
  public sendUserRegistrationOTP = async (
    payload: ISendRegistrationOPTPayload
  ) => {
    const response = await this.post<
      ISendRegistrationOPTResponse,
      ISendRegistrationOPTPayload
    >(sendUserRegistrationOTPUrl(), payload, {
      requiresAuth: false,
    });

    if (!response.success) throw response;

    return response.data.data;
  };

  /**
   * This sends the OTP to the server to be verified if it's what was sent on mobile.
   */
  public validateUserRegistrationOTP = async (
    payload: IValidateRegistrationOPTPayload
  ) => {
    const response = await this.post<
      IValidateRegistrationOPTResponse,
      IValidateRegistrationOPTPayload
    >(validateUserRegistrationOTPUrl(), payload, {
      requiresAuth: false,
    });

    if (!response.success) throw response;

    return response.data.data;
  };

  /**
   * This fetches the user's registration details based on the provided token
   * from mobile OTP validation.
   */
  public registerUser = async (options: TRegistrationOptions) => {
    const { accessToken, stage: signupStage, ...payload } = options;

    const response = await this.put<
      IRegistrationResponse,
      TRegistrationPayload
    >(updateUserRegistrationDetailsUrl(), payload, {
      requiresAuth: false,
      accessToken,
    });

    if (!response.success) throw response;

    return response.data.data;
  };

  /**
   * This fetches the user's registration details based on the provided token
   * from mobile OTP validation.
   */
  public fetchUserRegistrationDetails = async (token: string) => {
    const response = await this.get<IAllRegistrationDetailsResponse>(
      fetchUserRegistrationDetailsUrl(),
      {
        requiresAuth: false,
        accessToken: token,
      }
    );

    if (!response.success) throw response;

    return response.data.data;
  };

  /**
   * This fetches the device categories the users can trade in.
   */
  public fetchTradeInDeviceCategories = async () => {
    const response = await this.get<IDeviceCategoriesResponse>(
      fetchTradeInDeviceCategoriesUrl(),
      {
        requiresAuth: false,
      }
    );

    if (!response.success) throw response;

    return response?.data?.data;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for All User Registration related requests.
 */
export const USER_REGISTRATION_API =
  UserRegistrationAPIClient.getClientInstance();
