import { CircularProgressLoader } from '@northladder/design-system';
import { IApiError, IFetchAppraisalsQueryData } from '@northladder/apis';
import { useNextPageIntersectionObserver } from '@northladder/utilities';

import { SectionWrapper } from '../../../../styles';

import { AppraisalListFallback } from './AppraisalListFallback';
import { AppraisalListTable } from './AppraisalListTable';

interface IAppraisalListProps {
  /**
   * appraisal list for mapping over the table from appraisalListPage.
   * this appraisalListPage is autogenerated from React query infinite
   */
  error: IApiError | null;
  isLoading: boolean;
  data: IFetchAppraisalsQueryData | undefined;
  refetch: () => void;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * @param IAppraisalListProps
 * @returns JSX.Element
 */
export function AppraisalList({
  error,
  isLoading,
  data,
  refetch,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}: IAppraisalListProps) {
  const { nextPageObserverRef } = useNextPageIntersectionObserver({
    hasNextPage,
    onFetchNextPage: fetchNextPage,
  });

  return (
    <SectionWrapper className="flex flex-col justify-between gap-2 p-4">
      <div className="w-full">
        <div
          className="
          -mx-4 overflow-x-auto shadow-sm shadow-white/50 ring-1 ring-black
          ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg
        "
        >
          {error || isLoading || !data || data?.pages[0]?.count === 0 ? (
            <AppraisalListFallback
              error={error}
              isLoading={isLoading}
              onRefreshList={refetch}
            />
          ) : (
            <>
              <AppraisalListTable items={data?.pages} />

              <div
                ref={nextPageObserverRef}
                className="flex flex-row justify-center"
              >
                {isFetchingNextPage && hasNextPage ? (
                  <CircularProgressLoader
                    className="h-5 w-5"
                    strokecolor="#000000"
                  />
                ) : (
                  'No search left'
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </SectionWrapper>
  );
}
