import { TTextLocalized } from '@northladder/utilities';

import { ITutorialSummary } from '../types';

/**
 * This highlights the blog post that is being viewed by the user.
 * NOTE: This is to be moved out to a config folder.
 */
export const gradeChangeRequestContent: ITutorialSummary = {
  id: 1,
  title: {
    en: 'How to attach better videos to Grade Review Requests',
    ar: 'كيفية إرفاق مقاطع فيديو أفضل بطلبات مراجعة التقدير',
  },
  href: '/tutorials/better-videos-for-grade-review-requests',
  description: {
    en: "Capturing a meaningful, detailed and clear video recording of the device is crucial for better grade review requests. In these video tutorials, we'll guide you through different ways for you to do the same.",
    ar: 'يعد التقاط تسجيل فيديو مفيد ومفصل وواضح للجهاز أمرًا ضروريًا لتحسين طلبات مراجعة الدرجات. في مقاطع الفيديو التعليمية هذه، سنرشدك عبر طرق مختلفة للقيام بنفس الشيء.',
  },
  imageUrl:
    'https://images.unsplash.com/photo-1698266852138-c591a88ab8fa?q=80&w=600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  date: 'May 2, 2024',
  datetime: '2024-05-02',
  category: {
    title: {
      en: 'Grade Review Request',
      ar: 'طلب مراجعة الدرجة',
    },

    href: '#',
  },
  author: {
    name: {
      en: 'NorthLadder Support',
      ar: 'NorthLadder دعم.',
    },
    role: {
      en: 'Dealer Team',
      ar: 'فريق التاجر',
    },
    href: '#',
    imageUrl: '/images/fallback.png',
  },
};

interface IGradeReviewVideoPost {
  id: number;
  name: TTextLocalized;
  videoTag: TTextLocalized;
  description: TTextLocalized;
  videoSrc: string;
}

/**
 * This is a sample list of all the videos that are to be displayed on the page.
 */
export const videosPosts: IGradeReviewVideoPost[] = [
  {
    id: 1,
    name: {
      en: 'Dealer explains the fold issue in the device and clearly demonstrates it in the video. He also mentions the current grade and desired grade after the review.',
      ar: 'يشرح الوكيل مشكلة الطي في الجهاز ويوضحها بوضوح في الفيديو. ويذكر أيضًا الدرجة الحالية والدرجة المطلوبة بعد المراجعة.',
    },
    videoTag: {
      en: '01',
      ar: '١',
    },
    description: {
      en: 'English',
      ar: 'إنجليزي',
    },
    videoSrc: 'tutorials/grade-review-request/video-01.mp4',
  },
  {
    id: 2,
    name: {
      en: 'Phone with major dents and scratches on the sides. The dealer is clear about the condition of the device and their Grade Request.',
      ar: 'الهاتف به خدوش وخدوش كبيرة على الجوانب. لدى الوكيل معلومات واضحة عن حالة الجهاز وطلب الدرجة الخاصة به.',
    },
    videoTag: {
      en: '02',
      ar: '٢',
    },
    description: {
      en: 'English',
      ar: 'إنجليزي',
    },
    videoSrc: 'tutorials/grade-review-request/video-02.mp4',
  },
  {
    id: 3,
    name: {
      en: 'The phone has a broken back side. The dealer almost mentions current grade and their desired grade after the review.',
      ar: 'الهاتف لديه جانب خلفي مكسور. يذكر الوكيل تقريبًا الدرجة الحالية والدرجة المطلوبة بعد المراجعة.',
    },
    videoTag: {
      en: '03',
      ar: '٣',
    },
    description: {
      en: 'English',
      ar: 'إنجليزي',
    },
    videoSrc: 'tutorials/grade-review-request/video-03.mp4',
  },
  {
    id: 4,
    name: {
      en: 'The dealer captures the close up of the device such that the scratches in the corners are clearly visible.',
      ar: 'يقوم الموزع بالتقاط صورة مقربة للجهاز بحيث تكون الخدوش الموجودة في الزوايا مرئية بوضوح.',
    },
    videoTag: {
      en: '04',
      ar: '٤',
    },
    description: {
      en: 'Hindi',
      ar: 'الهندية',
    },
    videoSrc: 'tutorials/grade-review-request/video-04.mp4',
  },
  {
    id: 5,
    name: {
      en: 'In this video, the dealer first captures the screen with the very helpful device information and goes ahead to show a good closeup of the scratches and wear on the device.',
      ar: 'في هذا الفيديو، يقوم الوكيل أولاً بالتقاط الشاشة بمعلومات الجهاز المفيدة للغاية ثم يمضي قدمًا لعرض لقطة مقربة جيدة للخدوش والتآكل الموجود على الجهاز.',
    },
    videoTag: {
      en: '05',
      ar: '٥',
    },
    description: {
      en: 'Hindi',
      ar: 'الهندية',
    },
    videoSrc: 'tutorials/grade-review-request/video-05.mp4',
  },
];
