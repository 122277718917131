import { useQueryWithUserId } from '@northladder/services';

import {
  IApiError,
  IApiResponse,
  TDownloadUploadBidFilePayload,
} from '../../../../types';
import { BIDS_API } from '../bidsAPIClient';

/**
 * This is to track the download upload bid template query key in react query cache.
 */
const DOWNLOAD_UPLOAD_BID_TEMPLATE = 'download-upload-bid-template-key';

interface IUseDownloadUploadBidTemplateQueryProps {
  queryParams: TDownloadUploadBidFilePayload;
  onSuccessCallBack: (data: IApiResponse<BlobPart> | undefined) => void;
}

export const useDownloadUploadBidTemplateQuery = ({
  queryParams,
  onSuccessCallBack,
}: IUseDownloadUploadBidTemplateQueryProps) => {
  const {
    data,
    error,
    isError,
    refetch,
    isSuccess,
    isInitialLoading,
    isRefetching,
    remove,
  } = useQueryWithUserId({
    queryKey: [DOWNLOAD_UPLOAD_BID_TEMPLATE],
    enabled: false,
    queryFn: () => BIDS_API.downloadUploadBidFileTemplate(queryParams),
    onSuccess: (templateData) => onSuccessCallBack(templateData),
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isError,
    data,
    /**
     * NOTE: For disabled query isLoading is set to true by default which
     * causes discrepancies. So this solution works as per the official docs.
     */
    isLoading: isInitialLoading || isRefetching,
    refetch,
    isSuccess,
    remove,
  };
};
