import { useMutation } from '@northladder/services';
import { ErrorEventsEnum, errorLogToRemoteUtil } from '@northladder/utilities';

import { IApiError } from '../../../../types';
import { USER_ACCOUNTS_API } from '../userAccountsAPIClient';

/**
 * This is to track the login mutation keys in react query cache.
 */
const MUT_LOGOUT_USER = 'logout-mutation-key';

/**
 * The useLogoutMutation Props
 */
export interface IUseLogoutOptions {
  /**
   * Callback on a successful logout.
   */
  onSuccessCallback: () => void;
  /**
   * Callback on a failed logout.
   */
  onErrorCallback: (err: IApiError) => void;
}

/**
 * This performs a logout request.
 *
 * @param IUseLogoutOptions
 *
 * TODO: Refactor this logic to handle logout on a per-account basis.
 * Currently, multiple users can log out simultaneously, leading to potential
 * issues. If a logout request fails for one user, it prevents the entire
 * `onSuccessCallback` from being executed. As a result, successful logouts for
 * other users don't clear their sessions. Subsequent logout calls for those
 * users also fail due to the unresolved issue. Consider implementing a
 * sequential logout process, robust error handling, and local session clearing
 * to address these concerns.
 */
export const useLogoutMutation = ({
  onSuccessCallback,
  onErrorCallback,
}: IUseLogoutOptions) => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: [MUT_LOGOUT_USER],
    networkMode: 'always',
    mutationFn: USER_ACCOUNTS_API.logoutUser,
    onSuccess: onSuccessCallback,
    onError: (err: IApiError) => {
      errorLogToRemoteUtil({
        error: err,
        errorCode: ErrorEventsEnum.ERROR_IN_API_CALL,
        errorTitle: 'Logout Error',
        message: err?.message,
      });
      onErrorCallback(err);

      return err;
    },
  });

  return {
    error,
    isLoading,
    callLogout: mutate,
  };
};
