import { Text } from '@northladder/i18n';
import { getImageAssetsCDNBaseUrl } from '@northladder/utilities';
import { useRegionStore } from '@northladder/services';

import { videosPosts } from './content';

/**
 * -----------------------------------------------------------------------------
 * This renders a list of the videos that are to be displayed on the tutorial with
 * each having helpful meta information.
 */
export function GradeChangeVideosList() {
  const regionId = useRegionStore().region?.id || 'uae';
  const basePath = getImageAssetsCDNBaseUrl(regionId);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only" id="products-heading">
          Grade Change Request Video Tutorials
        </h2>

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          {videosPosts.map((video) => (
            <div key={video.id} className="group">
              <div className="aspect-h-1 aspect-w-1 sm:aspect-h-3 sm:aspect-w-2 w-full overflow-hidden rounded-2xl">
                <slot name="media">
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                    controls
                    muted={false}
                    preload="metadata"
                    src={`${basePath}${video.videoSrc}`}
                  />
                </slot>
              </div>
              <div className="mt-4 flex items-center justify-between text-sm font-normal text-gray-900">
                <h3>
                  <Text text={video.name} />
                </h3>
                <p className="px-4 text-2xl font-bold">
                  <Text text={video.videoTag} />
                </p>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                <Text text={video.description} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
