import dynamic from 'next/dynamic';

/**
 * This dynamically imports `RegistrationForm2Content`content at the lazily
 * when required. This helps prevent nextjs hydration errors.
 */
const DynamicRegistrationForm2Content = dynamic(
  () => import('./RegistrationForm2Content'),
  {
    ssr: false,
  }
);

export default DynamicRegistrationForm2Content;
