import { useRouter } from 'next/router';
import { Text } from '@northladder/i18n';

import { format } from '@northladder/utilities';

import { IApiError, useFetchAppraisalDetailsQuery } from '@northladder/apis';
import {
  Button,
  CircularLoader,
  FieldsSection,
  IItem,
} from '@northladder/design-system';

import { translations } from './translations';
import {
  AppraisalDetailsHeaderWrapper,
  FieldsSectionContainer,
} from './styles';

/**
 * Interface for props of AppraisalDetailsLoading.
 */
interface IAppraisalDetailsLoadingProps {
  /**
   * Loading boolean to show the loading status.
   */
  isLoading: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the loading state for appraisal details component.
 *
 * @returns JSX.Element | null
 */
function AppraisalDetailsLoading({ isLoading }: IAppraisalDetailsLoadingProps) {
  if (isLoading) {
    return (
      <div className="flex h-[70vh] w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-8">
          <CircularLoader className="h-6 w-6" />
          <p className="text-xl text-purple-600">Please wait...</p>
        </div>
      </div>
    );
  }
  return null;
}

/**
 * Interface for props of AppraisalDetailsError.
 */
interface IAppraisalDetailsErrorProps {
  /**
   * Error from the API.
   */
  error: IApiError | null;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the error state for appraisal details component.
 *
 * @returns JSX.Element | null
 */
function AppraisalDetailsError({ error }: IAppraisalDetailsErrorProps) {
  const { reload } = useRouter();
  const handleRetry = () => reload();

  if (error) {
    return (
      <div className="flex h-[70vh] w-full items-center justify-center">
        <div className="flex items-center justify-center gap-3">
          <Text className="text-red-800" text={error.message} />
          <Button
            className="w-36 bg-purple-600"
            isEnabled
            isLoading={false}
            label="Please Retry"
            loadingLabel="Retrying"
            onClick={handleRetry}
            type="button"
          />
        </div>
      </div>
    );
  }
  return null;
}

/**
 * Interface for props of AppraisalDetailsEmpty.
 */
interface IAppraisalDetailsEmptyProps {
  /**
   * data from the API.
   */
  data: Record<string, IItem[]> | null;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the empty data state for appraisal details component.
 *
 * @returns JSX.Element | null
 */
function AppraisalDetailsEmpty({ data }: IAppraisalDetailsEmptyProps) {
  if (!data) {
    return <div>No data available</div>;
  }
  return null;
}

/**
 * Interface for the AppraisalDetailsFallback component which extents
 * IAppraisalDetailsLoadingProps, IAppraisalDetailsErrorProps,
 * IAppraisalDetailsEmptyProps
 */
interface IAppraisalDetailsFallbackProps
  extends IAppraisalDetailsLoadingProps,
    IAppraisalDetailsErrorProps,
    IAppraisalDetailsEmptyProps {}

/**
 * -----------------------------------------------------------------------------
 * This renders the fallback component for appraisal details component.
 *
 * @returns JSX.Element | null
 */
function AppraisalDetailsFallback({
  isLoading,
  error,
  data,
}: IAppraisalDetailsFallbackProps) {
  return (
    <>
      <AppraisalDetailsLoading isLoading={isLoading} />
      <AppraisalDetailsError error={error} />
      <AppraisalDetailsEmpty data={data} />
    </>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders the profile details UI save for the sidebar navigation.
 *
 * @returns JSX.Element
 */
export function AppraisalDetails() {
  const { query } = useRouter();
  const appraisalId = (query?.appraisalId || '') as string;

  const { appraisalDetails, appraisalCreatedAt, error, isLoading } =
    useFetchAppraisalDetailsQuery({
      appraisalId,
    });

  if (isLoading || error || !appraisalDetails) {
    return (
      <AppraisalDetailsFallback
        data={appraisalDetails}
        error={error}
        isLoading={isLoading}
      />
    );
  }

  return (
    <>
      <AppraisalDetailsHeaderWrapper>
        <div className="detail-container">
          <h3 className="detail-label">Deal details for:</h3>
          <h2 className="detail-value">{appraisalId}</h2>
        </div>

        <div className="detail-container">
          <h3 className="detail-label">Date:</h3>
          <h2 className="detail-value">
            {appraisalCreatedAt
              ? format(appraisalCreatedAt, 'do MMM yyyy')
              : '-'}
          </h2>
        </div>
      </AppraisalDetailsHeaderWrapper>

      <FieldsSectionContainer>
        <div className="field-wrapper">
          <FieldsSection
            className="field-section"
            items={appraisalDetails?.deviceDetails}
            title={translations.deviceDetails}
          />

          <FieldsSection
            className="field-section"
            items={appraisalDetails?.transactionDetails}
            title={translations.transactionDetails}
          />
          <FieldsSection
            className="field-section"
            items={appraisalDetails?.customerInfo}
            title={translations.customerInfo}
          />
        </div>

        <div className="field-wrapper">
          <FieldsSection
            className="field-section"
            items={appraisalDetails?.evaluationResults}
            title={translations.evaluationResultsManual}
          />
        </div>
      </FieldsSectionContainer>
    </>
  );
}
