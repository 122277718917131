import {
  TAccountStatement,
  IAccountStatementData,
  IAccountStatementRawData,
  ITransactionAccountStatement,
} from '../../types';

/**
 * This transforms the `raw account statement API response` to the format that
 * the UI expects.
 * We get the response and isolate the data into `STATEMENT_TYPE_TRANSACTION`,
 * 'STATEMENT_TYPE_BULK_PURCHASE', 'STATEMENT_TYPE_PAYMENT' types based on
 * certain conditions.
 */
export function formatAccountStatementsData(
  response: IAccountStatementRawData
): IAccountStatementData {
  const { accountStatement, count } = response;

  const records = Object.entries(accountStatement).map(([title, items]) => {
    const formattedItems = items.map((item) => {
      let record: TAccountStatement;

      const {
        _id,
        id,
        payable,
        receivable,
        createdAt: dealDate, // TODO: @Anand/@Yaten why not transform this too.
        product,
        appraisalInfo,
        displayStatus,
        courierStatus,
        deliveryDate,
      } = item;

      const purchasePrice = payable - receivable;
      const assetName = { en: product, ar: product };

      // TODO: @Shawaf remove this.... null possibility
      const filteredDisplayStatus = displayStatus?.filter(
        (status) => status !== null
      );

      const common = {
        id: id || _id || '',
        assetName,
        purchasePrice,
        courierStatus,
        deliveryDate,
        isDelivered: appraisalInfo?.isDelivered,
        dealDate,
        displayStatus: filteredDisplayStatus,
      };

      /**
       * If the response contains stackId or if the stackId is not null, then
       * it is a bulk purchase transaction and we render the `BulkPurchaseCard`.
       */
      if (item.stackId) {
        const {
          stackId = '',
          stackID,
          invoiceUrl = '',
          transactions = [],
        } = item || {};

        /**
         * On expanding the bulk purchase card we display the transactions in
         * `TransactionCards`
         * Transforming the transactions list for bulk purchase to format it in
         * a way that the transactions card expects.
         */
        const formattedTransactions: ITransactionAccountStatement[] =
          transactions.map((transaction) => {
            const {
              appraisalCode,
              // TODO: Get this from the API for a single transaction
              appraisalInfo: { isDelivered },
              createdAt,
              // eslint-disable-next-line @typescript-eslint/no-shadow
              courierStatus,
              displayStatus: transactionStatuses,
              id: ID,
              payable: payableValue,
              product: productTxn,
              receivable: receivableValue,
            } = transaction;

            const assetNameTxn = { en: productTxn, ar: productTxn };

            // TODO: @Shawaf remove this.... null possibility
            const displayStatusTxn = transactionStatuses?.filter(
              (status) => status !== null
            );
            const price = payableValue - receivableValue;

            return {
              appraisalCode,
              assetName: assetNameTxn,
              courierStatus,
              dealDate: createdAt,
              deliveryDate,
              displayStatus: displayStatusTxn,
              id: ID,
              isDelivered,
              purchasePrice: price,
              statementType: 'STATEMENT_TYPE_TRANSACTION',
            };
          });

        /**
         * If the deal is marked as a bulk purchase but it only has one single
         * transaction, treat it as single deal on the app UI.
         */
        const isSingleTransaction = !!stackID;

        record = {
          ...common,
          statementType: 'STATEMENT_TYPE_BULK_PURCHASE',
          stackId,
          isSingleTransaction,
          transactions: formattedTransactions,
          invoiceUrl,
        };

        return record;
      }

      /**
       * If the `appraisalCode` key is present, then we render the `TransactionCard`.
       */
      if (item.appraisalCode) {
        const { appraisalCode } = item;
        record = {
          ...common,
          statementType: 'STATEMENT_TYPE_TRANSACTION',
          appraisalCode,
        };

        return record;
      }

      /**
       * In cases where the `stackId` and `appraisalCode` are null, we display a
       * simple `PaymentDetailsCard`.
       */
      const { type = '', narrative = '' } = item;

      record = {
        ...common,
        statementType: 'STATEMENT_TYPE_PAYMENT',
        type,
        narrative,
      };

      return record;
    });

    return { title, data: formattedItems };
  });

  return { records, count };
}
