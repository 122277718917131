import { useState, useEffect } from 'react';

import { TCountryCurrency, IMyBid } from '@northladder/services';
import { useManageBidMutation } from '@northladder/apis';
import {
  BidPriceTextField,
  BidPriceTextLabel,
  Tooltip,
} from '@northladder/design-system';

interface IBidPriceTextFieldColumnProps {
  /**
   * Asset data for showing both `BidPriceTextLabel` , `BidPriceTextField` and
   * api call
   */
  myBid?: IMyBid;
  assetId?: string;
  /**
   * Currency type or currency name eg: AED, USD etc..
   * See also `TCountryCurrency`.
   */
  currency: TCountryCurrency;
  localBidActive: boolean;
}

/**
 * It is a helper component for toggling `BidPriceTextLabel` component to
 * `BidPriceTextField` component.
 *
 * @param `IBidPriceTextFieldColumnProps`
 *
 * @returns Jsx.Element
 */
export function BidPriceTextFieldColumn({
  myBid,
  assetId,
  currency,
  localBidActive,
}: IBidPriceTextFieldColumnProps) {
  const { bidId, isBidActive, spotPrice } = myBid || {};

  const [enablePriceField, setEnablePriceField] = useState(false);
  const [localSpotPrice, setLocalSpotPrice] = useState(0);

  useEffect(() => {
    if (localSpotPrice === 0) {
      setLocalSpotPrice(spotPrice ?? 0);
    }
  }, [spotPrice, localSpotPrice]);

  // Mutation for submitting data through API.
  const { isLoading, isError, error, createOrUpdateBid } =
    useManageBidMutation();

  const handleUpdatedSpotPrice = (newSpotPrice: string) => {
    const updatedSpotPrice = Number(newSpotPrice);

    if (!bidId) {
      createOrUpdateBid({
        assetId: assetId || '',
        spotPrice: updatedSpotPrice,
        isBidActive: true,
        autoTopupEnabled: false,
        maxQuantity: 0,
      });
      return;
    }

    createOrUpdateBid({
      assetId: assetId || '',
      bidId: bidId || '',
      spotPrice: updatedSpotPrice,
      isBidActive: true,
    });

    if (!isLoading || isError) {
      if (!isError) {
        setLocalSpotPrice(updatedSpotPrice);
      }

      setEnablePriceField(false);
    }
  };

  const handleBidPriceLabelOnClick = () => {
    if (!localBidActive) return;
    setEnablePriceField(true);
  };

  if (enablePriceField) {
    return (
      <>
        <BidPriceTextField
          currency={currency}
          isUpdatingSpotPrice={isLoading}
          minUpdateValue="10"
          onUpdateSpotPrice={handleUpdatedSpotPrice}
          spotPrice={localSpotPrice ?? 0}
        />
        {isError ? (
          <p className="self-center text-xs font-normal text-red-600">
            {`! ${error}`}
          </p>
        ) : null}
      </>
    );
  }

  return (
    <Tooltip
      message={!isBidActive || !localBidActive ? 'Please enable bid...' : ''}
    >
      <BidPriceTextLabel
        myPrice={`${localSpotPrice ?? 0}`}
        onClick={handleBidPriceLabelOnClick}
      />
    </Tooltip>
  );
}
