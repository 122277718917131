import { useQueryWithUserId } from '@northladder/services';

import { IApiError } from '../../../../types';
import { CUSTOMER_SERVICE_API } from '../customerServiceClient';

/**
 * This is to track the bid execution time query key in react query cache.
 */
const BID_EXECUTION_TIME_KEY = 'bid-execution-time-key';

/**
 * `useBidExecutionTimeQuery` handles the API call to get the bid execution
 * time.
 * @returns { data, error, isError, isLoading, refetch }
 */
export const useBidExecutionTimeQuery = () => {
  const { data, error, isError, isLoading, refetch } = useQueryWithUserId({
    queryKey: [BID_EXECUTION_TIME_KEY],
    enabled: true,
    queryFn: () => CUSTOMER_SERVICE_API.getBidExecutionTime(),
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isError,
    isLoading,
    refetch,
  };
};
