import { useMutation } from '@northladder/services';

import {
  IApiError,
  IApiResponse,
  IFetchAllBidsPayload,
} from '../../../../types';
import { BIDS_API } from '../bidsAPIClient';

/**
 * This is to track the download paas bid book query key in react query cache.
 */
const DOWNLOAD_PAAS_BID_BOOK_KEY = 'download-paas-bid-book-key';

/**
 * `IUsePaasDownloadBidBookProps` is hook parameters for
 * usePaasDownloadBidBookMutation.
 */
interface IUsePaasDownloadBidBookProps {
  /**
   * Payload for filters and search key mapping to download paas bid book.
   */
  payload: IFetchAllBidsPayload;
  /**
   * Success callback for download paas bid book API.
   */
  onSuccessCallBack: (bidBookData: IApiResponse<BlobPart> | undefined) => void;
}

/**
 * `usePaasDownloadBidBookMutation` handles the API call to download the paas
 * bid book.
 * @returns { error, isError, data, mutate }
 */
export const usePaasDownloadBidBookMutation = ({
  payload,
  onSuccessCallBack,
}: IUsePaasDownloadBidBookProps) => {
  let localPayload = { ...payload };

  if (payload.brandId === 'all' || !payload.brandId) {
    const { brandId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.filterBy) {
    const { filterBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.sortBy) {
    const { sortBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.orderBy) {
    const { orderBy, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.groupIds?.length === 0) {
    const { groupIds, ...rest } = localPayload;
    localPayload = rest;
  }

  const {
    categoryId,
    brandId,
    groupIds,
    count,
    search,
    toDate,
    fromDate,
    filterBy,
    sortBy,
    orderBy,
  } = localPayload;

  const { data, error, isError, mutate } = useMutation({
    mutationKey: [
      DOWNLOAD_PAAS_BID_BOOK_KEY,
      categoryId,
      brandId,
      groupIds,
      count,
      search,
      toDate,
      fromDate,
      filterBy,
      sortBy,
      orderBy,
    ],
    mutationFn: () => BIDS_API.downloadBidBookPaas({ ...localPayload }),
    onSuccess: (bidBookData) => onSuccessCallBack(bidBookData),
    onError: (err: IApiError) => err,
  });

  return {
    data,
    error,
    isError,
    mutate,
  };
};
