import dynamic from 'next/dynamic';

/**
 * This dynamically imports `RegistrationStatusForm` content at the lazily when required.
 * This helps prevent nextjs hydration errors.
 */
const DynamicRegistrationStatusForm = dynamic(
  () => import('./RegistrationStatusForm'),
  {
    ssr: false,
  }
);

export default DynamicRegistrationStatusForm;
