import { useInfiniteQuery, useAuthStore } from '@northladder/services';

import { IApiError, TFetchAppraisalsPayload } from '../../../../types';

import { CUSTOMER_SERVICE_API } from '../customerServiceClient';

/**
 * This is to track the appraisals listing key in react query cache.
 */
export const FETCH_APPRAISALS_KEY = 'fetch-appraisals-key';

/**
 * `IUseAppraisalsQuery` hook parameters.
 */
interface IUseAppraisalsQuery {
  /**
   * Payload for filters and search key mapping
   */
  payload: TFetchAppraisalsPayload;
}

/**
 * This performs a get request and retrieves the appraisals list data by
 * category.
 * Provides data fetching states to be used in the appraisals table.
 */
export const useAppraisalsQuery = ({ payload }: IUseAppraisalsQuery) => {
  let localPayload = { ...payload };

  if (payload.categoryId === 'all' || !payload.categoryId) {
    const { categoryId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.brandId === 'all' || !payload.brandId) {
    const { brandId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.fromDate) {
    const { fromDate, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload.toDate) {
    const { toDate, ...rest } = localPayload;
    localPayload = rest;
  }

  if (payload.brandId === 'all') {
    if (!payload.categoryId) {
      const { categoryId, ...rest } = localPayload;
      localPayload = rest;
    }

    if (!payload.brandId) {
      const { brandId, ...rest } = localPayload;
      localPayload = rest;
    }
  }
  const userId = useAuthStore.getState()?.activeSession?.userId || '';
  const { categoryId, brandId, search } = localPayload;

  const {
    data,
    isLoading,
    isError,
    error,
    isPaused,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [FETCH_APPRAISALS_KEY, userId, categoryId, brandId, search],
    enabled: false, // It's a dependant query so don't run immediately.
    networkMode: 'always', // Even make calls when offline
    queryFn: ({ pageParam = 1 }) =>
      CUSTOMER_SERVICE_API.fetchAppraisals({
        ...localPayload,
        page: pageParam,
        userId,
      }),
    select: (response) => response,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;

      const currentAssetCount = allPages.length * payload.count;

      return currentAssetCount < lastPage.count ? nextPage : undefined;
    },
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isLoading,
    isError,
    isPaused,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
