import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';

import { Text } from '@northladder/i18n';

import { translations } from './translations';

import { ProfileDetails } from './ProfileDetails';
import { TeamsList } from './TeamsList';

interface IPageViewProps {
  onOpenSideBar: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the main profile page content.
 */
export function PageView({ onOpenSideBar }: IPageViewProps) {
  const [isEditing, setIsEditing] = useState(false);
  const showTeamsList = false;

  const handleOnEdit = () => {
    setIsEditing(() => true);
  };

  const handleOnCancelEdit = () => {
    setIsEditing(() => false);
  };

  return (
    <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
      <div className="lg:hidden">
        <div
          className="
            flex items-center justify-between border-b border-gray-200
            bg-gray-50 px-4 py-1.5
          "
        >
          <h3
            className="
              inline-flex items-center space-x-3 text-sm font-medium
              text-gray-900
            "
          >
            <Text text={translations.account} />
          </h3>
          <div>
            <button
              className="
                -mr-3 inline-flex h-12 w-12 items-center justify-center
                rounded-md text-gray-500 hover:text-purple-900 focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-purple-200
              "
              onClick={onOpenSideBar}
              type="button"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <div className="relative z-0 flex flex-1 overflow-hidden">
        <main
          className="
            relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last
          "
        >
          <article className="pb-20">
            <ProfileDetails
              isEditing={isEditing}
              onCancel={handleOnCancelEdit}
              onEdit={handleOnEdit}
            />
            {!isEditing && showTeamsList ? <TeamsList /> : null}
          </article>
        </main>
      </div>
    </div>
  );
}
