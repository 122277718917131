import { useQueryWithUserId } from '@northladder/services';

import { IApiError } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';

/**
 * This is to track the download bid book query key in react query cache.
 */
const BIDS_QUANTITY_KEY = 'bids-quantity-key';

interface IUseBidQuantityQueryOptions {
  assetId: string;
  defaultValue: string;
  onSuccessCallback: () => void;
}

/**
 * Fetches the list of quantities supported by this bid.
 * Note: Identify why we have a separate hook for web and mobile. cc @Renji.
 * See also `useBidGetQuantityQuery` by @Renji.
 */
export const useBidQuantityQuery = ({
  assetId,
  defaultValue,
  onSuccessCallback,
}: IUseBidQuantityQueryOptions) => {
  const { data, isError, error, isLoading, refetch } = useQueryWithUserId({
    queryKey: [BIDS_QUANTITY_KEY, assetId],
    enabled: false,
    retry: true,
    queryFn: () => BIDS_API.fetchBidsQuantity(assetId),
    /**
     * Note: Updating the select too, not to break the web UI. But data is
     * already formatted in `formatBidDetailsQuantity`. See mobile usage.
     */
    select: (response) => {
      const quantityItems = response.map((item) => ({
        ...item,
        title: item.value,
      }));

      quantityItems.unshift({
        id: 'all',
        value: defaultValue,
        title: defaultValue,
      });

      const filteredItems = quantityItems.filter(
        (ele, ind) =>
          ind === quantityItems.findIndex((elem) => elem.title === ele.title)
      );
      return filteredItems;
    },
    onSuccess: () => onSuccessCallback(),
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isError,
    data,
    isLoading,
    refetch,
  };
};
