import { useCallback, useEffect } from 'react';
import { Text } from '@northladder/i18n';

import {
  SimpleDropdown,
  PopoverMultiSelect,
  TMenuItem,
  IPopoverItem,
  CircularProgressLoader,
} from '@northladder/design-system';
import {
  IApiError,
  IApiResponse,
  usePaasDownloadBidBookMutation,
  usePaasSearchBidsQuery,
} from '@northladder/apis';

import { createDomElementWithFileInBody } from '@northladder/utilities';
import { SectionWrapper, DashboardMainWrapper } from '../../../../styles';

import { UploadBidActionWrapper } from '../../styles';
import { useBidsFilter } from '../../hooks';

import { BidsResultsListPaas } from './BidsResultsListPaas';
import { BidsPaasActionBar } from './BidsPaasActionBar';

/**
 * Props for the `BidsDropdownFiltersSection` Component.
 */
interface IBidsDropdownFiltersSectionProps {
  brandOptions: TMenuItem[];
  selectedCategory: TMenuItem | null;
  categoryOptions: TMenuItem[];
  modelsOptions: IPopoverItem[];
  onCategoryOptionSelect: (item: TMenuItem) => void;
  onBrandOptionSelect: (item: TMenuItem) => void;
  onPopoverSelectCB: (popoverItems: IPopoverItem[]) => void;
  isLoadingCategoryData: boolean;
  isFetchingModels: boolean;
  categoryError: IApiError | null;
  modelError: IApiError | null;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * @returns JSX.Element
 */
function BidsDropdownFiltersSection({
  brandOptions,
  categoryOptions,
  selectedCategory,
  modelsOptions,
  onCategoryOptionSelect,
  onBrandOptionSelect,
  onPopoverSelectCB,
  isLoadingCategoryData,
  isFetchingModels,
  categoryError,
  modelError,
}: IBidsDropdownFiltersSectionProps) {
  return (
    <SectionWrapper
      className="flex h-max flex-wrap items-center gap-4 p-0 sm:p-4
      "
    >
      <div className="w-full sm:w-52">
        <SimpleDropdown
          className="z-10"
          defaultTitle="All categories"
          error={categoryError}
          items={categoryOptions}
          loadingState={isLoadingCategoryData}
          menuItemsClassName="menu-item-list"
          onDropDownItemSelect={onCategoryOptionSelect}
        />
      </div>
      <div className="w-full sm:w-52">
        <SimpleDropdown
          className="z-10"
          defaultTitle="All brands"
          error={categoryError}
          isEnabled={!!selectedCategory && !(selectedCategory?.id === 'all')}
          items={brandOptions}
          loadingState={isLoadingCategoryData}
          menuItemsClassName="menu-item-list"
          onDropDownItemSelect={onBrandOptionSelect}
        />
      </div>
      <div className="order-last min-w-[16rem] max-w-[42rem] grow lg:order-none">
        <PopoverMultiSelect
          error={modelError}
          isModelsLoading={isFetchingModels}
          items={modelsOptions}
          onPopoverItemSelect={onPopoverSelectCB}
          title="Model"
        />
      </div>
    </SectionWrapper>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This component houses all the main content of the bids screen, such as the
 * filters, list view, pagination, selected bid, etc.
 *
 * TODO: Update this with the other components being developed in isolation
 * TODO: Remove the grid layout and instead use flex.
 *
 * @returns JSX.Element
 */
export function BidsMainContentPaas() {
  const {
    nextPageObserverRef,
    selectedCategory,
    brandOptions,
    categoryOptions,
    modelsOptions,
    isLoadingCategoryData,
    isFetchingModels,
    categoryError,
    modelError,
    selectedBrand,
    selectedGroupIds,
    sortBy,
    orderBy,
    searchKey,
    bidRankFilterKey,
    onPopoverSelectCB,
    onCategoryOptionSelect,
    onBrandOptionSelect,
    onSortChange,
  } = useBidsFilter();

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
  } = usePaasSearchBidsQuery({
    payload: {
      page: 1, // initial page count
      count: 10,
      search: searchKey,
      categoryId: selectedCategory?.id.toString() || '',
      brandId: selectedBrand?.id.toString() || '',
      groupIds: selectedGroupIds,
      filterBy: bidRankFilterKey as string | null,
      sortBy,
      orderBy,
    },
  });

  function downloadPaasBidBook(
    bidBookData: IApiResponse<BlobPart> | undefined
  ) {
    if (bidBookData) {
      createDomElementWithFileInBody({
        fileData: bidBookData.data,
        fileNamePrefix: 'Bid_Book_',
      });
    }
  }

  const { mutate: handleDownloadBidBookPaas } = usePaasDownloadBidBookMutation({
    payload: {
      page: 1, // initial page count
      count: 10,
      search: searchKey,
      categoryId: selectedCategory?.id.toString() || '',
      brandId: selectedBrand?.id.toString() || '',
      groupIds: selectedGroupIds,
      filterBy: bidRankFilterKey as string | null,
      excelExport: true,
      sortBy,
      orderBy,
    },
    onSuccessCallBack: (bidBookData) => {
      downloadPaasBidBook(bidBookData);
    },
  });

  const handleObserver = useCallback(
    (entries: any[]) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const element = nextPageObserverRef.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [fetchNextPage, hasNextPage, handleObserver, nextPageObserverRef]);

  return (
    <>
      <UploadBidActionWrapper className="flex-col items-stretch !justify-start rounded-xl bg-white">
        <BidsPaasActionBar
          brandId={selectedBrand?.id || ''}
          onDownloadBidBookPaas={handleDownloadBidBookPaas}
        />
      </UploadBidActionWrapper>
      <DashboardMainWrapper className="flex-col items-stretch !justify-start rounded-xl bg-white">
        <SectionWrapper
          className="
            flex w-full flex-col items-start px-4 sm:flex-row sm:items-center
          "
        >
          <span className="pb-1 text-base font-semibold">Filter By</span>
          <BidsDropdownFiltersSection
            brandOptions={brandOptions}
            categoryError={categoryError}
            categoryOptions={categoryOptions}
            isFetchingModels={isFetchingModels}
            isLoadingCategoryData={isLoadingCategoryData}
            modelError={modelError}
            modelsOptions={modelsOptions}
            onBrandOptionSelect={onBrandOptionSelect}
            onCategoryOptionSelect={onCategoryOptionSelect}
            onPopoverSelectCB={onPopoverSelectCB}
            selectedCategory={selectedCategory}
          />
        </SectionWrapper>

        <SectionWrapper className="min-h-full grow">
          {error ? (
            <>
              <Text className="text-red-800" text={error?.message} />
              <button
                className="ml-1 mt-2 inline-flex justify-center rounded-md border
               border-transparent bg-purple-500 px-2 py-1 text-sm font-medium
                text-white hover:bg-purple-600"
                onClick={() => refetch()}
                type="button"
              >
                Retry
              </button>
            </>
          ) : null}
          <BidsResultsListPaas
            bidListPage={data?.pages}
            onSortChange={onSortChange}
          />
        </SectionWrapper>
        <div ref={nextPageObserverRef} className="flex flex-row justify-center">
          {isFetchingNextPage && hasNextPage ? (
            <CircularProgressLoader className="h-5 w-5" strokecolor="#000000" />
          ) : (
            'No search left'
          )}
        </div>
      </DashboardMainWrapper>
    </>
  );
}
