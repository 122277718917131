import { useEffect, useState } from 'react';

import { Moment } from '@northladder/utilities';
import { useBidsCategoryQuery } from '@northladder/apis';
import { IBrand, ICategoryItem } from '@northladder/services';
import { IDatesRanges, TMenuItem } from '@northladder/design-system';

/**
 * This hooks provides access to all the state for filters.
 */
export const useAppraisalsFilters = () => {
  const [categoryOptions, setCategoryOptions] = useState<TMenuItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<TMenuItem | null>(
    null
  );
  const [brandOptions, setBrandOptions] = useState<TMenuItem[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<TMenuItem | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
    null
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(null);

  const {
    data: bidFilterCategoryData,
    isLoading: isLoadingCategoryData,
    error: categoryError,
  } = useBidsCategoryQuery();
  const categoryList = bidFilterCategoryData?.categoryList;

  const addBrandOptions = (brands: IBrand[]) => {
    const brandItems = brands?.map((brand: IBrand) => ({
      id: brand.id,
      title: brand.name,
    }));

    brandItems.unshift({ id: 'all', title: 'All Brands' });

    setBrandOptions(brandItems);
    setSelectedBrand(brandItems[0]);
  };

  const handleOnCategoryOptionSelect = (item: TMenuItem) => {
    const filteredCategory = categoryList?.find(
      (category: ICategoryItem) => category.id === item.id
    );

    setSelectedCategory(item);
    addBrandOptions(filteredCategory?.brands || []);
  };

  const handleOnBrandOptionSelect = (item: TMenuItem) => {
    setSelectedBrand(item);
  };

  useEffect(() => {
    if (categoryList) {
      const categoryItems = categoryList.map((category: ICategoryItem) => ({
        id: category.id,
        title: category.categoryName,
      }));

      categoryItems.unshift({ id: 'all', title: 'All Categories' });

      setCategoryOptions(categoryItems);
      setSelectedCategory(categoryItems[0]);
      addBrandOptions(categoryList[0]?.brands);
    }
  }, [categoryList]);

  const handleOnDatesChange = ({ startDate, endDate }: IDatesRanges) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  return {
    brandOptions,
    categoryError,
    categoryOptions,
    isLoadingCategoryData,
    onBrandOptionSelect: handleOnBrandOptionSelect,
    onCategoryOptionSelect: handleOnCategoryOptionSelect,
    onDatesChange: handleOnDatesChange,
    selectedEndDate,
    selectedCategory,
    selectedStartDate,
    selectedBrand,
  };
};
