import { PieChart } from '@northladder/design-system';

import {
  DateSelectionPopup,
  TDaysUpdateValue,
} from '../DateSelectionPopup/DateSelectionPopup';

import IconRadioButton from './IconRadioButton';

import {
  ButtonWrapper,
  CardWrapper,
  PrimaryButton,
  SecondaryButton,
} from './styles';

export interface IBrandItem {
  /**
   * Displays brand name.
   */
  title: string;
  /**
   * Displays the brand stats.
   */
  desc: string;
}

export interface ICardProps {
  /**
   * Takes each brand with its title and desc.
   */
  brandItems?: IBrandItem[];
  /**
   * onClick call back function for `me` filter it will notify the parent
   * if the nl button is clicked
   */
  onClickMe?: () => void;
  /**
   * onClick call back function for `nl filter` it will notify the parent
   * if the nl button is clicked
   */
  onClickNL?: () => void;
  /**
   * For showing the `BrandCard` card tile
   */
  title: string;
  /**
   * Callback function for date selection It provide selected days to the parent
   */
  onSelectDays?: (selectedDays: TDaysUpdateValue) => void;
}

/**
 * -----------------------------------------------------------------------------
 * Brand card that lists the top brands and their stats.
 */
export function BrandCard({
  title: label,
  brandItems,
  onClickMe,
  onClickNL: onClickNl,
  onSelectDays,
}: ICardProps) {
  const handleDaySelectionChange = (value: TDaysUpdateValue) => {
    onSelectDays?.(value);
  };

  return (
    <CardWrapper>
      <div className="ml-5 mt-5 text-xl font-bold rtl:mr-4">{label}</div>
      <ButtonWrapper>
        <div className="flex items-center space-x-3">
          <PrimaryButton onClick={onClickMe} type="button">
            ME
          </PrimaryButton>
          <SecondaryButton onClick={onClickNl} type="button">
            NL
          </SecondaryButton>
        </div>
        <DateSelectionPopup
          className="dropdown-button"
          onSelectDays={handleDaySelectionChange}
        />
      </ButtonWrapper>
      {brandItems && brandItems.length > 0 ? (
        <div className="ml-5 mt-10 flex flex-row rtl:mr-4">
          <ul>
            {brandItems.map(({ title, desc }) => (
              <li key={title} className="py-3">
                <div className="flex items-center space-x-4">
                  <IconRadioButton className="fill-purple-500" />
                  <p className="w-32">{title}</p>
                  <div className="inline-flex items-center">{desc}</div>
                </div>
              </li>
            ))}
          </ul>
          <PieChart items={brandItems} label={label} />
        </div>
      ) : (
        <span>No Items Present</span>
      )}
    </CardWrapper>
  );
}
