import { ApiClient } from '../../../api-client';
import {
  changePasswordUrl,
  loginUrl,
  logoutUrl,
  fetchNotificationHistoryUrl,
  notificationViewedUrl,
  profileUpdateUrl,
  resetPasswordUrl,
  sendForgotPasswordOTPUrl,
  sendLoginOTPUrl,
  fetchUnreadNotificationUrl,
  validateLoginOTPUrl,
  updateUserLanguageUrl,
  updateUserExpoNotificationTokenUrl,
} from '../../../urls';

import { IApiError, IApiResponse } from '../../../types';

import { formatUserDetails } from './transformers/formatUserDetails';

import {
  IChangePasswordPayload,
  IChangePasswordResponse,
  ILoginPayload,
  ILoginResponseData,
  ILogoutOptions,
  ILogoutPayload,
  ILogoutResponseData,
  INotificationHistoryParam,
  INotificationHistoryResponse,
  INotificationViewedParam,
  IProfileUpdatePayload,
  IProfileUpdateResponse,
  IResetPasswordPayload,
  IResetPasswordResponse,
  ISendUserForgotPasswordOTPPayload,
  ISendUserForgotPasswordOTPResponse,
  ISendUserLoginOPTPayload,
  ISendUserLoginOPTResponse,
  IUnReadNotificationResponse,
  IUpdateExpoTokenPayload,
  IUpdateExpoTokenResponse,
  IUpdateUserLanguagePayload,
  IValidateUserLoginOPTPayload,
  IValidateUserLoginOPTResponse,
} from './types';

/**
 * This is provides the API client methods for all user authentication,
 * authorization and identity services like login, forgot password etc.
 *
 * NOTE: The `refreshToken` service has been moved out as a global to avoid
 * cyclic dependencies since its to be consumed within the base `ApiClient`.
 *
 */
export class UserAccountsAPIClient extends ApiClient {
  private static classInstance?: UserAccountsAPIClient;

  private constructor() {
    super({
      apiBaseURL: 'IDENTITY_SERVICE_BASE_URL',
    });
  }

  /**
   * Applying the dreaded singleton pattern here to reuse the axios instance.
   */
  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new UserAccountsAPIClient();
    }

    return this.classInstance;
  };

  /**
   * This provides login authentication services. Mainly expects a username/email
   * & password payload and retrieves the tokens and user info from the backend.
   */
  public loginUser = async (payload: ILoginPayload) => {
    const response = await this.post<ILoginResponseData, ILoginPayload>(
      loginUrl(),
      payload,
      { requiresAuth: false }
    );

    if (!response.success) throw response;

    return formatUserDetails({
      ...response.data.data,
      credentials: {
        type: 'EMAIL_LOGIN',
        email: payload.email,
        password: payload.password,
      },
    });
  };

  /**
   * This sends the user's phone number to which the OTP will be sent to start
   * the login process via phone number & OTP.
   */
  public sendUserLoginOTP = async (payload: ISendUserLoginOPTPayload) => {
    const response = await this.post<
      ISendUserLoginOPTResponse,
      ISendUserLoginOPTPayload
    >(sendLoginOTPUrl(), payload, {
      requiresAuth: false,
    });

    if (!response.success) throw response;

    return response.data.data;
  };

  /**
   * This sends the OTP to the server to be verified if it's what was sent on mobile
   * during the login process.
   */
  public validateUserLoginOTP = async (
    payload: IValidateUserLoginOPTPayload
  ) => {
    const response = await this.post<
      IValidateUserLoginOPTResponse,
      IValidateUserLoginOPTPayload
    >(validateLoginOTPUrl(), payload, {
      requiresAuth: false,
    });

    if (!response.success) throw response;

    return {
      ...response.data.data,
      credential: {
        type: 'PHONE_LOGIN',
        phoneNumber: payload.phoneNumber,
        // TODO: Generate this from keychain when payload.token is configured.
        token: '@TODO:GENERATE_THIS_FROM_KEY_CHAIN',
      },
    };
  };

  /**
   * This provides logout services.
   */
  public logoutUser = async (payloads: ILogoutOptions[]) => {
    const logoutPromises: Promise<
      IApiError | IApiResponse<ILogoutResponseData>
    >[] = [];

    payloads.forEach((payload: ILogoutOptions) => {
      const { token, regionId, refreshToken, identifier } = payload;

      const responsePromise = this.post<ILogoutResponseData, ILogoutPayload>(
        logoutUrl(),
        { refreshToken, identifier },
        { accessToken: token, regionId }
      );
      logoutPromises.push(responsePromise);
    });

    Promise.all(logoutPromises).then((logoutResponses) => {
      logoutResponses.forEach((response, index) => {
        const logoutResponse = { ...response, index };

        if (!logoutResponse.success) throw logoutResponse;
        return logoutResponse;
      });
    });
  };

  /**
   * This provides profile details update services.
   */
  public userProfileUpdate = async (payload: IProfileUpdatePayload) => {
    const response = await this.put<
      IProfileUpdateResponse,
      IProfileUpdatePayload
    >(profileUpdateUrl(), payload);

    if (!response.success) throw response;

    return response;
  };

  /**
   * This expects users email and sends the otp to that email address.
   */
  public sendUserForgotPasswordOTP = async (
    payload: ISendUserForgotPasswordOTPPayload
  ) => {
    const response = await this.post<
      ISendUserForgotPasswordOTPResponse,
      ISendUserForgotPasswordOTPPayload
    >(sendForgotPasswordOTPUrl(), payload, {
      requiresAuth: false,
    });

    if (!response.success) throw response;

    return response.data.data;
  };

  /**
   * This allows user to use OTP received in the email and change their password.
   */
  public resetPassword = async (payload: IResetPasswordPayload) => {
    const response = await this.post<
      IResetPasswordResponse,
      IResetPasswordPayload
    >(resetPasswordUrl(), payload, {
      requiresAuth: false,
    });

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * This allows user to change their password.
   */
  public changePassword = async (payload: IChangePasswordPayload) => {
    const response = await this.post<
      IChangePasswordResponse,
      IChangePasswordPayload
    >(changePasswordUrl(), payload);

    if (!response.success) throw response;

    return response;
  };

  /**
   * API call to fetch all notification history.
   */
  public fetchNotificationHistoryList = async (
    queryParams: INotificationHistoryParam
  ) => {
    const response = await this.get<INotificationHistoryResponse>(
      fetchNotificationHistoryUrl(queryParams)
    );

    if (!response.success) throw response;

    return response.data.data;
  };

  public updateNotificationViewedStatus = async (
    payload: INotificationViewedParam
  ) => {
    const response = await this.put<INotificationViewedParam>(
      notificationViewedUrl(),
      payload
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api call to fetch Unread notification status.
   */
  public fetchUnreadNotification = async () => {
    const response = await this.get<IUnReadNotificationResponse>(
      fetchUnreadNotificationUrl()
    );

    if (!response.success) throw response;

    return response.data?.data?.unReadNotification || false;
  };

  /**
   * Api call to update language of the user.
   */
  public updateUserLanguage = async (payload: IUpdateUserLanguagePayload) => {
    const response = await this.put<IUnReadNotificationResponse>(
      updateUserLanguageUrl(),
      payload
    );

    if (!response.success) throw response;

    return response.data;
  };

  /**
   * Api call to update expo notification token on server.
   */
  public updateExpoNotificationToken = async (
    payload: IUpdateExpoTokenPayload | null
  ) => {
    if (!payload) return null;

    const response = await this.put<IUpdateExpoTokenResponse>(
      updateUserExpoNotificationTokenUrl(),
      payload
    );

    if (!response.success) throw response;

    return response.data;
  };
}

/**
 * This creates a new instance of the class. is th base Axios API client Class
 * wrapper for All User Accounts related requests.
 */
export const USER_ACCOUNTS_API = UserAccountsAPIClient.getClientInstance();
