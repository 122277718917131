import { getUserTimeZone } from '@northladder/utilities';

import {
  IFetchAppraisalDetailsPayload,
  TFetchAppraisalsPayload,
  IFetchCreditLimitUrlOptions,
  IFetchDealsUrlOptions,
  IFetchDealDetailsUrlOptions,
  IFetchDeviceGradeConditionUrlOptions,
  IFetchGradeReviewUrlOptions,
  IFetchGradeDealsUrlOptions,
  IFetchGradeReviewDetailsUrlOptions,
  TFetchGradeReviewHistoryUrlOptions,
} from '../types';

export const topBrandsUrl = () => '/report/top/brands';

/**
 * `topModelsUrl` is a function to generate endpoint for the top models.
 */
export const topModelsUrl = () => '/report/top/models';

export const inventorySummaryUrl = () => '/report/deals/summary';

/**
 * This endpoint retrieves uploads files needed for user registration.
 */
export const uploadFileUrl = () => '/files/upload';

/**
 * This endpoint will upload videos currently for grade review request.
 */
export const uploadVideoUrl = () => '/utils/video';

/**
 * This endpoint retrieves appraisal list.
 */
export const fetchAppraisalsUrl = ({
  count,
  page,
  userId,
  dealProcessType,
  listType,
  fromDate,
  toDate,
  categoryId,
  brandId,
  search,
}: TFetchAppraisalsPayload) => {
  let url: string = `/appraisal?count=${count}&page=${page}&userId=${userId}&dealProcessType=${dealProcessType}&listType=${listType}`;

  if (fromDate && toDate) {
    url += `&fromDate=${fromDate}&toDate=${toDate}`;
    url += `&timeZone=${getUserTimeZone()}`;
  }

  if (categoryId) {
    url += `&categoryId=${categoryId}`;
  }

  if (brandId) {
    url += `&brandId=${brandId}`;
  }

  if (search) {
    url += `&search=${search}`;
  }

  return url;
};

/**
 * This endpoint retrieves download appraisals.
 */
export const downloadAppraisalsUrl = ({
  count,
  page,
  userId,
  dealProcessType,
  listType,
  fromDate,
  toDate,
  categoryId,
  brandId,
  search,
}: TFetchAppraisalsPayload) => {
  let url: string = `/appraisal/export?count=${count}&page=${page}&userId=${userId}&dealProcessType=${dealProcessType}&listType=${listType}`;

  if (fromDate && toDate) {
    url += `&fromDate=${fromDate}&toDate=${toDate}`;
  }

  if (categoryId) {
    url += `&categoryId=${categoryId}`;
  }

  if (brandId) {
    url += `&brandId=${brandId}`;
  }

  if (search) {
    url += `&search=${search}`;
  }

  return url;
};

/**
 * This function generates the url for appraisal details endpoint.
 */
export const fetchAppraisalDetailsUrl = ({
  appraisalId,
}: IFetchAppraisalDetailsPayload) => `/appraisal/${appraisalId}`;

/**
 * This function generate the url for signed url.
 */
export const getSignedUrlEndpoint = () => '/secure/files/getSignedUrl';

/**
 * This endpoint retrieves bid execution time.
 */
export const getBidExecutionTimeUrl = () =>
  `/master-config-balance/bid-execution-time?timeZone=${getUserTimeZone()}`;

/**
 * This endpoint retrieves list of deals based on the appraisal code.
 */
export const dealsListUrl = ({
  appraisalCode,
  count,
  filters,
  fromDate,
  page,
  toDate,
}: IFetchDealsUrlOptions) => {
  let url: string = `/appraisal?page=${page}&count=${count}&search=${appraisalCode}`;

  if (filters) {
    url += `&filter=${filters}`;
  }

  if (fromDate && toDate) {
    url += `&fromDate=${fromDate}&toDate=${toDate}`;
  }

  return url;
};

/**
 * This endpoint retrieves details of a deal.
 */
export const dealDetailsUrl = ({
  appraisalCode,
}: IFetchDealDetailsUrlOptions) => `appraisal/${appraisalCode}`;

/**
 * Credit limit endpoint for fetching credit limit under storeId
 */
export const creditLimitUrl = ({ storeId }: IFetchCreditLimitUrlOptions) =>
  `/b2b-data/credit-limit/details/${storeId}`;

/**
 * Device Grade Condition endpoint for fetching device grade condition using
 * appraisalCode
 */
export const DeviceGradeConditionUrl = ({
  appraisalCode,
}: IFetchDeviceGradeConditionUrlOptions) =>
  `/grade-review-request/asset-condition/${appraisalCode}`;

/**
 * Device Grade Condition endpoint for fetching device grade condition using
 * appraisalCode
 */
export const DeviceItemConditionUrl = ({
  appraisalCode,
}: IFetchDeviceGradeConditionUrlOptions) =>
  `/grade-review-request/item-condition/${appraisalCode}`;

/**
 * This endpoint retrieves grade review request list.
 */
export const fetchGradeReviewUrl = ({
  appraisalCode,
  count,
  page,
}: IFetchGradeReviewUrlOptions) => {
  const url = `/grade-review-request/dealer?page=${page}&limit=${count}&searchAppraisalCode=${appraisalCode}`;

  return url;
};

/**
 * This endpoint retrieves grade review request list.
 */
export const fetchDealsForGradeRequestSearchUrl = ({
  appraisalCode,
  count,
  page,
}: IFetchGradeDealsUrlOptions) => {
  if (appraisalCode) {
    return `/grade-review-request/dispute-appraisal?searchAppraisalCode=${appraisalCode}&page=${page}&limit=${count}`;
  }

  return `/grade-review-request/dispute-appraisal?page=${page}&limit=${count}`;
};

/**
 * This endpoint retrieves grade review request details.
 */
export const fetchGradeReviewDetailsUrl = ({
  gradeReviewRequestId,
}: IFetchGradeReviewDetailsUrlOptions) =>
  `/grade-review-request/${gradeReviewRequestId}`;

/**
 * This endpoint retrieves grade review request list.
 */
export const fetchGradeReviewsListUrl = ({
  appraisalCode,
  count,
  page,
  status,
}: IFetchGradeReviewUrlOptions) => {
  const url = `/grade-review-request/dealer?page=${page}&limit=${count}&searchAppraisalCode=${appraisalCode}&status=${status}`;

  return url;
};

/**
 * This endpoint retrieves grade review request history.
 */
export const fetchGradeReviewHistoryUrl = ({
  gradeReviewRequestId,
}: TFetchGradeReviewHistoryUrlOptions) =>
  `/grade-review-request/history/${gradeReviewRequestId}`;

/**
 * This endpoint used to create the grade change request.
 */
export const createGradeChangeRequestUrl = () => '/grade-review-request/dealer';

/**
 * This endpoint used to update the grade change request.
 */
export const updateGradeChangeRequestUrl = () =>
  '/grade-review-request/additional-info';
