import { INotificationHistoryParam } from '../dealer-api/clients/user-accounts/types';
/**
 * -----------------------------------------------------------------------------
 * These services provide user authentication and authorization stateful logic.
 * -----------------------------------------------------------------------------
 */

/**
 * Main `identity service` that makes login requests and provides the necessary
 * `user` info, `token` and initial `refreshToken` to be used across other services.
 * - `POST`
 */
export const loginUrl = () => '/identity/member/login';

/**
 * This `identity service` endpoint sends the `otp` sent to the user's mobile.
 */
export const sendLoginOTPUrl = () => '/identity/dealer-otp';

/**
 * This `identity service` endpoint verifies the `otp` sent to the user's mobile.
 */
export const validateLoginOTPUrl = () => '/identity/dealer-verify-otp';

/**
 * This `identity service` generates the `refreshToken` if expired.
 */
export const refreshTokenUrl = () => '/identity/token';

/**
 * Makes logout requests.
 */
export const logoutUrl = () => '/user/member/logout';

/**
 * This makes profile update requests.
 */
export const profileUpdateUrl = () => '/user/update-profile';

/**
 * This `identity service` endpoint sends the `otp` sent to the user's email when
 * the user has forgotten their password.
 */
export const sendForgotPasswordOTPUrl = () => '/identity/forgot-password';

/**
 * This `identity service` endpoint allows user to enter the `otp` and reset
 * their password.
 */
export const resetPasswordUrl = () => '/identity/reset-password';

/**
 * This `identity service` endpoint allows user to change their password from the
 * profile screen.
 */
export const changePasswordUrl = () => '/identity/change-password';

/**
 * This `identity service` endpoint for fetching notification history
 */
export const fetchNotificationHistoryUrl = ({
  count,
  lastDocumentId,
}: INotificationHistoryParam) => {
  if (lastDocumentId) {
    return `/notifications/app/history?count=${count}&lastDocumentId=${lastDocumentId}`;
  }

  return `/notifications/app/history?count=${count}`;
};

/**
 * This `identity service` endpoint allows to update the viewed status of
 * notification history item
 */
export const notificationViewedUrl = () => '/notifications/app/history/viewed';

/**
 * This `identity service` endpoint for fetching notification unread boolean
 */
export const fetchUnreadNotificationUrl = () =>
  '/notifications/app/history/unread';

export const updateUserLanguageUrl = () => '/user/member/device-locale';

export const updateUserExpoNotificationTokenUrl = () =>
  '/user/notification/token';
