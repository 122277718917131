import { IAsset } from '@northladder/services';

import { IFetchAllBidsRawData, IFetchAllBidsData } from '../types';

/**
 * TODO: Remove the rank summary from the response, as we are using standalone
 * rank api
 */
export function formatBidSearchResults(
  response: IFetchAllBidsRawData,
  page: number
): IFetchAllBidsData {
  const cleanAssetList: IAsset[] = response.assets.map((asset) => {
    const { myBid, hotDealFlag } = asset || {};
    const {
      autoTopupEnabled: isAutoTopupEnabled,
      availableQuantity,
      _id: myBidId,
      isBidActive,
      isSpotSummit,
      maxQuantity,
      rank,
      spotPrice,
      updatedDate,
    } = myBid ?? {};

    const {
      _id,
      attributes,
      brandId,
      brandName,
      categoryName,
      imageUrl,
      name,
      stepperValue,
      topBid,
    } = asset || {};

    let assetNameStr = `${brandName || ''} ${name || ''} `;

    assetNameStr += attributes.map((attr) => `${attr.value}`).join(', ');

    // TODO: Add arabic name later as iraq team needs english name for now.
    // let assetNameAr = `${brandName || ''} ${name || ''} `;
    // assetNameAr += attributes.map((attr) => `${attr.value_ar}`).join(', ');

    const cleanAsset: IAsset = {
      assetId: _id,
      brandId,
      brandName,
      categoryName,
      assetImage: imageUrl,
      stepperValue: stepperValue || 0,
      topBidAmount: topBid?.spotPrice || 0,
      isHotDeal: hotDealFlag,
      assetName: {
        en: assetNameStr,
        // ar: assetNameAr || assetNameStr,
        ar: assetNameStr,
      },
      myBid: {
        availableQuantity,
        bidId: myBidId,
        isAutoTopupEnabled,
        isBidActive,
        isSpotSummit,
        /**
         * TODO:
         * @Rhea / @Renji - Please assign this value from the response
         */
        itemCondition: '',
        maxQuantity,
        rankNumber: rank,
        spotPrice,
        updatedDate,
      },
      page,
    };

    return cleanAsset;
  });

  return {
    assets: cleanAssetList,
    totalCount: response.totalCount,
    rankSummary: response.rankSummary,
    contextId: response.contextId,
  };
}
