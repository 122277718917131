import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconHelp component is svg for help icon which is customizable with some
 * default values configured. It is mainly used in Menu Items for navigation
 * but also can be used elsewhere.
 *
 * @param TSvgComponentProps Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
function IconHelp(props: TSvgComponentProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M11.9 15.1c-.5 0-.8-.4-.8-.8v-.7c0-.8.5-1.6 1.3-1.9.4-.1.7-.4.9-.7.2-.4.2-.7.1-1.1-.1-.4-.4-.7-.7-.8-.4-.2-.7-.2-1.1-.1-.5.2-.8.5-1 1-.1.2-.2.4-.4.5-.2.1-.4.1-.6.1s-.5-.3-.6-.5c-.1-.2-.2-.4-.1-.6.2-.8.7-1.5 1.4-1.9.7-.4 1.6-.6 2.4-.4.8.2 1.5.7 1.9 1.4.4.7.5 1.6.3 2.4-.3 1-1 1.8-2 2.2-.1 0-.2.2-.2.3v.7c0 .5-.3.9-.8.9zM11.9 17.5c-.5 0-1-.4-1-1s.4-1 1-1c.5 0 1 .4 1 1s-.5 1-1 1z" />
      <path d="M12 23.8C5.5 23.8.2 18.5.2 12 .2 5.5 5.5.2 12 .2 18.5.2 23.8 5.5 23.8 12c-.1 6.5-5.3 11.7-11.8 11.8zm0-22C6.3 1.8 1.8 6.3 1.8 12c0 5.7 4.6 10.2 10.2 10.2s10.2-4.6 10.2-10.3c0-5.6-4.5-10.1-10.2-10.1z" />
    </svg>
  );
}

export default IconHelp;
