import {
  IDeviceItemConditionRawResponse,
  IDeviceItemConditions,
  IGradePopoverItem,
} from '../../types';

/**
 * Type intermediate function for converting IDeviceItemConditionRawResponse to
 *  IDeviceItemConditions data for Device Item condition api response api response
 */
export function formatDeviceItemConditionResponse(
  response: IDeviceItemConditionRawResponse
): IDeviceItemConditions | undefined {
  const { data } = response || {};

  const { _id, conditions } = data || {};

  const deviceItemConditions: IGradePopoverItem[] = conditions?.map(
    (conditionItem) => {
      // eslint-disable-next-line camelcase
      const {
        _id: conditionId,
        condition,
        condition_ar: conditionAr,
        freeze,
      } = conditionItem || {};
      return {
        checked: false,
        freeze,
        id: conditionId,
        title: condition,
        // eslint-disable-next-line camelcase
        titleAr: conditionAr,
      };
    }
  );

  return { id: _id, conditions: deviceItemConditions };
}
