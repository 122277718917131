import Link from 'next/link';

import { Text, TTextLocalized } from '@northladder/i18n';

interface ITutorialHeaderProps {
  title: TTextLocalized;
  description: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a the summary of the section header title content
 */
export function TutorialHeader({ title, description }: ITutorialHeaderProps) {
  const TUTORIALS_PATH = '/tutorials';

  const headerTitle: TTextLocalized = {
    en: 'Tutorials',
    ar: 'دروس',
  };

  return (
    <div className="bg-white px-6 py-12 sm:py-16 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <Link
          className="text-base font-semibold leading-7 text-violet-600"
          href={TUTORIALS_PATH}
        >
          <Text text={headerTitle} />
        </Link>
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          <Text text={title} />
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          <Text text={description} />
        </p>
      </div>
    </div>
  );
}
