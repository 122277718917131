import {
  DateSelectionPopup,
  IDateSelectionPopupProps,
  TDays,
  TDaysUpdateValue,
} from '../../../../components/DateSelectionPopup/DateSelectionPopup';

/**
 * A list of sort days to show on the drop down select menu.
 */
export const bidDaysDropdown: TDays[] = [
  { id: 1, label: 'Last Bid Update', value: 1 },
  { id: 2, label: 'Older than 30 days', value: 30 },
  { id: 3, label: 'Older than 60 days', value: 60 },
  { id: 4, label: 'Older than 90 days', value: 90 },
  { id: 5, label: 'All', value: 0 },
];

/**
 * -----------------------------------------------------------------------------
 * Dropdown provides UX for choosing sort days from a list of days, rendering
 * the days we need to sort.
 */
export function BidCalendarPopup({ onSelectDays }: IDateSelectionPopupProps) {
  const handleDaySelectionFilterChange = (value: TDaysUpdateValue) => {
    onSelectDays?.(value);
  };

  return (
    <div className="mr-2 rtl:ml-2 rtl:mr-0">
      <DateSelectionPopup
        className="dropdown-button-bidscreen"
        days={bidDaysDropdown}
        onSelectDays={handleDaySelectionFilterChange}
      />
    </div>
  );
}
