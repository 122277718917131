import { motion, Transition, Variants } from 'framer-motion';

import { ToggleButtonWrapper } from '../../styles';

/**
 * `IPathProps` is a type definition for Path of the SVG element.
 */
interface IPathProps {
  /**
   * path data of the path to render. It draws the path to the passed
   * arguments
   */
  pathData?: string;
  /**
   * transition is optional prop to support the transition of path.
   */
  transition?: Transition;
  /**
   * All the possible variants to add the animation.
   */
  variants: Variants;
}

/**
 * -----------------------------------------------------------------------------
 * Adds line to the hamburger menu with animations. On closed the lines are
 * added horizontally and when open the two lines intersect to form a cross
 * Icon feel.
 *
 * @param { pathData, transition, variants }- d is the path data, transition
 * property adds transition to the path, variants are supported by
 * `framer motion` lib to add multiple variants of the animations.
 *
 * @returns JSX.Element
 */
function Path({ pathData, transition, variants }: IPathProps): JSX.Element {
  return (
    <motion.path
      d={pathData}
      fill="transparent"
      strokeLinecap="round"
      strokeWidth="2"
      transition={transition}
      variants={variants}
    />
  );
}

/**
 * `IMenuToggleProps` is type definition for `MenuToggle` with `TIsRTL`
 * optional props.
 */
interface IMenuToggleProps {
  /**
   * Toggle function switches the state of the hamburger menu to open and close.
   */
  toggleMenu: () => void;
  /**
   * Boolean which indicates the state of the toggle menu.
   */
  isOpen: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * Menu Toggle component is responsible for rendering the AppNavMenu hamburger
 * menu with the toggle property. It also uses framer motion library to toggle
 * with animations.
 *
 * @param IMenuToggleProps
 *
 * @returns JSX.Element
 */
export default function MenuToggle({
  toggleMenu,
  isOpen,
}: IMenuToggleProps): JSX.Element {
  return (
    <ToggleButtonWrapper
      aria-label={isOpen ? 'Close the Menu' : 'Open the Menu'}
      className={isOpen ? 'active' : ''}
      onClick={toggleMenu}
      type="button"
    >
      <svg height="28" viewBox="0 0 28 28" width="28">
        <Path
          variants={{
            closed: { d: 'M 2 2.5 L 20 2.5' },
            open: { d: 'M 3 16.5 L 17 2.5' },
          }}
        />
        <Path
          pathData="M 2 9.423 L 20 9.423"
          transition={{ duration: 0.1 }}
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
        />
        <Path
          variants={{
            closed: { d: 'M 2 16.346 L 20 16.346' },
            open: { d: 'M 3 2.5 L 17 16.346' },
          }}
        />
      </svg>
    </ToggleButtonWrapper>
  );
}
