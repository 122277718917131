import {
  IUploadedBidFilesPaginatedData,
  IGetSignedUrlPayload,
} from '@northladder/apis';

import { SectionWrapper } from '../../../../styles';
import UploadBidFilesListItem from './UploadBidFilesListItem';

interface IUploadBidsFilesListProps {
  /**
   * uploaded files list for mapping over the table from uploadBidFilesListPage.
   * this uploadBidFilesListPage is autogenerated from React query infinite
   */
  uploadBidFilesListPage?: IUploadedBidFilesPaginatedData[];
  onFileDownload: (payload: IGetSignedUrlPayload) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * @param IUploadBidsFilesListProps
 * @returns JSX.Element
 */
export function UploadBidsFilesList({
  uploadBidFilesListPage,
  onFileDownload,
}: IUploadBidsFilesListProps) {
  return (
    <SectionWrapper className="flex gap-2 p-4">
      <div className="w-full">
        <div
          className="
            -mx-4 overflow-x-auto overflow-y-hidden shadow-sm shadow-white/50
            ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg
          "
        >
          <table className="min-w-full divide-y divide-gray-100">
            <thead className="bg-purple-300">
              <tr>
                <th
                  className="text-xs font-medium text-gray-900 lg:table-cell"
                  scope="col"
                >
                  <div className="flex flex-row justify-start px-6">
                    <span>Uploaded Date</span>
                  </div>
                </th>
                <th
                  className="text-start text-xs font-medium text-gray-900 lg:table-cell"
                  scope="col"
                >
                  <div className="mr-5 flex flex-row justify-start">
                    <span>File Name</span>
                  </div>
                </th>

                <th
                  className="px-3 py-3.5 text-left text-xs font-medium text-gray-900 sm:table-cell"
                  scope="col"
                >
                  Status
                </th>
                <th
                  className="flex flex-row px-3 py-3.5 text-left text-xs font-medium text-gray-900"
                  scope="col"
                >
                  <span>Status updated at</span>
                </th>
                <th
                  className="px-3 py-3.5 text-left text-xs font-medium text-gray-900"
                  scope="col"
                >
                  Uploaded by
                </th>
                <th
                  className="relative py-3.5 pl-3 pr-4 text-center text-xs font-medium text-gray-900 sm:pr-6"
                  scope="col"
                >
                  Download
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-50 bg-white">
              {uploadBidFilesListPage && uploadBidFilesListPage.length > 0 ? (
                uploadBidFilesListPage.map(
                  ({ uploadedFiles }) =>
                    uploadedFiles.map((uploadedFileData) => (
                      <UploadBidFilesListItem
                        key={uploadedFileData?._id}
                        onUploadBidFileDownload={onFileDownload}
                        uploadedBidFileData={uploadedFileData}
                      />
                    ))
                  // eslint-disable-next-line function-paren-newline
                )
              ) : (
                <tr>
                  <td>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </SectionWrapper>
  );
}
