// TODO: Make this hook common
import { useState } from 'react';
import {
  getFormattedDate,
  getSubDate,
} from '@northladder/utilities/src/common/dateFunctions';

/**
 * It is summary filter type. this will only accept `count` or `value`
 */
export type TSummaryType = 'count' | 'value';

interface IUseTopStatsProps {
  initialSubDayCount: number;
}

/**
 * This hook used for change  `modelsFilterState` data and change from date
 * to date data
 *
 * @returns modelsFilterState, handleBrandFilter, today, subtractedDate, setSubDate
 */
export const useSummaryFilter = ({ initialSubDayCount }: IUseTopStatsProps) => {
  const [summaryType, setSummaryType] = useState<TSummaryType>('count');
  const now = new Date();
  const today = getFormattedDate(now);
  const [fromDay, setFromDay] = useState(getSubDate(initialSubDayCount));

  const handleSummaryTypeChange = (handleChange: TSummaryType) => {
    setSummaryType(handleChange);
  };

  const setSubDate = (date: number) => {
    setFromDay(getSubDate(date));
  };

  return {
    summaryType,
    handleSummaryTypeChange,
    today,
    fromDay,
    setSubDate,
  };
};
