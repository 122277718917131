import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { Text } from '@northladder/i18n';

import { TBaseLinkWithIcon } from '../../types';
import { IconLinkWrapper } from '../../styles';

import menuIcons from '../icons';

interface INavLinkWithIconProps extends TBaseLinkWithIcon {
  /**
   * Provides override to avoid tabbing through items when the menu is closed.
   */
  tabIndex: number;
}

/**
 * -----------------------------------------------------------------------------
 * This component renders a Navigation `Link` with an `Icon`.
 *
 * @param- ILinkWithIcon
 *
 * @returns JSX.Element
 */
export function NavLinkWithIcon({
  className,
  href,
  icon,
  isExpandable,
  name,
  tabIndex,
}: INavLinkWithIconProps): JSX.Element {
  const MenuIcon = menuIcons[icon];

  return (
    <IconLinkWrapper className={className} href={href} tabIndex={tabIndex}>
      <MenuIcon className="h-5 w-5" />
      <Text text={name} />

      {isExpandable ? (
        <ChevronRightIcon className="h-5 w-5 rtl:rotate-180" />
      ) : null}
    </IconLinkWrapper>
  );
}
