import { TBaseURLS, getRegionEnvironment } from '@northladder/utilities';
import { TRegionId, useAuthStore, useRegionStore } from '@northladder/services';

interface TGetRegionApiBaseURLOptions {
  apiBaseURL: TBaseURLS;
  requiresAuth: boolean;
  regionId?: TRegionId;
}

/**
 * Retrieves the region specific baseUrl based on the active session or selected
 * region.
 */
export const getRegionApiBaseURL = ({
  apiBaseURL,
  requiresAuth,
  regionId,
}: TGetRegionApiBaseURLOptions) => {
  const activeRegionId = useAuthStore.getState().activeSession?.regionId;
  const publicRegionId = useRegionStore.getState().region?.id;

  if (regionId) {
    const env = getRegionEnvironment(regionId);

    return env[apiBaseURL];
  }

  /**
   * If there is no active session with `region`, fallback to the generic region
   * (as selected from dropdown), or fallback to `uae` as the default.
   */
  const apiRegionId = (requiresAuth ? activeRegionId : publicRegionId) || 'uae';
  const env = getRegionEnvironment(apiRegionId);

  return env[apiBaseURL];
};
