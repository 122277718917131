import { DashboardMainWrapper } from '../../../../styles';

import { ISidebarOptions } from './types';

import { ProfilePage } from './ProfilePage';

interface IProfileMainContentProps extends ISidebarOptions {
  // Add more props to ProfilePage here.
}

/**
 * -----------------------------------------------------------------------------
 * This renders the main layout and content of the profile page.
 */
export function ProfileMainContent({
  navigationItems,
  secondaryNavigationItems,
}: IProfileMainContentProps) {
  return (
    <DashboardMainWrapper className="flex-col items-stretch !justify-start rounded-xl bg-white">
      <ProfilePage
        navigationItems={navigationItems}
        secondaryNavigationItems={secondaryNavigationItems}
      />
    </DashboardMainWrapper>
  );
}
