import { useQueryWithUserId } from '@northladder/services';

import { IApiError, TFetchTopBrandsPayload } from '../../../../types';

import { CUSTOMER_SERVICE_API } from '../customerServiceClient';

/**
 * This is to track the top brands react query cached data
 */
const TOP_BRANDS_KEY = 'TOP_BRANDS_QUERY';

/**
 * this hook will call top brands customer service api and manage the cached
 * data. and it return the loading, data, error etc.. states reactQuery
 */
export const useTopBrandsQuery = (payload: TFetchTopBrandsPayload) => {
  const { by, fromDate, toDate } = payload;
  const { data, isError, error, isLoading, refetch } = useQueryWithUserId({
    queryKey: [TOP_BRANDS_KEY, by, fromDate, toDate],
    queryFn: () => CUSTOMER_SERVICE_API.fetchTopBrands(payload),
    onSuccess: (response) => response,
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isError,
    data,
    isLoading,
    refetch,
  };
};
