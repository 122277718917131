import dynamic from 'next/dynamic';

/**
 * This dynamically imports `RegistrationStatusForm` content at the lazily when required.
 * This helps prevent nextjs hydration errors.
 */
const DynamicFloatingWhatsApp = dynamic(() => import('./FloatingWhatsApp'), {
  ssr: false,
});

export default DynamicFloatingWhatsApp;
