import { useEffect, useState, useRef, useCallback } from 'react';

import {
  useBidsCategoryQuery,
  useUploadBidFilesQuery,
  useGetSignedUrlMutation,
  IGetSingedUrlResponse,
} from '@northladder/apis';
import { ICategoryItem } from '@northladder/services';
import { TMenuItem } from '@northladder/design-system';

export const useUploadBidsContentData = () => {
  const nextPageObserverRef = useRef(null);
  const [categoryOptions, setCategoryOptions] = useState<TMenuItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<TMenuItem | null>(
    null
  );

  const {
    data: bidFilterCategoryData,
    isLoading: isLoadingCategoryData,
    error: categoryError,
  } = useBidsCategoryQuery();

  const {
    data: bidSearchResultData,
    refetch: callUploadBidFilesQuery,
    fetchNextPage,
    hasNextPage: bidSearchResultHasNextPage,
    isFetchingNextPage,
    error: bidSearchResultError,
    isError: isBidSearchResultError,
  } = useUploadBidFilesQuery({
    payload: {
      page: 1, // initial page count
      count: 10,
      categoryId: selectedCategory?.id.toString() || '',
    },
  });

  const onGetSignedUrl = (signedUrlResponse: IGetSingedUrlResponse) => {
    if (signedUrlResponse) {
      const href = signedUrlResponse.url;

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      const filename = `UploadedBids_${new Date().toJSON().slice(0, 10)}.xlsx`;
      link.setAttribute('download', filename); // or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
    }
  };

  const { mutate: getSignedUrl } = useGetSignedUrlMutation({
    onSuccessCallback: onGetSignedUrl,
  });

  const handleObserver = useCallback(
    (entries: any[]) => {
      const [target] = entries;
      if (target.isIntersecting && bidSearchResultHasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, bidSearchResultHasNextPage]
  );

  useEffect(() => {
    const element = nextPageObserverRef.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [fetchNextPage, bidSearchResultHasNextPage, handleObserver]);

  const onCategoryOptionSelect = (item: TMenuItem) => {
    setSelectedCategory(item);
  };

  useEffect(() => {
    if (selectedCategory) {
      callUploadBidFilesQuery();
    }
  }, [callUploadBidFilesQuery, selectedCategory]);

  useEffect(() => {
    if (bidFilterCategoryData?.categoryList) {
      const categoryItems = bidFilterCategoryData.categoryList.map(
        (category: ICategoryItem) => ({
          id: category.id,
          title: category.categoryName,
        })
      );
      setCategoryOptions(categoryItems);
      setSelectedCategory(categoryItems[0]);
    }
  }, [bidFilterCategoryData]);

  const handleOnRefreshList = () => {
    callUploadBidFilesQuery();
  };

  return {
    nextPageObserverRef,
    selectedCategory,
    bidSearchResultHasNextPage,
    categoryOptions,
    isLoadingCategoryData,
    categoryError,
    bidSearchResultData,
    isFetchingNextPage,
    bidSearchResultError,
    isBidSearchResultError,
    categoryListData: bidFilterCategoryData?.categoryList,
    callUploadBidFilesQuery,
    onCategoryOptionSelect,
    onRefreshList: handleOnRefreshList,
    getSignedUrl,
  };
};
