import { useQueryWithUserId } from '@northladder/services';

import { IApiError, IFetchRankReportPayload } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';

const FETCH_RANK_REPORT_KEY = 'FETCH_RANK_REPORT';

/**
 * ----------------------------------------------------------------------------\
 * React Query hook for bid summary rank api management it will call
 *`fetchRankReports` api and give error loading success state with response
 *
 * @returns error,isError,bidsHistoryData,isLoading,refetch,
 */
export const useFetchRankReportQuery = (payload?: IFetchRankReportPayload) => {
  let localPayload = { ...payload };

  if (!payload?.categoryId) {
    const { categoryId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload?.brandId) {
    const { brandId, ...rest } = localPayload;
    localPayload = rest;
  }

  if (!payload?.groupIds) {
    const { groupIds, ...rest } = localPayload;
    localPayload = rest;
  }

  const { data, isError, error, isLoading, refetch } = useQueryWithUserId({
    queryKey: [
      FETCH_RANK_REPORT_KEY,
      localPayload?.categoryId,
      localPayload?.brandId,
      localPayload?.groupIds,
    ],
    queryFn: () => BIDS_API.fetchRankReports(localPayload),
    onSuccess: (response) => response,
    onError: (err: IApiError) => err,
  });

  return {
    error,
    isError,
    data,
    isLoading,
    refetch,
  };
};
