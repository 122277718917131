export const translations = {
  sessionTimeoutError: {
    description: {
      en: 'Hello. It looks like you have been idle for some time. You need to login again.',
      ar: 'مرحبًا. يبدو أنك كنت خاملاً لبعض الوقت. تحتاج إلى تسجيل الدخول مرة أخرى.',
    },
    message: {
      en: 'Sorry! The session expired',
      ar: 'آسف! انتهت الجلسة',
    },
  },
  errorResponse: {
    message: {
      en: 'Something went wrong while fetching your request',
      ar: 'حدث خطأ ما أثناء جلب طلبك',
    },
    description: {
      en: 'If this persists, please try again later or contact support',
      ar: 'إذا استمر هذا ، يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بالدعم',
    },
    status400: {
      message: {
        en: 'Something went wrong!',
        ar: 'هناك خطأ ما!',
      },
      description: {
        en: 'Please double check your actions or retry.',
        ar: 'يرجى التحقق مرة أخرى من أفعالك أو إعادة المحاولة.',
      },
    },
    status404: {
      message: {
        en: 'Sorry, we could not process your request',
        ar: 'عذرا ، لم نتمكن من معالجة طلبك',
      },
      description: {
        en: 'Please try again later or contact support. Thank you.',
        ar: 'يرجى المحاولة مرة أخرى في وقت لاحق أو الاتصال بالدعم. شكرًا لك.',
      },
    },
    errNetwork: {
      message: {
        en: 'Sorry! No internet detected',
        ar: 'آسف! لم يتم الكشف عن الإنترنت',
      },
      description: {
        en: 'Please check your internet connection and retry.',
        ar: 'يرجى التحقق من اتصالك بالإنترنت وإعادة المحاولة.',
      },
    },
    econnAborted: {
      message: {
        en: 'Oops! This has taken longer than usual! Our bad!',
        ar: 'أُووبس! لقد استغرق هذا وقتا أطول من المعتاد! سيئنا!',
      },
      description: {
        en: 'Might be a slow connection. Please try again or retry later.',
        ar: '.قد يكون الاتصال بطيئا. يرجى المحاولة مرة أخرى أو المحاولة لاحقًا',
      },
    },
  },
};
