import { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';

import { IBidQueryPaasDataPage } from '@northladder/apis';

import { SectionWrapper } from '../../../../styles';

import BidResultListItemPaas from './BidResultListItemPaas';

/**
 * BidFilterArrow component props
 */
interface IBidFilterArrowProps {
  isAscending: boolean;
}

/**
 * BidFilterArrow is a icon showing component for key sort it have `isAscending`
 * argument for checking the keys
 *
 * @param IBidFilterArrowProps
 *
 * @returns JSX.Element
 */
function BidFilterArrow({ isAscending }: IBidFilterArrowProps) {
  if (isAscending) {
    return <ArrowUpIcon className="mt-[0.2rem] h-3 w-5" />;
  }

  return <ArrowDownIcon className="mt-[0.2rem] h-3 w-5" />;
}

interface IBidsResultsListPaasProps {
  /**
   * Asset list for mapping over the table from bidListPage.
   * this bidListPage is autogenerated from React query infinite
   */
  bidListPage?: IBidQueryPaasDataPage[];
  onSortChange: (sort: string) => void;
}

/**
 * TKey is the column header key which is dynamic now.
 */
type TKey = string;

interface IKeyFilterToggle {
  key?: TKey;
  isAscending: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This contains the list of all the search drop down filters, like device, brands
 * models to be displayed at the top of the bids screen.
 *
 * TODO: Refactor a lot of the components here to re-use the previous components.
 *
 * @param IBidsResultsListPaasProps
 * @returns JSX.Element
 */
export function BidsResultsListPaas({
  bidListPage,
  onSortChange,
}: IBidsResultsListPaasProps) {
  const [keyFilter, setKeyFilter] = useState<IKeyFilterToggle>({
    isAscending: false,
  });

  const getIsAscending = (keyName: string): boolean =>
    !!(keyFilter.key === keyName && keyFilter.isAscending);

  const updateFilterKey = (key: TKey) => {
    onSortChange(key);
    setKeyFilter({
      key,
      isAscending: keyFilter.key === key ? !getIsAscending(key) : false,
    });
  };

  return (
    <SectionWrapper className="flex gap-2 p-4">
      <div className="w-full">
        <div
          className="
            -mx-4 overflow-x-auto overflow-y-hidden shadow-sm shadow-white/50
            ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg
          "
        >
          <table className="min-w-full divide-y divide-gray-100">
            <thead className="bg-purple-300">
              <tr>
                <th
                  className="flex flex-row py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-900 sm:pl-6"
                  scope="col"
                >
                  <span>Asset Model</span>
                </th>
                {bidListPage &&
                  bidListPage.length > 0 &&
                  bidListPage[0].assets &&
                  bidListPage[0].assets.length > 0 &&
                  bidListPage[0].assets[0].itemCondition &&
                  bidListPage[0].assets[0].itemCondition &&
                  bidListPage[0].assets[0].itemCondition.length > 0 &&
                  bidListPage[0].assets[0].itemCondition.map((condition) => (
                    <th
                      key={condition}
                      className="text-center text-xs font-medium text-gray-900 lg:table-cell"
                      onClick={() => {
                        updateFilterKey(condition);
                      }}
                      scope="col"
                    >
                      <div className="flex flex-row justify-center">
                        <span>{condition}</span>
                        <BidFilterArrow
                          isAscending={getIsAscending(condition)}
                        />
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-50 bg-white">
              {bidListPage && bidListPage.length > 0 ? (
                bidListPage.map(
                  ({ assets }) =>
                    assets.map((asset) => (
                      <BidResultListItemPaas
                        key={`${asset.assetId}${asset.latestBibUpdatedDate}`}
                        asset={asset}
                      />
                    ))
                  // eslint-disable-next-line function-paren-newline
                )
              ) : (
                <tr>
                  <td>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </SectionWrapper>
  );
}
