import dynamic from 'next/dynamic';

/**
 * This dynamically imports `RegistrationFormStep2` content at the lazily when required.
 * This helps prevent nextjs hydration errors.
 */
const DynamicRegistrationFormStep2 = dynamic(
  () => import('./RegistrationStep2Form'),
  {
    ssr: false,
  }
);

export default DynamicRegistrationFormStep2;
