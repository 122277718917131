import { useRouter } from 'next/router';
import { motion, Variants } from 'framer-motion';

import { TMenuItem } from '../../types';

import { NavLinkWithIcon } from './NavLinkWithIcon';

/**
 * The variants open and closed add animation to the li element. When the
 * hamburger menu is in open state the li element will show. In case of closed
 * the li element will hide. In addition to that when closing the menu the
 * li will go down 50px and hide with animation and vice-versa.
 */
const variants: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

interface IMenuItemProps extends TMenuItem {
  /**
   * The accountId from the path params
   */
  accountId: number;
  /**
   * Provides override to avoid tabbing through items when the menu is closed.
   */
  tabIndex: number;
}

/**
 * -----------------------------------------------------------------------------
 *  Menu Item component leverages the framer motion library for animations
 *  and returns the li with the icon and link of the navigation item.
 *
 * @param IMenuItemProps
 *
 * @returns JSX.Element
 */
export default function MenuItem({
  accountId,
  icon,
  isExpandable,
  name,
  path,
  tabIndex,
}: IMenuItemProps): JSX.Element {
  const { pathname } = useRouter();

  const isActiveLink = (): boolean => {
    if (path === '/' && pathname !== '/') {
      // If, the pathname is not exactly '/', deselect home
      return false;
    }

    return pathname === path || pathname.includes(path);
  };

  const navPathWithSession = `/${accountId}${path}`;

  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <NavLinkWithIcon
        className={isActiveLink() ? 'active' : ''}
        href={navPathWithSession}
        icon={icon}
        isExpandable={isExpandable}
        name={name}
        tabIndex={tabIndex}
      />
    </motion.li>
  );
}
