import { AppraisalDetails } from './AppraisalDetails';

/**
 * -----------------------------------------------------------------------------
 * This renders the main profile page content.
 */
export function AppraisalDetailsView() {
  return (
    <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
      <div className="relative z-0 flex flex-1 overflow-hidden">
        <main
          className="
            relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last
          "
        >
          <article className="pb-20">
            <AppraisalDetails />
          </article>
        </main>
      </div>
    </div>
  );
}
