import { IUploadedBidFileData, IGetSignedUrlPayload } from '@northladder/apis';
import {
  ActionBarButton,
  IconWarning,
  Tooltip,
} from '@northladder/design-system';
import { format } from '@northladder/utilities';

/**
 * Upload bids map over uploaded bid files data
 * on to be displayed in the components below.
 */
export interface IUploadBidFilesListItem {
  uploadedBidFileData: IUploadedBidFileData;
  onUploadBidFileDownload: (payload: IGetSignedUrlPayload) => void;
}

const fileStatus = {
  PENDING: 'Pending',
  IGNORED: 'Ignored',
  PROCESSED: 'Processed',
};

/**
 * -----------------------------------------------------------------------------
 * @todo: TODO: @Juned can you describe what this does?
 */
export default function UploadBidFilesListItem({
  uploadedBidFileData,
  onUploadBidFileDownload,
}: IUploadBidFilesListItem) {
  const {
    fileLocation,
    fileName,
    status,
    validationStatus,
    createdDate,
    statusUpdatedAt,
    uploadedBy,
  } = uploadedBidFileData;

  const dateCreatedAt = format(createdDate, 'dd MMM yyyy HH:mm:ss');
  const dateStatusUpdatedAt = format(statusUpdatedAt, 'dd MMM yyyy HH:mm:ss');

  const uploadStatusClass =
    status === fileStatus.PROCESSED ? 'text-green-400' : 'text-gray-500';
  const showPointClass = validationStatus ? '' : 'cursor-pointer';

  const validationMsg = !validationStatus
    ? 'Please check your email for detailed report of issues with the file'
    : '';

  const handleBidFileDownload = () => {
    onUploadBidFileDownload({ fileName: fileLocation });
  };

  return (
    <tr
      className="group transition-all delay-100 duration-200 ease-in-out
        even:bg-purple-50/40 hover:bg-purple-100/50"
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        {dateCreatedAt}
      </td>
      <td className="py-4 text-sm text-gray-500 lg:table-cell">{fileName}</td>
      <td
        className={`
          flex flex-row items-center gap-2 px-3 py-4 text-sm
          ${uploadStatusClass} ${showPointClass}
        `}
      >
        <Tooltip message={validationMsg}>
          {status}
          {!validationStatus && <IconWarning className="h-4 w-4" />}
        </Tooltip>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {status === fileStatus.PENDING ? '---' : dateStatusUpdatedAt}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">{uploadedBy}</td>
      <td className="flex justify-center py-4 text-center text-sm font-medium">
        <ActionBarButton
          className="no-border-button"
          icon="iconArrowDown"
          iconClassName="h-4 w-4"
          label=""
          onClick={handleBidFileDownload}
        />
      </td>
    </tr>
  );
}
