import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconFileUpload component is svg for file upload icon which is customizable with some
 * default values configured. It is mainly used in Menu Items for navigation
 * but also can be used elsewhere.
 *
 * @param TSvgComponentProps Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
function IconFileUpload(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2497_41539)">
        <path
          d="M20 10.6899V5.68994C20 4.57994 19.1 3.68994 18 3.68994H4.98999C3.87999 3.68994 2.98999 4.57994 2.98999 5.68994V19.6899C2.98999 20.7899 3.87999 21.6799 4.98999 21.6799H9.98999"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M10 13.6899H15"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M7.11997 13.8199H7.10997C7.16997 13.8199 7.22997 13.7599 7.22997 13.6899C7.22997 13.6199 7.16997 13.5599 7.09997 13.5599C7.02997 13.5499 6.96997 13.6099 6.96997 13.6799C6.96997 13.7399 7.01997 13.7999 7.08997 13.7999"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M10 8.65991H15"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M7.11997 8.77989H7.10997C7.16997 8.77989 7.22997 8.71989 7.22997 8.64989C7.22997 8.57989 7.16997 8.51989 7.09997 8.51989C7.02997 8.50989 6.96997 8.56989 6.96997 8.63989C6.96997 8.69989 7.01997 8.75989 7.08997 8.75989"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M17.4301 16.8101L19.4601 18.8401"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M15.52 18.7201L17.43 16.8101"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M17.4301 16.8101V22.6701"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2497_41539">
          <rect
            fill="white"
            height="24"
            transform="translate(0 0.689941)"
            width="24"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconFileUpload;
