import { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { IAssetRaw } from '@northladder/apis';
import { IAsset, IMyBid, TLocale } from '@northladder/services';
import { convertToHumanReadableTimestamp } from '@northladder/utilities';
import { BidRankBadge, Tooltip, TRankIcon } from '@northladder/design-system';

import { ColumnDeviceDetails } from '../../../../components';

import { useSessionInstance } from '../../../user-accounts';

import {
  BidPriceTextFieldColumn,
  BidQuantityDropdown,
  BidItemOptions,
} from '../../components';

import { ToggleBidAction } from './ToggleBidAction';

type TDeviceBrands = {
  [brandId: string]: {
    id: string;
    iconId: string;
    imageUrl: string;
  };
};

/**
 * TODO: remove these hardcoded configs.
 */
const deviceBrands: TDeviceBrands = {
  '5bf02c2c0d6fb901c0c66153': {
    id: 'apple',
    iconId: 'APPLE',
    imageUrl: 'https://prod-cdn.ksa.northladder.com/brand/1613997248411.jpeg',
  },
  '5c09241db200ef01b7a3a4c2': {
    id: 'samsung',
    iconId: 'SAMSUNG',
    imageUrl: 'https://prod-cdn.ksa.northladder.com/brand/1613997284865.jpeg',
  },
};

/**
 * Asset maps over fields like device name, spotPrice, isBidActive and so
 * on to be displayed in the components below.
 */
export interface IBidResultListItem {
  asset: IAsset | IAssetRaw;
}

export default function BidResultListItem({ asset }: IBidResultListItem) {
  const locale = useRouter().locale as TLocale;

  const {
    assetId,
    assetName: assetNameLocalized,
    brandId,
    gapToFirstRank,
  } = asset || {};
  const myBid = (asset?.myBid as IMyBid) || {};
  let assetName = '';
  if (typeof assetNameLocalized === 'string') {
    assetName = assetNameLocalized;
  } else {
    assetName = assetNameLocalized[locale];
  }

  const {
    spotPrice,
    maxQuantity,
    updatedDate,
    rank,
    isSpotSummit,
    availableQuantity,
    isBidActive,
  } = myBid;

  const [localBidActive, setLocalBidActive] = useState(isBidActive || false);
  const topBidSpotPrice = asset.topBidAmount;

  const calculateRank = (): TRankIcon | null => {
    let iconRank: TRankIcon | null = null;

    if (!rank) return null;

    if (isSpotSummit) {
      if (rank === 1) {
        iconRank = 'firstRank';
      } else if (rank < 1) {
        iconRank = 'winningRange';
      }
    }
    return iconRank;
  };

  const updateLocalBidActive = (value: boolean) => {
    setLocalBidActive(value);
  };

  const bidTimeStamp = convertToHumanReadableTimestamp(updatedDate);
  const imageUrl = deviceBrands[asset?.brandId]?.imageUrl;

  // currencyType
  const { activeSession } = useSessionInstance();
  const { currency } = activeSession?.user || {};
  return (
    <tr
      className="group transition-all delay-100 duration-200 ease-in-out
        even:bg-purple-50/40 hover:bg-purple-100/50"
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            {imageUrl ? (
              <Image
                alt={`logo-${assetName.toString}`}
                className="h-10 w-10 rounded-full"
                height={24}
                src={deviceBrands[brandId]?.imageUrl}
                width={24}
              />
            ) : null}
          </div>
          <ColumnDeviceDetails
            description={bidTimeStamp}
            isHotDeal={false}
            title={assetName}
          />
        </div>
      </td>

      <td
        className="
          flex w-full max-w-0 flex-row items-end justify-end
            py-8 pl-4 pr-3 text-sm font-medium
          text-gray-900 sm:w-auto sm:max-w-none sm:pl-6
        "
      >
        <Tooltip message="First Rank bid">
          <BidRankBadge icon={calculateRank} />
        </Tooltip>
        {spotPrice}
        <Tooltip message="Option need to add">
          <ExclamationCircleIcon
            aria-hidden="true"
            className="mx-1 mb-1 h-4 w-4"
          />
        </Tooltip>
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Title</dt>
          <dd className="mt-1 truncate text-gray-700">{spotPrice}</dd>
          <dt className="sr-only sm:hidden">lastBidDate</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {updatedDate}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
        <BidPriceTextFieldColumn
          assetId={assetId}
          currency={currency || 'AED'}
          localBidActive={localBidActive}
          myBid={myBid}
        />
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <BidQuantityDropdown
          asset={asset}
          defaultTitle={maxQuantity?.toString() ?? '0'}
        />
        <p className="text-xs">{`My Quantity (${availableQuantity || 0})`}</p>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {topBidSpotPrice}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">{gapToFirstRank}</td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <ToggleBidAction
          assetId={assetId}
          myBid={myBid}
          onChangeBidActive={updateLocalBidActive}
        />
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <BidItemOptions assetId={assetId} assetName={assetName} />
      </td>
    </tr>
  );
}
