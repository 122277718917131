import { useState } from 'react';

import { useSessionInstance } from '../../hooks';

import { ISidebarOptions } from './types';

import { PageView } from './PageView';
import { SidebarDesktop } from './SidebarDesktop';
import { SidebarMobile } from './SidebarMobile';

interface IProfilePageProps extends ISidebarOptions {
  // Add more props to ProfilePage here.
}

/**
 * -----------------------------------------------------------------------------
 * This structures the different sidebars (web & mobile) and page view components
 * for the profile page like screens, such as profile & settings.
 */
export function ProfilePage({
  navigationItems,
  secondaryNavigationItems,
}: IProfilePageProps) {
  const { activeSession } = useSessionInstance();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (!activeSession) {
    return null;
  }

  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className="flex h-full">
      <SidebarMobile
        isSidebarOpen={sidebarOpen}
        navigationItems={navigationItems}
        onCloseSidebar={handleCloseSidebar}
        secondaryNavigationItems={secondaryNavigationItems}
      />
      <SidebarDesktop
        navigationItems={navigationItems}
        secondaryNavigationItems={secondaryNavigationItems}
      />
      <PageView onOpenSideBar={handleOpenSidebar} />
    </div>
  );
}
