import { useQueryWithUserId } from '@northladder/services';

import { IApiError, TFetchBidHistoryParams } from '../../../../types';

import { BIDS_API } from '../bidsAPIClient';

const BIDS_HISTORY_KEY = 'bids-history-key';

/**
 * ----------------------------------------------------------------------------\
 * React Query hook for bid item history api management it will call
 * `fetchBidHistory` api give error loading success state with response
 *
 * @returns error,isError,bidsHistoryData,isLoading,refetch,
 */
export const useBidHistoryQuery = (queryParam: TFetchBidHistoryParams) => {
  const {
    data: bidsHistoryData,
    isError,
    error,
    isLoading,
    refetch,
  } = useQueryWithUserId({
    queryKey: [BIDS_HISTORY_KEY, queryParam.assetId],
    queryFn: () => BIDS_API.fetchBidHistory(queryParam),
    onSuccess: (response) => response,
    onError: (err: IApiError) => err,
    enabled: true,
  });

  return {
    error,
    isError,
    bidsHistoryData,
    isLoading,
    refetch,
  };
};
