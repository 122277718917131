import {
  IDealDetailsResponseDataRaw,
  IDealDetailsResponseData,
} from '../../types';
import { formatGradeReviewRequestDetailsResponse } from './formatGradeReviewRequestDetailsResponse';

/**
 * This transforms the `raw deal details API response` to the format that
 * the UI expects.
 */
export function formatDealDetailsResponse(
  response: IDealDetailsResponseDataRaw
): IDealDetailsResponseData {
  const {
    appraisalData,
    assetInfo,
    brandName,
    outRightSaleInfo,
    gradeReviewRequestData,
  } = response || {};

  const {
    appraisalCode,
    approvedDeal,
    awbNumber: shipmentNumber,
    awbTrackingURL: shipmentTrackingUrl,
    courierStatus,
    dealTS: dealDate,
    deliveryDate,
    displayStatus: statusLabels,
    invoices,
    isDelivered,
    isReturned,
    paymentType,
    serialNumber: imeiNumber,
    totalAmount,
  } = appraisalData || {};

  const { outRightsale } = outRightSaleInfo || {};
  const purchasePrices =
    outRightsale?.map((item) => {
      const { label, label_ar: labelAr, value } = item || {};
      return {
        label: {
          en: label,
          ar: labelAr,
        },
        value,
      };
    }) || [];

  const { assetPrice: myBidOnDealDate, assetCondition } = approvedDeal || {};
  // TODO: Add arabic name later as iraq team needs english name for now.
  // const { label_ar: labelAr, label, attributes } = assetInfo || {};
  const { label, attributes } = assetInfo || {};

  const {
    condition: mobileGrade,
    percentValue,
    dealerPercentValue,
  } = assetCondition?.memberCondition || {};

  const gradeReviewList = gradeReviewRequestData?.map((item) =>
    formatGradeReviewRequestDetailsResponse(item)
  );

  return {
    appraisalCode,
    assetName: {
      en: label,
      // ar: labelAr || label,
      ar: label,
    },
    attributes,
    brandName,
    courierStatus,
    dealDate,
    deliveryDate,
    gradeReviewRequestData: gradeReviewList || [],
    imeiNumber,
    invoices,
    isDelivered,
    isReturned,
    mobileGrade,
    myBidOnDealDate,
    paymentType,
    purchasePrices,
    shipmentNumber,
    shipmentTrackingUrl,
    statusLabels,
    totalAmount,
    // isInTransit,
    // isPaid,
    itemDetailGrade: dealerPercentValue || percentValue,
  };
}
