import { useEffect, useRef, RefObject } from 'react';

/**
 * Type definition for the use dimension hook
 */
interface UseDimensionsReturnType {
  /**
   * width of the element.
   */
  width: number;
  /**
   * height of the element.
   */
  height: number;
}

/**
 * Calculates the dimensions of an element and return it on change.
 *
 * @param ref- reference of the element you want to calculate dimension of.
 *
 * @returns UseDimensionsReturnType
 */
const useDimensions = (
  ref: RefObject<HTMLElement>
): UseDimensionsReturnType => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    if (ref.current) {
      dimensions.current.width = ref.current.offsetWidth;
      dimensions.current.height = ref.current.offsetHeight;
    }
  }, [ref]);

  return dimensions.current;
};

export default useDimensions;
