import { IApiError, TSummary } from '@northladder/apis';
import { Text } from '@northladder/i18n';
import { CircularProgressLoader } from '@northladder/design-system';

import { DateSelectionPopup, TDaysUpdateValue } from '../../DateSelectionPopup';

import {
  StatsSectionTile,
  IStatsSectionTileItem,
} from '../StatsSectionTile/StatsSectionTile';

import { OptionButton } from './styles';
import { TSummaryType } from './useSummaryFilter';

interface IStatsSummaryListProps {
  /**
   * List of summary data(Summary Of transaction and bids).
   * List object contains label and its value (eg: Deals won 50 etc...)
   */
  items: IStatsSectionTileItem[];
  /**
   * Callback supplied to the parent component to provide summary filter.
   */
  onSummaryTypeFilter: (selectedFilter: TSummaryType) => void;
  /**
   * Callback supplied to the parent component to provide the selected days.
   */
  onSelectDays: (selectedDays: TDaysUpdateValue) => void;
  /**
   * For showing item values.this values are coming from inventory summary api
   */
  summaryValues?: TSummary;
  /**
   * Api loading state
   */
  isLoading: boolean;
  /**
   * Api error state
   */
  isError: boolean;
  /**
   * Api error type with messages, status code etc...
   */
  error?: IApiError | null;
}

/**
 * -----------------------------------------------------------------------------
 * This component renders a summery item list. It will accept Items List.
 *
 * @param IStatsSummaryListProps
 *
 * @return JSX.Element
 */
export function StatsSummaryList({
  items,
  summaryValues,
  isLoading,
  isError,
  error,
  onSelectDays,
  onSummaryTypeFilter,
}: IStatsSummaryListProps) {
  const handleDaySelectionChange = (selectedDate: TDaysUpdateValue) => {
    onSelectDays(selectedDate);
  };
  return (
    <div
      className="
          m-8XX w-3/6XX inline-block w-full flex-col rounded-md bg-white px-6 pb-3 pt-6
          drop-shadow-sm
        "
    >
      {isLoading ? <CircularProgressLoader strokecolor="#020203" /> : null}
      {isError && error ? (
        <Text className="text-red-800" text={error.message} />
      ) : null}
      <h4 className="mb-2 text-lg font-semibold">Summary</h4>
      <div className="flex-row lg:flex xl:flex 2xl:flex">
        <OptionButton
          className="
              mr-3 bg-purple-600 text-white hover:bg-purple-800
              dark:bg-purple-600 dark:hover:bg-purple-700
            "
          onClick={() => onSummaryTypeFilter('count')}
          type="button"
        >
          Quantity
        </OptionButton>
        <OptionButton
          className="
              border border-gray-200 text-center text-gray-500
              focus:ring-purple-300 dark:focus:ring-purple-800
            "
          onClick={() => onSummaryTypeFilter('value')}
          type="button"
        >
          Value
        </OptionButton>
        <div className="flex flex-1" />
        <DateSelectionPopup
          className="dropdown-button"
          onSelectDays={handleDaySelectionChange}
        />
      </div>

      <div className="gap-y-px-3 grid grid-cols-2 items-start gap-2 self-start">
        {items.map(({ title, valueKey }) => {
          const value = summaryValues ? summaryValues[valueKey] : 0;
          return <StatsSectionTile key={title} title={title} value={value} />;
        })}
      </div>
    </div>
  );
}
