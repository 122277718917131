import { useQueryWithUserId } from '@northladder/services';

import { IApiError } from '../../../../types';

import { USER_REGISTRATION_API } from '../userRegistrationAPIClient';

/**
 * This is to track the users registration details from he backend.
 */
const REGISTRATION_DETAILS_KEY = 'registration-details-key';

/**
 * This hook fetches a list of device dimensions details and tokens and urls to
 * which images would later be uploaded.
 */
export const useRegistrationDetailsQuery = (token: string) => {
  const { data, isError, error, isLoading, isFetching, refetch } =
    useQueryWithUserId({
      queryKey: [REGISTRATION_DETAILS_KEY, token],
      queryFn: () => USER_REGISTRATION_API.fetchUserRegistrationDetails(token),
      onSuccess: (response) => response,
      onError: (err: IApiError) => err,
      retry: false,
      enabled: !!token,
      refetchOnWindowFocus: false,
    });

  return {
    error,
    isError,
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
