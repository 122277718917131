import { IDealsListResponseData, IDealsListResponseRawData } from '../../types';

/**
 * This transforms the `raw deal list API response` to the format that
 * the UI expects.
 */
export function formatDealsListResponse(
  response: IDealsListResponseRawData
): IDealsListResponseData {
  const {
    count,
    appraisals: allAppraisals,
    filterOptions: rawFilterOptions,
  } = response;
  const appraisals = allAppraisals.map((appraisal) => {
    const {
      appraisalCode,
      approvedDeal,
      assetInfo,
      courierStatus,
      dealTS: dealDate,
      deliveryDate,
      displayStatus,
      isDelivered,
      isSellAsset,
      sellAmount: sellAmountObject,
    } = appraisal;

    const approvedAmount = approvedDeal?.approvedAmount;
    const sellAmount = sellAmountObject?.sellAmount;
    // TODO: Add arabic name later as iraq team needs english name for now.
    // const { label, label_ar: labelAr, value } = filters;
    const { label } = assetInfo;
    const purchasePrice = isSellAsset ? sellAmount : approvedAmount;

    return {
      appraisalCode,
      assetName: {
        en: label,
        ar: label,
      },
      courierStatus,
      dealDate,
      deliveryDate,
      displayStatus,
      isDelivered,
      purchasePrice,
    };
  });

  const filterOptions = rawFilterOptions?.map((filters) => {
    // TODO: Add arabic name later as iraq team needs english name for now.
    // const { label, label_ar: labelAr, value } = filters;
    const { label, value } = filters;

    return {
      label: {
        en: label,
        // ar: labelAr || label,
        ar: label,
      },
      value,
    };
  });

  return {
    appraisals,
    count,
    filterOptions,
  };
}
