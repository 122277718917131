interface IRadioButtonProps {
  /**
   * Expects `tailwind` css classes to customize the `svg`.
   */
  className: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a svg radio button icon.
 */
function IconRadioButton({ className }: IRadioButtonProps) {
  return (
    <div>
      <svg
        className={className}
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a5 5 0 1 1 0-10 5 5 0 0 1 0 10z" />
      </svg>
    </div>
  );
}

export default IconRadioButton;
